<template>
  <Layout :confirmation="true" @on-confirm="passValue($event)">
    <div>
      <InertiaHead :title="`${lead ? 'Edit' : 'Create'} lead`" />
      <RecordSingle
        :consumer-types="consumerTypes"
        :dealers="dealers"
        :dealerships="dealerships"
        :dist-members="distMembers"
        :distributors="distributors"
        :errors="$attrs.errors"
        :extensions="extensions"
        :marketing-types="marketingTypes"
        :on-confirm="confirmSignal"
        :print-data="formatOrderPrintData(lead)"
        :products="products"
        :record="lead"
        :statuses="statuses"
        entity="lead"
      />
    </div>
  </Layout>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Layout from 'Shared/LeadsAndOrders/LayoutWide'
import RecordSingle from 'Shared/LeadsAndOrders/RecordSingle'
import {formatOrderPrintData} from 'Shared/helpers'

export default {
  components: {
    InertiaHead,
    RecordSingle,
    Layout
  },
  props: {
    consumerTypes: Array,
    dealers: Array,
    dealerships: Array,
    distMembers: Array,
    distributors: Array,
    extensions: Array,
    lead: Object,
    marketingTypes: Array,
    products: Array,
    statuses: Array
  },
  data () {
    return {
      confirmSignal: null
    }
  },
  methods:{
    formatOrderPrintData,
    passValue(e){
      this.confirmSignal = e
    }
  }
}
</script>
