<template>
  <div>
    <InertiaHead :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <div v-if="user" class="border-b-2 border-gray-200 px-4 sm:px-6 lg:px-8">
        <Tabs :tabs="tabs" />
      </div>

      <div class="divide-y-2 divide-gray-200">
        <slot />
      </div>
    </Panel>
  </div>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import Tabs from 'Shared/Navigation/Tabs'

export default {
  components: {
    InertiaHead,
    Panel,
    Tabs
  },
  layout: Layout,
  props: {
    title: String,
    user: Object,
    auth: Object
  },
  data() {
    return {
      tabs: [
        {
          label: 'General',
          route: this.user ? this.$route('users.edit', this.user.uuid) : null,
          current: ['users.edit']
        },
        {
          label: 'Notifications',
          route: this.user ? this.$route('users.notifications.edit', this.user.uuid) : null,
          current: ['users.notifications.edit']
        },
        {
          label: 'Documents',
          route: this.user ? this.$route('users.documents.index', this.user.uuid) : null,
          current: ['users.documents.index', 'users.documents.show']
        },
        ...(['organization_manager', 'organization_employee'].includes(this.user?.role?.name) ?
          [{
            label: 'Integrations',
            route: this.user ? this.$route('users.integrations.index', this.user.uuid) : null,
            current: ['users.integrations.index']
          }] : [])
      ]
    }
  }
}
</script>
