<template>
  <div v-for="(day, dayIndex) in modelValue" :key="dayIndex" class="mb-5">
    <table class="table border-2 border-atc-gray">
      <thead class="table-head">
        <tr class="table-row">
          <th scope="col" class="table-header py-3">{{ day.label }}</th>
          <th scope="col" class="py-3 w-52">
            <button
              v-if="dayIndex === 0"
              type="button"
              class="table-controls-create-btn font-heading uppercase"
              @click="applyToAllDays(day)"
            >
              <Icon class="table-controls-create-icon" name="plus" />
              Apply to All Days
            </button>
          </th>
          <th scope="col" class="py-3 w-36">
            <button
              type="button"
              class="table-controls-create-btn font-heading uppercase"
              @click="addPeriod(day)"
            >
              <Icon class="table-controls-create-icon" name="plus" />
              Add Period
            </button>
          </th>
        </tr>
      </thead>
      <tbody class="table-body !border-t-0">
        <tr v-for="(period, periodIndex) in day.periods" :key="periodIndex">
          <td class="table-cell py-1 overflow-visible" colspan="2">
            <VueDatePicker
              v-model="day.periods[periodIndex]"
              :clearable="false"
              :input-class-name="'!py-3 !rounded-none !leading-3 !focus:ring-atc-secondary !focus:border-atc-secondary ' +
                (errors[`availability.${dayIndex}.periods.${periodIndex}.0`] || errors[`availability.${dayIndex}.periods.${periodIndex}.1`]
                  ? '!border-red-600' : '!border-none')"
              :is-24="false"
              :uid="`availability-${dayIndex}-periods-${periodIndex}`"
              auto-apply
              disable-time-range-validation
              model-type="HH:mm"
              range
              time-picker
            />
          </td>
          <td class="table-cell py-1">
            <div class="flex justify-end">
              <button type="button" @click="removePeriod(day, periodIndex)">
                <XIcon class="w-5 ml-auto hover:text-red-500 duration-200" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>

    <div v-if="errors[`availability.${dayIndex}.periods`]" class="form-error">
      {{ errors[`availability.${dayIndex}.periods`] }}
    </div>
  </div>
</template>

<script>
import {defineComponent} from 'vue'
import {XIcon} from '@heroicons/vue/outline'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import Icon from 'Shared/Icon'

export default defineComponent({
  name: 'AvailabilityPeriods',
  components: {
    Icon,
    VueDatePicker,
    XIcon
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    errors: Object
  },
  emits: ['update:modelValue'],
  watch: {
    modelValue: {
      handler(newValue) {
        this.$emit('update:modelValue', newValue)
      },
      deep: true
    }
  },
  methods: {
    addPeriod(day) {
      day.periods.push(['12:00', '12:00'])
    },
    removePeriod(day, periodIndex) {
      day.periods.splice(periodIndex, 1)
    },
    applyToAllDays(sourceDay) {
      for (const day of this.modelValue) {
        if (day !== sourceDay) {
          day.periods = JSON.parse(JSON.stringify(sourceDay.periods))
        }
      }
    }
  }
})
</script>
