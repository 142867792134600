<template>
  <Panel data-testid="payments-panel">
    <InertiaHead :title="title" />
    <div class="flex justify-between">
      <PanelHeading :heading="title" />
    </div>
    <div class="pb-12">
      <div class="flex flex-row justify-between gap-3 w-full pb-5">
        <div class="flex items-center w-64">
          <VueMultiselect
            v-model="query.status"
            :custom-label="option => startCase(statuses.find(status => status.value === option).label)"
            :options="statuses.map(status => status.value)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Select status"
          >
            <template #singleLabel="props">
              <span>{{ startCase(statuses.find(status => status.value === props.option).label) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('status')" />
            </template>
          </VueMultiselect>
        </div>
      </div>
      <table class="table">
        <thead class="table-head">
          <tr class="table-row">
            <th scope="col" class="table-header">
              <SortingColumn name="start_at" :sort="query.sort" @click="sortBy('start_at')">Date</SortingColumn>
            </th>
            <th scope="col" class="table-header">Amount</th>
            <th scope="col" class="table-header">Reservation</th>
            <th v-if="auth.can['payments.view.any']" scope="col" class="table-header">Client</th>
            <th scope="col" class="table-header">Status</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <tr
            v-for="payment in payments.data"
            :key="payment.uuid"
            class="table-row"
          >
            <td class="table-cell truncate">
              <div class="grid">
                <span>{{ formatDate(payment.created_at) }}</span>
                <span>{{ formatTime(payment.created_at) }}</span>
              </div>
            </td>
            <td class="table-cell truncate">${{ formatPrice(payment.amount) }}</td>
            <td class="table-cell truncate">
              <InertiaLink :href="$route('reservations.show', payment.reservation.uuid)" class="hover:underline">
                {{ payment.reservation.organizationProduct.product.name }}
              </InertiaLink>
            </td>
            <td v-if="auth.can['payments.view.any']" class="table-cell truncate">
              <InertiaLink :href="$route('users.edit', payment.user?.uuid)" class="hover:underline">
                {{ payment.user?.full_name ?? payment.reservation.client_data.fullName }}
              </InertiaLink>
            </td>
            <td class="table-cell truncate">
              <StatusBadge
                :label="statuses.find(status => status.value === payment.status).label"
                :value="payment.status"
              />
            </td>
          </tr>

          <tr v-if="payments.data.length === 0">
            <td
              class="table-cell text-center"
              :colspan="auth.can['payments.view.any'] ? '5' : '4'"
            >
              No payments found.
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :links="payments.links"
        class="flex items-center justify-center mt-5"
        :current-page="payments.current_page"
        @change-page="key => query.page = key"
      />
    </div>
  </Panel>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import {formatPrice} from 'Shared/helpers'
import {PencilIcon} from '@heroicons/vue/outline'
import {pickBy, startCase, throttle} from 'lodash'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import SortingColumn from 'Shared/SortingColumn'
import StatusBadge from 'Shared/Misc/StatusBadge.vue'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  components: {
    InertiaHead,
    InertiaLink,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading,
    PencilIcon,
    SortingColumn,
    StatusBadge,
    VueMultiselect
  },
  layout: Layout,
  props: {
    auth: Object,
    filters: Object,
    payments: Object,
    statuses: Array
  },
  data() {
    return {
      title: 'Payments',
      query: {
        sort: {
          column: this.filters.sort?.column,
          order: this.filters.sort?.order
        },
        page: this.filters.page,
        status: this.filters.status
      }
    }
  },
  watch: {
    query: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('payments.index'), pickBy(this.query), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  methods: {
    formatPrice,
    startCase,
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    },
    formatTime(date) {
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    sortBy(column) {
      this.query.sort = {
        column: column,
        order: this.query.sort.column === column ?
          (this.query.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
    },
    reset(filter) {
      this.query[filter] = null
    }
  }
}
</script>
s
