<template>
  <div>
    <InertiaHead title="Support" />
    <Panel class-name="sm:px-0 lg:px-0">
      <div
        v-if="article && auth?.can['news.update']"
        class="border-b-2 border-gray-200 px-4 sm:px-6 lg:px-8"
      >
        <Tabs :tabs="tabs" />
      </div>

      <div class="divide-y-2 divide-gray-200">
        <slot />
      </div>
    </Panel>
  </div>
</template>

<script>
import { Head as InertiaHead } from '@inertiajs/inertia-vue3'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import Tabs from 'Shared/Navigation/Tabs'

export default {
  components: {
    InertiaHead,
    Panel,
    Tabs
  },
  layout: Layout,
  props: {
    article: Object,
    auth: Object
  },
  data() {
    return {
      tabs: [
        {
          label: 'Content',
          route: this.article ? this.$route('news.edit', this.article.uuid) : null,
          current: ['news.edit', 'news.show']
        },
        {
          label: 'Documents',
          route: this.article ? this.$route('news.documents.index', this.article.uuid) : null,
          current: ['news.documents.index', 'news.documents.show']
        }
      ]
    }
  }
}
</script>
