<template lang="">
<div class="fixed top-0 left-0 right-0 bottom-0 w-full h-screen z-50 overflow-hidden bg-atc-graphite opacity-100 flex flex-col items-center justify-center fullpage-loader hidden">
    <Spinner color="white" />
    <h2 class="text-center text-white text-xl font-semibold mt-3">Loading...</h2>
    <p class="w-1/3 text-center text-white">This may take a few seconds, please don't close this page.</p>
</div>
</template>

<script>
import Spinner from 'Shared/Spinner'
export default {
    components: {
        Spinner
    }
}
</script>
