<template>
  <div class="flex flex-row items-center py-6 font-heading uppercase">
    <template v-if="confirmation">
      <a v-if="create && $page.props.auth.can[createPermission]" class="table-controls-create-btn" :href="create" @click.prevent="$emit('onConfirm', $event)">
        <Icon class="table-controls-create-icon" name="plus" />
        Create New {{ text }}
      </a>
      <a v-if="widget" :class="`table-controls-view-btn ${create ? 'border-l' : ''}`" :href="link" @click.prevent="$emit('onConfirm', $event)">
        View All
        <Icon class="table-controls-view-icon" name="pointer" />
      </a>
    </template>
    <template v-else>
      <Link v-if="(create && $page.props.auth.can[createPermission]) || (create && overrideCreatePermission)" class="table-controls-create-btn" :href="create">
        <Icon class="table-controls-create-icon" name="plus" />
        Create New {{ text }}
      </Link>
      <Link v-if="widget" :class="`table-controls-view-btn ${create && $page.props.auth.can[createPermission] ? 'border-l' : ''}`" :href="link">
        View All
        <Icon class="table-controls-view-icon" name="pointer" />
      </Link>
    </template>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Icon from 'Shared/Icon'

export default {
  components: {
    Link,
    Icon
  },
  props: {
    className: String,
    create: String,
    link: String,
    text: String,
    confirmation: Boolean,
    widget: {
      type: Boolean,
      default: true
    },
    createPermission: String,
    overrideCreatePermission: Boolean
  }
}
</script>
