<template>
  <div>
    <ProductLayout title="Product rental" :product="organizationProduct">
      <form class="divide-y-2 divide-gray-200" data-testid="organizationProductRentalForm" @submit.prevent="submit">
        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Reservation settings" />
          <div class="grid gap-y-6 gap-x-4 sm:grid-cols-2 md:grid-cols-3 grid-flow-row">
            <FormInput
              id="price"
              v-model="form.price"
              :disabled="!auth.can['organization-products.update']"
              :error="form.errors.price"
              class="form-input-edit"
              label="Price"
              min="0"
              required
              step=".01"
              type="number"
            />
            <div class="form-input-edit">
              <label class="form-label">Buffer Time Before</label>
              <VueMultiselect
                v-model="form.buffer_time_before"
                :allow-empty="true"
                :class="`form-multiselect-edit ${form.errors.buffer_time_before ? 'form-multiselect-error' : ''}`"
                :custom-label="option => durations.find(buffer => buffer.value === option).label"
                :options="durations.map(buffer => buffer.value)"
                :searchable="false"
                :show-labels="false"
                placeholder="Select time"
              >
                <template #singleLabel="props">
                  <span>{{ durations.find(buffer => buffer.value === props.option).label }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="reset('buffer_time_before')" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors.buffer_time_before" class="form-error">
                {{ form.errors.buffer_time_before }}
              </div>
            </div>
            <div class="form-input-edit">
              <label class="form-label">Buffer Time After</label>
              <VueMultiselect
                v-model="form.buffer_time_after"
                :allow-empty="true"
                :class="`form-multiselect-edit ${form.errors.buffer_time_after ? 'form-multiselect-error' : ''}`"
                :custom-label="option => durations.find(buffer => buffer.value === option).label"
                :options="durations.map(buffer => buffer.value)"
                :searchable="false"
                :show-labels="false"
                placeholder="Select time"
              >
                <template #singleLabel="props">
                  <span>{{ durations.find(buffer => buffer.value === props.option).label }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="reset('buffer_time_after')" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors.buffer_time_after" class="form-error">
                {{ form.errors.buffer_time_after }}
              </div>
            </div>
            <div class="form-input-edit">
              <label class="form-label">
                Slot Duration <span class="text-red-500">*</span>
              </label>
              <VueMultiselect
                v-model="form.slot_duration_minutes"
                :allow-empty="false"
                :custom-label="option => durations.find(duration => duration.value === option).label"
                :class="`form-multiselect-edit required ${form.errors.slot_duration_minutes ? 'form-multiselect-error' : ''}`"
                :options="durations.map(duration => duration.value)"
                :searchable="false"
                :show-labels="false"
                placeholder="Select time"
              />
              <div v-if="form.errors.slot_duration_minutes" class="form-error">
                {{ form.errors.slot_duration_minutes }}
              </div>
            </div>
            <div class="form-input-edit">
              <label class="form-label">Minimum Days Before</label>
              <VueMultiselect
                v-model="form.min_days_before"
                :allow-empty="true"
                :class="`form-multiselect-edit ${form.errors.min_days_before ? 'form-multiselect-error' : ''}`"
                :custom-label="option => minDaysBefore.find(days => days.value === option).label"
                :options="minDaysBefore.map(days => days.value)"
                :searchable="false"
                :show-labels="false"
                placeholder="Select time"
              >
                <template #singleLabel="props">
                  <span>{{ minDaysBefore.find(days => days.value === props.option).label }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="reset('min_days_before')" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors.min_days_before" class="form-error">{{ form.errors.min_days_before }}</div>
            </div>
            <div class="form-input-edit">
              <label class="form-label">
                Maximum Days Before <span class="text-red-500">*</span>
              </label>
              <VueMultiselect
                v-model="form.max_days_before"
                :class="`form-multiselect-edit required ${form.errors.max_days_before ? 'form-multiselect-error' : ''}`"
                :custom-label="option => maxDaysBefore.find(days => days.value === option).label"
                :options="maxDaysBefore.map(days => days.value)"
                :searchable="false"
                :show-labels="false"
                placeholder="Select time"
                required
              >
                <template #singleLabel="props">
                  <span>{{ maxDaysBefore.find(days => days.value === props.option).label }}</span>
                </template>
              </VueMultiselect>
              <div v-if="form.errors.max_days_before" class="form-error">{{ form.errors.max_days_before }}</div>
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Availability" />
          <AvailabilityPeriods v-model="form.availability" :errors="form.errors" />
          <div v-if="form.errors.availability" class="form-error">
            {{ form.errors.availability }}
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Days Off" />
          <DaysOff v-model="form.days_off" :errors="form.errors" />
        </div>

        <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
          <button
            v-if="organizationProduct && auth.can['organization-products.delete']"
            class="form-button"
            data-testid="deleteButton"
            type="button"
            @click="showConfirmPopup"
          >
            Delete
          </button>
          <InertiaLink
            :href="$route('organization-products.index')"
            as="button"
            class="ml-auto form-button"
            data-testid="cancelButton"
            type="button"
          >
            {{ auth.can['organization-products.update'] ? 'Cancel' : 'Back' }}
          </InertiaLink>
          <LoadingButton
            v-if="auth.can['organization-products.update']"
            :loading="form.processing"
            class="ml-3 form-submit-button"
            data-testid="submitButton"
            type="submit"
          >
            Save
          </LoadingButton>
        </div>
      </form>
    </ProductLayout>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this product? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import {Link as InertiaLink, useForm} from '@inertiajs/inertia-vue3'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import AvailabilityPeriods from 'OrganizationsComponents/AvailabilityPeriods'
import DaysOff from 'OrganizationsComponents/DaysOff'
import FormInput from 'Shared/Form/FormInput'
import ProductLayout from 'OrganizationsComponents/ProductLayout'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import PanelHeading from 'Shared/PanelHeading'
import {isEmpty} from 'lodash'

export default {
  components: {
    AvailabilityPeriods,
    ConfirmModal,
    DaysOff,
    FormInput,
    InertiaLink,
    LoadingButton,
    PanelHeading,
    ProductLayout,
    VueMultiselect
  },
  layout: Layout,
  props: {
    auth: Object,
    organizationProduct: Object
  },
  setup(props) {
    const form = useForm({
      _method: 'PUT',
      price: props.organizationProduct?.price ?? null,
      buffer_time_before: props.organizationProduct?.buffer_time_before > 0
        ? props.organizationProduct?.buffer_time_before
        : null,
      buffer_time_after: props.organizationProduct?.buffer_time_after > 0
        ? props.organizationProduct?.buffer_time_after
        : null,
      min_days_before: props.organizationProduct?.min_days_before > 0
        ? props.organizationProduct?.min_days_before
        : null,
      max_days_before: props.organizationProduct?.max_days_before > 0
        ? props.organizationProduct?.max_days_before
        : null,
      slot_duration_minutes: props.organizationProduct?.slot_duration_minutes ?? 120,
      availability: !isEmpty(props.organizationProduct?.availability)
        ? props.organizationProduct?.availability
        : [
          {label: 'Monday', periods: []},
          {label: 'Tuesday', periods: []},
          {label: 'Wednesday', periods: []},
          {label: 'Thursday', periods: []},
          {label: 'Friday', periods: []},
          {label: 'Saturday', periods: []},
          {label: 'Sunday', periods: []}
        ],
      days_off: props.organizationProduct?.days_off ?? []
    })

    return { form }
  },
  data() {
    const hours = []
    for (let i = 1; i <= 24; i++) {
      hours.push({
        label: `${i} hour${i > 1 ? 's' : ''}`,
        value: i * 60}
      )
    }

    return {
      isConfirmPopupOpen: false,
      durations: hours,
      minDaysBefore: [
        {label: '15 days', value: 15},
        {label: '30 days', value: 30},
        {label: '45 days', value: 45}
      ],
      maxDaysBefore: [
        {label: '30 days', value: 30},
        {label: '60 days', value: 60},
        {label: '120 days', value: 120},
        {label: '180 days', value: 180},
        {label: '365 days', value: 365}
      ]
    }
  },
  methods: {
    submit() {
      this.form
        .transform(data => ({
          ...data,
          buffer_time_before: data.buffer_time_before ?? 0,
          buffer_time_after: data.buffer_time_after ?? 0,
          min_days_before: data.min_days_before ?? 0
        }))
        .post(this.$route('organization-products.rental.update', this.organizationProduct.uuid), {
          preserveState: page => Object.keys(page.props.errors).length
        })
    },
    deleteProduct() {
      this.$inertia
        .post(this.$route('organization-products.destroy', this.organizationProduct.uuid), {
          _method: 'DELETE'
        })
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteProduct()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    reset(attribute) {
      this.form[attribute] = null
    }
  }
}
</script>
