<template>
  <Panel>
    <div class="flex justify-between">
      <PanelHeading :heading="name" />
      <PanelActions
        :create="$route('news.create')"
        :create-permission="permission"
        :link="$route('news.index')"
        :widget="widget"
      />
    </div>
    <div class="pb-12">
      <div class="overflow-x-auto">
        <table class="table">
          <tbody class="table-body">
            <tr v-for="(item, index) in news.data" :key="index" class="py-2">
              <td class="whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium sm:pl-6 uppercase flex justify-between items-center">
                <Link :href="$route('news.show', item)" class="flex justify-between content-center flex-col">
                  <div class="text-xs flex flex-wrap gap-2 mb-1">
                    <p class="py-0.5">{{ getFormattedDate(item.created_at) }}</p>
                    <span
                      v-for="(tag, index) in item.tags"
                      :key="index"
                      class="bg-atc-gray rounded-full px-2 py-0.5"
                    >
                      {{ tag.name }}
                    </span>
                  </div>
                  <p class="flex items-center flex-row">
                    {{ item.title }}
                    <Icon class="ml-3 w-3 -rotate-90" :name="`pointer`" />
                  </p>
                </Link>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
  </Panel>
</template>

<script>
import {
  Link
} from '@inertiajs/inertia-vue3'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import PanelActions from 'Shared/PanelActions'
import moment from 'moment'

export default {
  components: {
    Link,
    Panel,
    PanelHeading,
    PanelActions,
    Icon
  },
  layout: Layout,
  props: {
    news: Object,
    name: String,
    dashboard: {
      type: Boolean,
      default: false
    },
    widget: {
      type: Boolean,
      default: true
    },
    permissions: Object
  },
  data() {
    return {
      permission: 'news.create'
    }
  },
  methods: {
    getFormattedDate(date) {
      return moment(date).format('MM/DD/YYYY')
    }
  }
}
</script>
