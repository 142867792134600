<template>
  <div :class="$attrs.class" :data-testid="dataTestid">
    <FormLabel :id="id" :text="label" :required="required" class="mt-3 mb-3" />
    <datalist :id="`${id}-values`" class="flex justify-between text-atc-text">
      <option
        v-for="(option, index) in options"
        :key="`${id}-option-${index}`"
        :value="option"
        :label="option"
        class="w-5 text-center"
      />
    </datalist>
    <input
      :id="id"
      :class="`!pl-0 w-full accent-atc-primary ${disabled ? 'cursor-not-allowed' : 'cursor-pointer'}`"
      :disabled="disabled"
      :list="`${id}-values`"
      :max="max"
      :min="min"
      :name="id"
      :step="step"
      :value="modelValue"
      type="range"
      v-bind="$attrs"
      @change="$emit('update:modelValue', $event.target.value)"
    />
    <div v-if="error" class="text-red-600 text-xs font-light mt-2">{{ error }}</div>
    <div v-if="hint" class="text-gray-500 text-xs font-light mt-2">{{ hint }}</div>
  </div>
</template>

<script>
import FormLabel from 'Shared/Form/FormLabel'

export default {
  components: {
    FormLabel
  },
  inheritAttrs: false,
  props: {
    id: {
      required: true,
      type: String
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    hint: {
      type: String,
      required: false
    },
    required: {
      type: Boolean,
      default: false
    },
    options: {
      type: [Array, Number],
      default: () => ([
        0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10
      ])
    },
    max: {
      type: Number,
      default: 10
    },
    min: {
      type: Number,
      default: 0
    },
    step: {
      type: Number,
      default: 1
    },
    dataTestid: {
      type: String,
      default: (props) => `${props.id}-range`
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
}
</script>
