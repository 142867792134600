<template>
  <ProfileLayout title="Notifications" :user="user">
    <form class="divide-y-2 divide-gray-200" @submit.prevent="submit">
      <div class="px-4 sm:px-6 lg:px-8 pt-2 pb-7">
        <PanelHeading heading="Google Calendar" />
        <div class="flex gap-3 place-items-center">
          <GoogleAuthButton :disabled="user.linkedCalendar" @click="authorize" />
          <button
            v-if="user.linkedCalendar"
            class="px-1 text-sm font-heading hover:text-atc-secondary duration-200"
            type="button"
            @click="showConfirmPopup"
          >
            Revoke access
          </button>
          <VueMultiselect
            v-if="user.linkedCalendar"
            v-model="selectedCalendar"
            :loading="isLoading.calendars"
            :options="calendars"
            :show-labels="false"
            class="form-multiselect-edit !w-72"
            label="summary"
            placeholder="Select calendar"
            track-by="id"
            @open="getCalendars"
          >
            <template #noResult>
              No calendars found.
            </template>
            <template #singleLabel="props">
              <span>{{ props.option.summary }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="selectedCalendar = null" />
            </template>
          </VueMultiselect>
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
        <button type="submit" :disabled="form.processing" class="ml-auto form-submit-button">Save</button>
      </div>
    </form>
    <ConfirmModal
      :loading="isLoading.revoke"
      :open="isConfirmPopupOpen"
      button-label="Revoke"
      text="Are you sure you want to revoke access? It will remove reservations from your Google Calendar."
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </ProfileLayout>
</template>

<script>
import {useForm} from '@inertiajs/inertia-vue3'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import GoogleAuthButton from 'Shared/Buttons/GoogleAuthButton'
import Layout from 'Shared/Layouts/Layout'
import PanelHeading from 'Shared/PanelHeading'
import ProfileLayout from 'UsersComponents/ProfileLayout'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import axios from 'axios'

export default {
  components: {
    ConfirmModal,
    GoogleAuthButton,
    PanelHeading,
    ProfileLayout,
    VueMultiselect
  },
  layout: Layout,
  props: {
    user: Object
  },
  setup(props) {
    const form = useForm({
      _method: 'PUT',
      calendar_name: props.user.linkedCalendar?.calendar_name ?? null,
      calendar_id: props.user.linkedCalendar?.calendar_id ?? null
    })

    return { form }
  },
  data() {
    return {
      isLoading: {
        calendars: false,
        revoke: false
      },
      revokeLoading: false,
      selectedCalendar: this.user.linkedCalendar?.calendar_name && this.user.linkedCalendar?.calendar_id ? {
        summary: this.user.linkedCalendar?.calendar_name,
        id: this.user.linkedCalendar?.calendar_id
      } : null,
      calendars: [],
      isConfirmPopupOpen: false
    }
  },
  mounted() {
    window.addEventListener('message', (event) => this.receiveMessage(event), false)
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          return {
            ...data,
            calendar_name: this.selectedCalendar?.summary ?? null,
            calendar_id: this.selectedCalendar?.id ?? null
          }
        })
        .post(this.$route('users.integrations.update', this.user.uuid), {
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    authorize() {
      axios
        .get(this.$route('users.integrations.google.authorize', this.user.uuid))
        .then(response => {
          if (response.status !== 200) {
            return
          }
          this.openPopupWindow(response.data, 'oauth', 500, 600)
        })
    },
    openPopupWindow(url, target, w, h) {
      const left = (screen.width / 2) - (w / 2)
      const top = (screen.height / 2) - (h / 2)

      return window.open(url, target, `toolbar=no,menubar=no,width=${w},height=${h},top=${top},left=${left}`)
    },
    receiveMessage(event) {
      if (event.origin !== window.origin ||
        event.source.name !== 'oauth') {
        return
      }

      this.$inertia.get(this.$route(this.$route().current(), this.user.uuid))
    },
    getCalendars() {
      this.isLoading.calendars = true
      axios
        .get(this.$route('users.integrations.google.calendars', this.user.uuid))
        .then(response => {
          if (response.status !== 200) {
            this.isLoading.calendars = false
            return
          }
          this.calendars = response.data
          this.isLoading.calendars = false
        })
    },
    revokeAccess() {
      this.isLoading.revoke = true
      this.$inertia.get(this.$route('users.integrations.google.disconnect', this.user.uuid), {}, {
        onFinish: () => {
          this.isLoading.revoke = false
          this.closeConfirmPopup()
        }
      })
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.revokeAccess()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
