<template>
  <div class="flex">
    <SidePanel
      :disabled-inputs="true"
      :disabled-multiselects="true"
      :disabled-multiselects-dist="true"
      :entity="entity"
      :errors="$props.errors"
      :form="sidePanelData"
      :permissions="permissions"
      :record="record"
      @on-print-price="printPriceTable"
      @on-print-parts="printPartsList"
      @on-delete="showConfirmPopup('delete')"
    />

    <Panel class-name="w-full xl:w-4/5 ml-4 mt-8 md:mt-12 md:ml-12 sm:px-0 lg:px-0 main-panel h-fit">
      <FlashMessages data-html2canvas-ignore />

      <div class="border-b-2 border-gray-200 px-4 sm:px-6 lg:px-8">
        <Tabs :tabs="tabs" />
      </div>

      <div class="flex min-h-full">
        <div class="flex w-0 flex-1 flex-col">
          <main class="flex-1">
            <div class="xl:grid xl:grid-cols-3">
              <div class="px-6 lg:px-8 xl:col-span-2 xl:border-r xl:border-gray-200">
                <section aria-labelledby="notes-title" class="mt-5 xl:mt-3">
                  <div class="divide-y divide-gray-200">
                    <PanelHeading heading="Conversation" class="pb-4 pt-4" />
                    <div class="bg-gray-50 px-4 py-6 sm:px-6">
                      <div class="flex space-x-3">
                        <div class="flex-shrink-0">
                          <img
                            class="h-10 w-10 rounded-full"
                            :src="$page.props.auth.user.photo ?? '/assets/images/test-avatar.png'"
                            :alt="`${$page.props.auth.user.name} photo`"
                          />
                        </div>
                        <div class="min-w-0 flex-1">
                          <form @submit.prevent="sendMessage">
                            <div>
                              <label for="message" class="sr-only">About</label>
                              <FormTextArea
                                id="message"
                                v-model="form.content"
                                name="message"
                                rows="4"
                                class="block w-full rounded-md focus:border-atc-secondary focus:ring-atc-secondary sm:text-sm"
                                placeholder="Add a message"
                                :error="form.errors.content"
                                @change="saved = false"
                              />
                            </div>
                            <div class="mt-3 flex items-center justify-between">
                              <PrimaryButton type="submit" class="ml-auto">Send</PrimaryButton>
                            </div>
                          </form>
                        </div>
                      </div>
                    </div>
                    <div class="px-4 py-6 sm:px-6">
                      <ul role="list" class="space-y-4 divide-y-2 divide-atc-gray">
                        <li
                          v-for="message in allMessages"
                          :key="message.uuid"
                          class="space-x-3 pt-4"
                        >
                          <Message
                            :message="message"
                            @on-delete="() => deleteMessage(message.uuid)"
                          />
                        </li>
                      </ul>
                      <span ref="loadMoreMessagesIntersect" />
                    </div>
                  </div>
                </section>
              </div>

              <aside class="px-6 lg:px-8 xl:col-span-1">
                <section aria-labelledby="activity-title" class="mt-5 xl:mt-3">
                  <div class="divide-y divide-gray-200">
                    <PanelHeading heading="Activity" class="pb-4 pt-4" />
                    <div class="pt-6">
                      <div class="flow-root">
                        <ul role="list" class="-mb-8 inline">
                          <li v-for="(log, index) in allActivity" :key="log.id">
                            <Log :log="log" :is-oldest-log="index + 1 === allActivity.length" />
                          </li>
                        </ul>
                        <span ref="loadMoreLogsIntersect" />
                      </div>
                    </div>
                  </div>
                </section>
              </aside>
            </div>
          </main>
        </div>
      </div>
    </Panel>
  </div>
  <ConfirmModal
    :open="isConfirmPopupOpen"
    :text="popupText"
    button-label="Confirm"
    @on-close="closeConfirmPopup"
    @on-confirm="confirmAction"
  />

  <FullPageLoader />
  <Pdf v-if="record" ref="pdf" :record="record" :entity="entity" :data="printData" />
</template>

<script>
import {useForm} from '@inertiajs/inertia-vue3'
import Log from 'ActivitylogComponents/Log'
import Message from 'MessagesComponents/Message'
import FormTextArea from 'Shared/Form/FormTextArea'
import SidePanel from 'Shared/LeadsAndOrders/SidePanel'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import PrimaryButton from 'Shared/Buttons/PrimaryButton'
import FlashMessages from 'Shared/FlashMessages'
import FullPageLoader from 'Shared/FullPageLoader'
import Tabs from 'Shared/Navigation/Tabs'
import axios from 'axios'
import Pdf from 'Shared/PDF/PartsList'

export default {
  components: {
    Pdf,
    ConfirmModal,
    FlashMessages,
    FormTextArea,
    FullPageLoader,
    Log,
    Message,
    Panel,
    PanelHeading,
    PrimaryButton,
    SidePanel,
    Tabs
  },
  props: {
    record: Object,
    messages: Object,
    activity: Object,
    entity: String,
    errors: Object,
    permissions: Object,
    onConfirm: Object,
    printData: Object,
    statuses: Array
  },
  setup(props) {
    const form = useForm({
      record: {
        type: props.entity,
        uuid: props.record.uuid
      },
      content: ''
    })

    return {form}
  },
  data() {
    return {
      allMessages: this.messages.data,
      allActivity: this.activity.data,
      tabs: [
        {
          label: 'Details',
          route: this.$route(`${this.entity}s.edit`, this.record.uuid),
          current: [`${this.entity}s.edit`]
        },
        {
          label: 'Documents',
          route: this.$route(`${this.entity}s.documents.index`, this.record.uuid),
          current: [`${this.entity}s.documents.index`, `${this.entity}s.documents.show`]
        },
        {
          label: 'Activity',
          route: this.$route(`${this.entity}s.activity.index`, this.record.uuid),
          current: [`${this.entity}s.activity.index`]
        }
      ],
      sidePanelData: {
        client: {
          firstName: this.record?.client?.firstName ?? '',
          lastName: this.record?.client?.lastName ?? '',
          email: this.record?.client?.email ?? null,
          phone: this.record?.client?.phone ?? null,
          address: this.record?.client?.address ?? null,
          city: this.record?.client?.city ?? null,
          country: this.record?.client?.country ?? 'US',
          state: this.record?.client?.state ?? null,
          zipCode: this.record?.client?.zipCode ?? null,
          clientNotes: this.record?.client?.clientNotes ?? null
        },
        distributor: this.record?.distributor ?? null,
        dealer: this.record?.dealer ?? null,
        dealership: this.record?.dealership ?? null,
        distMember: this.record?.distMember ?? null
      },
      isConfirmPopupOpen: false,
      redirectLink: '',
      popupAction: '',
      popupText: '',
      saved: true
    }
  },
  watch: {
    onConfirm(e) {
      this.onRedirect(e)
    }
  },
  mounted() {
    this.setupIntersectObserver(this.$refs.loadMoreMessagesIntersect, this.loadMoreMessages)
    this.setupIntersectObserver(this.$refs.loadMoreLogsIntersect, this.loadMoreLogs)
  },
  methods: {
    setupIntersectObserver(ref, loadFunc) {
      const observer = new IntersectionObserver(entries =>
        entries.forEach(entry => entry.isIntersecting && loadFunc(), {
          rootMargin: '-150px 0px 0px 0px'
        })
      )

      observer.observe(ref)
    },
    sendMessage() {
      this.form.post(this.$route('messages.store'), {
        forceFormData: true,
        preserveState: page => Object.keys(page.props.errors).length
      })
    },
    loadMoreMessages() {
      if (this.messages.next_page_url === null) {
        return
      }

      this.$inertia.get(
        this.messages.next_page_url,
        {},
        {
          preserveState: true,
          preserveScroll: true,
          only: ['messages'],
          onSuccess: () => {
            this.allMessages = [...this.allMessages, ...this.messages.data]
          }
        }
      )
    },
    loadMoreLogs() {
      if (this.activity.next_page_url === null) {
        return
      }

      this.$inertia.get(
        this.activity.next_page_url,
        {},
        {
          preserveState: true,
          preserveScroll: true,
          only: ['activity'],
          onSuccess: () => {
            this.allActivity = [...this.allActivity, ...this.activity.data]
          }
        }
      )
    },
    deleteRecord() {
      this.$inertia.post(this.$route(`${this.entity}s.destroy`, this.record.uuid), {
        _method: 'DELETE'
      })
    },
    deleteMessage(uuid) {
      this.$inertia.post(
        this.$route('messages.destroy', uuid),
        {
          _method: 'DELETE'
        },
        {
          preserveState: true,
          preserveScroll: true,
          only: ['messages'],
          onSuccess: () => {
            this.allMessages = this.messages.data
          }
        }
      )
    },
    async printPriceTable() {
      try {
        const response = await axios.get(this.$route(`${this.entity}s.pdf`, this.record.uuid), {
          responseType: 'blob'
        })

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const newWindow = window.open(url, '_blank')

        if (newWindow) {
          const interval = setInterval(() => {
            if (newWindow.document.readyState === 'complete') {
              clearInterval(interval)
              newWindow.print()
            }
          }, 100)
        } else {
          alert('Popup blocked. Please allow popups for this website.')
        }
      } catch (error) {
        console.error('Error printing PDF: ', error)
        alert('An error occurred while printing the PDF.')
      }
    },
    printPartsList() {
      this.$refs.pdf.exportToPDF()
    },
    onRedirect(e) {
      this.redirectLink = e.target.pathname + e.target.search
      if (!this.saved) {
        this.showConfirmPopup('redirect')
      } else {
        this.popupAction = 'redirect'
        this.confirmAction()
      }
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'delete') {
        this.deleteRecord()
      }
      if (this.popupAction === 'redirect') {
        this.$inertia.visit(this.redirectLink)
      }
    },
    showConfirmPopup(action) {
      this.popupAction = action
      if (this.popupAction === 'delete') {
        this.popupText = `Are you sure you want to delete this ${this.entity}? This action cannot be undone.`
      }
      if (this.popupAction === 'redirect') {
        this.popupText = 'Are you sure you want to leave this page without saving?'
      }
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
