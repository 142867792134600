<template>
  <div>
    <div class="flex mb-5">
      <Panel class="sm:px-0 lg:px-0 flex-1 w-64">
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">Name</th>
              <th v-if="config.draggable" scope="col" class="table-header w-[5.6rem]">Position</th>
              <th scope="col" class="table-header w-16" />
            </tr>
          </thead>
          <VueDraggableNext v-if="types.length" :list="types" handle=".handle" animation="200" tag="tbody" class="table-body" @update="onOrderChange">
            <tr v-for="type in types" :key="type.uuid" class="table-row cursor-pointer" @click="editType(type)">
              <td class="table-cell py-4">{{ type.name }}</td>
              <td v-if="config.draggable" class="table-cell handle">
                <span title="Drag to change order" class="flex justify-center"><SelectorIcon class="w-4" /></span>
              </td>
              <td class="table-cell">
                <span title="Edit"><PencilIcon class="w-4 ml-auto" /></span>
              </td>
            </tr>
          </VueDraggableNext>

          <tbody v-else class="table-body">
            <tr>
              <td class="table-cell text-center" colspan="2">No types found.</td>
            </tr>
          </tbody>
        </table>
      </Panel>

      <form class="flex-1 ml-8" @submit.prevent="submit">
        <div class="flex justify-between">
          <div v-if="config.showMultiSelect" class="pt-2">
            <FormCheckbox id="multiselect" v-model="form.multiselect" label="Multiselect" wrapper-class="mb-0" />
          </div>
          <button type="button" class="ml-auto font-heading table-controls-create-btn uppercase" @click="resetType">
            <Icon class="table-controls-create-icon" name="plus" />
            <span class="">Create new type</span>
          </button>
        </div>
        <FormInput
          id="name"
          v-model="form.name"
          :error="form.errors.name"
          class="form-input-edit"
          label="Name"
          required
        />
        <FormTextArea
          id="description"
          v-model="form.description"
          :error="form.errors.description"
          class="form-textarea-edit"
          rows="4"
          label="Description"
        />
        <div class="flex justify-between mt-5">
          <button v-if="currentType" type="button" class="form-button" @click.stop="showConfirmPopup">Delete</button>
          <Link type="button" as="button" class="form-submit-button ml-auto" @click.stop="submit">Save</Link>
        </div>
      </form>
    </div>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this type? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import { Link, useForm } from '@inertiajs/inertia-vue3'
import { PencilIcon, SelectorIcon } from '@heroicons/vue/outline'
import { VueDraggableNext } from 'vue-draggable-next'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Icon from 'Shared/Icon'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import FormTextArea from 'Shared/Form/FormTextArea'
import Panel from 'Shared/Panel'

export default {
  components: {
    Link,
    PencilIcon,
    SelectorIcon,
    VueDraggableNext,
    ConfirmModal,
    Icon,
    FormCheckbox,
    Panel,
    FormTextArea,
    FormInput
  },
  props: {
    types: Object,
    config: {
      type: Object,
      default: () => ({
        route: 'extensions.types',
        showMultiSelect: true,
        draggable: true
      })
    }
  },
  emits: ['resetType'],
  setup() {
    const form = useForm({
      name: null,
      description: null,
      multiselect: false
    })

    return { form }
  },
  data() {
    return {
      currentType: null,
      isConfirmPopupOpen: false
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => ({
          ...data,
          _method: this.currentType ? 'PUT' : 'POST'
        }))
        .post(this.currentType ? this.$route(`${this.config.route}.update`, this.currentType.uuid) : this.$route(`${this.config.route}.store`), {
          onSuccess: () => this.resetType()
        })
    },
    editType(type) {
      this.currentType = type
      this.form.name = type.name
      this.form.description = type.description
      this.form.multiselect = type.multiselect
    },
    deleteType() {
      this.$inertia
        .post(this.$route(`${this.config.route}.destroy`, this.currentType.uuid), {
          _method: 'DELETE',
          preserveState: (page) => Object.keys(page.props.errors).length
        })
      this.resetType()
    },
    resetType() {
      this.currentType = null
      this.form.reset()
      this.form.clearErrors()
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteType()
      this.isConfirmPopupOpen = false
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    onOrderChange() {
      this.types.map((type, index) => {
        type.custom_order = index + 1
      })

      this.$inertia
        .post(this.$route(`${this.config.route}.updateMany`), {
          _method: 'PUT',
          types: this.types,
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    }
  }
}
</script>
