<template>
  <div :class="`${collapsed ? 'collapsed' : ''}`">
    <div class=" w-full pl-[1.65rem] pb-5 pt-[30px] text-atc-white text-[0.95rem] font-heading tracking-[0.19px] uppercase">
      Quick Links
    </div>

    <div class="w-full text-atc-gray text-[0.9rem] uppercase bg-atc-text font-heading tracking-[0.19px]">
      <LinksAccordion v-if="$page.props.auth.can['leads.index']" title="Leads">
        <QuickLink
          v-for="(quickLink, index) in quickLinks.leads"
          :key="index"
          :confirmation="confirmation"
          :count="$page.props.recordsCount.leads.find(s => (s['mapped_status'] === quickLink.slug))?.count ?? 0"
          :href="`${$route('leads.index')}?status=${quickLink.slug}`"
          :label="quickLink.label"
          @on-confirm-nested="$emit('on-confirm', $event)"
        />
      </LinksAccordion>
      <LinksAccordion v-if="$page.props.auth.can['orders.index']" title="Orders">
        <QuickLink
          v-for="(quickLink, index) in quickLinks.orders"
          :key="index" :confirmation="confirmation"
          :count="$page.props.recordsCount.orders.find(s => (s['mapped_status'] === quickLink.slug))?.count ?? 0"
          :href="`${$route('orders.index')}?status=${quickLink.slug}`"
          :label="quickLink.label"
          @on-confirm-nested="$emit('on-confirm', $event)"
        />
      </LinksAccordion>
      <LinksAccordion v-if="$page.props.auth.can['reservations.index']" title="Reservations">
        <QuickLink
          v-for="(quickLink, index) in quickLinks.reservations"
          :key="index" :confirmation="confirmation"
          :count="$page.props.recordsCount.reservations.find(s => (s['mapped_status'] === quickLink.slug))?.count ?? 0"
          :href="`${$route('reservations.index')}?status=${quickLink.slug}`"
          :label="quickLink.label"
          @on-confirm-nested="$emit('on-confirm', $event)"
        />
      </LinksAccordion>
      <div v-if="$page.props.auth.can['news.index']">
        <component
          :is="confirmation ? 'a' : 'InertiaLink'"
          :href="$route('news.index')"
          class="flex pl-[1.65rem] hover:text-atc-secondary duration-200 py-3 items-center justify-between uppercase w-full bg-atc-accent mb-[0.3rem] border-l-4 border-atc-success"
          @click.prevent="$emit('on-confirm', $event)"
        >
          Support
        </component>
      </div>
      <div class="absolute bottom-2 right-0 group flex cursor-pointer" @click="toggleCollapse">
        <Icon name="pointer" class="rotate-90 side-panel-icon mt-[2px]" />
        <span class="text-atc-gray text-sm capitalize mx-2">Collapse Navigation</span>
      </div>
    </div>

    <div :class="`quick-links-btn ${collapsed ? '' : 'collapsed'}`" @click="toggleCollapse">
      <Icon name="pointer" class="-rotate-90 side-panel-icon w-4 m-4" />
    </div>
  </div>
</template>

<script>
import {Link as InertiaLink} from '@inertiajs/inertia-vue3'
import Icon from 'Shared/Icon'
import LinksAccordion from 'CoreComponents/LinksAccordion'
import QuickLink from 'CoreComponents/QuickLink'

export default {
  components: {
    Icon,
    InertiaLink,
    LinksAccordion,
    QuickLink
  },
  props: {
    confirmation: Boolean
  },
  emits: ['on-confirm'],
  data() {
    return {
      collapsed: false,
      quickLinks:{
        leads: [],
        orders: [],
        reservations: []
      }
    }
  },
  mounted() {
    this.prepareStatuses()
  },
  methods: {
    prepareStatuses() {
      const entities = ['leads', 'orders', 'reservations']

      entities.forEach(entity => {
        if (this.$page.props.quickLinksStatuses[entity]) {
          this.prepareStatusesFor(entity)
        }
      })
    },
    prepareStatusesFor(entity) {
      const statuses = this.$page.props.quickLinksStatuses[entity]
      const keys = Object.keys(statuses)
      keys.forEach(key => {
        this.quickLinks[entity].push({
          slug: key,
          label: statuses[key]
        })
      })
    },
    toggleCollapse() {
      this.collapsed = !this.collapsed
      this.emitter.emit('toggle-quicklinks', this.collapsed)
    }
  }
}
</script>
