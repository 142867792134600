<template>
  <div>
    <div
      class="relative aspect-w-4 aspect-h-4 group block w-full overflow-hidden rounded-lg bg-gray-100 duration-200 focus-within:ring-2 focus-within:ring-atc-secondary focus-within:ring-offset-gray-100"
      :class="{'border-4 border-atc-secondary': $page.url.endsWith(attachment.uuid)}"
    >
      <img
        v-if="imageFormats.includes(attachment.type)"
        class="object-cover duration-200 group-hover:opacity-75"
        :src="attachment.reference"
      />
      <DocumentTextIcon v-else class="text-[#aaa] duration-200 group-hover:opacity-75" />
      <button type="button" class="absolute inset-0 focus:outline-none">
        <span class="sr-only">View details for {{ attachment.name }}</span>
      </button>
    </div>
    <p class="pointer-events-none mt-2 block truncate text-sm font-medium text-gray-900">
      {{ attachment.name }}
    </p>
    <p v-if="attachment.expired_at" class="pointer-events-none block truncate text-sm font-medium">
      <span v-if="isAfterToday(attachment.expired_at)" class="text-green-500">(active)</span>
      <span v-else class="text-red-600">(expired)</span>
    </p>
    <p class="pointer-events-none block text-sm font-medium text-gray-500">
      {{ formatBytes(attachment.size) }}
    </p>
  </div>
</template>

<script>
import {formatBytes} from 'Shared/helpers'
import {DocumentTextIcon} from '@heroicons/vue/solid'

export default {
  components: {
    DocumentTextIcon
  },
  props: {
    attachment: Object
  },
  data() {
    return {
      imageFormats: ['jpeg', 'jpg', 'png', 'gif']
    }
  },
  methods: {
    formatBytes,
    isAfterToday(date) {
      if (!date) return true

      const today = new Date()

      today.setHours(23, 59, 59, 998)

      return new Date(date) > today
    }
  }
}
</script>
