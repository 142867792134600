<template>
  <div class="configurator-topbar flex justify-between  bg-atc-white h-16 fixed top-0 left-0 w-full z-10 border-b border-gray-200">
    <div class="flex items-stretch overflow-x-auto">
      <nav class="h-full">
        <ul class="flex h-full font-heading">
          <li class="flex items-center px-4">
            <a href="https://actiontrackchair.com/" target="_blank">
              <div class="mt-1 w-60 md:min-w-60">
                <Logo class="fill-black" width="160" height="54" />
              </div>
            </a>
          </li>
          <li
            v-if="$route().current() !== 'build.index'"
            class="hover:text-atc-secondary duration-200"
            :class="{'configurator-menu-link': true, 'configurator-menu-link--active': $route().current() === 'build.index' }"
          >
            <a :href="$route('build.index')" @click.prevent="modelSelectionRedirect">
              Back to model selection
            </a>
          </li>
          <li v-for="menuItem in allMenuItems" :id="slugifyString(menuItem.name)" ref="menuItemRef" :key="menuItem.name" :class="linkItemClass">
            <a class="hover:cursor-pointer hover:text-atc-secondary duration-200" @click.prevent="linkClick(menuItem.name, $event)">{{ menuItem.name }}</a>
          </li>
        </ul>
      </nav>
    </div>
    <div class="px-2 lg:px-5 lg:py-3 flex items-center">
      <a href="http://actiontrackchair.com/" target="_blank">
        <PrimaryButton>
          Home page
        </PrimaryButton>
      </a>
    </div>
  </div>
</template>

<script>
import {
  Link
} from '@inertiajs/inertia-vue3'
import PrimaryButton from 'Shared/Buttons/PrimaryButton'

import {
  slugifyString
} from 'Shared/helpers.js'

import Logo from 'Shared/Logo'

export default {
  name: 'ConfiguratorTopBar',
  components: {
    Link,
    PrimaryButton,
    Logo
  },
  props: {
    menuItems: Array,
    visibleSection: String
  },
  computed: {
    linkItemClass() {
      return {
        'configurator-menu-link hidden lg:flex': true,
        'hidden lg:hidden': this.$page.url === '/' || this.$page.url.includes('/?') || this.$page.url.startsWith('/dealer/')
      }
    },
    allMenuItems() {
      const modelMenuItem = {
        name: 'Chair model'
      }
      const formMenuItem = {
        name: 'Finish order'
      }
      const menuItems = this.menuItems ? this.menuItems : []
      return [modelMenuItem, ...menuItems, formMenuItem]
    }
  },
  watch: {
    visibleSection: {
      deep: true,
      handler: function (newVal, oldVal) {
        this.toggleClassOnScroll(newVal)
      }
    }
  },
  mounted() {
    if (this.$refs['menuItemRef']) {
      this.$refs['menuItemRef'][0].classList.add('configurator-menu-link--active')
    }
  },
  methods: {
    slugifyString,
    linkClick(name, e) {
      this.$refs['menuItemRef'].forEach(item => item.classList.remove('configurator-menu-link--active'))
      e.target.parentElement.classList.add('configurator-menu-link--active')
      this.$emit('link-click', name)
    },
    toggleClassOnScroll(item) {
      const active = this.$refs['menuItemRef'].find(ref => ref.id === item)
      this.$refs['menuItemRef'].forEach(item => item.classList.remove('configurator-menu-link--active'))
      active.classList.add('configurator-menu-link--active')
    },
    modelSelectionRedirect() {
      this.$emit('model-selection-redirect')
    }
  }
}
</script>
