<template>
  <div>
    <InertiaHead :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <form class="divide-y-2 divide-gray-200" @submit.prevent="submit">
        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading :heading="title" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="name" v-model="form.name" :error="form.errors.name" class="form-input-edit" label="Name" required />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="price" v-model="form.price" :error="form.errors.price" type="number" step=".01" min="0" class="form-input-edit" label="Price" />
            </div>

            <div class="col-span-2 sm:col-span-1">
              <FormInput id="sku" v-model="form.sku" :error="form.errors.sku" class="form-input-edit" label="SKU" required />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <label class="form-label-edit block my-3">Type <span class="text-red-500">*</span></label>
              <VueMultiselect
                v-model="form.type"
                :options="types"
                placeholder="Start typing to search"
                label="name"
                track-by="uuid"
                :class="`form-multiselect-edit ${form.errors.type ? 'form-multiselect-error' : ''}`"
              >
                <template #singleLabel="props">
                  <span>{{ props.option.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="clearSelection" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors.type" class="form-error">The type field is required.</div>
            </div>

            <div class="col-span-2">
              <FormTextArea id="description" v-model="form.description" :error="form.errors.description" class="form-textarea-edit" rows="4" maxlength="2500" label="Description" />
            </div>

            <div class="col-span-2">
              <FormCheckbox
                id="support"
                v-model="form.flags.isSupport"
                label="Support Item"
                wrapper-class="mb-0"
              />
            </div>
          </div>
        </div>

        <div class="sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Available products" />
          <VueMultiselect
            v-model="form.products"
            :options="products"
            :multiple="true"
            placeholder="Start typing to search"
            label="name"
            track-by="uuid"
            :close-on-select="false"
            class="form-multiselect-edit form-multiselect-edit--big"
          />
        </div>

        <div class="sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Gallery" />
          <ProductGallery :images="form.media.urls" :errors="Object.keys(form.errors).filter((k) => k.startsWith('media.files.')).map(k => form.errors[k])" @delete-image="deleteImage" @update-gallery="updateGallery" />
        </div>

        <div class="sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Product previews" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div v-for="(product) in form.products" :key="`image-url-${product.sku}`">
              <label class="form-label-edit block my-3">{{ product.sku }}</label>
              <ImagePreview
                :id="`preview-${product.sku}`"
                :image-file="form.preview.files[product.sku]"
                :image-url="form.preview.urls[product.sku]"
                :error="form.errors[`preview.files.${product.sku}`]"
                @update="handlePreviewUpdate(product.sku, $event.data, $event.event)"
              />
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
          <button v-if="extension" type="button" class="form-button" @click="showConfirmPopup">Delete</button>
          <InertiaLink type="button" :href="$route('extensions.index')" as="button" class="ml-auto form-button">Cancel</InertiaLink>
          <LoadingButton type="submit" :loading="form.processing" class="ml-3 form-submit-button">Save</LoadingButton>
        </div>
      </form>
    </Panel>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this extension? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink,
  useForm
} from '@inertiajs/inertia-vue3'
import {XIcon} from '@heroicons/vue/outline'
import {VueDraggableNext} from 'vue-draggable-next'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import FormTextArea from 'Shared/Form/FormTextArea'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import ProductGallery from 'Shared/Form/Uploads/ImageGallery'
import ImagePreview from 'Shared/Form/Uploads/ImagePreview'

export default {
  components: {
    ImagePreview,
    InertiaHead,
    InertiaLink,
    XIcon,
    VueDraggableNext,
    VueMultiselect,
    FormCheckbox,
    FormInput,
    FormTextArea,
    FileUpload,
    LoadingButton,
    ConfirmModal,
    Panel,
    PanelHeading,
    ProductGallery
  },
  layout: Layout,
  props: {
    extension: Object,
    types: Array,
    products: Array
  },
  setup(props) {
    const form = useForm({
      _method: props.extension ? 'PUT' : 'POST',
      name: props.extension?.name ?? null,
      price: parseFloat(props.extension?.price ?? 0).toFixed(2),
      sku: props.extension?.sku ?? null,
      type: props.extension?.type ?? null,
      description: props.extension?.description ?? null,
      media: {
        urls: props.extension?.media ?? [],
        files: []
      },
      preview: {
        urls: {...props.extension?.preview},
        files: {}
      },
      flags: props.extension?.flags ?? {
        isSupport: false
      },
      products: props.extension ? props.extension?.products : []
    })

    return { form }
  },
  data() {
    return {
      title: `${this.extension ? 'Edit' : 'Create'} extension`,
      isConfirmPopupOpen: false
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          return data.price === '' ? { ...data, price: 0 } : data
        })
        .post(this.extension ? this.$route('extensions.update', this.extension.uuid) : this.$route('extensions.store'), {
          forceFormData: true,
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deleteExtension() {
      this.$inertia
        .post(this.$route('extensions.destroy', this.extension.uuid), {
          _method: 'DELETE'
        })
    },
    updateGallery(files) {
      [...files].forEach(file => {
        this.form.media.files.push(file)
        this.form.media.urls.push(URL.createObjectURL(file))
      })
    },
    deleteImage(index) {
      this.form.media.files.splice(index, 1)
      this.form.media.urls.splice(index, 1)
    },
    handlePreviewUpdate(sku, dataObject) {
      const file = dataObject.file

      if (!file) {
        const url = this.form.preview.urls[sku]

        delete this.form.preview.files[sku]
        delete this.form.preview.urls[sku]
        URL.revokeObjectURL(url)

        return
      }
      this.form.preview.files[sku] = file
      this.form.preview.urls[sku] = URL.createObjectURL(file)
    },
    clearSelection() {
      this.form.type = null
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteExtension()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
