<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_963_7393)">
      <path d="M10.0023 14.8366C10.7048 15.2897 11.5419 15.4871 12.3728 15.3955C13.2037 15.3039 13.9777 14.929 14.5646 14.3337L15.0778 13.8331C15.3027 13.603 15.4287 13.2939 15.4287 12.972C15.4287 12.6501 15.3027 12.341 15.0778 12.1109L12.8995 9.95428C12.6712 9.73002 12.364 9.60436 12.0441 9.60436C11.7241 9.60436 11.4169 9.73002 11.1886 9.95428C10.9584 10.1793 10.6494 10.3052 10.3275 10.3052C10.0056 10.3052 9.69652 10.1793 9.46634 9.95428L6.04577 6.53371C5.93163 6.42121 5.841 6.28715 5.77914 6.13931C5.71728 5.99148 5.68542 5.83282 5.68542 5.67257C5.68542 5.51231 5.71728 5.35365 5.77914 5.20582C5.841 5.05798 5.93163 4.92392 6.04577 4.81142C6.27003 4.58317 6.39568 4.27598 6.39568 3.95599C6.39568 3.63601 6.27003 3.32882 6.04577 3.10057L3.87776 0.933709C3.64758 0.708728 3.33849 0.582764 3.01662 0.582764C2.69475 0.582764 2.38566 0.708728 2.15548 0.933709L1.65376 1.44799C1.05944 2.03501 0.685172 2.80863 0.593814 3.63897C0.502455 4.46931 0.699567 5.30581 1.15205 6.008C3.51282 9.48776 6.51679 12.4844 10.0023 14.8366Z" stroke="#626263" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_963_7393">
        <rect width="16" height="16" fill="white" transform="matrix(-1 0 0 1 16 0)"/>
      </clipPath>
    </defs>
  </svg>
</template>
