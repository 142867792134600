<template>
  <div>
    <InertiaHead :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <div v-if="organization" class="border-b-2 border-gray-200 px-4 sm:px-6 lg:px-8">
        <Tabs :tabs="tabs" />
      </div>

      <div class="divide-y-2 divide-gray-200">
        <slot />
      </div>
    </Panel>
  </div>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Panel from 'Shared/Panel'
import Tabs from 'Shared/Navigation/Tabs'

export default {
  components: {
    InertiaHead,
    Panel,
    Tabs
  },
  props: {
    title: String,
    organization: Object
  },
  data() {
    return {
      tabs: [
        {
          label: 'General',
          route: this.organization ? this.$route('organizations.show', this.organization.uuid) : null,
          current: ['organizations.edit', 'organizations.show']
        },
        {
          label: 'Payments',
          route: this.organization ? this.$route('organizations.payments.show', this.organization.uuid) : null,
          current: ['organizations.payments.edit', 'organizations.payments.show']
        },
        {
          label: 'Branding',
          route: this.organization ? this.$route('organizations.branding.show', this.organization.uuid) : null,
          current: ['organizations.branding.edit', 'organizations.branding.show']
        },
        {
          label: 'Documents',
          route: this.organization ? this.$route('organizations.documents.show', this.organization.uuid) : null,
          current: ['organizations.documents.edit', 'organizations.documents.show']
        }
      ]
    }
  }
}
</script>
