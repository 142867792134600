<template>
  <div>
    <InertiaHead :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <div v-if="product" class="border-b-2 border-gray-200 px-4 sm:px-6 lg:px-8">
        <Tabs :tabs="tabs" />
      </div>

      <div class="divide-y-2 divide-gray-200">
        <slot />
      </div>
    </Panel>
  </div>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Panel from 'Shared/Panel'
import Tabs from 'Shared/Navigation/Tabs'

export default {
  components: {
    InertiaHead,
    Panel,
    Tabs
  },
  props: {
    title: String,
    product: Object
  },
  data() {
    return {
      tabs: [
        {
          label: 'General',
          route: this.product ? this.$route('organization-products.show', this.product.uuid) : null,
          current: ['organization-products.edit', 'organization-products.show']
        },
        {
          label: 'Rental',
          route: this.product ? this.$route('organization-products.rental.show', this.product.uuid) : null,
          current: ['organization-products.rental.edit', 'organization-products.rental.show']
        }
      ]
    }
  }
}
</script>
