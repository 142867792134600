<template>
  <div class="py-4">
    <div v-if="attachment?.name" class="flex py-2">
      <h3 class="table-title">{{ attachment.name }}</h3>
      <Icon name="cheveron-down" className="table-title-icon" />
    </div>
    <div class="aspect-1/1 bg-atc-secondary p-1">
      <pdf
        :src="attachment.url"
        :page="attachment.page"
        resize
        @numpages="value => (attachment.totalPages = value)"
      />
    </div>
    <div class="mt-5 flex items-center justify-center">
      <div class="-mb-1 flex flex-wrap">
        <button
          type="button"
          class="focus:text-atc-text-500 form-button mb-1 mr-1 px-4 py-3 text-sm font-normal leading-4"
          :class="{'cursor-default text-gray-400 hover:bg-atc-white': attachment.page === 1}"
          @click="prevPage(attachment)"
        >
          &laquo; Previous
        </button>
        <template v-for="index in attachment.totalPages" :key="`link-${index}`">
          <button
            type="button"
            class="focus:text-atc-text-500 form-button mb-1 mr-1 px-4 py-3 text-sm font-normal leading-4"
            :class="{'form-submit-button': attachment.page === index}"
            @click="attachment.page = index"
          >
            {{ index }}
          </button>
        </template>
        <button
          type="button"
          class="focus:text-atc-text-500 form-button mb-1 mr-1 px-4 py-3 text-sm font-normal leading-4"
          :class="{
            'cursor-default text-gray-400 hover:bg-atc-white':
              attachment.page === attachment.totalPages
          }"
          @click="nextPage(attachment)"
        >
          Next &raquo;
        </button>
      </div>
    </div>
    <div class="mt-1 flex items-center justify-center">
      <button
        type="button"
        class="focus:text-atc-text-500 form-submit-button mb-1 mr-1 px-4 py-3 text-sm font-normal leading-4"
        @click="download(attachment.uuid)"
      >
        Download
      </button>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Icon from 'Shared/Icon'
import pdf from 'pdfvuer'

export default {
  components: {
    Icon,
    pdf
  },
  props: {
    attachment: Object
  },
  methods: {
    prevPage(attachment) {
      if (attachment.page > 1) {
        attachment.page--
      }
    },
    nextPage(attachment) {
      if (attachment.page < attachment.totalPages) {
        attachment.page++
      }
    },
    download(uuid) {
      axios
        .post(
          this.$route('media.download', uuid),
          {},
          {
            responseType: 'blob'
          }
        )
        .then(response => {
          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(response.data)
          link.download = this.attachment.name
          link.click()
          setTimeout(() => {
            window.URL.revokeObjectURL(response.data)
          }, 100)
        })
        .catch(error => {
          console.error(error.response)
        })
    }
  }
}
</script>
