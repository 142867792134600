<template>
  <div class="flex justify-end items-center bg-black h-16 bottom-0 left-0 w-full z-10 px-5">
    <p v-if="price" class="text-white">Starting price: <span class="text-atc-yellow">{{ price }}</span></p>
  </div>
</template>

<script>
export default {
  name: 'ConfiguratorBottomBar',
  props: {
    price: String
  }
}
</script>
