<template>
  <div class="flex items-center py-6">
    <button class="table-controls-create-btn font-heading uppercase" @click="open = true">
      <Icon class="table-controls-create-icon" name="plus" />
      {{ buttonLabel }}
    </button>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="cm-dialog">
        <div class="cm-container">
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0"
            enter-to="opacity-100"
            leave="ease-in duration-200"
            leave-from="opacity-100"
            leave-to="opacity-0"
          >
            <DialogOverlay class="cm-dialog-overlay" />
          </TransitionChild>

          <!-- This element is to trick the browser into centering the modal contents. -->
          <span class="cm-center-modal" aria-hidden="true">&#8203;</span>
          <TransitionChild
            as="template"
            enter="ease-out duration-300"
            enter-from="opacity-0 sm:scale-95"
            enter-to="opacity-100 sm:scale-100"
            leave="ease-in duration-200"
            leave-from="opacity-100 sm:scale-100"
            leave-to="opacity-0  sm:scale-95"
          >
            <div class="cm-content">
              <div class="cm-flex w-full">
                <div class="cm-title-wrapper w-full">
                  <DialogTitle as="h3" class="cm-title">
                    {{ modalTitle }}
                  </DialogTitle>
                  <div class="mt-2 justify-center">
                    <FileUpload
                      id="import-excel"
                      accept-types="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      :description="fileName"
                      @update="files => excelGet(files)"
                    />
                  </div>
                </div>
              </div>
              <div class="mt-2 flex w-full justify-center">
                <button type="button" class="form-button ml-3" @click="postImportData">
                  Import
                </button>
                <button
                  ref="cancelButtonRef"
                  type="button"
                  class="form-button ml-3"
                  @click="open = false"
                >
                  Cancel
                </button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>
  </div>
</template>

<script>
import * as XLSX from 'xlsx'
import {useForm, Link} from '@inertiajs/inertia-vue3'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import Icon from 'Shared/Icon'
import {Dialog, DialogOverlay, DialogTitle, TransitionChild, TransitionRoot} from '@headlessui/vue'

export default {
  components: {
    FileUpload,
    Link,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    Icon
  },
  props: {
    buttonLabel: {
      type: String,
      default: 'Import statuses'
    },
    hint: {
      type: String,
      default: 'Allowed formats: xlsx, xls'
    },
    modalTitle: {
      type: String,
      default: 'Import excel file with statuses'
    },
    route: String
  },
  data() {
    return {
      excelData: [],
      open: false,
      fileName: this.hint
    }
  },
  methods: {
    excelGet(event) {
      const input = event
      const reader = new FileReader()

      this.excelData = []

      reader.onload = () => {
        const fileData = reader.result
        const wb = XLSX.read(fileData, {
          type: 'binary',
          cellDates: true
        })

        wb.SheetNames.forEach(sheetName => {
          const rowObj = XLSX.utils.sheet_to_json(wb.Sheets[sheetName], {
            raw: false,
            dateNF: 'yyyy-mm-dd'
          })

          rowObj.forEach(row => {
            if (row['Stock #']) {
              this.excelData.push({
                stock: row?.['Stock #'],
                dueDate: row?.['Due Date'],
                status: row?.['Work Center']
              })
            }
            if (row['__EMPTY_10'] && row['__EMPTY_10'].toLowerCase() != 'work center') {
              this.excelData.push({
                stock: row['__EMPTY_1'],
                dueDate: row['__EMPTY_3'],
                status: row['__EMPTY_10']
              })
            }
            if (row['Part Number']) {
              this.excelData.push(row)
            }
          })
        })
      }
      reader.readAsBinaryString(input[0])
      this.fileName = input[0].name
    },
    postImportData() {
      let form = useForm({
        __method: 'PUT',
        excelData: this.excelData
      })
      form.put(this.$route(this.route))
      this.open = false
    }
  }
}
</script>
