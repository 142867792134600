export const formatOrderPrintData = (record) => {
  if (!record) return null
  const accessories = record.product?.extensions?.accessories
  const support = accessories ? accessories.filter(a => (a?.flags?.isSupport === '1')) : []
  return {
    accessories: accessories ? accessories.filter(a => (!a?.flags?.isSupport || a?.flags?.isSupport === '0')) : [],
    productTable: [
      [
        {label: 'Stock number', value: record.stock},
        {label: 'Serial #', value: record.client?.registration}
      ], [
        {label: 'Distributor', value: record.distributor?.name}
      ], [
        {label: 'Dealership', value: record.dealership?.name}
      ], [
        {label: 'Model', value: record.product?.data?.sku},
        {label: 'Size', value: record.product?.model},
        {label: 'Frame Color', value: record.product?.extensions?.frameColor?.name}
      ], [
        {label: 'Joystick Mounting', value: record.product?.joystickMounting?.id},
        {label: 'Track Type', value: record.product?.extensions?.tracks?.name}
      ], [
        {label: 'Seat Option', value: record.product?.extensions?.seatOptions?.name}
      ], [
        {label: 'Armrest', value: record.product?.extensions?.armrest?.name}
      ], [
        {label: 'Support', value: support.map(item => (`(${item.sku}) ${item.name}`)).join(', ')}
      ]
    ]
  }
}

export const formatPartOrderPrintData = (record, parts) => {
  if (!record) return null
  const productTable = [
    [
      {label: 'Order number', value: record.number},
      {label: 'Tracking number', value: record.tracking}
    ], [
      {label: 'Value', value: record.value + '$'}
    ], [
      {label: 'Distributor', value: record.distributor?.name}
    ], [
      {label: 'Dealership', value: record.dealership?.name}
    ], [
      {title: 'Parts'}
    ]
  ]
  if (parts){
    parts.forEach((partType) => {
      const partNames = partType.parts.map((part) => part.name).join(', ')
      productTable.push([{ label: partType.name, value: partNames }])
    })
  }

  return {
    accessories: null,
    productTable
  }
}
