<template>
  <div>
    <label>
      <input class="absolute opacity-0 peer" type="checkbox" :name="extensionType.name" :value="extension.uuid" @change="onChange($event)" />
      <div class="relative after:content-[''] after:block after:pb-[100%] border-3 border-transparent peer-checked:border-atc-secondary cursor-pointer">
        <img class="absolute object-cover w-full h-full" :src="extension.preview?.[product.sku] ?? extension.media[0]" />
      </div>
      <p class="uppercase text-xs lg:text-sm font-semibold  mt-1">{{ extension.name }}</p>
      <p v-if="extension.price > 0" class="font-light text-xs lg:text-sm">+ ${{ formattedPrice }}</p>
    </label>
    <!-- <a v-if="extensionType.name.toLowerCase() === 'accessories'" class="font-light text-xs uppercase underline cursor-pointer" @click="openPopup(extension)">View details</a> -->
    <a v-if="extension.description" class="font-light text-xs uppercase underline cursor-pointer" @click="openPopup(extension)">View details</a>
  </div>
</template>

<script>
import {
  formatPrice
} from 'Shared/helpers.js'
export default {
  name: 'ConfiguratorExtensionCheckboxInput',
  inject: ['openPopup'],
  props: {
    extension: Object,
    extensionType: Object,
    product: Object
  },
  data() {
    return {
      selectedExtensionData: {
        uuid: this.extensionType.uuid,
        name: this.extensionType.name,
        multiselect: this.extensionType.multiselect,
        selectedExtensions: [this.extension]
      }
    }
  },
  computed: {
    formattedPrice() {
      if (!this.extension.price) {
        return ''
      }
      return this.formatPrice(this.extension.price)
    }
  },
  methods: {
    formatPrice,
    onChange() {
      this.$emit('extension-choice', this.selectedExtensionData)
    }
  }
}
</script>

<style>

</style>
