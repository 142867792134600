<template>
  <div class="bg-white text-atc-text min-h-screen" @click="dismissAlert">
    <InertiaHead title="Trackchair Builder" />
    <ConfiguratorTopBar ref="topbar" :menu-items="menuItems" :visible-section="visibleSection" @link-click="scrollTo" @model-selection-redirect="showConfirmPopup" />
    <div class="pt-16">
      <Alert v-if="currentAlertMessage" ref="alert" :message="currentAlertMessage" />
      <slot />
    </div>
    <ConfiguratorModal :open="isPopupOpen" :data="popupData" @on-close="closePopup" />
    <ConfirmModal :open="isConfirmPopupOpen" @on-close="closeConfirmPopup" @on-confirm="confirmAction" />
  </div>
</template>

<script>
import {
  Head as InertiaHead
} from '@inertiajs/inertia-vue3'
import ConfiguratorTopBar from 'ConfiguratorComponents/ConfiguratorTopBar'
import ConfiguratorModal from 'ConfiguratorComponents/ConfiguratorModal'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Alert from 'Shared/Alert'

export default {
  components: {
    InertiaHead,
    ConfiguratorTopBar,
    ConfiguratorModal,
    Alert,
    ConfirmModal
  },
  provide: function () {
    return {
      openPopup: this.openPopup
    }
  },
  props: {
    menuItems: Array,
    visibleSection: String,
    dealershipUuid: String
  },
  data() {
    return {
      isPopupOpen: false,
      isConfirmPopupOpen: false,
      popupData: null,
      currentAlertMessage: null,
      alertMessages: {
        'form-submitted': 'Thank you for submitting the quote. We will contact you soon!'
      },
      scrollPosition: 0,
      buildUrl: this.dealershipUuid ? `/dealer/${this.dealershipUuid}` : '/'
    }
  },
  mounted: function () {

    window.onpopstate = () => {
      this.showConfirmPopup()
    }
    history.pushState({}, '')

    this.maybeShowAlert()
    window.addEventListener('beforeunload', (event) => {
      // Cancel the event as stated by the standard.
      event.preventDefault()
      // Chrome requires returnValue to be set.
      event.returnValue = ''
    })

  },
  methods: {
    scrollTo(name) {
      this.$emit('scroll-to', name)
    },
    openPopup(data) {
      this.popupData = data
      const offsetY = window.scrollY
      this.scrollPosition = offsetY
      document.querySelector('html').classList.add('popup-open')
      document.querySelector('html').style.top = `-${offsetY}px`
      this.isPopupOpen = true

    },
    closePopup() {
      this.isPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
        window.scrollTo({
          top: this.scrollPosition,
          left: 0,
          behavior: 'instant'
        })
      }, 500)

    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
        window.scrollTo({
          top: this.scrollPosition,
          left: 0,
          behavior: 'instant'
        })
      }, 500)

    },
    dismissAlert(e) {
      if (e.target !== this.$refs['alert']) {
        this.currentAlertMessage = null
      }
    },
    maybeShowAlert() {
      const queryString = window.location.search
      const urlParams = new URLSearchParams(queryString)
      const status = urlParams.get('status')
      if (status) {
        this.currentAlertMessage = this.alertMessages[status]
      }
    },
    confirmAction() {
      this.$inertia.visit(this.buildUrl)
      document.querySelector('html').classList.remove('popup-open')
    },
    showConfirmPopup() {
      const offsetY = window.scrollY
      this.scrollPosition = offsetY
      document.querySelector('html').style.top = `-${offsetY}px`
      document.querySelector('html').classList.add('popup-open')

      this.isConfirmPopupOpen = true
    }
  }
}
</script>

<style>
html.popup-open {
    padding-right: 0 !important;
    overflow: auto !important;
    overflow-y: scroll !important;
    position: fixed;
    right: 0;
    left: 0;
}
</style>
