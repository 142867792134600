<template lang="">
<div class="relative">
    <CustomMessages :status="status" />
    <div class="tiptap-menu" v-if="editor">
        <div class="mb-3 tiptap-menu-top text-right">
            <button @click.prevent="resetContent">
                Reset all changes
            </button>
            <button @click.prevent="editor.chain().focus().undo().run()">
                undo
            </button>
            <button @click.prevent="editor.chain().focus().redo().run()">
                redo
            </button>
        </div>
        <div class="color-button">
            <input type="color" @input="editor.chain().focus().setColor($event.target.value).run()" :value="editor.getAttributes('textStyle').color">
        </div>
        <button @click.prevent="setLink" :class="{ 'is-active': editor.isActive('link') }">
            setLink
        </button>
        <button @click.prevent="editor.chain().focus().unsetLink().run()" :disabled="!editor.isActive('link')">
            unsetLink
        </button>
        <button @click.prevent="editor.chain().focus().setTextAlign('left').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'left' }) }">
            left
        </button>
        <button @click.prevent="editor.chain().focus().setTextAlign('center').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'center' }) }">
            center
        </button>
        <button @click.prevent="editor.chain().focus().setTextAlign('right').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'right' }) }">
            right
        </button>
        <button @click.prevent="editor.chain().focus().setTextAlign('justify').run()" :class="{ 'is-active': editor.isActive({ textAlign: 'justify' }) }">
            justify
        </button>
        <button @click.prevent="editor.chain().focus().unsetTextAlign().run()">
            unsetTextAlign
        </button>
        <button @click.prevent="addImage">
            setImage
        </button>
        <button @click.prevent="editor.chain().focus().toggleBold().run()" :class="{ 'is-active': editor.isActive('bold') }">
            bold
        </button>
        <button @click.prevent="editor.chain().focus().toggleItalic().run()" :class="{ 'is-active': editor.isActive('italic') }">
            italic
        </button>
        <button @click.prevent="editor.chain().focus().toggleStrike().run()" :class="{ 'is-active': editor.isActive('strike') }">
            strike
        </button>
        <button @click.prevent="editor.chain().focus().toggleCode().run()" :class="{ 'is-active': editor.isActive('code') }">
            code
        </button>

        <button @click.prevent="editor.chain().focus().setParagraph().run()" :class="{ 'is-active': editor.isActive('paragraph') }">
            paragraph
        </button>
        <button @click.prevent="editor.chain().focus().toggleHeading({ level: 1 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 1 }) }">
            h1
        </button>
        <button @click.prevent="editor.chain().focus().toggleHeading({ level: 2 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 2 }) }">
            h2
        </button>
        <button @click.prevent="editor.chain().focus().toggleHeading({ level: 3 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 3 }) }">
            h3
        </button>
        <button @click.prevent="editor.chain().focus().toggleHeading({ level: 4 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 4 }) }">
            h4
        </button>
        <button @click.prevent="editor.chain().focus().toggleHeading({ level: 5 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 5 }) }">
            h5
        </button>
        <button @click.prevent="editor.chain().focus().toggleHeading({ level: 6 }).run()" :class="{ 'is-active': editor.isActive('heading', { level: 6 }) }">
            h6
        </button>
        <button @click.prevent="editor.chain().focus().toggleBulletList().run()" :class="{ 'is-active': editor.isActive('bulletList') }">
            bullet list
        </button>
        <button @click.prevent="editor.chain().focus().toggleOrderedList().run()" :class="{ 'is-active': editor.isActive('orderedList') }">
            ordered list
        </button>
        <button @click.prevent="editor.chain().focus().toggleCodeBlock().run()" :class="{ 'is-active': editor.isActive('codeBlock') }">
            code block
        </button>
        <button @click.prevent="editor.chain().focus().toggleBlockquote().run()" :class="{ 'is-active': editor.isActive('blockquote') }">
            blockquote
        </button>
        <button @click.prevent="editor.chain().focus().setHardBreak().run()">
            hard break
        </button>
        <button @click.prevent="modalOpen = true">
            upload image
        </button>
        <button @click.prevent="iframeModalOpen = true">
            add video
        </button>
    </div>
    <editor-content :editor="editor" />
</div>
<UploadModal :open="modalOpen" buttonLabel="Upload" @on-close="modalOpen = false" @on-confirm="uploadImage" :loading="loading" />
<IframeModal :open="iframeModalOpen" @on-close="iframeModalOpen = false" @on-confirm="addVideo" />
</template>

<script>
import {
    Editor,
    EditorContent,
} from '@tiptap/vue-3'
import StarterKit from '@tiptap/starter-kit';
import {
    Color
} from '@tiptap/extension-color';
import TextStyle from '@tiptap/extension-text-style'
import Link from '@tiptap/extension-link'
import TextAlign from '@tiptap/extension-text-align'
import Image from '@tiptap/extension-image'
import UploadModal from 'NewsComponents/UploadModal.vue'
import axios from 'axios';
import Iframe from "NewsComponents/Iframe";
import IframeModal from "NewsComponents/IframeModal";
import CustomMessages from 'Shared/CustomMessages'
export default {
    data() {
        return {
            editor: null,
            articleBody: this.content,
            modalOpen: false,
            iframeModalOpen: false,
            loading: false,
            errors: '',
            showErrors: false,
            status: {
                success: '',
                error: '',
                errors: ''
            }
        }
    },
    props: {
        content: String
    },
    components: {
        EditorContent,
        UploadModal,
        IframeModal,
        CustomMessages
    },
    methods: {
        updatePreview(files) {
            this.image = files[0];
        },
        openModal() {
            this.modalOpen = true;
        },
        resetContent() {
            this.editor.destroy();
            this.initEditor()
        },
        getContent() {
            const content = this.editor.getHTML();
            return content;
        },
        initEditor() {
            const component = this;
            this.editor = new Editor({
                content: this.articleBody,
                extensions: [
                    StarterKit,
                    Color,
                    TextStyle,
                    Link.configure({
                        openOnClick: true
                    }),
                    TextAlign.configure({
                        types: ['heading', 'paragraph'],
                    }),
                    Image,
                    Iframe
                ]
            });
            this.editor.commands.focus('start');
        },
        addImage() {
            const url = window.prompt('URL')
            if (url) {
                this.editor.chain().focus().setImage({
                    src: url
                }).run()
            }
        },
        addVideo(url) {
            const embedUrl = url.replace('/watch?v=', '/embed/');
            if (url) {
                this.editor.chain().focus().setExternalVideo({
                    src: embedUrl
                }).run();
                this.iframeModalOpen = false;
            }
        },
        uploadImage(image) {
            this.loading = true;
            let formData = new FormData();
            formData.append("file", image);
            axios.post("/news/image", formData).then((res) => {
                    this.modalOpen = false;
                    this.loading = false;

                    this.editor.chain().focus().setImage({
                        src: res.data
                    }).run()

                })
                .catch(error => {
                    if (error.response) {
                        this.modalOpen = false;
                        this.loading = false;
                        const errors = error.response.data.errors.file.join(' ');
                        this.status.errors = errors;
                        this.status.error = 'Error';
                    }
                });
        },
        setLink() {
            const previousUrl = this.editor.getAttributes('link').href
            const url = window.prompt('URL', previousUrl)

            // cancelled
            if (url === null) {
                return
            }

            // empty
            if (url === '') {
                this.editor
                    .chain()
                    .focus()
                    .extendMarkRange('link')
                    .unsetLink()
                    .run()

                return
            }

            // update link
            this.editor
                .chain()
                .focus()
                .extendMarkRange('link')
                .setLink({
                    href: url
                })
                .run()
        }
    },
    mounted() {
        this.initEditor();
        this.editor.on('update', () => {
            this.$emit('contentChanged')
        });
    },
    beforeUnmount() {
        this.editor.destroy()
    },

}
</script>
