<template>
  <AuthLayout title="Reset Password">
    <div class="mt-20 hidden flex-row items-center py-12 lg:flex">
      <h2 class="text-4xl uppercase text-atc-black">Reset Password</h2>
      <Icon name="cheveron-down" class-name="mx-2 w-8" />
    </div>
    <form class="space-y-6" @submit.prevent="reset">
      <div class="space-y-1">
        <div class="mt-1">
          <label class="form-label my-5 uppercase text-atc-black" for="email">Email</label>
          <FormInput
            id="email"
            v-model="form.email"
            :error="form.errors.email"
            type="email"
            autofocus
            autocapitalize="off"
          />
        </div>
        <div class="mt-1">
          <label class="form-label my-5 uppercase text-atc-black" for="password">
            New Password
          </label>
          <FormInput
            id="password"
            v-model="form.password"
            :error="form.errors.password"
            type="password"
          />
        </div>
        <div class="mt-1">
          <label class="form-label my-5 uppercase text-atc-black" for="password_confirmation">
            Confirm New Password
          </label>
          <FormInput
            id="password_confirmation"
            v-model="form.password_confirmation"
            :error="form.errors.password_confirmation"
            type="password"
          />
        </div>
      </div>
      <div class="flex flex-col items-center justify-between gap-x-4 xl:flex-row">
        <LoadingButton
          :loading="form.processing"
          class="w-full justify-center border-2 border-transparent bg-atc-primary py-2 px-12 text-sm font-medium uppercase text-white shadow-sm transition ease-in-out hover:bg-atc-secondary xl:w-auto"
          type="submit"
        >
          Reset password
        </LoadingButton>
        <div class="text-md my-3">
          <Link
            :href="$route('auth.login')"
            class="delay-50 font-medium text-atc-black transition ease-in-out hover:text-atc-secondary"
          >
            Click here to login
          </Link>
        </div>
      </div>
    </form>
    <div class="text-md mt-3 mb-10 self-start">
      <a
        href="http://archive.actiontrackchair.com/"
        target="_blank"
        class="delay-50 font-medium text-atc-black transition ease-in-out hover:text-atc-secondary"
      >
        Go to archive page
      </a>
    </div>
  </AuthLayout>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue3'
import AuthLayout from 'Shared/Layouts/AuthLayout'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import FormInput from 'Shared/Form/FormInput'
import Icon from 'Shared/Icon'

export default {
  components: {
    Link,
    AuthLayout,
    LoadingButton,
    FormInput,
    Icon
  },
  props: {
    token: String
  },
  data() {
    return {
      form: this.$inertia.form({
        email: '',
        password: '',
        password_confirmation: '',
        token: this.token
      })
    }
  },
  methods: {
    reset() {
      this.form.post(this.$route('auth.reset.update'))
    }
  }
}
</script>
