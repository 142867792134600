<template>
  <ProductLayout title="Product details" :product="organizationProduct">
    <form class="divide-y-2 divide-gray-200" data-testid="organization-product-edit-form" @submit.prevent="submit">
      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Product details" />
        <div class="grid grid-cols-2 gap-y-6 gap-x-4">
          <div class="col-span-2 sm:col-span-1">
            <FormInput
              id="serial"
              v-model="form.serial"
              :disabled="!auth.can['organization-products.update']"
              :error="form.errors.serial"
              class="form-input-edit"
              label="Serial #"
              required
              @change="handleSerialChange"
            />
          </div>
          <div class="col-span-2 sm:col-span-1">
            <label class="form-label-edit block my-3">Product <span class="text-red-500">*</span></label>
            <VueMultiselect
              v-model="form.product"
              :allow-empty="false"
              :class="`form-multiselect-edit required ${form.errors.product ? 'form-multiselect-error' : ''}`"
              :custom-label="option => (option.name)"
              :disabled="!auth.can['organization-products.update']"
              :options="products"
              deselect-label=""
              placeholder="Start typing to search"
              track-by="uuid"
            />
            <div v-if="form.errors.product" class="form-error">{{ form.errors.product }}</div>
          </div>

          <div class="form-input-edit col-span-2 sm:col-span-1">
            <label class="form-label">Model <span class="text-red-500">*</span></label>
            <VueMultiselect
              v-model="form.details.variant"
              :allow-empty="false"
              :class="`form-multiselect-edit required ${
                form.errors['details.variant'] ? 'form-multiselect-error' : ''
              }`"
              :disabled="!auth.can['organization-products.update'] || variants.length === 0"
              :options="variants"
              deselect-label=""
              placeholder="Start typing to search"
            />
            <div v-if="form.errors['details.variant']" class="form-error">
              {{ form.errors['details.variant'] }}
            </div>
          </div>

          <div class="form-input-edit col-span-2 sm:col-span-1">
            <label class="form-label">Joystick Mounting <span class="text-red-500">*</span></label>
            <VueMultiselect
              v-model="form.details.joystickMounting"
              :allow-empty="false"
              :class="`form-multiselect-edit required ${
                form.errors['details.joystickMounting'] ? 'form-multiselect-error' : ''
              }`"
              :custom-label="option => joystickMountings.find(joystick => joystick.id === option).label"
              :disabled="!auth.can['organization-products.update']"
              :options="joystickMountings.map(joystick => joystick.id)"
              :searchable="false"
              :show-labels="false"
              placeholder="Select"
            >
              <template #singleLabel="props">
                <span>{{ joystickMountings.find(joystick => joystick.id === props.option)?.label }}</span>
              </template>
            </VueMultiselect>
            <div v-if="form.errors['details.joystickMounting']" class="form-error">
              {{ form.errors['details.joystickMounting'] }}
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Extensions" />
        <div class="grid grid-cols-2 gap-y-6 gap-x-4">
          <span v-if="!productUuid" class="col-span-2">
            Select product to see available extensions.
          </span>
          <div
            v-for="(typeExtensions, typeName) in extensions"
            :key="typeName"
            class="form-input-edit"
            :class="typeExtensions[0].type?.multiselect ? 'sm:col-span-2' : 'sm:col-span-1'"
          >
            <label class="form-label">
              {{ typeName }} <span v-if="!typeExtensions[0].type?.multiselect" class="text-red-500">*</span>
            </label>
            <VueMultiselect
              v-model="selectedExtensionsByType[camelCase(typeName)]"
              :close-on-select="!typeExtensions[0].type?.multiselect"
              :disabled="!auth.can['organization-products.update']"
              :multiple="!!typeExtensions[0].type?.multiselect"
              :options="typeExtensions"
              :class="`form-multiselect-edit required ${typeExtensions[0].type?.multiselect
                ? 'form-multiselect-edit--big form-multiselect-product'
                : '' } ${form.errors.extensions ? 'form-multiselect-error' : ''}`"
              label="name"
              placeholder="Start typing to search"
              track-by="uuid"
            />
          </div>
        </div>
      </div>

      <div v-if="auth.can['organization-products.update.any']" class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Organization" />
        <VueMultiselect
          v-model="form.organization"
          :allow-empty="false"
          :class="`form-multiselect-edit required ${form.errors.organization ? 'form-multiselect-error' : ''}`"
          :options="organizations"
          deselect-label=""
          label="name"
          placeholder="Start typing to search"
          track-by="uuid"
        />
        <div v-if="form.errors['organization']" class="form-error">{{ form.errors['organization'] }}</div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
        <button
          v-if="organizationProduct && auth.can['organization-products.delete']"
          class="form-button"
          data-testid="deleteButton"
          type="button"
          @click="showConfirmPopup"
        >
          Delete
        </button>
        <InertiaLink
          :href="$route('organization-products.index')"
          as="button"
          class="ml-auto form-button"
          data-testid="cancelButton"
          type="button"
        >
          {{ auth.can['organization-products.update'] ? 'Cancel' : 'Back' }}
        </InertiaLink>
        <LoadingButton
          v-if="auth.can['organization-products.update']"
          :loading="form.processing"
          class="ml-3 form-submit-button"
          data-testid="submitButton"
          type="submit"
        >
          Save
        </LoadingButton>
      </div>
    </form>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this product? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </ProductLayout>
</template>

<script>
import {Link as InertiaLink, useForm} from '@inertiajs/inertia-vue3'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import ProductLayout from 'OrganizationsComponents/ProductLayout'
import FormInput from 'Shared/Form/FormInput'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import PanelHeading from 'Shared/PanelHeading'
import {camelCase, isEmpty} from 'lodash'
import axios from 'axios'

export default {
  components: {
    ConfirmModal,
    FormInput,
    Icon,
    InertiaLink,
    LoadingButton,
    PanelHeading,
    ProductLayout,
    VueMultiselect
  },
  layout: Layout,
  props: {
    filters: Object,
    auth: Object,
    organizationProduct: Object,
    extensions: Object,
    products: Array,
    organizations: Array
  },
  setup(props) {
    const form = useForm({
      _method: props.organizationProduct ? 'PUT' : 'POST',
      serial: props.organizationProduct?.serial ?? null,
      product: !isEmpty(props.filters.product) ? props.filters.product : (props.organizationProduct?.product ?? null),
      details: {
        variant: props.organizationProduct?.details?.variant ?? null,
        joystickMounting: props.organizationProduct?.details?.joystickMounting ?? null
      },
      extensions: props.organizationProduct?.extensions ?? [],
      organization: props.organizationProduct?.organization ?? (
        props.auth.can['organization-products.update.any'] ? null : props.auth.user.organization
      )
    })

    return { form }
  },
  data() {
    return {
      isConfirmPopupOpen: false,
      modelDataSet: [],
      joystickMountings: [
        {label: 'Left mounted', id: 'left'},
        {label: 'Right mounted', id: 'right'}
      ],
      selectedExtensionsByType: [],
      orderedProduct: null
    }
  },
  computed: {
    variants() {
      return this.form.product ? this.products.find(p => p.uuid === this.form.product?.uuid)?.variants : []
    },
    productUuid() {
      return this.form.product?.uuid
    }
  },
  watch: {
    productUuid(newValue) {
      this.form.details.variant = null
      this.$inertia
        .get(
          this.organizationProduct
            ? this.$route('organization-products.show', this.organizationProduct.uuid)
            : this.$route('organization-products.create'),
          {
            product: newValue
          },
          {
            preserveState: true,
            preserveScroll: true,
            onSuccess: this.setProductDataBySerial
          })
    }
  },
  mounted() {
    this.groupSelectedExtensions()
  },
  methods: {
    camelCase,
    submit() {
      this.form
        .transform(data => ({
          ...data,
          product: data.product?.uuid,
          extensions: this.getExtensionsUuids(),
          buffer_time_before: data.buffer_time_before ?? 0,
          buffer_time_after: data.buffer_time_after ?? 0,
          organization: data.organization?.uuid
        }))
        .post(
          this.organizationProduct
            ? this.$route('organization-products.update', this.organizationProduct.uuid)
            : this.$route('organization-products.store'),
          {
            preserveState: page => Object.keys(page.props.errors).length
          }
        )
    },
    deleteProduct() {
      this.$inertia
        .post(this.$route('organization-products.destroy', this.organizationProduct.uuid), {
          _method: 'DELETE'
        })
    },
    handleSerialChange(event) {
      axios
        .get(this.$route('orders.get-product', event.target.value))
        .then(({data, status}) => {
          if (status === 200 && !isEmpty(data)) {
            this.orderedProduct = data
            if (this.productUuid === data.data.uuid) {
              this.setProductDataBySerial()
              return
            }
            this.form.product = this.products.find(p => p.uuid === data.data.uuid)
            return
          }
          this.orderedProduct = null
        })
    },
    setProductDataBySerial() {
      if (!this.orderedProduct) {
        return
      }

      this.form.details.variant = this.orderedProduct.model
      this.form.details.joystickMounting = this.orderedProduct.joystickMounting.id

      this.selectedExtensionsByType = []
      Object.entries(this.orderedProduct.extensions).forEach(([key, value]) => {
        if (value.uuid) {
          this.selectedExtensionsByType[key] = value
          return
        }

        value.forEach(ext => {
          this.selectedExtensionsByType[key] = [
            ...(this.selectedExtensionsByType[key] ? this.selectedExtensionsByType[key] : []),
            ext
          ]
        })
      })
    },
    groupSelectedExtensions() {
      this.selectedExtensionsByType = []

      this.organizationProduct?.extensions.forEach(ext => {
        const typeName = camelCase(ext.type.name)

        this.selectedExtensionsByType[typeName] = [
          ...(this.selectedExtensionsByType[typeName] ? this.selectedExtensionsByType[typeName] : []),
          ext
        ]
      })
    },
    getExtensionsUuids() {
      const uuids = []
      Object.entries(this.selectedExtensionsByType).forEach(([, value]) => {
        if (!value) {
          return
        }
        if (value.uuid) {
          uuids.push(value.uuid)
          return
        }
        value.forEach(ext => {
          uuids.push(ext.uuid)
        })
      })
      return uuids
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteProduct()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    reset(attribute) {
      this.form[attribute] = null
    }
  }
}
</script>
