<template>
  <div>
    <label>
      <span v-if="label" class="mb-1 block font-medium text-[15px] font-heading">{{ label }} <span v-if="required" class="text-red-500">*</span></span>
      <input
        v-bind="$attrs"
        :value="modelValue"
        class="border-1 border-gray-700 w-full p-2 font-normal text-[15px] focus:border-atc-secondary focus:ring-atc-secondary"
        :class="{ error: error }"
        :aria-invalid="error ? true : null"
        @input="$emit('update:modelValue', $event.target.value)"
      />
    </label>
    <div v-if="error" class="text-red-600 text-xs font-light mt-2">{{ error }}</div>
  </div>
</template>

<script>

export default {
  inheritAttrs: false,
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update:modelValue']
}
</script>
