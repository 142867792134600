<template>
  <OrganizationLayout title="Organization documents" :organization="organization">
    <form class="divide-y-2 divide-gray-200" data-testid="organizations-edit-form" @submit.prevent="submit">
      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <div class="flex justify-between">
          <PanelHeading heading="Terms & Conditions" />
          <div class="py-8">
            <FormCheckbox
              id="requireAgreementAccept"
              v-model="form.agreement.require_accept"
              label="Require agreement acceptance"
              wrapper-class="mb-0"
            />
          </div>
        </div>

        <div class="grid grid-cols-2 gap-y-6 gap-x-4">
          <FormInput
            id="agreement_label"
            v-model="form.agreement.checkbox_label"
            :disabled="!form.agreement.require_accept"
            :error="form.errors['agreement.checkbox_label'] ?? ''"
            class="form-input-edit col-span-2"
            hint="Use the :document and :link placeholders to insert clickable document and link names."
            label="Agreement checkbox label"
            placeholder="I agree to the terms of the :document and the :link."
          />

          <FormInput
            id="link_name"
            v-model="form.agreement.link_name"
            :disabled="!form.agreement.require_accept"
            :error="form.errors['agreement.link_name'] ?? ''"
            class="form-input-edit"
            label="Link name"
            placeholder="link"
          />
          <FormInput
            id="link"
            v-model="form.agreement.link"
            :disabled="!form.agreement.require_accept"
            :error="form.errors['agreement.link'] ?? ''"
            class="form-input-edit"
            label="Link"
            placeholder="https://actiontrackchair.com/privacy-policy"
          />

          <FormInput
            id="document_name"
            v-model="form.agreement.document_name"
            :disabled="!form.agreement.require_accept"
            :error="form.errors['agreement.document_name'] ?? ''"
            class="form-input-edit col-span-2"
            label="Document name"
            placeholder="document"
          />

          <div>
            <FormLabel id="file-upload" text="Document" class="mt-3 mb-3" />
            <div v-if="attachment" class="grid grid-cols-2 gap-x-4">
              <div>
                <Attachment :attachment="attachment" />
              </div>
              <div v-if="attachment">
                <button
                  type="button"
                  class="form-submit-button mb-3 mr-3"
                  @click="downloadAttachment"
                >
                  Download
                </button>
                <button
                  type="button"
                  class="form-button"
                  @click="showConfirmPopup('deleteAttachment')"
                >
                  Delete
                </button>
              </div>
            </div>
            <div v-else class="block text-sm text-center">No attachment found.</div>
          </div>
          <div>
            <FormLabel id="file-upload" text="Select file to upload" class="mt-3 mb-3" />
            <FileUpload
              id="file-upload"
              :disabled="!form.agreement.require_accept"
              description="PDF, TXT, DOC, XLS, XLSX up to 20MB"
              @update="files => uploadFiles(files)"
            />
            <div v-for="(error, index) in errors" :key="`error-${index}`" class="form-error">
              {{ error }}
            </div>
            <div v-if="files.length > 0" class="py-4">
              <div class="mb-1 text-sm font-medium text-gray-500">
                Uploading... {{ progressPercentage ? `${parseInt(progressPercentage)}%` : '' }}
              </div>
              <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
                  <li
                    v-for="(file, index) in files"
                    :key="`uploaded-file-${index}`"
                    class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
                  >
                    <div class="flex w-0 flex-1 items-center">
                      <PaperClipIcon class="w-5 text-gray-400" />
                      <span class="ml-2 w-0 flex-1 truncate">{{ file.name }}</span>
                    </div>
                  </li>
                </ul>
              </dd>
            </div>
          </div>
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
        <button
          v-if="organization && auth.can['organizations.delete']"
          type="button"
          class="form-button"
          data-testid="deleteButton"
          @click="showConfirmPopup('deleteOrganization')"
        >
          Delete
        </button>
        <div class="ml-auto">
          <InertiaLink
            v-if="auth.can['organizations.index']"
            as="button"
            type="button"
            :href="$route('organizations.index')"
            class="form-button"
            data-testid="cancelButton"
          >
            Cancel
          </InertiaLink>
          <button
            type="submit"
            :disabled="form.processing"
            class="ml-3 form-submit-button"
            data-testid="submitButton"
          >
            Save
          </button>
        </div>
      </div>
    </form>
    <ConfirmModal
      :open="popup.open"
      :text="popup.text"
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </OrganizationLayout>
</template>

<script>
import {Link as InertiaLink, useForm} from '@inertiajs/inertia-vue3'
import Layout from 'Shared/Layouts/Layout'
import PanelHeading from 'Shared/PanelHeading'
import OrganizationLayout from 'OrganizationsComponents/OrganizationLayout'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import FormLabel from 'Shared/Form/FormLabel'
import UploadSection from 'MediaComponents/UploadSection.vue'
import axios from 'axios'
import {PaperClipIcon} from '@heroicons/vue/solid'
import FileUpload from 'Shared/Form/Uploads/FileUpload.vue'
import Attachment from 'MediaComponents/Attachment'

export default {
  components: {
    FormLabel,
    Attachment,
    FileUpload,
    PaperClipIcon,
    UploadSection,
    FormInput,
    FormCheckbox,
    ConfirmModal,
    OrganizationLayout,
    InertiaLink,
    PanelHeading
  },
  layout: Layout,
  props: {
    auth: Object,
    organization: Object
  },
  setup(props) {
    const form = useForm({
      _method: 'PUT',
      agreement: {
        require_accept: props.organization?.agreement?.require_accept ?? false,
        checkbox_label: props.organization?.agreement?.checkbox_label ?? '',
        link_name: props.organization?.agreement?.link_name ?? '',
        link: props.organization?.agreement?.link ?? '',
        document_name: props.organization?.agreement?.document_name ?? ''
      }
    })

    return { form }
  },
  data() {
    return {
      attachment: this.organization.attachment,
      popup: {
        open: false,
        text: '',
        action: ''
      },
      errors: [],
      files: [],
      progressEvents: []
    }
  },
  computed: {
    progressPercentage() {
      if (this.progressEvents.length === 0) {
        return null
      }

      let loaded = 0
      let total = 0

      this.progressEvents.forEach(event => {
        loaded += event.loaded
        total += event.total
      })

      if (loaded === total) {
        return null
      }

      return (loaded / total) * 100
    }
  },
  watch: {
    organization() {
      this.attachment = this.organization.attachment
    }
  },
  methods: {
    submit() {
      this.form
        .post(this.$route('organizations.documents.update', this.organization.uuid), {
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    uploadFiles(files) {
      this.$page.props.flash.success = null
      this.$page.props.flash.error = null
      this.files = [...files]
      this.errors = []
      this.progressEvents = []

      Array.from(files).forEach((file, index) => {
        const formData = new FormData()

        formData.append('attachment', file)
        axios
          .post(this.$route('organizations.documents.store', this.organization.uuid), formData, {
            onUploadProgress: progressEvent => {
              this.progressEvents[index] = progressEvent
            }
          })
          .then(({data}) => {
            this.attachment = data.attachment
            this.$page.props.flash.success = data.message
          })
          .catch(({response}) => {
            if (response.data.errors) {
              this.errors.push(response.data.errors.attachment[0])
            } else {
              this.errors.push(response.data.message)
            }
          })
          .then(() => {
            this.files.shift()
          })
      })
    },
    downloadAttachment() {
      axios
        .post(
          this.$route('media.download', this.attachment.uuid),
          {},
          {
            responseType: 'blob'
          }
        )
        .then(response => {
          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(response.data)
          link.download = this.attachment.name
          link.click()
          setTimeout(() => {
            window.URL.revokeObjectURL(response.data)
          }, 100)
        })
        .catch(error => {
          console.error(error.response)
        })
    },
    deleteAttachment() {
      this.$inertia
        .post(this.$route('media.destroy', this.attachment.uuid), {
          _method: 'DELETE'
        })
    },
    deleteOrganization() {
      this.$inertia
        .post(this.$route('organizations.destroy', this.organization.uuid), {
          _method: 'DELETE'
        })
    },
    closeConfirmPopup() {
      this.popup.open = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popup.action === 'deleteAttachment') {
        this.popup.open = false
        this.deleteAttachment()
      }
      if (this.popup.action === 'deleteOrganization') {
        this.deleteOrganization()
      }
    },
    showConfirmPopup(action) {
      this.popup.action = action
      if (this.popup.action === 'deleteAttachment') {
        this.popup.text =
          'Are you sure you want to delete this attachment? This action cannot be undone.'
      }
      if (this.popup.action === 'deleteOrganization') {
        this.popup.text =
          'Are you sure you want to delete this organization? This action cannot be undone.'
      }
      document.querySelector('html').classList.add('popup-open')
      this.popup.open = true
    }
  }
}
</script>
