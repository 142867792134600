<template>
  <svg width="231" height="20" viewBox="0 0 231 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M5.02734 14.0419V14.9989H20.0677V0H5.02734V14.0419Z" fill="#111111"/>
    <path d="M5.01726 14.9985H0V20.0001H5.01726V14.9985Z" fill="#111111"/>
    <path d="M37.22 7.01881L34.93 3.29053H33V11.6941H34.93V6.80947L37.11 10.3783H37.33L39.51 6.80947V11.6941H41.44V3.29053H39.51L37.22 7.01881Z" fill="#111111"/>
    <path d="M47.4208 3.28076L44.5508 11.6943H46.6608L47.0808 10.3785H50.2108L50.6408 11.6943H52.7508L49.8808 3.28076H47.4208ZM47.6608 8.57413L48.6608 5.48384L49.6608 8.57413H47.6608Z" fill="#111111"/>
    <path d="M59.1496 6.66985C57.8396 6.28107 57.6296 6.01192 57.6296 5.67298C57.6296 5.33405 57.9296 5.03499 58.5396 5.03499C58.8569 5.02276 59.1696 5.11326 59.431 5.29295C59.6923 5.47265 59.8883 5.73188 59.9896 6.03185L61.6196 5.03499C61.3513 4.45129 60.9156 3.95989 60.3675 3.6228C59.8193 3.28571 59.1833 3.11804 58.5396 3.14094C58.1831 3.1138 57.8248 3.15914 57.4864 3.27419C57.148 3.38924 56.8366 3.57162 56.571 3.81025C56.3055 4.04888 56.0913 4.33881 55.9415 4.66243C55.7917 4.98605 55.7094 5.33664 55.6996 5.69292C55.6996 7.30784 56.9296 7.90596 58.1896 8.25487C59.4496 8.60377 59.9296 8.81311 59.9296 9.32151C59.9296 9.65048 59.6996 10.0094 58.7896 10.0094C58.4053 10.0303 58.0246 9.92588 57.7052 9.71182C57.3859 9.49776 57.1451 9.18576 57.0196 8.82308L55.3496 9.81995C55.6152 10.4814 56.0872 11.0401 56.6959 11.4135C57.3046 11.7869 58.0176 11.9551 58.7296 11.8934C60.5596 11.8934 61.8596 10.8966 61.8596 9.31154C61.8596 7.50722 60.4296 7.05863 59.1496 6.66985Z" fill="#111111"/>
    <path d="M68.6594 6.66985C67.3494 6.28107 67.1394 6.01192 67.1394 5.67298C67.1394 5.33405 67.4394 5.03499 68.0494 5.03499C68.368 5.02219 68.6822 5.11231 68.9453 5.29193C69.2084 5.47156 69.4062 5.73108 69.5094 6.03185L71.1294 5.03499C70.8611 4.45129 70.4254 3.95989 69.8772 3.6228C69.3291 3.28571 68.693 3.11804 68.0494 3.14094C67.6929 3.1138 67.3346 3.15914 66.9962 3.27419C66.6578 3.38924 66.3464 3.57162 66.0808 3.81025C65.8152 4.04888 65.601 4.33881 65.4513 4.66243C65.3015 4.98605 65.2192 5.33664 65.2094 5.69292C65.2094 7.30784 66.4394 7.90596 67.6994 8.25487C68.9594 8.60377 69.4494 8.81311 69.4494 9.32151C69.4494 9.65048 69.2194 10.0094 68.2994 10.0094C67.9175 10.0317 67.5387 9.93013 67.2196 9.71987C66.9006 9.50961 66.6584 9.20197 66.5294 8.84302L64.8594 9.83989C65.125 10.5013 65.597 11.06 66.2057 11.4334C66.8143 11.8068 67.5274 11.9751 68.2394 11.9134C70.0694 11.9134 71.3694 10.9165 71.3694 9.33148C71.3694 7.50722 69.9394 7.05863 68.6594 6.66985Z" fill="#111111"/>
    <path d="M76.8109 3.28076H74.8809V11.6943H76.8109V3.28076Z" fill="#111111"/>
    <path d="M83.8804 9.4711L82.0104 3.29053H79.9004L82.6504 11.6941H85.1104L87.8504 3.29053H85.7404L83.8804 9.4711Z" fill="#111111"/>
    <path d="M92.8909 8.35461H95.9009V6.53035H92.8909V5.13473H96.2009V3.29053H90.9609V11.6941H96.2609V9.84991H92.8909V8.35461Z" fill="#111111"/>
    <path d="M110.7 3.28086H107.5V11.6944H109.43V9.05272H110.7C111.094 9.07585 111.49 9.01835 111.861 8.88377C112.232 8.74919 112.572 8.54037 112.86 8.27012C113.147 7.99986 113.376 7.67389 113.533 7.31219C113.689 6.9505 113.77 6.56072 113.77 6.16679C113.77 5.77286 113.689 5.38309 113.533 5.02139C113.376 4.6597 113.147 4.33372 112.86 4.06347C112.572 3.79322 112.232 3.58439 111.861 3.44981C111.49 3.31523 111.094 3.25774 110.7 3.28086ZM110.7 7.26833H109.43V5.08519H110.7C110.97 5.11104 111.22 5.23616 111.402 5.43614C111.585 5.63612 111.686 5.89661 111.686 6.16679C111.686 6.43698 111.585 6.69747 111.402 6.89745C111.22 7.09743 110.97 7.22255 110.7 7.24839V7.26833Z" fill="#111111"/>
    <path d="M118.86 3.28076H116.93V11.6943H118.86V3.28076Z" fill="#111111"/>
    <path d="M129.12 3.29053H126.93L125.63 5.51354L124.32 3.29053H122.13L124.53 7.38765L122.01 11.6941H124.2L125.63 9.27173L127.05 11.6941H129.24L126.72 7.38765L129.12 3.29053Z" fill="#111111"/>
    <path d="M134.33 8.35461H137.35V6.53035H134.33V5.13473H137.65V3.29053H132.41V11.6941H137.71V9.84991H134.33V8.35461Z" fill="#111111"/>
    <path d="M143.35 3.29053H141.42V11.6941H146.37V9.84991H143.35V3.29053Z" fill="#111111"/>
    <path d="M161.391 4.99517C161.783 4.9669 162.176 5.04456 162.528 5.21998C162.88 5.3954 163.178 5.6621 163.391 5.99204L165.051 4.99517C164.669 4.38176 164.135 3.8763 163.502 3.52721C162.868 3.17812 162.155 2.99709 161.431 3.00144C160.851 2.98642 160.274 3.08901 159.735 3.30299C159.196 3.51697 158.707 3.83788 158.296 4.24622C157.886 4.65456 157.563 5.14182 157.347 5.67841C157.131 6.21501 157.027 6.78974 157.041 7.36772C157.025 7.94652 157.129 8.52234 157.344 9.06011C157.559 9.59788 157.882 10.0863 158.293 10.4958C158.703 10.9052 159.193 11.227 159.733 11.4416C160.272 11.6562 160.85 11.759 161.431 11.744C162.156 11.7532 162.871 11.5742 163.505 11.2247C164.14 10.8751 164.672 10.367 165.051 9.75023L163.391 8.75336C163.179 9.08484 162.882 9.35275 162.529 9.52837C162.177 9.70398 161.783 9.78068 161.391 9.75023C161.058 9.76814 160.726 9.71529 160.416 9.59519C160.106 9.47509 159.826 9.2905 159.593 9.05374C159.36 8.81697 159.181 8.53346 159.066 8.22211C158.952 7.91075 158.906 7.5787 158.931 7.24809C158.941 6.9382 159.013 6.63345 159.143 6.35166C159.272 6.06986 159.457 5.81668 159.686 5.60691C159.915 5.39715 160.184 5.235 160.477 5.12997C160.769 5.02493 161.08 4.97911 161.391 4.99517Z" fill="#111111"/>
    <path d="M174.661 6.17168C174.662 5.79251 174.588 5.41681 174.444 5.06612C174.299 4.71544 174.086 4.39667 173.818 4.12809C173.549 3.85952 173.23 3.64642 172.879 3.50101C172.528 3.35561 172.151 3.28076 171.771 3.28076H168.391V11.6943H170.321V8.933H171.241L172.831 11.6943H174.901L173.111 8.614C173.569 8.38756 173.955 8.04 174.229 7.60929C174.502 7.17858 174.652 6.68127 174.661 6.17168ZM171.771 7.24829H170.321V5.08509H171.771C171.91 5.08453 172.048 5.11296 172.175 5.16857C172.303 5.22419 172.417 5.30575 172.512 5.40804C172.606 5.51033 172.677 5.63109 172.722 5.76259C172.767 5.89409 172.783 6.03342 172.771 6.17168C172.782 6.30914 172.764 6.44739 172.719 6.57769C172.673 6.708 172.602 6.82752 172.508 6.92871C172.414 7.0299 172.3 7.11054 172.173 7.16555C172.046 7.22056 171.909 7.24873 171.771 7.24829Z" fill="#111111"/>
    <path d="M180.211 8.35461H183.231V6.53035H180.211V5.13473H183.531V3.29053H178.291V11.6941H183.591V9.84991H180.211V8.35461Z" fill="#111111"/>
    <path d="M189.38 3.28076L186.51 11.6943H188.62L189.04 10.3785H192.17L192.6 11.6943H194.71L191.84 3.28076H189.38ZM189.62 8.57413L190.62 5.48384L191.62 8.57413H189.62Z" fill="#111111"/>
    <path d="M196.5 5.13473H198.67V11.6941H200.6V5.13473H202.77V3.29053H196.5V5.13473Z" fill="#111111"/>
    <path d="M207.86 3.28076H205.93V11.6943H207.86V3.28076Z" fill="#111111"/>
    <path d="M215.771 3.12128C214.902 3.12128 214.053 3.3782 213.331 3.85951C212.609 4.34082 212.046 5.02488 211.714 5.82512C211.382 6.62535 211.296 7.50578 211.466 8.35496C211.636 9.20414 212.055 9.98389 212.67 10.5955C213.285 11.2072 214.068 11.6232 214.921 11.7909C215.773 11.9586 216.656 11.8705 217.458 11.5378C218.26 11.205 218.945 10.6426 219.426 9.92158C219.907 9.2006 220.163 8.35352 220.161 7.48756C220.165 6.91221 220.054 6.34183 219.835 5.80963C219.615 5.27743 219.292 4.79407 218.883 4.38769C218.475 3.98132 217.989 3.66006 217.455 3.44264C216.92 3.22522 216.348 3.11597 215.771 3.12128ZM215.771 9.98969C215.442 9.99687 215.115 9.9366 214.811 9.81261C214.506 9.68862 214.231 9.50357 214.001 9.26894C213.771 9.03431 213.592 8.7551 213.475 8.44866C213.358 8.14222 213.306 7.81508 213.321 7.48756C213.321 7.16552 213.384 6.84663 213.508 6.5491C213.632 6.25158 213.813 5.98124 214.041 5.75352C214.27 5.52581 214.541 5.34517 214.839 5.22193C215.138 5.09869 215.458 5.03526 215.781 5.03526C216.104 5.03526 216.424 5.09869 216.722 5.22193C217.021 5.34517 217.292 5.52581 217.52 5.75352C217.749 5.98124 217.93 6.25158 218.054 6.5491C218.177 6.84663 218.241 7.16552 218.241 7.48756C218.256 7.8168 218.203 8.14566 218.085 8.45348C217.967 8.76131 217.786 9.04147 217.554 9.2764C217.322 9.51133 217.044 9.69596 216.737 9.8187C216.43 9.94144 216.101 9.99966 215.771 9.98969Z" fill="#111111"/>
    <path d="M228.391 3.29053V7.85618L225.141 3.29053H223.691V11.6941H225.621V7.12847L228.871 11.6941H230.321V3.29053H228.391Z" fill="#111111"/>
  </svg>
</template>
