<template>
  <label>
    <input
      class="peer absolute opacity-0"
      :checked="index === 0"
      type="radio"
      :name="extensionType.name"
      :value="extension.uuid"
      @change="onChange($event)"
    />
    <div
      class="relative cursor-pointer border-3 border-transparent after:block after:pb-[100%] after:content-[''] peer-checked:border-atc-secondary"
    >
      <img
        class="absolute h-full w-full object-cover"
        :src="extension.preview?.[product.sku] ?? extension.media[0]"
      />
    </div>
    <p class="mt-1 text-xs font-semibold uppercase lg:text-sm">{{ extension.name }}</p>
    <p v-if="extension.price > 0" class="text-xs font-light lg:text-sm">+ ${{ formattedPrice }}</p>
    <a
      v-if="extension.description"
      class="cursor-pointer text-xs font-light uppercase underline"
      @click="openPopup(extension)"
      >View details</a
    >
  </label>
</template>

<script>
import {formatPrice} from 'Shared/helpers.js'
export default {
  name: 'ConfiguratorExtensionRadioInput',
  inject: ['openPopup'],
  props: {
    extension: Object,
    extensionType: Object,
    index: Number,
    product: Object
  },
  data() {
    return {
      selectedExtensionData: {
        uuid: this.extensionType.uuid,
        name: this.extensionType.name,
        multiselect: this.extensionType.multiselect,
        selectedExtensions: [this.extension]
      }
    }
  },
  computed: {
    formattedPrice() {
      if (!this.extension.price) {
        return ''
      }
      return this.formatPrice(this.extension.price)
    }
  },
  mounted() {
    if (this.index == 0) {
      this.$emit('extension-choice', this.selectedExtensionData)
    }
  },
  methods: {
    formatPrice,
    onChange() {
      this.$emit('extension-choice', this.selectedExtensionData)
    }
  }
}
</script>
