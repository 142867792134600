<template>
  <div>
    <InertiaHead title="Parts" />

    <Panel class="relative">
      <div class="flex justify-between">
        <PanelHeading :tabs="tabs" :current="2" />
        <div class="flex w-full flex-row sm:w-auto">
          <PanelActions
            :create="$route('parts.create')"
            :widget="false"
            create-permission="parts.create"
            text="part"
          />
          <ExcelImport
            v-if="$attrs.auth.can['parts.import']"
            route="parts.import"
            button-label="Import Parts"
            hint="Allowed formats: xlsx, xls"
            modal-title="Import parts from file"
          />
        </div>
      </div>
      <div class="pb-12">
        <div class="flex flex-row justify-between items-center w-full pb-5">
          <div class="flex items-center">
            <div class="min-w-[18rem]">
              <VueMultiselect
                v-model="type"
                :options="types"
                placeholder="Select type"
                label="name"
                track-by="uuid"
                :close-on-select="true"
                class="form-multiselect-edit"
                :show-labels="false"
                @select="value => buildQuery('type', value.uuid)"
              >
                <template #singleLabel="props">
                  <span>{{ props.option.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="buildQuery('type')" />
                </template>
              </VueMultiselect>
            </div>
            <button
              class="text-[0.92rem] font-medium whitespace-nowrap ml-3 hover:text-atc-secondary duration-200"
              @click="showTypeModal = true"
            >
              Manage Types
            </button>
          </div>
          <FormInput
            v-model="term"
            class="form-input-edit w-64"
            placeholder="Search"
            @keyup="e => buildQuery('term', e.target.value)"
          />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header w-36">
                <SortingColumn name="sku" :sort="sort" @click="sortBy('sku')">SKU #</SortingColumn>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn name="name" :sort="sort" @click="sortBy('name')">Name</SortingColumn>
              </th>
              <th scope="col" class="table-header">Type</th>
              <th scope="col" class="table-header">Preview</th>
              <th scope="col" class="table-header">
                <SortingColumn name="price" :sort="sort" @click="sortBy('price')">Price</SortingColumn>
              </th>
              <th scope="col" class="table-header w-16" />
            </tr>
          </thead>

          <tbody class="table-body">
            <InertiaLink
              v-for="part in parts.data"
              :key="part.uuid"
              as="tr"
              :href="$route('parts.edit', part.uuid)"
              class="table-row cursor-pointer"
            >
              <td class="table-cell">{{ part.sku }}</td>
              <td class="table-cell">{{ part.name }}</td>
              <td class="table-cell">{{ part.type?.name }}</td>
              <td class="table-cell">
                <div v-if="part?.preview" class="flex">
                  <img class="block -my-2 mr-2 w-9 h-9 object-cover" :src="part?.preview" alt="Part preview" />
                </div>
              </td>
              <td class="table-cell">${{ formatPrice(part.price) }}</td>
              <td class="table-cell">
                <span title="Edit"><PencilIcon class="w-4 ml-auto" /></span>
              </td>
            </InertiaLink>

            <tr v-if="parts.data.length === 0">
              <td class="table-cell text-center" colspan="6">No parts found.</td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="parts.links"
          :current-page="parts.current_page"
          @change-page="key => buildQuery('page', key)"
        />
      </div>
      <div class="text-sm absolute right-4 bottom-2">
        Records per page:
        <input
          v-model="records"
          class="w-12 px-1 text-sm border-none focus:ring-0 text-atc-text"
          type="number"
          max="99"
          min="1"
          @change="e => buildQuery('records', e.target.value)"
        />
      </div>
    </Panel>
    <VTailwindModal v-model="showTypeModal" title="Types" z-index="5" @close="closeModal">
      <TypesManagement ref="typesManagement" :types="types" :config="typesConfig" />
    </VTailwindModal>
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import {formatPrice} from 'Shared/helpers'
import {PencilIcon, SelectorIcon} from '@heroicons/vue/outline'
import {VueDraggableNext} from 'vue-draggable-next'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import TypesManagement from 'ProductsComponents/TypesManagement'
import VTailwindModal from 'ProductsComponents/VTailwindModal'
import ExcelImport from 'Shared/ExcelImport'
import FormInput from 'Shared/Form/FormInput'
import SortingColumn from 'Shared/SortingColumn'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    InertiaHead,
    InertiaLink,
    PencilIcon,
    SelectorIcon,
    VueDraggableNext,
    VueMultiselect,
    TypesManagement,
    VTailwindModal,
    ExcelImport,
    FormInput,
    SortingColumn,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading
  },
  layout: Layout,
  props: {
    parts: Object,
    types: Array
  },
  data() {
    return {
      tabs: [
        { url: this.$route('products.index'), label: 'Products' },
        { url: this.$route('extensions.index'), label: 'Extensions' },
        { url: this.$route('parts.index'), label: 'Parts' }
      ],
      sort: {
        column: '',
        order: ''
      },
      term: '',
      records: 25,
      type: '',
      showTypeModal: false,
      query: {},
      typesConfig: {
        route: 'parts.types',
        showMultiSelect: false,
        draggable: false
      }
    }
  },
  methods: {
    formatPrice,
    buildQuery(key, value = null) {
      if (key !== 'page') {
        delete this.query.page
      }
      if (value) {
        this.query = {
          ...this.query,
          [key]: value
        }
      } else {
        this[key] = ''
        delete this.query[key]
      }
      this.fetchData(this.query)
    },
    selectType(value) {
      this.query = {
        ...this.query,
        type: value.uuid
      }
      this.fetchData(this.query)
    },
    clearSelection() {
      this.type = ''
      this.product = ''
      this.selectType(this.type)
    },
    fetchData(query) {
      this.$inertia
        .get(this.$route('parts.index'), query, {
          preserveState: true,
          preserveScroll: true,
          replace: true
        })
    },
    closeModal(close) {
      close()
      this.$refs.typesManagement.resetType()
    },
    sortBy(column) {
      this.sort = {
        column: column,
        order: this.sort.column === column ?
          (this.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
      this.query = {
        ...this.query,
        column: this.sort.column,
        order: this.sort.order
      }
      this.fetchData(this.query)
    }
  }
}
</script>
