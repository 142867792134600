<template>
  <div>
    <Head title="Distributors" />

    <Panel>
      <div class="flex justify-between">
        <PanelHeading heading="Distributors" />
        <PanelActions
          :create="$route('distributors.create')"
          :widget="false"
          create-permission="distributors.create"
          text="distributor"
        />
      </div>
      <div class="pb-12">
        <div class="flex pb-5">
          <FormInput v-model="term" class="form-input-edit ml-auto w-64" placeholder="Search" @keyup="e => buildQuery('term', e.target.value)" />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">
                <SortingColumn name="name" :sort="sort" @click="sortBy('name')">Name</SortingColumn>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn name="address->street" :sort="sort" @click="sortBy('address->street')">Address</SortingColumn>
              </th>
              <th scope="col" class="table-header">Phone</th>
              <th scope="col" class="table-header">Email</th>
              <th scope="col" class="table-header w-16" />
            </tr>
          </thead>
          <tbody class="table-body">
            <Link v-for="distributor in distributors.data" :key="distributor.uuid" as="tr" :href="$route('distributors.edit', distributor.uuid)" class="table-row cursor-pointer">
              <td class="table-cell truncate">{{ distributor.name }}</td>
              <td class="table-cell truncate" @click.stop>
                <a :href="`https://maps.google.com/?q=${formatAddress(distributor.address)}`" target="_blank">
                  {{ formatAddress(distributor.address) }}
                </a>
              </td>
              <td class="table-cell truncate" @click.stop>
                <a :href="`tel:${distributor.phone_number}`">{{ distributor.phone_number ?? '—' }}</a>
              </td>
              <td class="table-cell truncate" @click.stop>
                <a :href="`mailto:${distributor.email}`">{{ distributor.email ?? '—' }}</a>
              </td>
              <td class="table-cell truncate">
                <span title="Edit"><PencilIcon class="w-4 ml-auto" /></span>
              </td>
            </Link>

            <tr v-if="distributors.data.length === 0">
              <td class="table-cell text-center" colspan="5">No distributors found.</td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="distributors.links"
          class="flex items-center justify-center mt-5"
          :current-page="distributors.current_page"
          @change-page="key => buildQuery('page', key)"
        />
      </div>
    </Panel>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import { formatAddress } from 'Shared/helpers'
import { PencilIcon } from '@heroicons/vue/outline'
import FormInput from 'Shared/Form/FormInput'
import SortingColumn from 'Shared/SortingColumn'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    Head,
    Link,
    PencilIcon,
    FormInput,
    SortingColumn,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading
  },
  layout: Layout,
  props: {
    distributors: Array
  },
  data() {
    return {
      sort: {
        column: '',
        order: ''
      },
      term: '',
      type: '',
      query: {}
    }
  },
  methods: {
    formatAddress,
    buildQuery(key, value = null) {
      if (key !== 'page') {
        delete this.query.page
      }
      if (value) {
        this.query = {
          ...this.query,
          [key]: value
        }
      } else {
        this[key] = ''
        delete this.query[key]
      }
      this.fetchData(this.query)
    },
    fetchData(query) {
      this.$inertia
        .get(this.$route('distributors.index'), query, {
          preserveState: true,
          preserveScroll: true,
          replace: true
        })
    },
    sortBy(column) {
      this.sort = {
        column: column,
        order: this.sort.column === column ?
          (this.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
      this.query = {
        ...this.query,
        column: this.sort.column,
        order: this.sort.order
      }
      this.fetchData(this.query)
    }
  }
}
</script>
