<template>
  <Layout :confirmation="true" :auth="$attrs.auth" @on-confirm="passValue($event)">
    <div>
      <InertiaHead title="Order activity" />
      <RecordActivity
        :activity="activity"
        :entity="entity ? entity : 'order'"
        :errors="$attrs.errors"
        :messages="messages"
        :on-confirm="confirmSignal"
        :permissions="$attrs.auth.can"
        :print-data="entity === 'parts-order' ? formatPartOrderPrintData(order, orderParts) : formatOrderPrintData(order)"
        :record="order"
        :statuses="statuses"
      />
    </div>
  </Layout>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Layout from 'Shared/LeadsAndOrders/LayoutWide'
import RecordActivity from 'Shared/LeadsAndOrders/RecordActivity'
import {formatOrderPrintData, formatPartOrderPrintData} from 'Shared/helpers'

export default {
  components: {
    InertiaHead,
    RecordActivity,
    Layout
  },
  props:{
    order: Object,
    messages: Object,
    activity: Object,
    entity: String,
    orderParts: Array,
    statuses: Array
  },
  data () {
    return {
      confirmSignal: null
    }
  },
  methods:{
    formatOrderPrintData,
    formatPartOrderPrintData,
    passValue(e) {
      this.confirmSignal = e
    }
  }
}
</script>
