<template>
  <div>
    <label>
      <span v-if="label" class="mb-1 block text-[15px] font-heading font-medium">
        {{ label }} <span v-if="required" class="text-red-500">*</span>
      </span>
      <select
        class="border-1 border-gray-700 w-full p-2 invalid:text-gray-500 text-[15px] font-normal focus:border-atc-secondary focus:ring-atc-secondary" v-bind="{
          ...$attrs,
          onChange: ($event) => { $emit('update:modelValue', $event.target.value) }
        }"
      >
        <option disabled value="default" class="text-gray-500" :selected="modelValue === null">Select an option</option>
        <option
          v-for="option in options"
          :key="trackBy ? option[trackBy] : option"
          class="text-black"
          :value="trackBy ? option[trackBy] : option"
          :selected="trackBy ? option[trackBy] === modelValue : option === modelValue"
        >
          {{ optionLabel ? option[optionLabel] : option }}
        </option>
      </select>
    </label>
    <div v-if="error" class="text-red-600 text-xs font-light mt-2">{{ error }}</div>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    options: {
      type: Array,
      required: true
    },
    optionLabel: {
      type: String
    },
    trackBy: {
      type: String
    },
    required: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    }
  },
  emits: ['update:modelValue']
}
</script>
