<template>
  <section aria-labelledby="notes-title" class="mt-5 xl:mt-3">
    <div class="divide-y divide-gray-200">
      <PanelHeading heading="All documents" class="pb-4 pt-4" />
      <div class="py-6">
        <ul v-if="attachments.data.length" role="list" class="grid grid-cols-2 gap-x-4 gap-y-8 sm:grid-cols-3 sm:gap-x-6 lg:grid-cols-4 xl:gap-x-8">
          <li v-for="attachment in attachments.data" :key="attachment.uuid" class="relative">
            <Link :href="$route(`${entity}s.documents.show`, [record.uuid, attachment.uuid])">
              <Attachment :attachment="attachment" />
            </Link>
          </li>
        </ul>
        <div v-else class="block text-sm text-center">No attachments found.</div>
        <Pagination
          :links="attachments.links"
          :current-page="attachments.current_page"
          allow-change-pagination
          @change-page="key => $emit('changePage', key)"
        />
      </div>
    </div>
  </section>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import Attachment from 'MediaComponents/Attachment'
import Pagination from 'Shared/Pagination'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    Link,
    Attachment,
    Pagination,
    PanelHeading
  },
  props: {
    attachments: Object,
    record: Object,
    entity: String
  },
  emits: ['changePage']
}
</script>
