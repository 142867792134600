import axios from 'axios'
import {Inertia} from '@inertiajs/inertia'

export const getCoordinatesByZipcode = async (zipcode, country = 'US') => {
  try {
    const {data} = await axios.get(
      'https://maps.googleapis.com/maps/api/geocode/json?address=' +
      zipcode +
      ',' +
      country +
      '&key=' +
      Inertia.page.props.googleMapsApiKey
    )
    if (data.error_message) {
      throw new Error(data.error_message)
    }
    if (data.status === 'ZERO_RESULTS') {
      return null
    }
    return data.results[0].geometry.location
  } catch (error) {
    console.error(error.message)
    return null
  }
}

export const getCountries = () => {
  return axios
    .get('https://api.countrystatecity.in/v1/countries', {
      headers: {
        'X-CSCAPI-KEY': Inertia.page.props.countryStateCityApiKey
      }
    })
    .catch(error => console.error(error))
}

export const getStates = () => {
  return axios
    .get('https://api.countrystatecity.in/v1/countries/US/states', {
      headers: {
        'X-CSCAPI-KEY': Inertia.page.props.countryStateCityApiKey
      }
    })
    .then(response => {
      response.data = response.data.filter(state => {
        if (
          state.iso2 === 'UM' ||
          state.iso2 === 'UM-67' ||
          state.iso2 === 'UM-71' ||
          state.iso2 === 'UM-76' ||
          state.iso2 === 'UM-84' ||
          state.iso2 === 'UM-86' ||
          state.iso2 === 'UM-89' ||
          state.iso2 === 'UM-95' ||
          state.iso2 === 'MP'
        ) {
          return
        }
        return state
      })

      response.data = response.data.sort((a, b) => {
        return a.name.localeCompare(b.name)
      })

      return response
    })
    .catch(error => console.error(error))
}
