<template>
  <div>
    <span
      class="flex rounded-full w-auto py-2 justify-center text-[0.91rem] font-medium uppercase leading-7"
      :class="`${statusClass} ${customClass}`"
      :data-status="value"
    >
      {{ label }}
    </span>
  </div>
</template>

<script>
export default {
  props: {
    active: {
      type: Boolean,
      default: true
    },
    label: String,
    value: String,
    customClass: {
      type: String,
      default: ''
    }
  },
  computed: {
    statusClass() {
      if (this.active) {
        if (this.$page.props.allStatuses.successes.includes(this.value) ) {
          return 'text-atc-white bg-atc-success'
        }
        if (this.$page.props.allStatuses.warnings.includes(this.value)) {
          return 'text-atc-white bg-atc-warning'
        }
        if (this.$page.props.allStatuses.errors.includes(this.value)) {
          return 'text-atc-white bg-atc-error'
        }
        if (this.$page.props.allStatuses.coldStats.includes(this.value)) {
          return 'text-atc-white bg-cyan-300'
        }
      }
      return 'bg-atc-gray text-atc-graphite'
    }
  }
}
</script>
