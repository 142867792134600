<template>
  <NewsLayout :article="article" :auth="$props.auth">
    <Search v-model="query.filter.search" placeholder="Type to search attachments" />

    <main v-if="article" class="flex-1">
      <div class="xl:grid xl:grid-cols-3">
        <div class="px-6 lg:px-8 xl:col-span-2 xl:border-r xl:border-gray-200">
          <DocumentsSection :attachments="attachments" :record="article" entity="new" />
        </div>

        <aside class="px-6 lg:px-8 xl:col-span-1">
          <section aria-labelledby="activity-title" class="mt-6 lg:mt-8">
            <div class="space-y-6 pb-16">
              <div>
                <div class="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
                  <img :src="attachment.reference" alt="" class="object-cover" />
                </div>
                <div class="mt-4 flex items-start justify-between">
                  <div class="w-full">
                    <h2 class="break-words text-lg font-medium text-gray-900">
                      <span class="sr-only">Details for </span>{{ attachment.name }}
                    </h2>
                    <p class="text-sm font-medium text-gray-500">
                      {{ formatBytes(attachment.size) }}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <h3 class="font-medium text-gray-900">Information</h3>
                <dl class="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                  <div class="flex justify-between py-3 text-sm font-medium">
                    <dt class="text-gray-500">Uploaded by</dt>
                    <dd class="text-gray-900">{{ attachment.user.name }}</dd>
                  </div>

                  <div class="flex justify-between py-3 text-sm font-medium">
                    <dt class="text-gray-500">Created</dt>
                    <dd class="text-gray-900">{{ formatDate(attachment.created_at) }}</dd>
                  </div>
                </dl>
              </div>
              <div class="flex">
                <button type="button" class="form-submit-button flex-1" @click="downloadAttachment">
                  Download
                </button>
                <button
                  v-if="$page.props.auth.role.name === 'admin'"
                  type="button"
                  class="form-button ml-3 flex-1"
                  @click="showConfirmPopup('deleteAttachment')"
                >
                  Delete
                </button>
              </div>
            </div>
          </section>
        </aside>
      </div>
    </main>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      :text="popupText"
      button-label="Confirm"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </NewsLayout>
</template>

<script>
import {Inertia} from '@inertiajs/inertia'
import {formatBytes} from 'Shared/helpers'
import {throttle} from 'lodash'
import axios from 'axios'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import DocumentsSection from 'MediaComponents/DocumentsSection'
import FormInput from 'Shared/Form/FormInput'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import TipTap from 'NewsComponents/TipTap'
import Search from 'Shared/Misc/Search'
import UploadSection from 'MediaComponents/UploadSection'
import NewsLayout from 'NewsComponents/NewsLayout'

export default {
  components: {
    ConfirmModal,
    DocumentsSection,
    FormInput,
    Icon,
    Layout,
    LoadingButton,
    Pagination,
    Panel,
    TipTap,
    Search,
    UploadSection,
    NewsLayout
  },
  props: {
    article: Object,
    attachment: Object,
    attachments: Array,
    auth: Object
  },
  layout: Layout,
  data() {
    return {
      isConfirmPopupOpen: false,
      popupAction: '',
      popupText: '',
      query: {
        page: this.attachments.current_page,
        filter: {}
      }
    }
  },
  computed: {
    progressPercentage() {
      if (this.progressEvents.length === 0) {
        return null
      }

      let loaded = 0
      let total = 0

      this.progressEvents.forEach(event => {
        loaded += event.loaded
        total += event.total
      })

      if (loaded === total) {
        return null
      }

      return (loaded / total) * 100
    }
  },
  methods: {
    formatBytes,
    downloadAttachment() {
      axios
        .post(
          this.$route('media.download', this.attachment.uuid),
          {},
          {
            responseType: 'blob'
          }
        )
        .then(response => {
          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(response.data)
          link.download = this.attachment.name
          link.click()
          setTimeout(() => {
            window.URL.revokeObjectURL(response.data)
          }, 100)
        })
        .catch(error => {
          console.error(error.response)
        })
    },
    deleteAttachment() {
      this.$inertia.post(this.$route('media.destroy', this.attachment.uuid), {
        _method: 'DELETE'
      })
    },
    formatDate(date) {
      date = new Date(date)
      return date.toLocaleString('en-US', {dateStyle: 'long'})
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'deleteAttachment') {
        this.deleteAttachment()
      }
      if (this.popupAction === 'redirect') {
        this.$inertia.visit(this.redirectLink)
      }
    },
    showConfirmPopup(action) {
      this.popupAction = action
      if (this.popupAction === 'deleteAttachment') {
        this.popupText =
          'Are you sure you want to delete this attachment? This action cannot be undone.'
      }
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  },
  watch: {
    query: {
      handler: throttle(function (newQuery) {
        Inertia.get(
          route(`news.documents.show`, [this.article.uuid, this.attachment.uuid]),
          newQuery,
          {
            preserveState: true,
            preserveScroll: true,
            replace: true
          }
        )
      }, 150),
      deep: true
    }
  }
}
</script>
