<template>
<carousel ref="slider" :items-to-show="1" class="configurator-carousel">
    <slide v-for="slide in images" :key="slide" class="p-3 lg:p-10">
        <img :src="slide" alt="" class="">
    </slide>
    <template #addons>
        <navigation />
    </template>
</carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css';
import {
    Carousel,
    Slide,
    Navigation
} from 'vue3-carousel';
export default {
    props: {
        images: Array
    },
    components: {
        Carousel,
        Slide,
        Navigation
    },
    watch: {
        images: {
            deep: true,
            handler: function () {
                this.$refs.slider.slideTo(0)
            }
        }
    }
}
</script>

<style>
.carousel.configurator-carousel,
.configurator-carousel .configurator-carousel .carousel__viewport,
.configurator-carousel .carousel__track,
.configurator-carousel .carousel__slide,
.carousel__slide img {
    max-height: 25vh;
}

@media (min-width: 768px) {

    .carousel.configurator-carousel,
    .configurator-carousel .configurator-carousel .carousel__viewport,
    .configurator-carousel .carousel__track,
    .configurator-carousel .carousel__slide,
    .carousel__slide img {
        max-height: 30vh;
    }

}

@media (min-width: 1024px) {

    .carousel.configurator-carousel,
    .configurator-carousel .configurator-carousel .carousel__viewport,
    .configurator-carousel .carousel__track,
    .configurator-carousel .carousel__slide,
    .carousel__slide img {
        max-height: 60vh;
    }

}

.configurator-carousel .carousel__next,
.configurator-carousel .carousel__prev {
    background: transparent;
    border: 1px solid black;
    border-radius: 0;
}

@media(max-width: 1023px) {
    .configurator-carousel .carousel__next {
        right: 5px;
    }

    .configurator-carousel .carousel__prev {
        left: 5px;
    }
}

.configurator-carousel .carousel__next--in-active,
.configurator-carousel .carousel__prev--in-active {
    display: none;
}

.configurator-carousel .carousel__icon {
    fill: black;
}
</style>
