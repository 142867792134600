<template>
  <Panel data-testid="verify-email-panel" class="py-8 text-center">
    <InertiaHead title="Verify email" />

    <div class="pb-1">You have to verify email address to get full access to your account.</div>
    <div class="pb-5">Check your email inbox for verification link.</div>

    <div class="flex">
      <PrimaryButton class="m-auto" @click="resendLink">Resend verification link</PrimaryButton>
    </div>
  </Panel>
</template>

<script>
import { Head as InertiaHead } from '@inertiajs/inertia-vue3'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PrimaryButton from 'Shared/Buttons/PrimaryButton'

export default {
  components: {
    PrimaryButton,
    InertiaHead,
    Panel
  },
  layout: Layout,
  methods: {
    resendLink() {
      this.$inertia.post(this.$route('verification.send'))
    }
  }
}
</script>
