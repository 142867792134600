<template>
  <div class="flex">
    <SidePanel
      :entity="entity"
      :form="sidePanelData"
      :record="record"
      :permissions="permissions"
      :disabled-inputs="true"
      :disabled-multiselects="true"
      :disabled-multiselects-dist="true"
      @on-print-price="printPriceTable"
      @on-print-parts="printPartsList"
      @on-delete="showConfirmPopup('delete')"
    />

    <Panel class-name="w-full xl:w-4/5 ml-4 mt-8 md:mt-12 md:ml-12 sm:px-0 lg:px-0 main-panel h-fit">
      <FlashMessages />

      <div class="border-b-2 border-gray-200 px-4 sm:px-6 lg:px-8">
        <Tabs :tabs="tabs" />
      </div>

      <div class="flex min-h-full">
        <div class="flex w-0 flex-1 flex-col">
          <div class="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white">
            <div class="flex flex-1 justify-between px-4">
              <div class="flex flex-1">
                <form class="flex w-full lg:ml-0" action="#" method="GET">
                  <label for="search-field" class="sr-only">Type to search attachments</label>
                  <div class="relative w-full text-gray-400 focus-within:text-gray-600">
                    <div
                      class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3"
                    >
                      <SearchIcon class="h-5 w-5" />
                    </div>
                    <input
                      id="search-field"
                      v-model="term"
                      class="block h-full w-full border-transparent py-2 pl-10 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
                      placeholder="Type to search attachments"
                      type="search"
                      name="search"
                      @keyup="e => buildQuery('term', e.target.value)"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>

          <main class="flex-1">
            <div class="xl:grid xl:grid-cols-3">
              <div class="px-6 lg:px-8 xl:col-span-2 xl:border-r xl:border-gray-200">
                <DocumentsSection
                  :attachments="allAttachments"
                  :record="record"
                  :entity="entity"
                  @change-page="key => buildQuery('page', key)"
                />
              </div>

              <aside class="px-6 lg:px-8 xl:col-span-1">
                <UploadSection
                  :files="files"
                  :errors="errors"
                  :progress="progressPercentage"
                  @update="uploadFiles"
                />
              </aside>
            </div>
          </main>
        </div>
      </div>
    </Panel>
  </div>
  <ConfirmModal
    :open="isConfirmPopupOpen"
    :text="popupText"
    button-label="Confirm"
    @on-close="closeConfirmPopup"
    @on-confirm="confirmAction"
  />

  <FullPageLoader />
  <Pdf v-if="record" ref="pdf" :record="record" :entity="entity" :data="printData" />
</template>

<script>
import {useForm} from '@inertiajs/inertia-vue3'
import axios from 'axios'
import {SearchIcon} from '@heroicons/vue/outline'
import DocumentsSection from 'MediaComponents/DocumentsSection'
import UploadSection from 'MediaComponents/UploadSection'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import SidePanel from 'Shared/LeadsAndOrders/SidePanel'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import FlashMessages from 'Shared/FlashMessages'
import FullPageLoader from 'Shared/FullPageLoader'
import Tabs from 'Shared/Navigation/Tabs'
import Pdf from 'Shared/PDF/PartsList'

export default {
  components: {
    Pdf,
    SearchIcon,
    DocumentsSection,
    UploadSection,
    FileUpload,
    SidePanel,
    ConfirmModal,
    Panel,
    PanelHeading,
    FlashMessages,
    FullPageLoader,
    Tabs
  },
  props: {
    record: Object,
    attachments: Object,
    entity: String,
    permissions: Object,
    onConfirm: Object,
    printData: Object,
    statuses: Array
  },
  setup() {
    const form = useForm({
      _method: 'POST',
      attachments: []
    })

    return {form}
  },
  data() {
    return {
      allAttachments: this.attachments,
      tabs: [
        {
          label: 'Details',
          route: this.$route(`${this.entity}s.edit`, this.record.uuid),
          current: [`${this.entity}s.edit`]
        },
        {
          label: 'Documents',
          route: this.$route(`${this.entity}s.documents.index`, this.record.uuid),
          current: [`${this.entity}s.documents.index`, `${this.entity}s.documents.show`]
        },
        {
          label: 'Activity',
          route: this.$route(`${this.entity}s.activity.index`, this.record.uuid),
          current: [`${this.entity}s.activity.index`]
        }
      ],
      sidePanelData: {
        client: {
          firstName: this.record?.client?.firstName ?? '',
          lastName: this.record?.client?.lastName ?? '',
          email: this.record?.client?.email ?? null,
          phone: this.record?.client?.phone ?? null,
          address: this.record?.client?.address ?? null,
          city: this.record?.client?.city ?? null,
          country: this.record?.client?.country ?? 'US',
          state: this.record?.client?.state ?? null,
          zipCode: this.record?.client?.zipCode ?? null,
          clientNotes: this.record?.client?.clientNotes ?? null
        },
        distributor: this.record?.distributor ?? null,
        dealer: this.record?.dealer ?? null,
        dealership: this.record?.dealership ?? null,
        distMember: this.record?.distMember ?? null
      },
      isConfirmPopupOpen: false,
      redirectLink: '',
      popupAction: '',
      popupText: '',
      saved: true,
      term: '',
      query: {},
      files: [],
      errors: [],
      progressEvents: []
    }
  },
  computed: {
    progressPercentage() {
      if (this.progressEvents.length === 0) {
        return null
      }

      let loaded = 0
      let total = 0

      this.progressEvents.forEach(event => {
        loaded += event.loaded
        total += event.total
      })

      if (loaded === total) {
        return null
      }

      return (loaded / total) * 100
    }
  },
  watch: {
    attachments(newAttachments) {
      this.allAttachments = newAttachments
    },
    onConfirm(e) {
      this.onRedirect(e)
    },
    errors: {
      handler(newVal, oldVal) {
        if (newVal.length <= 0) {
          return
        }
        this.$page.props.flash.error = `${newVal.length} upload error(s) occurred`
      },
      deep: true
    }
  },
  methods: {
    uploadFiles(files) {
      this.$page.props.flash.success = null
      this.$page.props.flash.error = null
      this.files = [...files]
      this.errors = []
      this.progressEvents = []

      files.forEach((file, index) => {
        const formData = new FormData()

        formData.append('attachment', file)
        axios
          .post(this.$route(`${this.entity}s.documents.store`, this.record.uuid), formData, {
            onUploadProgress: progressEvent => {
              this.progressEvents[index] = progressEvent
            }
          })
          .then(({data}) => {
            this.allAttachments.data.unshift(data.attachment)
            this.$page.props.flash.success = data.message
          })
          .catch(({response}) => {
            if (response.data.errors) {
              this.errors.push(response.data.errors.attachment[0])
            } else {
              this.errors.push(response.data.message)
            }
          })
          .then(() => {
            this.files.shift()
          })
      })
    },
    deleteRecord() {
      this.$inertia.post(this.$route(`${this.entity}s.destroy`, this.record.uuid), {
        _method: 'DELETE'
      })
    },
    async printPriceTable() {
      try {
        const response = await axios.get(this.$route(`${this.entity}s.pdf`, this.record.uuid), {
          responseType: 'blob'
        })

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const newWindow = window.open(url, '_blank')

        if (newWindow) {
          const interval = setInterval(() => {
            if (newWindow.document.readyState === 'complete') {
              clearInterval(interval)
              newWindow.print()
            }
          }, 100)
        } else {
          alert('Popup blocked. Please allow popups for this website.')
        }
      } catch (error) {
        console.error('Error printing PDF: ', error)
        alert('An error occurred while printing the PDF.')
      }
    },
    printPartsList() {
      this.$refs.pdf.exportToPDF()
    },
    onRedirect(e) {
      this.redirectLink = e.target.pathname + e.target.search
      if (!this.saved) {
        this.showConfirmPopup('redirect')
      } else {
        this.popupAction = 'redirect'
        this.confirmAction()
      }
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'delete') {
        this.deleteRecord()
      }
      if (this.popupAction === 'redirect') {
        this.$inertia.visit(this.redirectLink)
      }
    },
    showConfirmPopup(action) {
      this.popupAction = action
      if (this.popupAction === 'delete') {
        this.popupText = `Are you sure you want to delete this ${this.entity}? This action cannot be undone.`
      }
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    buildQuery(key, value = null) {
      if (key !== 'page') {
        delete this.query.page
      }
      if (value) {
        this.query = {
          ...this.query,
          [key]: value
        }
      } else {
        this[key] = ''
        delete this.query[key]
      }
      this.fetchData(this.query)
    },
    fetchData(query) {
      this.$inertia.get(this.$route(`${this.entity}s.documents.index`, this.record.uuid), query, {
        preserveState: true,
        preserveScroll: true,
        replace: true
      })
    }
  }
}
</script>
