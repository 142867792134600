<template>
  <div class="pb-5 lg:pb-0 px-5 lg:px-0">
    <div class="flex justify-between flex-col lg:flex-row">
      <h1 class="font-heading text-lg mt-4 lg:mt-0 lg:text-4xl">{{ productName }}</h1>
      <div v-if="showProductPrice" class="flex items-center">
        <p class="text-gray-800 font-light text-sm mr-3">Starting at</p>
        <p class="font-bold text-sm lg:text-2xl"> ${{ price }}</p>
      </div>
    </div>
    <div class="mt-3 lg:mt-20 lg:max-w-[80%] mx-auto">
      <ConfiguratorSlider :images="images" />
    </div>
  </div>
</template>

<script>
import ConfiguratorSlider from 'ConfiguratorComponents/ConfiguratorSlider'
export default {
  components: {
    ConfiguratorSlider
  },
  props: {
    productName: String,
    showProductPrice: Boolean,
    price: String,
    images: Array
  }

}
</script>
