<template>
  <div>
    <Head title="Leads" />
    <RecordList
      :records="leads"
      :filters="true"
      :widget="false"
      name="Leads"
      :statuses="formattedStatuses"
      :dealerships="dealerships"
      :permissions="$attrs.auth.can"
    />
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from 'Shared/Layouts/Layout'
import RecordList from 'Shared/LeadsAndOrders/RecordList'

export default {
  components: {
    Head,
    RecordList
  },
  layout: Layout,
  props:{
    dealerships: Array,
    leads: Object,
    statuses: Array
  },
  data() {
    return {
      formattedStatuses: this.statuses.slice(0, -1)
    }
  }
}
</script>
