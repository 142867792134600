<template>
  <div ref="externalEvents" class="mb-5 p-3 bg-atc-gray">
    <div class="fc-event fc-h-event fc-daygrid-event fc-daygrid-block-event w-32 p-1 bg-atc-secondary border-none cursor-pointer">
      <div class="fc-event-main">Drag onto calendar</div>
    </div>
  </div>

  <FullCalendar ref="fullCalendar" :options="calendarOptions" />
</template>

<script>
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin, {Draggable} from '@fullcalendar/interaction'
import {addOpacityToRGB, minutesToHours} from 'Shared/helpers'
import moment from 'moment'
import {isEmpty} from 'lodash'

export default {
  components: {
    FullCalendar
  },
  props: {
    service: Object,
    reservations: Array,
    selectedDate: Array
  },
  emits: ['update:selectedDate'],
  data() {
    return {
      selectedColor: '#CB8443',
      isDragging: false,
      draggingEvent: null,
      calendarOptions: {
        allDaySlot: false,
        businessHours: this.mapBusinessHours(this.service?.availability),
        contentHeight: 'auto',
        dayMaxEventRows: 4,
        dayPopoverFormat: {
          weekday: 'short',
          month: 'numeric',
          day: '2-digit',
          omitCommas: true
        },
        defaultTimedEventDuration: minutesToHours(this.service?.slot_duration_minutes) ?? '01:00',
        drop: this.handleDrop,
        droppable: true,
        eventChange: this.handleEventChange,
        eventConstraint: 'businessHours',
        eventContent: this.handleEventContent,
        eventDidMount: this.handleEventDidMount,
        eventOverlap: false,
        events: this.setupEvents([
          ...this.reservations,
          ...(this.service?.days_off ?? [])
        ]),
        firstDay: 1,
        headerToolbar: {
          left: '',
          center: 'prev,title,next',
          right: 'today timeGridDay,timeGridWeek,dayGridMonth'
        },
        initialView: 'timeGridWeek',
        moreLinkContent: this.handleMoreLinkContent,
        navLinks: true,
        nowIndicator: true,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        slotDuration: '01:00:00',
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'long'
        },
        slotMinTime: '05:00:00'
      }
    }
  },
  computed: {
    calendarApi() {
      return this.$refs.fullCalendar.getApi()
    }
  },
  watch: {
    service(newValue) {
      this.calendarApi.setOption('businessHours', this.mapBusinessHours(newValue?.availability))
      this.calendarApi.setOption('defaultTimedEventDuration', minutesToHours(newValue?.slot_duration_minutes) ?? '01:00')
      this.calendarApi.removeAllEvents()
      this.calendarApi.addEventSource(
        this.setupEvents([
          ...this.reservations,
          ...(newValue?.days_off ?? [])
        ])
      )
    },
    reservations(newValue) {
      this.calendarApi.removeAllEvents()
      this.calendarApi.addEventSource(
        this.setupEvents([
          ...newValue,
          ...(this.service?.days_off ?? [])
        ])
      )
    }
  },
  mounted() {
    this.emitter.on('toggle-quicklinks', this.resizeCalendar)

    if (!this.calendarApi.getEventById('selected') && this.selectedDate) {
      this.calendarApi.addEvent({
        color: this.selectedColor,
        end: moment(this.selectedDate[1])
          .add(this.service.buffer_time_after, 'minutes')
          .format(),
        id: 'selected',
        start: moment(this.selectedDate[0])
          .subtract(this.service.buffer_time_before, 'minutes')
          .format(),
        startEditable: true,
        title: 'Selected'
      })
    }

    this.setupDraggable()
  },
  methods: {
    setupDraggable() {
      new Draggable(this.$refs.externalEvents, {
        itemSelector: '.fc-event',
        eventData: () => ({
          color: this.selectedColor,
          id: 'selected',
          startEditable: true,
          title: 'Selected',
          duration: minutesToHours(
            this.service.buffer_time_before +
            this.service.slot_duration_minutes +
            this.service.buffer_time_after
          )
        })
      })
    },
    handleEventChange({event}) {
      if (event.id !== 'selected') {
        return
      }
      this.$emit('update:selectedDate', this.prepareToEmit(event.start))
    },
    handleDrop({dateStr}) {
      const selected = this.calendarApi.getEventById('selected')
      if (selected) {
        selected.remove()
      }
      this.$emit('update:selectedDate', this.prepareToEmit(dateStr))
    },
    prepareToEmit(date) {
      return moment(date)
        .add(this.service.buffer_time_before, 'minutes')
        .format()
    },
    handleEventDidMount({event, el}) {
      if (this.calendarApi.currentData.currentViewType === 'dayGridMonth' || event.allDay) {
        return
      }

      const slotHeight = parseInt(
        getComputedStyle(document.documentElement)
          .getPropertyValue('--fc-timegrid-slot-height')
      )
      const container = el.parentElement

      container.style.borderRadius = '3px'
      container.style.backgroundColor = addOpacityToRGB(el.style.backgroundColor, 0.75)

      if (event.extendedProps.bufferTimeBefore > 0) {
        const paddingHeight = slotHeight * event.extendedProps.bufferTimeBefore / 60
        el.style.marginTop = `${paddingHeight}px`
      }
      if (event.extendedProps.bufferTimeAfter > 0) {
        const paddingHeight = slotHeight * event.extendedProps.bufferTimeAfter / 60

        el.style.marginBottom = `${paddingHeight}px`
      }
    },
    handleEventContent({event, view}) {
      if (event.allDay) {
        return {
          html: `<div class="font-medium text-sm text-atc-black">
            ${event.title}
          </div>`
        }
      }

      return {
        html: view.type === 'dayGridMonth' ?
          `<div
            class="w-2 h-2 rounded-full border-[1.5px]"
            style="background-color: ${event.backgroundColor}; border-color: ${event.borderColor};"
          ></div>
            <div class="overflow-hidden ml-1.5 leading-4">
            <span class="font-medium text-sm text-atc-accent">
              ${event.title}
            </span>
            <span class="text-xs whitespace-nowrap overflow-hidden text-[#626263]">
              ${this.formatActualTime(event, false)}
            </span>
          </div>` :
          `<div class="p-2 overflow-hidden">
            <div class="font-medium mb-1 text-sm text-atc-white">
               ${event.title}
            </div>
            <div class="text-xs whitespace-nowrap overflow-hidden text-atc-white">
              ${this.formatActualTime(event, true)}
            </div>
          </div>`
      }
    },
    handleMoreLinkContent({text}) {
      return text.substring(1)
    },
    formatActualTime(event, showEnd) {
      const formatOptions = {
        hour: 'numeric',
        minute: '2-digit'
      }
      const start = moment(event.start)
        .add(event.extendedProps.bufferTimeBefore, 'minutes')
        .toDate()
        .toLocaleTimeString('en-US', formatOptions)
      const end = moment(event.end)
        .subtract(event.extendedProps.bufferTimeAfter, 'minutes')
        .toDate()
        .toLocaleTimeString('en-US', formatOptions)
      return showEnd ? `${start} - ${end}` : start
    },
    mapBusinessHours(hours) {
      if (isEmpty(hours)) {
        return []
      }

      const daysOrder = [6, 0, 1, 2, 3, 4, 5]

      return daysOrder.flatMap((dayOrder, index) => {
        const dayObject = hours[dayOrder]

        return dayObject.periods.map(periodObject => ({
          daysOfWeek: [index],
          startTime: periodObject[0],
          endTime: periodObject[1]
        }))
      })
    },
    setupEvents(events) {
      return events.map(event => {
        if (!event.uuid) {
          return this.setupDaysOff(event)
        }

        let color = '#CB8443'
        switch (event.status) {
        case 'approved':
          color = '#899976'
          break
        case 'pending':
          color = '#F4F4F4'
          break
        case 'rejected':
          color = '#C91616'
          break
        }

        return {
          end: moment(event.start_at)
            .add(this.service?.slot_duration_minutes, 'minutes')
            .add(this.service?.buffer_time_after, 'minutes')
            .format(),
          id: event.uuid,
          start: moment(event.start_at)
            .subtract(this.service?.buffer_time_before, 'minutes')
            .format(),
          status: event.status,
          title: this.$page.props.auth.can['reservations.view.any']
          || this.isAssignedOrganizationMember()
          || this.isAssignedClient(event)
            ? event.client_data.fullName : 'Busy',
          color: color
        }
      })
    },
    setupDaysOff(event) {
      return {
        title: event.name,
        start: event.date['0'],
        end: event.date['1'],
        display: 'background',
        allDay: true
      }
    },
    isAssignedOrganizationMember() {
      return this.$page.props.auth.can['reservations.view'] &&
        this.$page.props.auth.user?.organization?.uuid === this.service.organization.uuid
    },
    isAssignedClient(event) {
      return this.$page.props.auth.can['reservations.view'] &&
        this.$page.props.auth.user?.uuid === event?.user?.uuid
    },
    resizeCalendar() {
      setTimeout(() => {
        this.calendarApi.updateSize()
      }, 200)
    }
  }
}
</script>
