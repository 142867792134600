<template>
  <div>
    <InertiaHead title="Reservations" />

    <Panel data-testid="reservations-panel">
      <div class="flex justify-between">
        <PanelHeading :tabs="tabs" />
        <PanelActions
          :create="$route('reservations.create')"
          :widget="false"
          create-permission="reservations.create"
          text="reservation"
        />
      </div>
      <div class="pb-12">
        <div class="flex flex-row justify-between gap-3 w-full pb-5">
          <div class="flex items-center w-64">
            <VueMultiselect
              v-model="query.status"
              :custom-label="option => statusLabel(option)"
              :options="statuses.map(status => status.value)"
              :show-labels="false"
              class="form-multiselect-edit"
              placeholder="Select status"
            >
              <template #singleLabel="props">
                <span>{{ statusLabel(props.option) }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="reset('status')" />
              </template>
            </VueMultiselect>
          </div>
          <div class="flex items-center w-64">
            <VueMultiselect
              v-model="query['organization-product']"
              :custom-label="option => productLabel(option)"
              :options="organizationProducts.map(product => product.uuid)"
              :show-labels="false"
              class="form-multiselect-edit"
              placeholder="Select product"
            >
              <template #singleLabel="props">
                <span>{{ productLabel(props.option) }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="reset('organization-product')" />
              </template>
            </VueMultiselect>
          </div>
          <div v-if="auth.can['reservations.view.any']" class="flex items-center w-64">
            <VueMultiselect
              v-model="query.organization"
              :custom-label="option => organizationLabel(option)"
              :options="organizations.map(organization => organization.uuid)"
              :show-labels="false"
              class="form-multiselect-edit"
              placeholder="Select organization"
            >
              <template #singleLabel="props">
                <span>{{ organizationLabel(props.option) }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="reset('organization')" />
              </template>
            </VueMultiselect>
          </div>
          <FormInput id="search" v-model="query.search" class="form-input-edit ml-auto w-64" placeholder="Search" />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">
                <SortingColumn name="start_at" :sort="query.sort" @click="sortBy('start_at')">Date</SortingColumn>
              </th>
              <th scope="col" class="table-header">Client</th>
              <th v-if="auth.can['reservations.view.any']" scope="col" class="table-header">Organization</th>
              <th scope="col" class="table-header">Product</th>
              <th scope="col" class="table-header">Price</th>
              <th scope="col" class="table-header">Status</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <InertiaLink
              v-for="reservation in reservations.data"
              :key="reservation.uuid"
              as="tr"
              :href="$route('reservations.show', reservation.uuid)"
              class="table-row cursor-pointer"
            >
              <td class="table-cell truncate">
                <div class="grid">
                  <span>{{ formatDate(reservation.start_at) }}</span>
                  <span>{{ formatTime(reservation.start_at) }} - {{ formatTime(reservation.end_at) }}</span>
                </div>
              </td>
              <td class="table-cell truncate" :title="reservation.user?.full_name ?? reservation.client_data.fullName">
                <InertiaLink :href="$route('users.edit', reservation.user?.uuid)" class="hover:underline">
                  {{ reservation.user?.full_name ?? reservation.client_data.fullName }}
                </InertiaLink>
              </td>
              <td v-if="auth.can['reservations.view.any']" class="table-cell truncate">
                <InertiaLink
                  :href="$route('organizations.edit', reservation.organizationProduct.organization.uuid)"
                  class="hover:underline"
                >
                  {{ reservation.organizationProduct.organization.name }}
                </InertiaLink>
              </td>
              <td class="table-cell truncate">
                <InertiaLink
                  :href="$route('organization-products.edit', reservation.organizationProduct.uuid)"
                  class="hover:underline"
                >
                  {{ reservation.organizationProduct.serial }}
                </InertiaLink>
              </td>
              <td class="table-cell truncate">
                ${{ formatPrice(reservation.payment?.amount ?? reservation.organizationProduct.price) }}
              </td>
              <td class="table-cell truncate">
                <StatusBadge
                  :label="statuses.find(status => status.value === reservation.status).label"
                  :value="reservation.status"
                />
              </td>
            </InertiaLink>

            <tr v-if="reservations.data.length === 0">
              <td
                class="table-cell text-center"
                :colspan="auth.can['reservations.view.any'] ? '6' : '5'"
              >
                No reservations found.
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="reservations.links"
          class="flex items-center justify-center mt-5"
          :current-page="reservations.current_page"
          @change-page="key => query.page = key"
        />
      </div>
    </Panel>
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import {formatPrice} from 'Shared/helpers'
import {pickBy, startCase, throttle} from 'lodash'
import FormInput from 'Shared/Form/FormInput'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import SortingColumn from 'Shared/SortingColumn'
import StatusBadge from 'Shared/Misc/StatusBadge.vue'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  components: {
    FormInput,
    InertiaHead,
    InertiaLink,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading,
    SortingColumn,
    StatusBadge,
    VueMultiselect
  },
  layout: Layout,
  props: {
    auth: Object,
    filters: Object,
    organizationProducts: Array,
    organizations: Array,
    reservations: Object,
    statuses: Array
  },
  data() {
    return {
      tabs: [
        { label: 'Reservations', url: this.$route('reservations.index') },
        { label: 'Calendar', url: this.$route('reservations.calendar') }
      ],
      query: {
        search: this.filters.search,
        sort: {
          column: this.filters.sort?.column,
          order: this.filters.sort?.order
        },
        page: this.filters.page,
        status: this.filters.status,
        organization: this.filters.organization,
        'organization-product': this.filters['organization-product']
      }
    }
  },
  watch: {
    query: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('reservations.index'), pickBy(this.query), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  methods: {
    formatPrice,
    startCase,
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    },
    formatTime(date) {
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      })
    },
    statusLabel(option) {
      return startCase(this.statuses.find(status => status.value === option).label)
    },
    productLabel(option) {
      const organizationProduct = this.organizationProducts.find(product => product.uuid === option)
      return organizationProduct.serial + (this.auth.can['reservations.view.any'] ? ` (${organizationProduct.organization.name})` : '')
    },
    organizationLabel(option) {
      return this.organizations.find(organization => organization.uuid === option).name
    },
    sortBy(column) {
      this.query.sort = {
        column: column,
        order: this.query.sort.column === column ?
          (this.query.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
    },
    reset(filter) {
      this.query[filter] = null
    }
  }
}
</script>
