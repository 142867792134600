<template>
  <form class="divide-y-2 divide-gray-200" data-testid="organizations-edit-form" @submit.prevent="submit">
    <InertiaHead :title="title" />
    <div class="px-4 sm:px-6 lg:px-8 pb-7">
      <PanelHeading :heading="title" />
      <div class="grid gap-y-6">
        <component
          :is="inputComponents[question.value.type]"
          v-for="(question, index) in questions"
          :id="`question-${index}`"
          :key="`question-${index}`"
          v-model="form.questions[index].answer"
          :class="`form-${question.value.type}-edit`"
          :error="form.errors[`questions.${index}.answer`] ?? ''"
          :label="question.value.label"
        />
      </div>
    </div>

    <div v-if="dealerships.length" class="px-4 sm:px-6 lg:px-8 pb-7">
      <div class="pt-4">
        <FormLabel id="dealership" text="Dealership" class="mt-3 mb-3" />
        <VueMultiselect
          id="dealership"
          v-model="form.dealership"
          :options="dealerships.map(dealer => dealer.uuid)"
          :custom-label="option => dealerships.find(dealer => dealer.uuid === option).name"
          :class="`form-multiselect-edit ${
            form.errors['dealership'] ? 'form-multiselect-error' : ''
          }`"
          :show-labels="false"
        >
          <template #singleLabel="props">
            <span>{{ dealerships.find(dealer => dealer.uuid === props.option)?.name }}</span>
            <i class="multiselect__tag-icon" @mousedown.stop="e => reset(e, 'dealership')" />
          </template>
        </VueMultiselect>
        <div v-if="form.errors['dealership']" class="text-red-500 text-xs font-light mt-2">{{ form.errors['dealership'] }}</div>
        <div class="text-gray-500 text-xs font-light mt-2">You can choose a dealership that will handle your potential purchase. If you don't, we will take care of it.</div>
      </div>
    </div>

    <div class="px-4 sm:px-6 lg:px-8 py-4 flex w-full">
      <div class="flex gap-3 text-atc-text">
        <span class="self-center">Powered by</span>
        <Logo class="fill-black" width="120" />
      </div>
      <button
        type="submit"
        :disabled="form.processing"
        class="ml-auto form-submit-button"
        data-testid="submitButton"
      >
        Submit
      </button>
    </div>
  </form>
</template>

<script>
import {shallowRef} from 'vue'
import {Head as InertiaHead, useForm} from '@inertiajs/inertia-vue3'
import axios from 'axios'
import FormInput from 'Shared/Form/FormInput'
import FormLabel from 'Shared/Form/FormLabel'
import FormRange from 'Shared/Form/FormRange'
import FormTextArea from 'Shared/Form/FormTextArea'
import CenterLayout from 'Shared/Layouts/CenterLayout'
import PanelHeading from 'Shared/PanelHeading'
import Logo from 'Shared/Logo'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import {getCoordinatesByZipcode} from  'Shared/helpers'

export default {
  components: {
    FormLabel,
    InertiaHead,
    FormInput,
    PanelHeading,
    Logo,
    VueMultiselect
  },
  layout: CenterLayout,
  props: {
    distributors: Array,
    questions: Array,
    reservation: Object
  },
  setup(props) {
    const form = useForm({
      questions: props.questions.map((question) => ({
        question: question.value.label,
        answer: question.value.type === 'range' ? 5 : '',
        type: question.value.type
      })),
      reservation: props.reservation.uuid,
      dealership: null
    })

    return { form }
  },
  data() {
    return {
      autoDealerAssign: false,
      client: {
        country: this.reservation.client_data.address.country ?? this.reservation.user.address.country,
        state: this.reservation.client_data.address.state ?? this.reservation.user.address.state,
        zipCode: this.reservation.client_data.address.zipCode ?? this.reservation.user.address.zipCode
      },
      dealerships: [],
      inputComponents: {
        input: shallowRef(FormInput),
        range: shallowRef(FormRange),
        textarea: shallowRef(FormTextArea)
      },
      title: 'Purchase Intent Survey'
    }
  },
  mounted() {
    this.checkAutoDealerAssign()
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          data.data = data.questions
          return data
        })
        .post(this.$route('purchase-intents.store'), {
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    checkAutoDealerAssign() {
      if (this.client.country !== 'US') {
        this.autoDealerAssign = true
        return
      }

      const distributor = this.getDistributorByState(this.client.state)

      this.autoDealerAssign = distributor ? !!distributor.auto_dealer_assignment : true

      this.getClosestDealerships()
    },
    getDistributorByState(state) {
      return this.distributors.find(d => d.operating_states.includes(state))
    },
    getClosestDealerships() {
      if (this.dealership || !this.autoDealerAssign || !this.client.zipCode) {
        this.dealerships = []
        return
      }

      getCoordinatesByZipcode(this.client.zipCode, this.client.country)
        .then(result => {
          if (!result) {
            this.dealerships = []
            return
          }

          const {lat, lng} = result

          axios
            .post(this.$route('dealerships.closest'), {
              latitude: lat,
              longitude: lng
            })
            .then(response => {
              if (response.status === 200) {
                this.dealerships = response.data
                return
              }
              console.error(response)
            })
            .catch(error => {
              console.error(error)
            })
        })
    },
    reset(attribute) {
      this.form[attribute] = null
    }
  }
}
</script>
