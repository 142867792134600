<template>
  <div>
    <InertiaHead title="Reservations" />

    <Panel data-testid="calendar-panel" class="px-0 sm:px-0 lg:px-0">
      <div class="flex items-center justify-center pt-5 pb-[11px]">
        <h2 class="uppercase font-medium font-sans text-[22px]">Reservations</h2>
      </div>

      <div ref="calendarFiltersRef" class="flex flex-row gap-2.5 3xl:gap-3 w-full px-4 sm:px-6 lg:px-8 mb-3.5 max-h-0 overflow-hidden transition-max-height duration-200 ease-out">
        <div class="flex items-center w-[227px] 3xl:w-[265px]">
          <VueMultiselect
            v-model="query.status"
            :custom-label="option => startCase(statuses.find(status => status.value === option).label)"
            :options="statuses.map(status => status.value)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Select status"
            @select="handleStatusFilterChange"
          >
            <template #singleLabel="props">
              <span>{{ startCase(statuses.find(status => status.value === props.option).label) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('status')" />
            </template>
          </VueMultiselect>
        </div>
        <div class="flex items-center w-[227px] 3xl:w-[265px]">
          <VueMultiselect
            v-model="query['organization-product']"
            :custom-label="option => productLabel(option)"
            :options="organizationProducts.map(product => product.uuid)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Select model"
          >
            <template #singleLabel="props">
              <span>{{ productLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('organization-product')" />
            </template>
          </VueMultiselect>
        </div>
        <div v-if="auth.can['reservations.view.any']" class="flex items-center w-[227px] 3xl:w-[265px]">
          <VueMultiselect
            v-model="query.organization"
            :options="organizations.map(organization => organization.uuid)"
            placeholder="Select organization"
            :custom-label="option => organizations.find(organization => organization.uuid === option).name"
            class="form-multiselect-edit"
            :show-labels="false"
          >
            <template #singleLabel="props">
              <span>{{ organizations.find(organization => organization.uuid === props.option).name }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('organization')" />
            </template>
          </VueMultiselect>
        </div>
        <button
          class="inline-flex uppercase rounded-sm px-2 3xl:px-3 items-center whitespace-nowrap text-sm 3xl:text-base"
          @click="clearFilters"
        >
          <CrossIcon class="w-3 h-3 mr-1" />
          Clear filters
        </button>

        <div class="flex items-center ml-auto">
          <div class="p-1.5 03xl:p-2 rounded-full duration-200 hover:bg-atc-gray">
            <input
              id="show-canceled-checkbox"
              v-model="query['show-rejected']"
              class="h-4 w-4 3xl:h-5 3xl:w-5 rounded-sm invert text-white border-white bg-transparent checked:bg-transparent checked:hover:bg-transparent checked:focus:bg-transparent checked:border-atc-white checked:hover:border-atc-white checked:focus:border-atc-white focus:ring-0 focus:ring-offset-0 focus:shadow-none"
              type="checkbox"
            />
          </div>
          <label for="show-canceled-checkbox" class="text-sm 3xl:text-base font-normal text-atc-accent">
            Show rejected reservations
          </label>
        </div>
      </div>

      <ReservationsCalendar ref="calendarRef" :reservations="reservations" :service="selectedProduct" />
    </Panel>
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import {pickBy, startCase, throttle} from 'lodash'
import {CrossIcon} from 'Shared/Icons/Icons'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import ReservationsCalendar from 'BookingComponents/ReservationsCalendar'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  components: {
    CrossIcon,
    InertiaHead,
    InertiaLink,
    Panel,
    ReservationsCalendar,
    VueMultiselect
  },
  layout: Layout,
  props: {
    auth: Object,
    filters: Object,
    organizationProducts: Array,
    organizations: Array,
    reservations: Array,
    statuses: Array
  },
  data() {
    return {
      tabs: [
        { label: 'Reservations', url: this.$route('reservations.index') },
        { label: 'Calendar', url: this.$route('reservations.calendar') }
      ],
      query: {
        'show-rejected': this.filters['show-rejected'],
        status: this.filters.status,
        organization: this.filters.organization,
        'organization-product': this.filters['organization-product']
      }
    }
  },
  computed: {
    selectedProduct() {
      return this.organizationProducts.find(product => product.uuid === this.query['organization-product'])
    }
  },
  watch: {
    query: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('reservations.calendar'), pickBy(this.query), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  mounted() {
    this.setFiltersBar()
  },
  methods: {
    startCase,
    setFiltersBar() {
      const calendarEl = this.$refs.calendarRef.calendarApi.el
      if (!calendarEl) {
        return
      }
      const toolbar = calendarEl.querySelector('.fc-toolbar')
      toolbar.after(this.$refs.calendarFiltersRef)
    },
    handleStatusFilterChange(status) {
      if (status === 'rejected') {
        this.query['show-rejected'] = true
      }
    },
    productLabel(option) {
      const organizationProduct = this.organizationProducts.find(product => product.uuid === option)
      return organizationProduct.serial + (this.auth.can['reservations.view.any'] ? ` (${organizationProduct.organization.name})` : '')
    },
    reset(filter) {
      this.query[filter] = null
    },
    clearFilters() {
      this.query.status = null
      this.query.organization = null
      this.query['organization-product'] = null
    }
  }
}
</script>
