<template>
  <div class="flex h-16 flex-shrink-0 border-b border-gray-200 bg-white">
    <div class="flex w-full flex-1 justify-between px-4 lg:ml-0">
      <label for="search-field" class="sr-only">{{ placeholder }}</label>
      <div class="relative w-full text-gray-400 focus-within:text-gray-600">
        <div class="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <SearchIcon class="h-5 w-5" />
        </div>
        <input
          id="search-field"
          v-model="modelValue"
          class="block h-full w-full border-transparent py-2 pl-10 pr-3 text-gray-900 placeholder-gray-500 focus:border-transparent focus:placeholder-gray-400 focus:outline-none focus:ring-0 sm:text-sm"
          :placeholder="placeholder"
          type="search"
          name="search"
          @keyup="onKeyUp"
        />
      </div>
    </div>
  </div>
</template>

<script>
import {SearchIcon} from '@heroicons/vue/outline'

export default {
  components: {
    SearchIcon
  },
  props: {
    placeholder: {
      type: String,
      default: 'Type to search'
    },
    modelValue: String
  },
  data() {
    return {
      confirmSignal: ''
    }
  },
  methods: {
    onKeyUp(event) {
      event.preventDefault()
      this.$emit('update:modelValue', event.target.value)
    }
  },
  emits: ['update:modelValue']
}
</script>
