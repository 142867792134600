<template>
  <div :class="wrapperClass" :data-testid="dataTestid" class="relative mb-3">
    <div class="flex items-center h-5">
      <input
        :id="id"
        :checked="modelValue"
        class="focus:ring-atc-secondary h-4 w-4 text-atc-secondary border-gray-300 rounded cursor-pointer"
        type="checkbox"
        v-bind="$attrs"
        @change="$emit('update:modelValue', $event.target.checked)"
      />
      <FormLabel :id="id" :text="label" class="pl-2 cursor-pointer mb-0 text-[14px]" />
    </div>
    <div v-if="error" class="text-red-600 text-xs font-light mt-2">{{ error }}</div>
    <div v-if="description" class="text-gray-500 text-xs font-light mt-2">{{ description }}</div>
  </div>
</template>

<script>
import {
  v4 as uuid
} from 'uuid'
import FormLabel from "Shared/Form/FormLabel.vue";
export default {
  components: {FormLabel},
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default () {
        return `form-checkbox-${uuid()}`
      }
    },
    label: {
      type: String,
      default: ''
    },
    description: {
      type: String,
      default: ''
    },
    modelValue: {
      type: Boolean,
      default: false
    },
    error: {
      type: String,
      default: ''
    },
    wrapperClass: String,
    dataTestid: {
      type: String,
      default: (props) => `${props.id}-checkbox`
    }
  },
  emits: ['update:modelValue']
}
</script>
