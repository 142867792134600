export const getStatusColor = id => {
  const colors = {
    'bg-atc-success': [
      'new-lead',
      'review',
      'submit-order',
      'received',
      'assembly',
      'delivered',
      'registered'
    ],
    'bg-atc-warning': ['contacted', 'pending', 'build', 'paint', 'shipped', 'stock', 'waiting'],
    'bg-cyan-300': ['cold'],
    'bg-atc-error': ['warm', 'canceled']
  }
  for (const [className, array] of Object.entries(colors)) {
    if (array.includes(id)) {
      return className
    }
  }
}

export const addOpacityToRGB = (rgbColor, opacity = 1) => {
  const match = rgbColor.match(/\d+/g)

  if (!match || match.length !== 3) {
    throw new Error('Invalid RGB color format')
  }

  const red = parseInt(match[0])
  const green = parseInt(match[1])
  const blue = parseInt(match[2])

  if (isNaN(red) || isNaN(green) || isNaN(blue)) {
    throw new Error('Invalid RGB color values')
  }

  if (opacity < 0 || opacity > 1) {
    throw new Error('Opacity should be between 0 and 1')
  }

  return `rgba(${red}, ${green}, ${blue}, ${opacity})`
}
