<template>
  <div>
    <table class="table border-2 border-atc-gray mb-5">
      <thead class="table-head">
        <tr class="table-row">
          <th scope="col" class="table-header py-3">Name</th>
          <th scope="col" class="table-header py-3">Date</th>
          <th scope="col" class="table-header py-3"></th>
          <th scope="col" class="table-header py-3 w-16"></th>
        </tr>
      </thead>
      <tbody class="table-body !border-t-0">
        <tr v-for="(dayOff, index) in modelValue" :key="index">
          <td class="table-cell py-0 pl-0.5">
            <FormInput
              :id="`dayOff-${index}-name`"
              v-model="dayOff.name"
              :error="errors[`days_off.${index}.name`] ?? ''"
              class="form-input-edit !bg-white"
              :class="!errors[`days_off.${index}.name`] ?? 'border-none'"
              required
              placeholder="Day Off Name"
            />
          </td>
          <td class="table-cell py-0 pl-0 overflow-visible">
            <VueDatePicker
              v-model="dayOff.date"
              :enable-time-picker="false"
              :input-class-name="'!py-3 !rounded-none !leading-3 !focus:ring-atc-secondary !focus:border-atc-secondary ' +
                (errors[`days_off.${index}.date.0`] || errors[`days_off.${index}.date.1`]
                  ? '!border-red-600' : '!border-none')"
              :uid="`dayOff-${index}-date`"
              auto-apply
              model-type="yyyy-MM-dd"
              placeholder="Day Off Dates"
              range
            />
            <div v-if="errors[`days_off.${index}.date.0`] || errors[`days_off.${index}.date.1`]" class="form-error">
              {{ errors[`days_off.${index}.date.0`] || errors[`days_off.${index}.date.1`] }}
            </div>
          </td>
          <td class="table-cell py-0">
            <FormCheckbox
              :id="`dayOff-${index}-repeat`"
              v-model="dayOff.repeat"
              label="Repeat Yearly"
              wrapper-class="mb-0"
            />
          </td>
          <td class="table-cell py-0">
            <div class="flex justify-end">
              <button type="button" class="ml-auto" @click="removeDayOff(index)">
                <XIcon class="w-5 hover:text-red-500 duration-200" />
              </button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
    <button
      type="button"
      class="table-controls-create-btn font-heading uppercase"
      @click="addDayOff"
    >
      <Icon class="table-controls-create-icon" name="plus" />
      Add Day Off
    </button>
  </div>
</template>

<script>
import { defineComponent } from 'vue'
import { XIcon } from '@heroicons/vue/outline'
import VueDatePicker from '@vuepic/vue-datepicker'
import '@vuepic/vue-datepicker/dist/main.css'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import Icon from 'Shared/Icon'

export default defineComponent({
  name: 'DaysOff',
  components: {
    FormCheckbox,
    FormInput,
    Icon,
    VueDatePicker,
    XIcon
  },
  props: {
    modelValue: {
      type: Array,
      required: true
    },
    errors: Object
  },
  emits: ['update:modelValue'],
  methods: {
    addDayOff() {
      this.modelValue.push({name: '', date: [], repeat: false})
    },
    removeDayOff(index) {
      this.modelValue.splice(index, 1)
    }
  }
})
</script>
