<template>
  <nav class="-mb-px flex space-x-8" aria-label="Tabs" data-html2canvas-ignore>
    <Link
      v-for="tab in tabs"
      :key="tab.label"
      :href="tab.route"
      class="border-transparent text-gray-500 hover:text-atc-secondary hover:border-atc-secondary duration-200 whitespace-nowrap py-4 px-1 border-b-2 font-medium text-sm"
      :class="{ 'border-atc-success text-atc-success': tab.current.includes($route().current()) }"
      aria-current="page"
    >
      {{ tab.label }}
    </Link>
  </nav>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'

export default {
  components: {
    Link
  },
  props: {
    tabs: Array
  },
  data () {
    return {
      isConfirmPopupOpen: false,
      popupText: '',
      popupAction: ''
    }
  }
}
</script>