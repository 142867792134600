<template>
  <Panel>
    <div class="flex flex-wrap justify-between">
      <PanelHeading v-if="dashboard || name === 'Leads'" :heading="name" />
      <PanelHeading v-else :tabs="tabs" />
      <div class="flex w-full flex-row sm:w-auto">
        <PanelActions
          :override-create-permission="roleCheck()"
          :link="$route(listRoutes.index)"
          :widget="widget"
          :create="$route(listRoutes.create)"
          :permissions="permissions"
          :create-permission="listRoutes.create"
        />
        <ExcelImport
          v-if="name.toLowerCase() === 'orders' && widget === false && permissions['orders.import.status']"
          route="orders.import"
        />
        <div v-if="widget === false && permissions['clients.export']" class="flex items-center py-6">
          <ActionButton label="Export client data" @click="onExport" />
        </div>
      </div>
    </div>
    <div class="pb-12">
      <div v-if="filters === true" class="flex items-center pb-5">
        <div class="flex w-full flex-row flex-wrap items-center justify-between xl:flex-nowrap">
          <div v-if="editMode === false" class="filters items-center justify-between">
            <a
              class="w-full cursor-pointer pb-5 text-[0.92rem] font-medium duration-200 hover:text-atc-secondary xl:w-auto xl:pb-0"
              @click="enterEditMode"
            >
              Bulk Edit
            </a>

            <div class="form-input-edit sm:col-span-3">
              <VueMultiselect
                v-model="dealership"
                :options="dealerships"
                placeholder="Select dealership"
                label="name"
                track-by="uuid"
                class="form-multiselect-edit"
                :show-labels="false"
                @select="value => buildQuery('dealership', value.uuid)"
              >
                <template #singleLabel="props">
                  <span>{{ props.option.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="buildQuery('dealership')" />
                </template>
              </VueMultiselect>
            </div>
            <div class="form-input-edit filters-datepicker sm:col-span-3">
              <Datepicker
                v-model="date"
                model-type="dd.mm.yyyy"
                placeholder="Select date"
                input-class-name=""
                range
                :auto-apply="true"
                :clearable="true"
                :enable-time-picker="false"
                @update:model-value="value => buildQuery('date', value)"
              />
            </div>
            <div class="form-input-edit sm:col-span-6">
              <VueMultiselect
                v-model="status"
                :options="statuses"
                :multiple="false"
                placeholder="Select status"
                label="label"
                track-by="value"
                :close-on-select="true"
                class="form-multiselect-edit"
                :show-labels="false"
                @select="value => buildQuery('status', value.value)"
              >
                <template #singleLabel="props">
                  <span>{{ props.option.label }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="buildQuery('status')" />
                </template>
              </VueMultiselect>
            </div>
          </div>
          <div
            v-else
            class="filters-bulk flex w-full flex-wrap items-center justify-between xl:w-auto xl:flex-nowrap"
          >
            <div v-if="name === 'Leads'" class="form-input-edit ml-0 sm:col-span-3">
              <VueMultiselect
                v-model="dealershipBulk"
                :options="dealershipsFilterData"
                placeholder="Assign dealership"
                label="name"
                track-by="uuid"
                :close-on-select="true"
                class="form-multiselect-edit"
                :show-labels="false"
                @select="value => selectDealershipBulk(value)"
                @remove="clearDealershipSelectionBulk"
              />
            </div>
            <div :class="`${name === 'Orders' ? 'xl:ml-0' : ''} form-input-edit sm:col-span-6 `">
              <VueMultiselect
                v-model="statusBulk"
                :options="statuses"
                :multiple="false"
                placeholder="Change status"
                label="label"
                track-by="value"
                :close-on-select="true"
                class="form-multiselect-edit"
                :show-labels="false"
                @select="value => selectStatusBulk(value)"
                @remove="clearStatusSelectionBulk"
              />
            </div>
            <a
              class="mx-0 cursor-pointer pt-5 text-[0.92rem] font-medium duration-200 hover:text-atc-secondary lg:mx-3 lg:pt-0"
              @click="editModeDelete"
            >
              Delete Selected
            </a>
            <a
              class="mx-0 cursor-pointer pt-5 text-[0.92rem] font-medium duration-200 hover:text-atc-secondary lg:mx-3 lg:pt-0"
              @click="enterEditMode"
            >
              Cancel
            </a>
          </div>

          <div class="flex w-full pt-5 xl:w-64 xl:pt-0">
            <FormInput
              v-model="term"
              class="form-input-edit ml-auto w-full"
              placeholder="Search"
              @keyup="e => buildQuery('term', e.target.value)"
            />
          </div>
        </div>
      </div>
      <div class="overflow-x-auto">
        <table class="table-record-list table">
          <thead class="table-head">
            <tr class="table-row">
              <th v-if="name.toLowerCase() === 'orders'" scope="col" class="table-header">
                <div class="flex items-center">
                  <FormCheckbox
                    v-if="editMode === true"
                    class="mr-2"
                    wrapper-class="mb-0"
                    @change="checkboxAllHandler"
                  />
                  <SortingColumn name="stock" :sort="sort" @click="sortBy('stock', name)">
                    Stock #
                  </SortingColumn>
                </div>
              </th>
              <th scope="col" class="table-header">
                <div class="flex items-center">
                  <FormCheckbox
                    v-if="editMode === true && name.toLowerCase() === 'leads'"
                    class="mr-2"
                    wrapper-class="mb-0"
                    @change="checkboxAllHandler"
                  />
                  <SortingColumn
                    name="product->model"
                    :sort="sort"
                    @click="sortBy('product->model', name)"
                  >
                    Model
                  </SortingColumn>
                </div>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn
                  name="dealership_id"
                  :sort="sort"
                  @click="sortBy('dealership_id', name)"
                >
                  Dealership
                </SortingColumn>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn
                  name="distributor_id"
                  :sort="sort"
                  @click="sortBy('distributor_id', name)"
                >
                  Distributor
                </SortingColumn>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn
                  name="client->lastName"
                  :sort="sort"
                  @click="sortBy('client->lastName', name)"
                >
                  Customer Name
                </SortingColumn>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn name="created_at" :sort="sort" @click="sortBy('created_at', name)">
                  Date
                </SortingColumn>
              </th>
              <th scope="col" class="table-header table-header-status">
                <SortingColumn
                  name="status->label"
                  :sort="sort"
                  @click="sortBy('status->label', name)"
                >
                  Status
                </SortingColumn>
              </th>
            </tr>
          </thead>
          <tbody v-if="!editMode" class="table-body">
            <InertiaLink
              v-for="record in records.data"
              :key="record.uuid"
              as="tr"
              :href="$route(listRoutes.edit, record.uuid)"
              class="table-row cursor-pointer"
            >
              <td v-if="name.toLowerCase() === 'orders'" class="table-cell">
                <div class="flex items-center">{{ record.stock }}</div>
              </td>
              <td class="table-cell">
                <div class="flex items-center">{{ record.product.data.sku }}</div>
              </td>
              <td class="table-cell">{{ record.dealership?.name ?? '—' }}</td>
              <td class="table-cell">{{ record.distributor?.name ?? '—' }}</td>
              <td class="table-cell">
                {{ record.client.firstName + ' ' + record.client.lastName }}
              </td>
              <td class="table-cell">{{ formatDate(record.created_at) }}</td>
              <td class="table-cell">
                <StatusBadge :label="statuses.find(status => status.value === record.status).label" :value="record.status" />
              </td>
            </InertiaLink>
            <tr v-if="records.data.length === 0">
              <td class="table-cell text-center" colspan="6">No {{ name.toLowerCase() }} found.</td>
            </tr>
          </tbody>
          <tbody v-else class="table-body">
            <tr v-for="record in records.data" :key="record.uuid" class="table-row cursor-pointer">
              <td v-if="name.toLowerCase() === 'leads'" class="table-cell">
                <div class="flex items-center">
                  <FormCheckbox
                    v-if="editMode === true"
                    class="bulk-checkbox mr-2"
                    wrapper-class="mb-0"
                    @change="value => checkboxHandler(record.uuid)"
                  />{{ record.product.model }}
                </div>
              </td>
              <td v-if="name.toLowerCase() === 'orders'" class="table-cell">
                <div class="flex items-center">
                  <FormCheckbox
                    v-if="editMode === true"
                    class="bulk-checkbox mr-2"
                    wrapper-class="mb-0"
                    @change="value => checkboxHandler(record.uuid)"
                  />{{ record.stock }}
                </div>
              </td>
              <td v-if="name.toLowerCase() === 'orders'" class="table-cell">
                <div class="flex items-center">{{ record.product.model }}</div>
              </td>
              <td class="table-cell">{{ record.dealership?.name ?? '—' }}</td>
              <td class="table-cell">{{ record.distributor?.name ?? '—' }}</td>
              <td class="table-cell">
                {{ record.client.firstName + ' ' + record.client.lastName }}
              </td>
              <td class="table-cell">{{ formatDate(record.created_at) }}</td>
              <td class="table-cell">
                <StatusBadge :label="statuses.find(status => status.value === record.status).label" :value="record.status" />
              </td>
            </tr>
            <tr v-if="records.data.length === 0">
              <td class="table-cell text-center" colspan="6">No {{ name.toLowerCase() }} found.</td>
            </tr>
          </tbody>
        </table>
      </div>
      <Pagination
        v-if="!dashboard"
        :links="records.links"
        class="mt-5 flex items-center justify-center"
        :current-page="records.current_page"
        @change-page="key => buildQuery('page', key)"
      />
    </div>
  </Panel>
</template>

<script>
import {ref} from 'vue'
import {Link as InertiaLink} from '@inertiajs/inertia-vue3'
import * as XLSX from 'xlsx/xlsx.mjs'
import {formatDate} from 'Shared/helpers'
import axios from 'axios'
import ActionButton from 'Shared/Buttons/ActionButton'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import ExcelImport from 'Shared/ExcelImport'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import SortingColumn from 'Shared/SortingColumn'
import StatusBadge from 'Shared/Misc/StatusBadge'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  components: {
    ActionButton,
    Datepicker,
    ExcelImport,
    FormCheckbox,
    FormInput,
    InertiaLink,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading,
    SortingColumn,
    StatusBadge,
    VueMultiselect
  },
  props: {
    auth: Object,
    dealerships: Array,
    statuses: Array,
    records: Object,
    widget: {
      type: Boolean,
      default: true
    },
    filters: {
      type: Boolean,
      default: false
    },
    name: String,
    dashboard: {
      type: Boolean,
      default: false
    },
    permissions: Object,
    role: Object,
    partsOrderingEnabled: Boolean
  },
  data() {
    return {
      bulkRecords: [],
      bulkSelectAll: false,
      date: ref(),
      dealer: '',
      dealership: null,
      dealershipBulk: '',
      dealershipsFilterData: this.dealerships,
      editMode: false,
      listRoutes: {
        index: 'orders.index',
        edit: 'orders.edit',
        create: 'orders.create'
      },
      query: {},
      sort: {
        column: '',
        order: ''
      },
      status: '',
      statusBulk: '',
      tabs: [
        {url: this.$route('orders.index'), label: 'Models'},
        {url: this.$route('parts-orders.index'), label: 'Parts', disabled: !this.partsOrderingEnabled}
      ],
      term: ''
    }
  },
  mounted() {
    this.prepareRoutes()
  },
  methods: {
    formatDate,
    roleCheck() {
      return this.name.toLowerCase() === 'orders' &&
        (this.role.name === 'dist_manager' || this.role.name === 'dist_employee')
    },
    prepareDealerships() {
      let records = []
      let record = ''
      this.dealershipsFilterData = []
      this.bulkRecords.forEach(element => {
        record = Object.values(this.records.data).filter(obj => {
          return obj.uuid === element
        })[0]
        if (records.indexOf(record.distributor.uuid) === -1) {
          records.push(record.distributor.uuid)
        }
      })
      if (records.length === 1) {
        this.dealerships.forEach(element => {
          if (element.distributor.uuid === records[0]) {
            if (this.dealershipsFilterData.indexOf(element) === -1) {
              this.dealershipsFilterData.push(element)
            }
          }
        })
      } else {
        this.dealershipsFilterData = []
      }
    },
    checkboxHandler(uuid) {
      if (this.bulkRecords.indexOf(uuid) >= 0) {
        this.bulkRecords.splice(this.bulkRecords.indexOf(uuid), 1)
      } else {
        this.bulkRecords.push(uuid)
      }
      this.prepareDealerships()
    },
    checkboxAllHandler() {
      this.bulkSelectAll = !this.bulkSelectAll
      let checkboxes = document.getElementsByClassName('bulk-checkbox')
      for (const [key, value] of Object.entries(checkboxes)) {
        value.checked = this.bulkSelectAll
        const event = new Event('change')
        value.dispatchEvent(event)
      }
      this.prepareDealerships()
    },
    editModeDelete() {
      this.query = {
        ...this.query,
        action: 'delete',
        records: this.bulkRecords
      }
      this.fetchData(this.query)
      this.enterEditMode()
    },
    selectStatusBulk(value) {
      this.statusBulk = value
      this.query = {
        ...this.query,
        action: 'status',
        value: this.statusBulk,
        records: this.bulkRecords
      }
      this.fetchData(this.query)
      this.enterEditMode()
    },
    clearStatusSelectionBulk() {
      this.statusBulk = ''
      this.selectStatusBulk(this.statusBulk)
    },
    selectDealershipBulk(value) {
      this.dealershipBulk = value
      this.query = {
        ...this.query,
        action: 'dealership',
        value: this.dealershipBulk,
        records: this.bulkRecords
      }
      this.fetchData(this.query)
      this.enterEditMode()
    },
    clearDealershipSelectionBulk() {
      this.dealershipBulk = ''
      this.selectDealershipBulk(this.dealershipBulk)
    },
    enterEditMode() {
      this.bulkSelectAll = false
      this.statusBulk = ''
      this.dealershipBulk = ''
      this.bulkRecords = []
      this.editMode = !this.editMode
    },
    buildQuery(key, value = null) {
      if (key !== 'page') {
        delete this.query.page
      }
      if (value) {
        this.query = {
          ...this.query,
          [key]: value
        }
      } else {
        this[key] = ''
        delete this.query[key]
      }
      this.fetchData(this.query)
    },
    sortBy(column, name) {
      this.sort = {
        column: column,
        order: this.sort.column === column ? (this.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
      if (name === 'Leads') {
        this.query = {
          ...this.query,
          columnLeads: this.sort.column,
          orderLeads: this.sort.order
        }
      } else if (name === 'Orders') {
        this.query = {
          ...this.query,
          columnOrders: this.sort.column,
          orderOrders: this.sort.order
        }
      }
      this.fetchData(this.query)
    },
    fetchData(query) {
      const route = !this.$props.dashboard ? this.listRoutes.index : 'dashboard'

      this.$inertia.get(this.$route(route), query, {
        preserveState: true,
        preserveScroll: true,
        replace: true
      })
    },
    prepareRoutes() {
      if (this.name === 'Leads') {
        this.listRoutes.index = 'leads.index'
        this.listRoutes.edit = 'leads.edit'
        this.listRoutes.create = 'leads.create'
      } else if (this.name === 'Orders') {
        this.listRoutes.index = 'orders.index'
        this.listRoutes.edit = 'orders.edit'
        this.listRoutes.create = 'orders.create'
      }
    },
    onExport() {
      axios
        .post(this.$route(`${this.name.toLowerCase()}.clients.export`), this.query)
        .then(({data}) => {
          this.exportToExcel(data)
        })
        .catch(response => {
          alert('An error occurred while exporting data. There might be too many entries selected. You can\'t export all entries at once. Please try to filter the data and export again.')
          console.error(response)
        })
    },
    exportToExcel(data) {
      if (data.length === 0) {
        return
      }

      const filename = 'ATC Client Data'

      const rows = data.map(row => ({
        firstName: row.client.firstName,
        lastName: row.client.lastName,
        email: row.client.email,
        phone: row.client.phone,
        address: row.client.address,
        city: row.client.city,
        country: row.client.country,
        state: row.client.state,
        zipCode: row.client.zipCode,
        prefmoc: row.client.prefmoc,
        marketing: row.client.marketing,
        clientNotes: row.client.clientNotes,
        chairModel: row.product.model,
        chairColor: row.product.extensions?.frameColor?.name
      }))

      const worksheet = XLSX.utils.json_to_sheet(rows)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, filename)

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            'First Name',
            'Last Name',
            'Email',
            'Phone',
            'Address',
            'City',
            'Country',
            'State',
            'Zip code',
            'Preferred method of contact',
            'Marketing',
            'Note to dealer',
            'Chair model',
            'Chair color'
          ]
        ],
        {origin: 'A1'}
      )

      worksheet['!cols'] = this.calculateColumnWidths(rows)

      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    calculateColumnWidths(rows) {
      const objectMaxLength = []
      const wscols = []

      rows.forEach(row => {
        Object.entries(row).forEach(([key, value], index) => {
          if (typeof value == 'number') {
            objectMaxLength[index] = 10
          } else {
            objectMaxLength[index] =
              objectMaxLength[index] >= value?.length ? objectMaxLength[index] : value?.length
          }
        })
      })

      Object.entries(rows[0]).forEach(([key, value], index) => {
        wscols.push({width: objectMaxLength[index]})
      })

      return wscols
    }
  }
}
</script>
