export const formatAddress = address => {
  if (!address) {
    return '—';
  }

  const parts = [];

  if (address.address) {
    parts.push(address.address);
  }
  if (address.street) {
    parts.push(address.street);
  }
  if (address.city) {
    parts.push(address.city);
  }
  const stateZip = [];
  if (address.state) {
    stateZip.push(address.state);
  }
  if (address.zip_code) {
    stateZip.push(address.zip_code);
  }
  if (address.zipCode) {
    stateZip.push(address.zipCode);
  }
  if (stateZip.length > 0) {
    parts.push(stateZip.join(' '));
  }

  if (address.country) {
    parts.push(address.country);
  }

  return parts.join(', ');
};
