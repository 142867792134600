<template>
  <div>
    <InertiaHead :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <form class="divide-y-2 divide-gray-200" data-testid="reservations-edit-form" @submit.prevent="submit">
        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading :heading="title" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <StatusSelector
              v-if="reservation"
              v-model="form.status"
              :auth="auth"
              :statuses="statuses"
              class="sm:!grid-cols-4 col-span-2"
              entity="reservation"
            />

            <div class="col-span-2">
              <label class="form-label-edit block my-3">Product <span class="text-red-500">*</span></label>
              <VueMultiselect
                v-model="form.organization_product"
                :allow-empty="false"
                :class="`form-multiselect-edit required ${
                  form.errors.organization_product ? 'form-multiselect-error' : ''
                }`"
                :custom-label="option => productLabel(option)"
                :disabled="!auth.can['reservations.update']"
                :options="organizationProducts.map(product => product.uuid)"
                :show-labels="false"
                placeholder="Start typing to search"
                required
              >
                <template #singleLabel="props">
                  <span>{{ productLabel(props.option) }}</span>
                </template>
              </VueMultiselect>
              <div v-if="form.errors.organization_product" class="form-error">
                {{ form.errors.organization_product }}
              </div>
            </div>

            <div class="col-span-2">
              <label class="form-label-edit block my-3">Date <span class="text-red-500">*</span></label>
              <div v-if="form.organization_product">
                <SingleReservationCalendar
                  :reservations="reservations"
                  :selected-date="form.start_at ? [form.start_at, form.end_at] : null"
                  :service="selectedProduct"
                  @update:selectedDate="onSelectedDateUpdate"
                />
              </div>
              <span v-else>Select product to see available dates.</span>
              <div v-if="form.errors.start_at || form.errors.end_at" class="form-error">
                {{ form.errors.start_at || form.errors.end_at }}
              </div>
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Client Information" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <FormInput
              id="firstName"
              v-model="form.client_data.firstName"
              :disabled="!auth.can['reservations.update']"
              :error="form.errors['client_data.firstName'] ?? ''"
              class="form-input-edit col-span-2 sm:col-span-1"
              label="First Name"
              required
            />
            <FormInput
              id="lastName"
              v-model="form.client_data.lastName"
              :disabled="!auth.can['reservations.update']"
              :error="form.errors['client_data.lastName'] ?? ''"
              class="form-input-edit col-span-2 sm:col-span-1"
              label="Last Name"
              required
            />

            <FormInput
              id="email"
              v-model="form.client_data.email"
              :disabled="!auth.can['reservations.update']"
              :error="form.errors['client_data.email'] ?? ''"
              class="form-input-edit col-span-2 sm:col-span-1"
              label="Email"
              required
            />
            <FormInput
              id="phone"
              v-model="form.client_data.phone"
              :disabled="!auth.can['reservations.update']"
              :error="form.errors['client_data.phone'] ?? ''"
              class="form-input-edit col-span-2 sm:col-span-1"
              label="Phone"
              required
            />

            <FormInput
              id="street"
              v-model="form.client_data.address.street"
              :disabled="!auth.can['reservations.update']"
              :error="form.errors['client_data.address.street'] ?? ''"
              class="form-input-edit col-span-2"
              label="Street"
            />

            <FormInput
              id="city"
              v-model="form.client_data.address.city"
              :disabled="!auth.can['reservations.update']"
              :error="form.errors['client_data.address.city'] ?? ''"
              class="form-input-edit col-span-2 sm:col-span-1"
              label="City"
            />
            <FormInput
              id="zipCode"
              v-model="form.client_data.address.zipCode"
              :disabled="!auth.can['reservations.update']"
              :error="form.errors['client_data.address.zipCode'] ?? ''"
              class="form-input-edit col-span-2 sm:col-span-1"
              label="Zip code"
              required
            />

            <div class="col-span-2 sm:col-span-1">
              <div v-if="countries">
                <label class="form-label-edit block my-3">Country <span class="text-red-500">*</span></label>
                <VueMultiselect
                  v-model="form.client_data.address.country"
                  :class="`form-multiselect-edit required ${
                    form.errors['client_data.address.country'] ? 'form-multiselect-error' : ''
                  }`"
                  :custom-label="option => countries.find(country => country.iso2 === option).name"
                  :options="countries.map(country => country.iso2)"
                  :show-labels="false"
                  placeholder="Start typing to search"
                />
                <div v-if="form.errors['client_data.address.country']" class="form-error">{{ form.errors['client_data.address.country'] }}</div>
              </div>
              <FormInput
                v-else
                id="country"
                v-model="form.client_data.address.country"
                :error="form.errors['client_data.address.country'] ?? ''"
                class="form-input-edit"
                label="Country"
                required
              />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <div v-if="states && form.client_data.address.country === 'US'">
                <label class="form-label-edit block my-3">
                  State <span v-if="form.client_data.address.country === 'US'" class="text-red-500">*</span>
                </label>
                <VueMultiselect
                  v-model="form.client_data.address.state"
                  :options="states.map(state => state.iso2)"
                  :custom-label="option => states.find(state => state.iso2 === option).name"
                  placeholder="Start typing to search"
                  :class="`form-multiselect-edit required ${
                    form.errors['client_data.address.state'] ? 'form-multiselect-error' : ''
                  }`"
                  :show-labels="false"
                >
                  <template #singleLabel="props">
                    <span>{{ states.find(state => state.iso2 === props.option)?.name }}</span>
                  </template>
                </VueMultiselect>
                <div v-if="form.errors['client_data.address.state']" class="form-error">{{ form.errors['client_data.address.state'] }}</div>
              </div>
              <FormInput
                v-else
                id="state"
                v-model="form.client_data.address.state"
                :error="form.errors['client_data.address.state'] ?? ''"
                class="form-input-edit"
                label="State"
                :required="form.client_data.address.country === 'US'"
              />
            </div>

            <FormTextArea
              id="notes"
              v-model="form.client_data.notes"
              :error="form.errors['client_data.notes'] ?? ''"
              class="form-textarea-edit col-span-2"
              label="Message"
              maxlength="500"
              rows="4"
            />

            <div v-if="auth.can['reservations.update']" class="col-span-2">
              <label class="form-label-edit block my-3">Client Account <span class="text-red-500">*</span></label>
              <VueMultiselect
                v-model="form.user"
                :options="clients.map(client => client.uuid)"
                :custom-label="option => clients.find(client => client.uuid === option).name"
                placeholder="Start typing to search"
                :class="`form-multiselect-edit required ${
                  form.errors.user ? 'form-multiselect-error' : ''
                }`"
                :show-labels="false"
              />
              <div v-if="form.errors.user" class="form-error">{{ form.errors.user }}</div>
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
          <button
            v-if="reservation && auth.can['reservations.delete']"
            type="button"
            class="form-button"
            data-testid="deleteButton"
            @click="showConfirmPopup"
          >
            Delete
          </button>
          <div class="ml-auto">
            <InertiaLink
              v-if="auth.can['reservations.index']"
              as="button"
              type="button"
              :href="$route('reservations.index')"
              class="form-button"
              data-testid="cancelButton"
            >
              Cancel
            </InertiaLink>
            <button
              type="submit"
              :disabled="form.processing"
              class="ml-3 form-submit-button"
              data-testid="submitButton"
            >
              Save
            </button>
          </div>
        </div>
      </form>
    </Panel>

    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this reservation? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink,
  useForm
} from '@inertiajs/inertia-vue3'
import {getCountries, getStates} from 'Shared/helpers'
import {isEmpty, startCase} from 'lodash'
import moment from 'moment'
import SingleReservationCalendar from 'BookingComponents/SingleReservationCalendar'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import FormInput from 'Shared/Form/FormInput'
import FormTextArea from 'Shared/Form/FormTextArea'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import StatusSelector from 'Shared/Misc/StatusSelector'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  components: {
    SingleReservationCalendar,
    ConfirmModal,
    FormInput,
    FormTextArea,
    InertiaHead,
    InertiaLink,
    Panel,
    PanelHeading,
    StatusSelector,
    VueMultiselect
  },
  layout: Layout,
  props: {
    auth: Object,
    clients: Array,
    filters: Object,
    organizationProducts: Array,
    reservation: Object,
    reservations: Array,
    statuses: Array
  },
  setup(props) {
    return {
      form: useForm({
        _method: props.reservation ? 'PUT' : 'POST',
        source: 'backend',
        status: props.reservation?.status ?? 'approved',
        client_data: {
          firstName: props.reservation?.client_data?.firstName ?? null,
          lastName: props.reservation?.client_data?.lastName ?? null,
          email: props.reservation?.client_data?.email ?? null,
          phone: props.reservation?.client_data?.phone ?? null,
          notes: props.reservation?.client_data?.notes ?? null,
          address: {
            street: props.reservation?.client_data?.address?.street ?? null,
            city: props.reservation?.client_data?.address?.city ?? null,
            state: props.reservation?.client_data?.address?.state ?? null,
            zipCode: props.reservation?.client_data?.address?.zipCode ?? null,
            country: props.reservation?.client_data?.address?.country ?? null,
          }
        },
        user: props.reservation?.user?.uuid ?? null,
        organization_product: !isEmpty(props.filters['organization-product'])
          ? props.filters['organization-product'] : (props.reservation?.organizationProduct.uuid ?? null),
        start_at: props.reservation?.start_at ?? null,
        end_at: props.reservation?.end_at ?? null,
        agreement: true
      })
    }
  },
  data() {
    return {
      title: `${this.reservation ? 'Edit' : 'Create'} reservation`,
      isConfirmPopupOpen: false,
      countries: null,
      states: null
    }
  },
  computed: {
    selectedProduct() {
      return this.organizationProducts.find(product => product.uuid === this.form.organization_product)
    },
    organizationProductUuid() {
      return this.form.organization_product
    }
  },
  watch: {
    organizationProductUuid(newValue) {
      this.form.start_at = null
      this.form.end_at = null
      this.$inertia
        .get(
          this.reservation
            ? this.$route('reservations.edit', this.reservation.uuid)
            : this.$route('reservations.create'),
          {
            'organization-product': newValue
          },
          {
            preserveState: true,
            preserveScroll: true
          })
    }
  },
  mounted() {
    getCountries().then(response => this.countries = response.data)
    getStates().then(response => this.states = response.data)
  },
  methods: {
    startCase,
    submit() {
      this.form
        .post(
          this.reservation
            ? this.$route('reservations.update', this.reservation.uuid)
            : this.$route('reservations.store'),
          {preserveState: (page) => Object.keys(page.props.errors).length}
        )
    },
    deleteReservation() {
      this.$inertia
        .post(this.$route('reservations.destroy', this.reservation.uuid), {
          _method: 'DELETE'
        })
    },
    productLabel(option) {
      const organizationProduct = this.organizationProducts.find(product => product.uuid === option)
      const name = `${organizationProduct.product.name} - ${organizationProduct.serial}`
      return name + (this.auth.can['reservations.view.any'] ? ` (${organizationProduct.organization.name})` : '')
    },
    onSelectedDateUpdate(selectedDate) {
      this.form.start_at = moment(selectedDate)
        .format('YYYY-MM-DD HH:mm:ss')
      this.form.end_at = moment(selectedDate)
        .add(this.selectedProduct?.slot_duration_minutes, 'minutes')
        .format('YYYY-MM-DD HH:mm:ss')
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteReservation()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
