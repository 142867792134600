<template>
  <ProfileLayout title="Notifications" :user="user">
    <form class="divide-y-2 divide-gray-200">
      <div v-if="user.role.name !== 'client'" class="px-4 pt-2 pb-7 sm:px-6 lg:px-8">
        <p class="my-5 text-sm text-gray-500">
          Get notified about leads or orders assigned to selected distributors.
        </p>

        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header" />
              <th scope="col" class="table-header text-center">Lead Created</th>
              <th scope="col" class="table-header text-center">Lead Status Changed</th>
              <th scope="col" class="table-header text-center">Order Created</th>
              <th scope="col" class="table-header text-center">Order Status Changed</th>
              <th scope="col" class="table-header text-center">New Message</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr v-for="dist in distributors" :key="dist.name">
              <td class="table-cell" colspan="1">{{ dist.name }}</td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="lead-created"
                  v-model="notifications.leadCreated[dist.uuid]"
                  title="Lead Created"
                  wrapper-class="mb-0 justify-center flex flex"
                  @change="e => onChange(e, 'lead_created', dist.uuid)"
                />
              </td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="lead-status"
                  v-model="notifications.leadStatusChanged[dist.uuid]"
                  title="Lead Status Changed"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'lead_status_changed', dist.uuid)"
                />
              </td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="order-created"
                  v-model="notifications.orderCreated[dist.uuid]"
                  title="Order Created"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'order_created', dist.uuid)"
                />
              </td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="order-status"
                  v-model="notifications.orderStatusChanged[dist.uuid]"
                  title="Order Status Changed"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'order_status_changed', dist.uuid)"
                />
              </td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="new-message"
                  v-model="notifications.newMessage[dist.uuid]"
                  title="New Message"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'new_message', dist.uuid)"
                />
              </td>
            </tr>

            <tr v-if="distributors.length === 0">
              <td class="table-cell text-center" colspan="4">No distributors found.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="px-4 pt-2 pb-7 sm:px-6 lg:px-8">
        <p class="my-5 text-sm text-gray-500">
          {{ user.role.name === 'client'
            ? 'Get notified about reservations.'
            : 'Get notified about reservations assigned to selected organizations.' }}
        </p>

        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header" />
              <th scope="col" class="table-header text-center">Reservation Created</th>
              <th scope="col" class="table-header text-center">Reservation Paid</th>
              <th scope="col" class="table-header text-center">Reservation Status Changed</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr v-for="organization in organizations" :key="organization.name">
              <td class="table-cell" colspan="1">{{ organization.name }}</td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="reservation-created"
                  v-model="notifications.reservationCreated[organization.uuid]"
                  title="Reservation Created"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'reservation_created', null, organization.uuid)"
                />
              </td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="reservation-paid"
                  v-model="notifications.reservationPaid[organization.uuid]"
                  title="Reservation Paid"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'reservation_paid', null, organization.uuid)"
                />
              </td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="reservation-status"
                  v-model="notifications.reservationStatusChanged[organization.uuid]"
                  title="Reservation Status Changed"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'reservation_status_changed', null, organization.uuid)"
                />
              </td>
            </tr>

            <tr v-if="organizations.length === 0">
              <td class="table-cell text-center" colspan="4">No organizations found.</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div class="px-4 pt-2 pb-7 sm:px-6 lg:px-8">
        <p class="my-5 text-sm text-gray-500">Get notified about changes in owned documents.</p>

        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header text-center">Document Expired</th>
              <th scope="col" class="table-header text-center">Document Expires In 7 Days</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <tr>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="document-expired"
                  v-model="notifications.documentExpired"
                  title="Lead Created"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'document_expired')"
                />
              </td>
              <td class="table-cell" colspan="1">
                <FormCheckbox
                  id="document-expires-soon"
                  v-model="notifications.documentExpiresSoon"
                  title="Lead Status Changed"
                  wrapper-class="mb-0 justify-center flex"
                  @change="e => onChange(e, 'document_expires_soon')"
                />
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </form>
  </ProfileLayout>
</template>

<script>
import ProfileLayout from 'UsersComponents/ProfileLayout'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    ProfileLayout,
    FormCheckbox,
    ConfirmModal,
    PanelHeading
  },
  layout: Layout,
  props: {
    user: Object,
    distributors: Array,
    organizations: Array
  },
  data() {
    return {
      notifications: {
        leadCreated: {},
        leadStatusChanged: {},
        orderCreated: {},
        orderStatusChanged: {},
        newMessage: {},
        reservationCreated: {},
        reservationPaid: {},
        reservationStatusChanged: {},
        documentExpired: this.user?.notifications?.document_expired ?? true,
        documentExpiresSoon: this.user?.notifications?.document_expires_soon ?? true
      }
    }
  },
  mounted() {
    this.setupCheckboxes()
  },
  methods: {
    setupCheckboxes() {
      this.distributors.forEach(dist => {
        const notifications = this.user.distributors.find(userDist => userDist.uuid === dist.uuid)
          ?.pivot?.notifications

        this.notifications.leadCreated[dist.uuid] = notifications?.lead_created ?? true
        this.notifications.leadStatusChanged[dist.uuid] = notifications?.lead_status_changed ?? true
        this.notifications.orderCreated[dist.uuid] = notifications?.order_created ?? true
        this.notifications.orderStatusChanged[dist.uuid] =
          notifications?.order_status_changed ?? true
        this.notifications.newMessage[dist.uuid] = notifications?.new_message ?? true
      })
      this.organizations.forEach(organization => {
        const notifications = this.user.organizations.find(userOrganization => userOrganization.uuid === organization.uuid)
          ?.pivot?.notifications

        this.notifications.reservationCreated[organization.uuid] = notifications?.reservation_created ?? true
        this.notifications.reservationPaid[organization.uuid] = notifications?.reservation_paid ?? true
        this.notifications.reservationStatusChanged[organization.uuid] = notifications?.reservation_status_changed ?? true
      })
    },
    onChange(e, name, distributor = null, organization = null) {
      this.$inertia.post(this.$route('users.notifications.update', this.user.uuid), {
        _method: 'PUT',
        distributor: distributor,
        organization: organization,
        notification: name,
        value: e.target.checked,
        preserveState: true,
        preserveScroll: true
      })
    }
  }
}
</script>
