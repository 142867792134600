<template>
  <div>
    <VueDraggableNext :list="images" animation="200">
      <div
        v-for="(imageUrl, index) in images"
        :key="`image-url-${index}`"
        class="w-40 mb-2 mr-4 inline-block relative cursor-pointer"
      >
        <img :src="imageUrl" class="w-full" :alt="`image-${index}`" />
        <button type="button" class="absolute top-0 right-0 rounded-md p-1.5" @click="deleteImage(index)">
          <XIcon class="w-5 ml-auto text-red-500 hover:text-red-700 duration-200" />
        </button>
      </div>
    </VueDraggableNext>
    <div
      v-for="error in errors"
      :key="error"
      class="form-error"
    >
      {{ error }}
    </div>
    <FileUpload
      id="file-upload"
      v-model="files"
      :multiple="true"
      description="JPEG, JPG, PNG, GIF up to 10MB"
      @update="updatePreview"
    />
  </div>
</template>

<script>
import { VueDraggableNext } from 'vue-draggable-next'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import { XIcon } from '@heroicons/vue/outline'

export default {
  name: 'ProductGallery',
  components: {
    VueDraggableNext,
    FileUpload,
    XIcon
  },
  props: {
    images: {
      type: Array,
      required: true
    },
    errors: {
      type: Object,
      required: false,
      default: () => {
        return {}
      }
    }
  },
  emits: ['delete-image', 'update-gallery'],
  data() {
    return {
      files: []

    }
  },
  methods: {
    deleteImage(index) {
      this.$emit('delete-image', index)
    },
    updatePreview(files) {
      this.files = files
      this.$emit('update-gallery', files)
    }
  }
}
</script>
