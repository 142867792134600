<template>
  <OrganizationLayout title="Organization payments" :organization="organization">
    <form class="divide-y-2 divide-gray-200" data-testid="organizations-edit-form">
      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Stripe settings" />
        <div class="flex gap-3 place-items-center">
          <StripeConnectButton
            :disabled="!!organization.stripe_account_id"
            :href="$route('stripe.connect.authorize', organization.uuid)"
          />
          <button
            v-if="!!organization.stripe_account_id"
            class="px-1 text-sm font-heading hover:text-atc-secondary duration-200"
            type="button"
            @click="showConfirmPopup"
          >
            Revoke access
          </button>
        </div>
      </div>
    </form>
    <ConfirmModal
      :loading="isLoading.revoke"
      :open="isConfirmPopupOpen"
      button-label="Revoke"
      text="Are you sure you want to revoke access?"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </OrganizationLayout>
</template>

<script>
import { Link as InertiaLink } from '@inertiajs/inertia-vue3'
import Layout from 'Shared/Layouts/Layout'
import PanelHeading from 'Shared/PanelHeading'
import OrganizationLayout from 'OrganizationsComponents/OrganizationLayout'
import StripeConnectButton from 'Shared/Buttons/StripeConnectButton'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'

export default {
  components: {
    ConfirmModal,
    StripeConnectButton,
    OrganizationLayout,
    InertiaLink,
    PanelHeading
  },
  layout: Layout,
  props: {
    auth: Object,
    organization: Object
  },
  data() {
    return {
      isConfirmPopupOpen: false,
      isLoading: false
    }
  },
  methods: {
    revokeAccess() {
      this.isLoading = true
      this.$inertia.get(this.$route('stripe.connect.deauthorize', this.organization.uuid), {}, {
        onFinish: () => {
          this.isLoading = false
          this.closeConfirmPopup()
        }
      })
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.revokeAccess()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
