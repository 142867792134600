<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_963_7399)">
      <path d="M10.2856 15.4287H1.71415C1.41104 15.4287 1.12035 15.3083 0.906024 15.0939C0.691697 14.8796 0.571289 14.5889 0.571289 14.2858V1.71439C0.571289 1.41129 0.691697 1.1206 0.906024 0.906268C1.12035 0.691941 1.41104 0.571533 1.71415 0.571533H14.2856C14.5887 0.571533 14.8794 0.691941 15.0937 0.906268C15.308 1.1206 15.4284 1.41129 15.4284 1.71439V10.2858L10.2856 15.4287Z" stroke="#626263" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M10.2852 10.8573V15.4287L15.428 10.2859H10.8566C10.705 10.2859 10.5597 10.3461 10.4525 10.4533C10.3454 10.5604 10.2852 10.7058 10.2852 10.8573Z" stroke="#626263" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_963_7399">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
