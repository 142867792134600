<template>
  <AuthLayout title="Login">
    <div class="mt-20 hidden flex-row items-center py-12 lg:flex">
      <h2 class="text-4xl uppercase text-atc-black">Login</h2>
      <Icon name="cheveron-down" class-name="mx-2 w-8" />
    </div>
    <form class="space-y-6" @submit.prevent="login">
      <FlashMessages />
      <div class="mt-1">
        <label class="form-label my-5 uppercase text-atc-black" for="email">Email</label>
        <FormInput
          id="email"
          v-model="form.email"
          :error="form.errors.email"
          type="email"
          autofocus
          autocapitalize="off"
        />
      </div>
      <div class="mt-1">
        <label class="form-label my-5 uppercase text-atc-black" for="password">Password</label>
        <FormInput
          id="password"
          v-model="form.password"
          :error="form.errors.password"
          type="password"
        />
      </div>
      <div class="flex flex-col items-center justify-between gap-x-4 xl:flex-row">
        <LoadingButton
          :loading="false"
          class="w-full justify-center border-2 border-transparent bg-atc-primary py-2 px-12 text-sm font-medium uppercase text-white shadow-sm transition ease-in-out hover:bg-atc-secondary xl:w-auto"
          type="submit"
        >
          Login
        </LoadingButton>
        <div class="grid gap-1 text-md my-3">
          <InertiaLink
            :href="$route('auth.recover')"
            class="delay-50 font-medium text-atc-black transition ease-in-out hover:text-atc-secondary"
          >
            Forgot your password?
          </InertiaLink>

          <InertiaLink
            :href="$route('auth.register')"
            class="delay-50 font-medium text-atc-black transition ease-in-out hover:text-atc-secondary"
          >
            Don't have an account?
          </InertiaLink>
        </div>
      </div>
    </form>
    <div class="text-md mt-3 mb-10">
      <a
        href="http://archive.actiontrackchair.com/"
        target="_blank"
        class="delay-50 font-medium text-atc-black transition ease-in-out hover:text-atc-secondary"
      >
        Go to archive page
      </a>
    </div>
  </AuthLayout>
</template>

<script>
import {Link as InertiaLink} from '@inertiajs/inertia-vue3'
import AuthLayout from 'Shared/Layouts/AuthLayout'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import Icon from 'Shared/Icon'
import FlashMessages from 'Shared/FlashMessages'
import FormInput from 'Shared/Form/FormInput'

export default {
  components: {
    InertiaLink,
    AuthLayout,
    LoadingButton,
    Icon,
    FlashMessages,
    FormInput
  },
  data() {
    return {
      form: this.$inertia.form({
        email: '',
        password: '',
        remember: false
      })
    }
  },
  methods: {
    login() {
      this.form.post(this.$route('auth.login'))
    }
  }
}
</script>
