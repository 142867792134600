<template>
  <ProfileLayout :title="title" :user="user">
    <form autocomplete="off" class="divide-y-2 divide-gray-200" @submit.prevent="submit">
      <div class="px-4 sm:px-6 lg:px-8 pt-2 pb-7">
        <PanelHeading :heading="title" />
        <div class="grid grid-cols-2 gap-y-6 gap-x-4">
          <div class="grid grid-cols-2 gap-y-6 gap-x-4 col-span-2">
            <div class="grid col-span-2 sm:col-span-1 gap-y-6 gap-x-4">
              <div class="col-span-2 sm:col-span-1">
                <FormInput
                  id="firstName"
                  v-model="form.firstname"
                  :error="form.errors.firstname ?? ''"
                  class="form-input-edit"
                  label="First Name"
                  required
                />
              </div>
              <div class="col-span-2 sm:col-span-1">
                <FormInput
                  id="lastName"
                  v-model="form.lastname"
                  :error="form.errors.lastname ?? ''"
                  class="form-input-edit"
                  label="Last Name"
                  required
                />
              </div>
              <div class="col-span-2 sm:col-span-1">
                <FormInput
                  id="password"
                  v-model="password"
                  :error="form.errors.password ?? ''"
                  :required="!user"
                  class="form-input-edit"
                  label="Password"
                  type="password"
                />
              </div>
            </div>

            <div class="col-span-2 sm:col-span-1">
              <div class="h-64 w-64 rounded-full border-1 border-[#e7e7e7] overflow-hidden m-auto flex mb-5">
                <img
                  class="max-w-none translate-x-[-50%] ml-[50%]"
                  :src="form.photo?.url === '' ? '/assets/images/test-avatar.png' : form.photo?.url"
                  alt="Profile photo"
                />
              </div>
              <div class="form-error">{{ form.errors['photo.file'] }}</div>
              <FileUpload
                id="file-upload"
                v-model="form.photo.file"
                :multiple="true"
                description="JPEG, JPG, PNG, GIF up to 10MB"
                @update="updatePreview"
              />
            </div>
          </div>

          <div class="col-span-2 sm:col-span-1">
            <FormInput
              id="phoneNumber"
              v-model="form.details.phone_number"
              :error="form.errors['details.phone_number'] ?? ''"
              class="form-input-edit"
              label="Phone number"
            />
          </div>
          <div class="col-span-2 sm:col-span-1">
            <FormInput
              id="email"
              v-model="form.email"
              :error="form.errors.email ?? ''"
              class="form-input-edit"
              label="Email"
              required
            />
          </div>

          <div v-if="auth.can['users.update.any']" class="col-span-2 sm:col-span-1">
            <label class="form-label-edit block my-3">User Role <span class="text-red-500">*</span></label>
            <VueMultiselect
              v-model="form.role"
              :options="roles"
              placeholder="Start typing to search"
              :custom-label="option => (startCase(toLower(option.name)))"
              track-by="id"
              :class="`form-multiselect-edit ${form.errors['role'] ? 'form-multiselect-error' : ''}`"
              :disabled="!auth.can['users.update.any']"
            >
              <template #singleLabel="props">
                <span>{{ (startCase(toLower(props.option.name))) }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'role')" />
              </template>
            </VueMultiselect>
            <div v-if="form.errors['role']" class="form-error">The role is required.</div>
          </div>
          <div v-if="auth.can['users.update.any']" class="col-span-2 sm:col-span-1">
            <label class="form-label-edit block my-3">Organization</label>
            <VueMultiselect
              v-model="form.organization"
              :options="organizations"
              placeholder="Start typing to search"
              label="name"
              track-by="uuid"
              class="form-multiselect-edit"
              :disabled="!auth.can['users.update.any']"
            >
              <template #singleLabel="props">
                <span>{{ props.option.name }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'organization')" />
              </template>
            </VueMultiselect>
            <div v-if="form.errors['organization']" class="form-error">{{ form.errors['organization'] }}</div>
          </div>

          <div v-if="auth.can['users.update.any']" class="col-span-2 sm:col-span-1">
            <label class="form-label-edit block my-3">Distributor</label>
            <VueMultiselect
              v-model="form.distributor"
              :options="distributors"
              placeholder="Start typing to search"
              label="name"
              track-by="uuid"
              class="form-multiselect-edit"
              :disabled="!auth.can['users.update.any']"
            >
              <template #singleLabel="props">
                <span>{{ props.option.name }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'distributor')" />
              </template>
            </VueMultiselect>
            <div v-if="form.errors['distributor']" class="form-error">{{ form.errors['distributor'] }}</div>
          </div>
          <div v-if="auth.can['users.update.any']" class="col-span-2 sm:col-span-1">
            <label class="form-label-edit block my-3">Dealership</label>
            <VueMultiselect
              v-model="form.dealership"
              :options="dealerships"
              placeholder="Start typing to search"
              label="name"
              track-by="uuid"
              class="form-multiselect-edit"
              :disabled="!auth.can['users.update.any']"
            >
              <template #singleLabel="props">
                <span>{{ props.option.name }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'dealership')" />
              </template>
            </VueMultiselect>
            <div v-if="form.errors['dealership']" class="form-error">{{ form.errors['dealership'] }}</div>
          </div>
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Address" />
        <div class="grid grid-cols-2 gap-y-6 gap-x-4">
          <div class="col-span-2">
            <FormInput
              id="street"
              v-model="form.address.street"
              :error="form.errors['address.street'] ?? ''"
              class="form-input-edit"
              label="Street"
            />
          </div>

          <div class="col-span-2 sm:col-span-1">
            <FormInput
              id="city"
              v-model="form.address.city"
              :error="form.errors['address.city'] ?? ''"
              class="form-input-edit"
              label="City"
            />
          </div>
          <div class="col-span-2 sm:col-span-1">
            <FormInput
              id="zipCode"
              v-model="form.address.zip_code"
              :error="form.errors['address.zip_code'] ?? ''"
              class="form-input-edit"
              label="Zip Code"
            />
          </div>

          <div class="col-span-2 sm:col-span-1">
            <div v-if="countries">
              <label class="form-label-edit block my-3">Country</label>
              <VueMultiselect
                v-model="form.address.country"
                :class="`form-multiselect-edit ${form.errors['address.country'] ? 'form-multiselect-error' : ''}`"
                :custom-label="option => countries.find(country => country.iso2 === option).name"
                :options="countries.map(country => country.iso2)"
                :show-labels="false"
                placeholder="Start typing to search"
              >
                <template #singleLabel="props">
                  <span>{{ countries.find(country => country.iso2 === props.option)?.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'address.country')" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors['address.country']" class="form-error">{{ form.errors['address.country'] }}</div>
            </div>
            <FormInput
              v-else
              id="country"
              v-model="form.address.country"
              :error="form.errors['address.country'] ?? ''"
              class="form-input-edit"
              label="Country"
            />
          </div>
          <div class="col-span-2 sm:col-span-1">
            <div v-if="states && form.address.country === 'US'">
              <label class="form-label-edit block my-3">State</label>
              <VueMultiselect
                v-model="form.address.state"
                :options="states.map(state => state.iso2)"
                :custom-label="option => states.find(state => state.iso2 === option).name"
                placeholder="Start typing to search"
                :class="`form-multiselect-edit ${form.errors['address.state'] ? 'form-multiselect-error' : ''}`"
                :show-labels="false"
              >
                <template #singleLabel="props">
                  <span>{{ states.find(state => state.iso2 === props.option)?.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'address.state')" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors['address.state']" class="form-error">{{ form.errors['address.state'] }}</div>
            </div>
            <FormInput
              v-else
              id="state"
              v-model="form.address.state"
              :error="form.errors['address.state'] ?? ''"
              class="form-input-edit"
              label="State"
            />
          </div>
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
        <button
          v-if="user && auth.can['users.delete']"
          type="button"
          class="form-button"
          @click="showConfirmPopup"
        >
          Delete
        </button>
        <div class="ml-auto">
          <InertiaLink
            v-if="auth.can['users.index']"
            type="button"
            :href="$route('users.index')"
            as="button"
            class="form-button"
          >
            Cancel
          </InertiaLink>
          <button type="submit" :disabled="form.processing" class="ml-3 form-submit-button">Save</button>
        </div>
      </div>
    </form>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this user? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </ProfileLayout>
</template>

<script>
import {Link as InertiaLink, useForm} from '@inertiajs/inertia-vue3'
import {getCountries, getStates} from 'Shared/helpers'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import FormInput from 'Shared/Form/FormInput'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import ProfileLayout from 'UsersComponents/ProfileLayout'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import {startCase, toLower} from 'lodash'

export default {
  components: {
    ConfirmModal,
    FileUpload,
    FormInput,
    InertiaLink,
    Panel,
    PanelHeading,
    ProfileLayout,
    VueMultiselect
  },
  layout: Layout,
  props: {
    user: Object,
    auth: Object,
    roles: Array,
    distributors: Array,
    dealerships: Array,
    organizations: Array
  },
  remember: 'form',
  setup(props) {
    const form = useForm({
      _method: props.user ? 'PUT' : 'POST',
      firstname: props.user?.firstname ?? null,
      lastname: props.user?.lastname ?? null,
      email: props.user?.email ?? null,
      address: {
        street: props.user?.address?.street ?? null,
        city: props.user?.address?.city ?? null,
        zip_code: props.user?.address?.zip_code ?? null,
        state: props.user?.address?.state ?? null,
        country: props.user?.address?.country ?? 'US'
      },
      details: {
        phone_number: props.user?.details?.phone_number ?? null
      },
      photo: {
        url: props.user?.photo ?? '',
        file: null
      },
      role: props.user ? props.user?.role : [],
      distributor: props.user ? props.user?.distributor : [],
      dealership: props.user ? props.user?.dealership : [],
      organization: props.user ? props.user?.organization : []
    })

    return { form }
  },
  data() {
    return {
      title: this.user ?
        ((this.form.firstname && this.form.lastname) ? `${this.form.firstname} ${this.form.lastname}` : 'Edit user')
        : 'Create user',
      isConfirmPopupOpen: false,
      password: null,
      countries: null,
      states: null
    }
  },
  mounted() {
    getCountries().then(response => this.countries = response.data)
    getStates().then(response => this.states = response.data)
  },
  methods: {
    getCountries,
    getStates,
    startCase,
    toLower,
    submit() {
      this.form
        .transform((data) => {
          if (data.distributor) {
            data.distributor = data.distributor.uuid
          }
          if (data.dealership) {
            data.dealership = data.dealership.uuid
          }
          if (data.organization) {
            data.organization = data.organization.uuid
          }
          if (this.password) return {
            ...data,
            password: this.password
          }
          return {
            ...data
          }
        })
        .post(this.user ? this.$route('users.update', this.user.uuid) : this.$route('users.store'), {
          forceFormData: true,
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deleteUser() {
      this.$inertia
        .post(this.$route('users.destroy', this.user.uuid), {
          _method: 'DELETE'
        })
    },
    updatePreview(files) {
      this.form.photo.file = files[0]
      this.form.photo.url = URL.createObjectURL(files[0])
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteUser()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
