<template>
  <DialogBase
    :modal-class="`rounded border-l-4 text-left text-atc-text`"
    :modal-style="{ borderColor: reservation?.borderColor }"
    :open="open"
    @close="handleDialogClose"
  >
    <div class="pl-6 pr-4 pt-4 pb-6 border-b border-[#b1b1b266]">
      <div class="flex justify-end">
        <button ref="cancelButtonRef" @click="handleDialogClose">
          <CrossIcon class="w-4 h-4" />
        </button>
      </div>
      <DialogTitle as="h3" class="text-xl font-medium leading-tight">
        {{ reservation?.title }}
      </DialogTitle>
      <p class="text-sm text-[#626263] mt-1">
        {{
          new Date(reservation?.start).toLocaleDateString('en-US', {
            weekday: "long",
            day: 'numeric',
            month: 'long',
          })
        }}, {{ formatActualTime(reservation, true) }}
      </p>
    </div>

    <div class="pl-6 pr-4 pt-4 pb-6 border-b border-[#b1b1b266]">
      <div class="flex">
        <h4 class="font-medium mb-4 pt-2">Reservation details:</h4>
        <InertiaLink
          as="div"
          title="Edit"
          class="ml-auto cursor-pointer"
          :href="reservation ? $route('reservations.show', reservation.extendedProps.uuid) : null"
        >
          <PencilIcon class="w-5 h-5" />
        </InertiaLink>
      </div>
      <div class="grid gap-3">
        <div class="flex">
          <UserIcon class="w-4 h-4 shrink-0" />
          <span class="ml-3 text-[#626263]">{{ reservation?.extendedProps.clientData.fullName }}</span>
        </div>
        <div class="flex">
          <EnvelopeIcon class="w-4 h-4 shrink-0" />
          <span class="ml-3 text-[#626263]">{{ reservation?.extendedProps.clientData.email }}</span>
        </div>
        <div class="flex">
          <PhoneIcon class="w-4 h-4 shrink-0" />
          <span class="ml-3 text-[#626263]">{{ reservation?.extendedProps.clientData.phone }}</span>
        </div>
        <div v-if="reservation?.extendedProps.clientData.notes" class="flex">
          <NoteIcon class="w-4 h-4 shrink-0" />
          <span class="ml-3 text-[#626263]">{{ reservation.extendedProps.clientData.notes }}</span>
        </div>
      </div>
    </div>

    <div
      v-if="$page.props.auth.can['reservations.update'] && reservation?.extendedProps.status === 'pending'"
      class="px-6 pt-2 pb-3 flex"
    >
      <span class="flex flex-col justify-center text-[#626263]">Will you confirm?</span>
      <div class="ml-auto flex gap-3">
        <button type="button" class="reservation-details-button" @click="changeStatus('approved')">
          <CheckIcon class="w-4 h-4 m-auto mr-2.5" />
          Approve
        </button>
        <button type="button" class="reservation-details-button" @click="changeStatus('rejected')">
          <CrossIcon class="w-4 h-4 m-auto mr-2.5" />
          Reject
        </button>
      </div>
    </div>
  </DialogBase>
</template>

<script>
import {Link as InertiaLink} from '@inertiajs/inertia-vue3'
import {
  CheckIcon,
  CrossIcon,
  EnvelopeIcon,
  NoteIcon,
  PencilIcon,
  PhoneIcon,
  UserIcon
} from 'Shared/Icons/Icons'
import DialogBase from 'Shared/Dialogs/DialogBase'
import {DialogTitle} from '@headlessui/vue'
import moment from 'moment'
import axios from 'axios'

export default {
  components: {
    CheckIcon,
    CrossIcon,
    EnvelopeIcon,
    DialogBase,
    DialogTitle,
    InertiaLink,
    NoteIcon,
    PencilIcon,
    PhoneIcon,
    UserIcon
  },
  props: {
    assignedColors: Object,
    calendarApi: Object,
    reservation: Object,
    service: Object,
    open: Boolean
  },
  emits: ['close'],
  methods: {
    moment,
    formatActualTime(event, showEnd) {
      const formatOptions = {
        hour: 'numeric',
        minute: '2-digit'
      }
      const start = moment(event?.start)
        .add(event?.extendedProps.bufferTimeBefore, 'minutes')
        .toDate()
        .toLocaleTimeString('en-US', formatOptions)
      const end = moment(event?.end)
        .subtract(event?.extendedProps.bufferTimeAfter, 'minutes')
        .toDate()
        .toLocaleTimeString('en-US', formatOptions)
      return showEnd ? `${start} - ${end}` : start
    },
    handleDialogClose() {
      this.$emit('close')
    },
    changeStatus(status) {
      axios
        .post(this.$route('reservations.change-status', this.reservation.extendedProps.uuid), {
          status: status
        })
        .then(response => {
          if (response.status !== 200) {
            return
          }

          const productUuid = this.reservation.extendedProps.productUuid
          const backgroundColor = status === 'approved'
            ? this.assignedColors[productUuid].accepted
            : this.assignedColors[productUuid].pending

          this.reservation.setExtendedProp('status', status)
          this.reservation.setProp('backgroundColor', backgroundColor)

          if (status === 'rejected') {
            this.reservation.remove()
            this.$emit('close')
          }
        })
    }
  }
}
</script>
