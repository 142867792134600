<template>
  <div class="relative pb-4">
    <span v-if="!isOldestLog" class="absolute top-5 left-5 -ml-px h-full w-0.5 bg-gray-200" aria-hidden="true" />
    <div class="relative flex items-start space-x-3">
      <div>
        <div class="relative px-1">
          <div class="h-8 w-8 bg-gray-100 rounded-full ring-8 ring-white flex items-center justify-center">
            <SwitchVerticalIcon v-if="log.event === 'converted'" class="h-5 w-5 text-gray-500" />
            <DocumentTextIcon v-else-if="log.subject_type === 'attachment'" class="h-5 w-5 text-gray-500" />
            <UserCircleIcon v-else-if="log.causer" class="h-5 w-5 text-gray-500" />
          </div>
        </div>
      </div>
      <div class="min-w-0 flex-1 py-1.5">
        <div class="text-sm text-gray-500 leading-6">
          <div>
            <Link
              v-if="log.subject && !log.subject.deleted_at"
              :href="log.subject_type === 'attachment' ?
                $route(`${log.subject.record_type}s.documents.show`, [log.subject.record.uuid, log.subject.uuid]) :
                $route(`${log.subject_type}s.edit`, log.subject.uuid)"
              class="font-medium text-gray-900"
            >
              {{ subjectTitle() }}
            </Link>
            <span v-else class="font-medium text-gray-900">{{ subjectTitle() }}</span>

            <span v-if="isLeadConverted()"> {{ ` ${log.description}` }} to <Link
              :href="$route('orders.edit', log.subject.order.uuid)"
              class="font-medium text-gray-900"
            >
              Order #{{ log.subject.order.stock }}
            </Link></span>

            <span v-if="isAssignmentUpdated() || isClientUpdated() || isProductUpdated()">
              <span v-if="isLeadConverted()"> and </span>
              <span v-if="isAssignmentUpdated()"> assignment</span>
              <span v-if="isClientUpdated()"><span v-if="isAssignmentUpdated()">,</span> client data</span>
              <span v-if="isProductUpdated()"><span v-if="isAssignmentUpdated() || isClientUpdated()">,</span> product data</span>
              <span v-if="!isStatusUpdated()"> changed</span>
            </span>

            <span v-if="isStatusUpdated()">
              <span v-if="isAssignmentUpdated() || isClientUpdated() || isProductUpdated() || isLeadConverted()"> and </span>
              <span>
                status changed from <span
                  class="font-medium text-white rounded-md px-2 py-1 whitespace-nowrap"
                  :class="getStatusColor(log.properties.old?.status?.id)"
                >{{ log.properties.old?.status?.label }}</span> to <span
                  class="font-medium text-white rounded-md px-2 py-1 whitespace-nowrap"
                  :class="getStatusColor(log.properties.attributes?.status?.id)"
                >{{ log.properties.attributes?.status?.label }}</span>
              </span>
            </span>

            <span v-if="!isStatusUpdated() && !isClientUpdated() && !isProductUpdated() && !isAssignmentUpdated() && !isLeadConverted()">
              <span v-if="log.event === 'created' && !log.causer"> submitted</span>
              <span v-else-if="log.event === 'created' && log.subject_type === 'attachment'"> uploaded</span>
              <span v-else>{{ ` ${log.description}` }}</span>
            </span>

            <span v-if="log.causer"> by <span class="font-medium text-gray-900">{{ `${log.causer.firstname} ${log.causer.lastname}` }}</span></span>

            <span
              v-if="isAssignmentUpdated()"
              class="ml-1 cursor-pointer"
              title="Show details"
              @click="showLogDetails(log.id)"
            >
              <Icon class="side-panel-icon inline-flex" :name="`pointer`" />
            </span>
          </div>

          <div ref="details" class="max-h-0 overflow-hidden duration-200">
            <div v-for="(change, key) in details" :key="key">
              {{ capitalize(key).replaceAll('_', ' ') }} changed<span v-if="change[0]"> from <span class="font-medium text-gray-900">{{ change[0]?.name }}</span></span> to <span class="font-medium text-gray-900">{{ change[1]?.name ?? '—' }}</span>
            </div>
          </div>

          <div class="whitespace-nowrap mt-2">
            <span class="flex">
              <ClockIcon class="w-4 mr-1" />{{ formatDateTime(log.created_at) }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import axios from 'axios'
import { ClockIcon, DocumentTextIcon, SwitchVerticalIcon } from '@heroicons/vue/outline'
import { UserCircleIcon } from '@heroicons/vue/solid'
import { getStatusColor } from 'Shared/helpers'
import Dropdown from 'Shared/Dropdown'
import Icon from 'Shared/Icon'
import { capitalize } from 'lodash'

export default {
  components: {
    Link,
    ClockIcon,
    DocumentTextIcon,
    SwitchVerticalIcon,
    UserCircleIcon,
    Dropdown,
    Icon
  },
  props: {
    log: Object,
    isOldestLog: {
      type: Boolean
    }
  },
  data() {
    return {
      details: []
    }
  },
  watch: {
    details() {
      setTimeout(() => {
        const details = this.$refs.details

        if (details.style.maxHeight) {
          details.style.maxHeight = null
        } else {
          details.style.maxHeight = details.scrollHeight + 'px'
        } 
      }, 200)
    }
  },
  methods: {
    capitalize,
    getStatusColor,
    subjectTitle() {
      return this.log.subject_type === 'attachment' ?
        this.log.subject.name : capitalize(this.log.subject_type)
    },
    isLeadConverted() {
      return this.log.event === 'converted'
    },
    isStatusUpdated() {
      return this.log.properties.old?.status?.id
    },
    isClientUpdated() {
      if (this.log.properties.attributes?.status?.id === 'delivered'
        && this.log.properties.old?.status?.id !== this.log.properties.attributes?.status?.id) {
        return false
      }
      return this.log.properties.old?.client
    },
    isProductUpdated() {
      return this.log.properties.old?.product
    },
    isAssignmentUpdated() {
      if (this.log.description === 'created') return
      return this.log.properties.old?.distributor_id || this.log.properties.attributes?.distributor_id ||
        this.log.properties.old?.dist_member_id || this.log.properties.attributes?.dist_member_id ||
        this.log.properties.old?.dealership_id || this.log.properties.attributes?.dealership_id ||
        this.log.properties.old?.dealer_id || this.log.properties.attributes?.dealer_id
    },
    showLogDetails(id) {
      axios
        .post(this.$route('activity.details', id))
        .then(response => {
          this.details = response.data
        })
    },
    formatDateTime(date) {
      date = new Date(date)
      return date.toLocaleString('en-US', {
        dateStyle: 'long',
        timeStyle: 'short'
      })
    }
  }
}
</script>
