<template>
  <div class="flex h-full flex-col place-content-between">
    <div v-if="data.total" class="mb-4 font-heading text-[1.65rem] font-normal">
      {{ data.total }}
    </div>
    <div
      v-if="chartData.labels.length === 0"
      class="flex h-full min-h-[12rem] items-center justify-center"
    >
      No data recorded
    </div>
    <Doughnut
      v-if="chartData.labels.length > 0"
      ref="chart"
      :chart-options="doughnutChartOptions"
      :chart-data="chartData"
    />
  </div>
</template>

<script>
import {Doughnut} from 'vue-chartjs'
import {Chart as ChartJS, Title, Tooltip, ArcElement, Legend, CategoryScale} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, ArcElement, CategoryScale)

export default {
  components: {
    Doughnut
  },
  props: {
    data: Object,
    total: String | Number,
    chartOptions: Object
  },
  data(props) {
    return {
      doughnutChartOptions: {
        ...this.chartOptions,
        maintainAspectRatio: false,
        scales: false,
        plugins: {
          tooltip: {
            callbacks: {
              label(context) {
                const totalValue = context.dataset.data.reduce((partialSum, a) => partialSum + a, 0)
                const value = context.dataset.data[context.dataIndex]
                const percentage = parseFloat((value / totalValue) * 100).toFixed(1)

                return ` ${props.data.labels[context.dataIndex]}: ${percentage}% (${value})`
              },
              footer(context) {
                if (context[0].label === 'Others') {
                  const totalValue = context[0].dataset.data.reduce(
                    (partialSum, a) => partialSum + a,
                    0
                  )
                  const others = []

                  Object.entries(props.data.others).forEach(([key, value]) => {
                    const percentage = parseFloat((value / totalValue) * 100).toFixed(1)

                    others.push(`${key}: ${percentage}% (${value})`)
                  })

                  return others
                }
              }
            }
          }
        }
      }
    }
  },
  computed: {
    chartData() {
      const count = this.data.labels.length
      const colors = []

      for (let i = 0; i < count; i++) {
        colors[i] = '#' + Math.floor(Math.random() * 16777215).toString(16)
      }

      return {
        labels: this.data.labels,
        datasets: [
          {
            label: this.data.label ?? 'Data',
            backgroundColor: colors,
            data: this.data.metrics
          }
        ]
      }
    }
  },
  mounted() {
    if (ChartJS.overrides.doughnut) {
      ChartJS.overrides.doughnut.plugins.legend.align = 'start'
    }
  }
}
</script>
