<template>
  <Layout :confirmation="true" @on-confirm="passValue($event)">
    <InertiaHead :title="`${order ? 'Edit' : 'Create'} order`" />
    <form class="lead-wrapper flex flex-wrap xl:flex-nowrap" @submit.prevent="submit">
      <SidePanel
        entity="parts-order"
        :form="form"
        :record="order"
        :errors="form.errors"
        :distributors="distributors"
        :dist-members="distMembers"
        :dealerships="dealerships"
        :dealers="dealers"
        :saved="saved"
        :disabled-inputs="isInputDisabled()"
        :disabled-multiselects="isInputDisabled()"
        :disabled-multiselects-dist=" blockDistFieldsIfPermissionDenied()"
        class-name="!block ml-4 md:ml-12 xl:ml-0"
        @on-print-price="printPriceTable"
        @on-print-parts="printPartsList"
        @on-delete="showConfirmPopup('delete')"
        @on-deselect="(e, dropdown) => clearSelection(e, dropdown)"
        @update:saved="saved = false"
      />

      <Panel class-name="w-full xl:w-4/5 ml-4 mt-8 md:mt-12 md:ml-12 sm:px-0 lg:px-0 main-panel h-fit">
        <FlashMessages />
        <div v-if="order" class="border-b-2 border-gray-200 px-4 sm:px-6 lg:px-8">
          <Tabs :tabs="tabs" />
        </div>
        <div class="panel-sub-section">
          <PanelHeading heading="Order details" />
          <div class="grid grid-cols-1 gap-y-6 gap-x-4 text-lg sm:grid-cols-2">
            <FormInput
              id="number"
              v-model="form.number"
              :error="formatError(form.errors['number'])"
              class="form-input-edit"
              label="Order number"
              :disabled="isInputDisabled()"
              @change="saved = false"
            />
            <FormInput
              id="tracking"
              v-model="form.tracking"
              :disabled="isInputDisabled()"
              :error="formatError(form.errors['tracking'])"
              class="form-input-edit"
              label="Tracking number"
              @change="saved = false"
            />
            <FormInput
              id="po"
              v-model="form.po"
              :error="formatError(form.errors['po'])"
              class="form-input-edit"
              label="PO #"
              :disabled="isInputDisabled()"
              @change="saved = false"
            />
            <div class="form-input-edit">
              <p class="text-sm mb-1 block font-medium text-atc-text order-value">Order value: ${{ Number(orderValue).toFixed(2) }}</p>
            </div>
          </div>
        </div>
        <div v-if="order" class="panel-sub-section text-area-break">
          <PanelHeading heading="Order status" />
          <StatusSelector
            v-model="form.status"
            :auth="$page.props?.auth"
            :disabled="isInputDisabled()"
            entity="parts-order"
            :statuses="statuses"
            class="lg:!grid-cols-3"
            @input="onStatusSelect"
          />
        </div>
        <div class="panel-sub-section">
          <PanelHeading heading="Order Information" />
          <div class="grid grid-cols-1 gap-y-6 gap-x-4 text-lg sm:grid-cols-2">
            <template v-for="partType in parts" :key="partType">
              <div class="form-input-edit">
                <FormLabel :id="partType.uuid" :text="partType.name" />
                <VueMultiselect
                  :id="partType.uuid"
                  v-model="form.parts[partType.name]"
                  :allow-empty="true"
                  :close-on-select="false"
                  :disabled="isInputDisabled()"
                  :multiple="true"
                  :options="partType.parts"
                  class="form-multiselect-edit form-multiselect-edit--big form-multiselect-product"
                  deselect-label="Press enter to remove"
                  label="name"
                  placeholder="Start typing to search"
                  track-by="uuid"
                  @remove="onPartsChanged"
                  @select="onPartsChanged"
                />
              </div>
            </template>
          </div>
          <div v-if="form.errors['parts']" class="form-error">
            {{ form.errors['parts'] }}
          </div>
        </div>

        <div class="record-control-panel px-10 py-7">
          <div class="justify-even flex">
            <div class="flex w-full">
              <button
                v-if="order &&
                  ($page.props.auth.can['parts-orders.delete'] ||
                    $page.props.auth.can['parts-orders.delete.any'])"
                type="button"
                class="form-button"
                @click="showConfirmPopup('delete')"
              >
                Delete
              </button>
              <InertiaLink
                type="button"
                :href="$route('parts-orders.index')"
                as="button"
                class="form-button ml-auto"
              >
                Cancel
              </InertiaLink>
              <button type="submit" :disabled="form.processing" class="form-submit-button ml-3">
                Save
              </button>
            </div>
          </div>
        </div>
      </Panel>
    </form>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      :text="popupText"
      button-label="Confirm"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
    <FullPageLoader />
    <Pdf
      v-if="order"
      ref="pdf"
      :data="formatPartOrderPrintData(order, orderParts)"
      :record="order"
      entity="parts-order"
      :statuses="statuses"
    />
  </Layout>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink,
  useForm
} from '@inertiajs/inertia-vue3'
import Layout from 'Shared/LeadsAndOrders/LayoutWide'
import {formatPartOrderPrintData} from 'Shared/helpers'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import SidePanel from 'Shared/LeadsAndOrders/SidePanel'
import StatusSelector from 'Shared/Misc/StatusSelector'
import FormInput from 'Shared/Form/FormInput'
import FormLabel from 'Shared/Form/FormLabel'
import FormTextArea from 'Shared/Form/FormTextArea'
import Pdf from 'Shared/PDF/PartsList'
import {getCountries, getStates} from 'Shared/helpers'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Icon from 'Shared/Icon'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import Dropdown from 'Shared/Dropdown'
import FlashMessages from 'Shared/FlashMessages'
import FullPageLoader from 'Shared/FullPageLoader'
import Tabs from 'Shared/Navigation/Tabs'
import axios from 'axios'

export default {
  components: {
    ConfirmModal,
    Dropdown,
    FlashMessages,
    FormInput,
    FormLabel,
    FormTextArea,
    FullPageLoader,
    Icon,
    InertiaHead,
    InertiaLink,
    Layout,
    Panel,
    PanelHeading,
    Pdf,
    SidePanel,
    StatusSelector,
    Tabs,
    VueMultiselect
  },
  props:{
    distributors: Array,
    dealers: Array,
    dealerships: Array,
    distMembers: Array,
    order: Object,
    statuses: Array,
    parts: Array,
    dealershipName: String,
    orderParts: Array
  },
  data() {
    return {
      confirmSignal: null,
      countries: null,
      form: useForm({
        _method: this.order ? 'PUT' : 'POST',
        _source: 'backend',
        number: this.order?.number ?? '',
        tracking: this.order?.tracking ?? '',
        po: this.order?.po ?? '',
        parts: this.formatPartsData(this.orderParts) ?? {},
        client: {
          firstName: this.order ? this.order?.client?.firstName : (this.$page.props.auth.user.firstname ?? ''),
          lastName: this.order ? this.order?.client?.lastName : (this.$page.props.auth.user.lastname ?? ''),
          email: this.order ? this.order?.client?.email : (this.$page.props.auth.user.email ?? null),
          phone: this.order ? this.order?.client?.phone : (this.$page.props.auth.user.details.phone_number ?? null),
          address: this.order ? this.order?.client?.address : (this.$page.props.auth.user.address.street ?? null),
          city: this.order ? this.order?.client?.city : (this.$page.props.auth.user.address.city ?? null),
          country: this.order ? this.order?.client?.country : (this.$page.props.auth.user.address.country ?? 'US'),
          state: this.order ? this.order?.client?.state : (this.$page.props.auth.user.address.state ?? null),
          zipCode: this.order ? this.order?.client?.zipCode : (this.$page.props.auth.user.address.street ?? null),
          clientNotes: this.order?.client?.clientNotes ?? null,
          dealership: this.order?.client?.dealership ?? null
        },
        distributor:
          this.order?.distributor ??
          this.$page.props?.auth?.user?.distributor ??
          this.$page.props?.auth?.user?.dealership?.distributor,
        dealer: this.order?.dealer ?? null,
        dealership: this.order?.dealership ?? this.$page.props?.auth?.user?.dealership,
        distMember: this.order?.distMember ?? null,
        status: this.order?.status ?? 'received'
      }),
      isConfirmPopupOpen: false,
      orderValue: this.order?.value ?? 0,
      popupAction: '',
      popupText: '',
      redirectLink: '',
      saved: true,
      states: null,
      tabs: this.order
        ? [
          {
            label: 'Details',
            route: this.$route('parts-orders.edit', this.order.uuid),
            current: ['parts-orders.edit']
          },
          {
            label: 'Documents',
            route: this.$route('parts-orders.documents.index', this.order.uuid),
            current: ['parts-orders.documents.index', 'parts-orders.documents.show']
          },
          {
            label: 'Activity',
            route: this.$route('parts-orders.activity.index', this.order.uuid),
            current: ['parts-orders.activity.index']
          }
        ]
        : null
    }
  },
  computed: {
    distributorUuid() {
      return this.form.distributor?.uuid
    },
    dealershipUuid() {
      return this.form.dealership?.uuid
    }
  },
  watch: {
    confirmSignal(e) {
      this.onRedirect(e)
    }
  },
  mounted() {
    this.$watch(
      () => [this.distributorUuid, this.dealershipUuid],
      ([newDist, newDealer]) => {
        this.$inertia
          .get(
            this.order
              ? this.$route('parts-orders.show', this.order.uuid)
              : this.$route('parts-orders.create'),
            {
              distributor: newDist,
              dealership: newDealer
            },
            {
              preserveState: true,
              preserveScroll: true
            })
      }
    )
    getCountries().then(response => (this.countries = response.data))
    getStates().then(response => (this.states = response.data))
    this.form.client.dealership = this.dealership
  },
  methods:{
    getCountries,
    getStates,
    formatPartOrderPrintData,
    async printPriceTable() {
      try {
        const response = await axios.get(this.$route('parts-orders.pdf', this.order.uuid), {
          responseType: 'blob'
        })

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const newWindow = window.open(url, '_blank')

        if (newWindow) {
          const interval = setInterval(() => {
            if (newWindow.document.readyState === 'complete') {
              clearInterval(interval)
              newWindow.print()
            }
          }, 100)
        } else {
          alert('Popup blocked. Please allow popups for this website.')
        }
      } catch (error) {
        console.error('Error printing PDF: ', error)
        alert('An error occurred while printing the PDF.')
      }
    },
    printPartsList() {
      this.$refs.pdf.exportToPDF()
    },
    passValue(e) {
      this.confirmSignal = e
    },
    formatPartsData(data) {
      if (!data) {
        return null
      }
      let transformedObject = {}

      for (let key in data) {
        let name = data[key].name
        let parts = data[key].parts
        transformedObject[name] = parts
      }
      return transformedObject
    },
    onPartsChanged() {
      this.$nextTick(() => {
        this.countPartsValue()
      })
    },
    countPartsValue() {
      const parts = {...this.form.parts}
      const total = Object.values(parts).reduce((sum, arr) => {
        return sum + arr.reduce((totalPrice, obj) => {
          return totalPrice + parseFloat(obj.price)
        }, 0)
      }, 0)
      this.orderValue = total
    },
    onStatusSelect(event) {
      this.form.status = event
      this.saved = false
    },
    isInputDisabled() {
      const allowedAny = this.$page.props.auth.can['parts-orders.update.any']
      const allowed = this.$page.props.auth.can['parts-orders.update']
      const canManage = this.$page.props.auth.can['parts-orders.manage']
      return canManage ? false : (!(allowed && allowedAny))
    },
    blockDistFieldsIfPermissionDenied() {
      if (!this.order) {
        return
      }
      return !(this.$page.props.auth.role.name === 'admin' || this.$page.props.auth.role.name === 'dist_manager')
    },
    submit() {
      this.form
        .transform(data => {
          data.dealership = data.dealership?.uuid
          data.dealer = data.dealer?.uuid
          data.distMember = data.distMember?.uuid
          data.distributor = data.distributor?.uuid
          data.parts = Object.values(data.parts).map(arr =>
            arr.map(obj => obj.uuid)
          ).flat()
          if (typeof  data.status !== 'string') {
            data.status = data.status?.id
          }

          return data
        })
        .post(
          this.order
            ? this.$route('parts-orders.update', this.order.uuid)
            : this.$route('parts-orders.store'),
          {
            forceFormData: true,
            preserveState: page => Object.keys(page.props.errors).length
          }
        )
    },
    deleteOrder() {
      this.$inertia.post(this.$route('parts-orders.destroy', this.order.uuid), {
        _method: 'DELETE'
      })
    },
    formatError(error) {
      if (error) {
        let result = error.replace('client.', '').replace('product.', '')
        return result
      } else {
        return error
      }
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    onRedirect(e) {
      this.redirectLink = e.target.pathname + e.target.search
      if (!this.saved) {
        this.showConfirmPopup('redirect')
      } else {
        this.popupAction = 'redirect'
        this.confirmAction()
      }
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'delete') {
        this.deleteOrder()
      }
      if (this.popupAction === 'redirect') {
        this.$inertia.visit(this.redirectLink)
      }
    },
    showConfirmPopup(action) {
      this.popupAction = action
      if (this.popupAction === 'delete') {
        this.popupText = 'Are you sure you want to delete this order? This action cannot be undone.'
      }
      if (this.popupAction === 'redirect') {
        this.popupText = 'Are you sure you want to leave this page without saving?'
      }
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>

<style>
.panel-inactive {
  cursor: pointer;
}
.cursor-not-allowed .panel-inactive,
.cursor-not-allowed .status-badge {
  cursor: not-allowed;
}
.record-control-panel {
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: block;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  padding: 0.6rem 1.5rem;
}
.panel-wrapper-padding {
  padding-bottom: 110px;
}
</style>
