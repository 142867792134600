<template>
<TransitionRoot as="template" :show="open">
    <Dialog as="div" class="cm-dialog">
        <div class="cm-container">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="cm-dialog-overlay" />
            </TransitionChild>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="cm-center-modal" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 sm:scale-95" enter-to="opacity-100 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 sm:scale-100" leave-to="opacity-0  sm:scale-95">
                <div class="cm-content">
                    <div class="cm-flex justify-center">
                        <div class="cm-title-wrapper w-full">
                            <DialogTitle as="h3" class="cm-title text-center mb-6">Upload Image</DialogTitle>
                            <div class="mt-2">
                                <FileUpload class="w-full" id="file-upload" :multiple="false" description="JPEG, JPG, PNG, GIF up to 10MB" @update="updatePreview" acceptTypes="image/png, image/gif, image/jpeg"/>
                            </div>
                            <div v-if="imageUrl">
                                <img :src="imageUrl" alt="">
                            </div>
                        </div>
                    </div>
                    <div class="cm-buttons-wrapper pl-0 pr-0 mx-0 mt-5 flex justify-center">
                        <button type="button" :disabled="!image" class="form-red-button" @click="onConfirm">{{ buttonLabel ?? 'Leave' }}</button>
                        <button ref="cancelButtonRef" type="button" class="form-button ml-3" @click="onClose">Cancel</button>
                    </div>
                    <div v-if="loading" class="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-full h-full flex bg-gray-100 justify-center items-center opacity-60">
                        <Spinner />
                    </div>
                </div>
            </TransitionChild>
        </div>
    </Dialog>
</TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
} from '@headlessui/vue'
import {
    ExclamationIcon
} from '@heroicons/vue/outline'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import Spinner from 'Shared/Spinner'

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        ExclamationIcon,
        FileUpload,
        Spinner
    },
    props: {
        open: Boolean,
        buttonLabel: String,
        loading: Boolean
    },
    data() {
        return {
            image: null,
            imageUrl: null
        }
    },
    methods: {
        updatePreview(files) {
            if (files) {
                this.image = files[0];
                this.imageUrl = URL.createObjectURL(this.image);
            }
        },
        onClose() {
            this.image = null;
            this.imageUrl = null;
            this.$emit('on-close')
        },
        onConfirm() {
            this.$emit('on-confirm', this.image);
            this.image = null;
            this.imageUrl = null;
        }
    },
    emits: ['on-close', 'on-confirm']
}
</script>
