<template>
  <div>
    <InertiaHead title="Statistics" />

    <Panel>
      <div class="flex flex-wrap justify-between">
        <PanelHeading
          v-if="auth.can['statistics.orders.index'] && auth.can['statistics.booking.index']"
          :tabs="tabs"
        />
        <PanelHeading v-else heading="Statistics" />
        <div class="flex flex-row items-center py-6 font-heading uppercase">
          <a class="table-controls-create-btn" @click="exportToExcel">
            <Icon class="table-controls-create-icon" name="plus" />
            Export
          </a>
        </div>
      </div>
      <div class="filters pb-5">
        <div class="form-input-edit filters-datepicker">
          <Datepicker
            v-model="form.date"
            :auto-apply="true"
            :enable-time-picker="false"
            :preset-ranges="presetRanges"
            model-type="dd.mm.yyyy"
            placeholder="Last 7 days"
            range
          />
        </div>
        <div v-if="auth.role.name === 'admin'" class="form-input-edit">
          <VueMultiselect
            v-model="form.distributor"
            :custom-label="option => distributorLabel(option)"
            :options="distributors.map(dist => dist.uuid)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Distributor"
          >
            <template #singleLabel="props">
              <span>{{ distributorLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('distributor')" />
            </template>
          </VueMultiselect>
        </div>
        <div class="form-input-edit">
          <VueMultiselect
            v-model="form.dealership"
            :custom-label="option => dealershipLabel(option)"
            :options="dealerships.map(dealer => dealer.uuid)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Dealership"
          >
            <template #singleLabel="props">
              <span>{{ dealershipLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('dealership')" />
            </template>
          </VueMultiselect>
        </div>
        <div class="form-input-edit">
          <VueMultiselect
            v-model="form.country"
            :custom-label="option => countryLabel(option)"
            :options="countries.map(country => country.iso2)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Country"
          >
            <template #singleLabel="props">
              <span>{{ countryLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('country')" />
            </template>
          </VueMultiselect>
        </div>
        <div class="form-input-edit">
          <VueMultiselect
            v-model="form.product"
            :custom-label="option => productLabel(option)"
            :options="products.map(product => product.uuid)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Trackchair model"
          >
            <template #singleLabel="props">
              <span>{{ productLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('product')" />
            </template>
          </VueMultiselect>
        </div>
      </div>
    </Panel>
    <div class="grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2 xl:grid-cols-3">
      <Chart
        v-for="(metric, key) in statistics"
        :key="key"
        :class="{'chart-expanded': expanded === key}"
        :data="metric"
        :days="days"
        :is-expanded="expanded === key"
        class="col-span-1"
        @expand-chart="expandChart(key)"
      />
    </div>
  </div>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import * as XLSX from 'xlsx/xlsx.mjs'
import Chart from 'Shared/Charts/Chart'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import {getCountries} from 'Shared/helpers'
import {pickBy, throttle} from 'lodash'
import {endOfMonth, startOfMonth, subMonths} from 'date-fns'
import {secondsToDays} from 'Shared/helpers'

export default {
  components: {
    InertiaHead,
    Datepicker,
    VueMultiselect,
    Chart,
    Icon,
    Panel,
    PanelHeading
  },
  layout: Layout,
  props: {
    auth: Object,
    filters: Object,
    days: Array,
    statistics: Array,
    dealerships: Array,
    distributors: Array,
    products: Array
  },
  setup() {
    return {
      presetRanges: [
        {label: 'Today', range: [new Date(), new Date()]},
        {
          label: 'Last 7 days',
          range: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()]
        },
        {
          label: 'This month',
          range: [startOfMonth(new Date()), endOfMonth(new Date())]
        },
        {
          label: 'Last month',
          range: [
            startOfMonth(subMonths(new Date(), 1)),
            endOfMonth(subMonths(new Date(), 1))
          ]
        }
      ]
    }
  },
  data() {
    return {
      form: {
        date: this.filters.date,
        dealership: this.filters.dealership,
        distributor: this.filters.distributor,
        country: this.filters.country,
        product: this.filters.product
      },
      countries: [],
      expanded: null,
      tabs: [
        {
          label: 'Orders statistics',
          url: this.$route('statistics.orders.index')
        },
        {
          label: 'Booking statistics',
          url: this.$route('statistics.booking.index')
        }
      ]
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get(this.$route('statistics.orders.index'), pickBy(this.form), {
          preserveState: true,
          preserveScroll: true
        })
      }, 150)
    }
  },
  mounted() {
    getCountries().then(({data}) => (this.countries = data))
  },
  methods: {
    secondsToDays,
    reset(filter) {
      this.form[filter] = null
    },
    expandChart(key) {
      this.expanded = this.expanded === key ? null : key
    },
    exportToExcel() {
      const filename = 'ATC Reports'
      const workbook = XLSX.utils.book_new()
      const rows = []

      this.statistics.forEach((stat, index) => {
        const metrics = stat?.allMetrics ?? stat?.datasets[0]?.data

        rows[0] = [
          ...(rows[0] ?? []),
          stat.name,
          stat.total ? this.formatData(stat, this.formatDecimals(stat, stat.total)) : null,
          null
        ]

        metrics.map((element, i) => {
          if (!rows[i + 1]) {
            rows[i + 1] = []
          }

          rows[i + 1][index * 3] = stat.allLabels?.[i] ?? this.days?.[i]
          rows[i + 1][index * 3 + 1] = this.formatData(stat, this.formatDecimals(stat, element))
          rows[i + 1][index * 3 + 2] = null
        })
      })

      const worksheet = XLSX.utils.json_to_sheet(rows, {
        skipHeader: true
      })

      if (!worksheet['!cols']) worksheet['!cols'] = []

      rows[0].forEach((column, index) => {
        if (!column) return 10

        const max_width = rows[0][index]
          ? rows.reduce((w, r) => {
            if (!r[index]) return w

            return Math.max(w, r[index]?.toString().length)
          }, 10)
          : 10

        worksheet['!cols'][index] = {wch: max_width}
      })

      XLSX.utils.book_append_sheet(workbook, worksheet, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    formatDecimals(data, value) {
      if (data.showPercentage) {
        value = value * 100
      }
      return data.showDecimals ? parseFloat(value).toFixed(2) : parseInt(value)
    },
    formatData(data, value) {
      if (data.isTime) {
        value = this.secondsToDays(value)
      }
      if (data.showPercentage) {
        value += '%'
      }
      if (data.showCurrency) {
        value = '$' + value
      }

      return value
    },
    distributorLabel(option) {
      return this.distributors.find(dist => dist.uuid === option).name
    },
    dealershipLabel(option) {
      return this.dealerships.find(dealer => dealer.uuid === option).name
    },
    countryLabel(option) {
      return this.countries.find(country => country.iso2 === option)?.name
    },
    productLabel(option) {
      return this.products.find(product => product.uuid === option).name
    }
  }
}
</script>
