<template>
  <div class="border-b-2 border-gray-300 pt-6 pb-8">
    <h2 class="mb-3 font-heading text-2xl">{{ extensionType.name }}</h2>
    <div class="flex flex-col">
      <p
        v-if="extensionType.description"
        class="mb-2 text-[15px] font-light leading-7 text-gray-800"
      >
        {{ extensionType.description }}
      </p>
      <div>
        <div v-if="extensionType.extensions.standard">
          <p
            v-if="
              !isEmptyObject(extensionType.extensions.standard) &&
              !isEmptyObject(extensionType.extensions.special)
            "
            class="mb-1 mt-2 text-sm font-semibold text-gray-800"
          >
            Standard (without additional payment)
          </p>
          <div class="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
            <div
              v-for="(extension, index) in extensionType.extensions.standard"
              :key="extension.id"
            >
              <ConfiguratorExtensionCheckboxInput
                v-if="extensionType.multiselect"
                :extension="extension"
                :extension-type="extensionType"
                :product="product"
                @extension-choice="value => $emit('extension-choice', value)"
              />
              <ConfiguratorExtensionRadioInput
                v-else
                :extension="extension"
                :index="+index"
                :extension-type="extensionType"
                :product="product"
                @extension-choice="value => $emit('extension-choice', value)"
              />
            </div>
          </div>
        </div>

        <div v-if="extensionType.extensions.special">
          <p
            v-if="
              !isEmptyObject(extensionType.extensions.standard) &&
              !isEmptyObject(extensionType.extensions.special)
            "
            class="mb-1 mt-4 text-sm font-semibold text-gray-800"
          >
            Special (with additional payment)
          </p>
          <div class="grid grid-cols-2 gap-2 sm:grid-cols-3 md:grid-cols-4">
            <div v-for="(extension, index) in extensionType.extensions.special" :key="extension.id">
              <ConfiguratorExtensionCheckboxInput
                v-if="extensionType.multiselect"
                :extension="extension"
                :extension-type="extensionType"
                :product="product"
                @extension-choice="value => $emit('extension-choice', value)"
              />
              <ConfiguratorExtensionRadioInput
                v-else
                :extension="extension"
                :index="+index"
                :extension-type="extensionType"
                :product="product"
                @extension-choice="value => $emit('extension-choice', value)"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConfiguratorExtensionRadioInput from 'ConfiguratorComponents/ConfiguratorExtensionRadioInput'
import ConfiguratorExtensionCheckboxInput from 'ConfiguratorComponents/ConfiguratorExtensionCheckboxInput'
export default {
  name: 'ConfiguratorExtensionSection',
  components: {
    ConfiguratorExtensionRadioInput,
    ConfiguratorExtensionCheckboxInput
  },
  props: {
    extensionType: Object,
    product: Object
  },
  emits: ['extension-choice'],
  methods: {
    isEmptyObject(object) {
      return Object.keys(object).length === 0
    }
  }
}
</script>
