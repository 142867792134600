<template>
  <a v-if="confirmation" class="flex items-center hover:text-atc-secondary duration-200" :href="url" @click.prevent="$emit('on-confirm-nested', $event)">{{ label }} <div class="quicklink-count--wrapper"><p class="quicklink-count">{{ count }}</p></div></a>
  <Link v-else :href="url" class="flex items-center hover:text-atc-secondary duration-200">{{ label }} <div class="quicklink-count--wrapper"><p class="quicklink-count">{{ count }}</p></div></Link>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'

export default {
  components: {
    Link
  },
  props: {
    label: String,
    count: Number,
    confirmation: Boolean,
    url: String,
    value: {
      type: String,
      default: ''
    }
  },
  data()  {
    return {
      status: this.value
    }
  }
}
</script>