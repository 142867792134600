<template>
  <div>
    <Head :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <form class="divide-y-2 divide-gray-200" @submit.prevent="submit">
        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading :heading="title" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="name" v-model="form.name" :error="form.errors.name ?? ''" class="form-input-edit" label="Name" required />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <label class="form-label-edit block my-3">Distributor</label>
              <VueMultiselect
                v-model="form.distributor"
                :options="distributors"
                placeholder="Start typing to search"
                label="name"
                track-by="uuid"
                :class="`form-multiselect-edit ${form.errors.type ? 'form-multiselect-error' : ''}`"
                :disabled="!$page.props.auth.can['dealerships.update.any']"
              >
                <template #singleLabel="props">
                  <span>{{ props.option.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'distributor')" />
                </template>
              </VueMultiselect>
            </div>

            <div class="col-span-2 sm:col-span-1">
              <FormInput id="phoneNumber" v-model="form.phone_number" :error="form.errors.phone_number ?? ''" class="form-input-edit" label="Phone number" />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="email" v-model="form.email" :error="form.errors.email ?? ''" class="form-input-edit" label="Email" />
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Address" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div class="col-span-2">
              <FormInput id="street" v-model="form.address.street" :error="form.errors['address.street'] ?? ''" class="form-input-edit" label="Street" />
            </div>

            <div class="col-span-2 sm:col-span-1">
              <FormInput id="city" v-model="form.address.city" :error="form.errors['address.city'] ?? ''" class="form-input-edit" label="City" />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="zipCode" v-model="form.address.zip_code" :error="form.errors['address.zip_code'] ?? ''" class="form-input-edit" label="Zip Code" />
            </div>

            <div class="col-span-2 sm:col-span-1">
              <div v-if="countries">
                <label class="form-label-edit block my-3">Country</label>
                <VueMultiselect
                  v-model="form.address.country"
                  :options="countries.map(country => country.iso2)"
                  :custom-label="option => countries.find(country => country.iso2 === option).name"
                  placeholder="Start typing to search"
                  :class="`form-multiselect-edit ${form.errors['address.country'] ? 'form-multiselect-error' : ''}`"
                  :show-labels="false"
                >
                  <template #singleLabel="props">
                    <span>{{ countries.find(country => country.iso2 === props.option)?.name }}</span>
                    <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'address.country')" />
                  </template>
                </VueMultiselect>
                <div v-if="form.errors['address.country']" class="form-error">The country field is required.</div>
              </div>
              <FormInput v-else id="country" v-model="form.address.country" :error="form.errors['address.country'] ?? ''" class="form-input-edit" label="Country" />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <div v-if="states && form.address.country === 'US'">
                <label class="form-label-edit block my-3">State</label>
                <VueMultiselect
                  v-model="form.address.state"
                  :options="states.map(state => state.iso2)"
                  :custom-label="option => states.find(state => state.iso2 === option).name"
                  placeholder="Start typing to search"
                  :class="`form-multiselect-edit ${form.errors['address.state'] ? 'form-multiselect-error' : ''}`"
                  :show-labels="false"
                >
                  <template #singleLabel="props">
                    <span>{{ states.find(state => state.iso2 === props.option)?.name }}</span>
                    <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'address.state')" />
                  </template>
                </VueMultiselect>
                <div v-if="form.errors['address.state']" class="form-error">The state field is required.</div>
              </div>
              <FormInput v-else id="state" v-model="form.address.state" :error="form.errors['address.state'] ?? ''" class="form-input-edit" label="State" />
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Members" />

          <div class="col-span-2 pb-4">
            <label class="form-label-edit block my-3">Manager</label>
            <VueMultiselect
              v-model="form.managers"
              :options="managers"
              :multiple="true"
              placeholder="Start typing to search"
              :custom-label="customLabel"
              track-by="uuid"
              :class="`form-multiselect-edit ${form.errors.managers ? 'form-multiselect-error' : ''}`"
              :disabled="!$page.props.auth.can['dealerships.update.any']"
            >
              <template #option="{ option }">
                <img class="w-6 h-6 rounded-full border-1 border-[#e7e7e7] inline mr-3" :src="option.photo ?? '/assets/images/test-avatar.png'" :alt="`${option.details.display_name} Photo`" />
                {{ `${option.name} ${option.email ? `(${option.email})` : ''}` }}
              </template>
            </VueMultiselect>
          </div>

          <div class="col-span-2 pb-4">
            <label class="form-label-edit block my-3">Employees</label>
            <VueMultiselect
              v-model="form.dealers"
              :options="dealers"
              :multiple="true"
              :custom-label="customLabel"
              placeholder="Start typing to search"
              track-by="uuid"
              :close-on-select="false"
              class="form-multiselect-edit form-multiselect-edit--big"
            >
              <template #option="{ option }">
                <img class="w-6 h-6 rounded-full border-1 border-[#e7e7e7] inline mr-3" :src="option.photo ?? '/assets/images/test-avatar.png'" :alt="`${option.details.display_name} Photo`" />
                {{ `${option.name} ${option.email ? `(${option.email})` : ''}` }}
              </template>
            </VueMultiselect>
          </div>

          <div class="col-span-2">
            <FormInput
              id="newEmployeeEmail"
              v-model="form.new_employee_email"
              :error="form.errors.new_employee_email ?? ''"
              class="form-input-edit"
              label="Add / Invite new employee"
              placeholder="example@email.com"
            />
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
          <button
            v-if="dealership && $page.props.auth.can['dealerships.delete']"
            type="button"
            class="form-button"
            @click="showConfirmPopup"
          >
            Delete
          </button>
          <div class="ml-auto">
            <Link
              v-if="$page.props.auth.can['dealerships.index']"
              as="button"
              type="button"
              :href="$route('dealerships.index')"
              class="ml-auto form-button"
            >
              Cancel
            </Link>
            <button type="submit" :disabled="form.processing" class="ml-3 form-submit-button">Save</button>
          </div>
        </div>
      </form>
    </Panel>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this dealership? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import {Head, Link, useForm} from '@inertiajs/inertia-vue3'
import {getCountries, getStates} from 'Shared/helpers'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormInput from 'Shared/Form/FormInput'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    Head,
    Link,
    VueMultiselect,
    FormInput,
    ConfirmModal,
    Panel,
    PanelHeading
  },
  layout: Layout,
  props: {
    dealership: Object,
    distributors: Array,
    dealers: Array,
    managers: Array
  },
  setup(props) {
    const form = useForm({
      _method: props.dealership ? 'PUT' : 'POST',
      name: props.dealership?.name ?? null,
      address: {
        street: props.dealership?.address?.street ?? null,
        city: props.dealership?.address?.city ?? null,
        zip_code: props.dealership?.address?.zip_code ?? null,
        state: props.dealership?.address?.state ?? null,
        country: props.dealership?.address?.country ?? 'US'
      },
      phone_number: props.dealership?.phone_number ?? null,
      email: props.dealership?.email ?? null,
      distributor: props.dealership?.distributor ?? null,
      managers: props.dealership?.managers ?? [],
      dealers: props.dealership?.dealers ?? [],
      new_employee_email: null
    })

    return { form }
  },
  data() {
    return {
      title: `${this.dealership ? 'Edit' : 'Create'} dealership`,
      isConfirmPopupOpen: false,
      countries: null,
      states: null
    }
  },
  mounted() {
    getCountries().then(response => this.countries = response.data)
    getStates().then(response => this.states = response.data)
  },
  methods: {
    getCountries,
    getStates,
    submit() {
      this.form
        .post(this.dealership ? this.$route('dealerships.update', this.dealership.uuid) : this.$route('dealerships.store'), {
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deleteDealership() {
      this.$inertia
        .post(this.$route('dealerships.destroy', this.dealership.uuid), {
          _method: 'DELETE'
        })
    },
    customLabel(options) {
      return options.name ?? options.email
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteDealership()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
