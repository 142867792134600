<template>
  <div :class="className" class="side-panel hidden w-full xl:w-1/5 bg-atc-white text-atc-text xl:block">
    <div class="side-panel-section">
      <div class="flex justify-between">
        <div class="group flex cursor-pointer select-none items-center">
          <Icon :name="`pointer`" class="side-panel-icon rotate-90" />
          <InertiaLink :href="$route(`${entity}s.index`)" class="side-panel-link">{{ entity }}s</InertiaLink>
        </div>

        <Dropdown v-if="record" class="mt-1" placement="bottom-end">
          <template #default>
            <div class="group flex cursor-pointer select-none items-center">
              <span class="side-panel-link">Actions</span>
              <Icon class="side-panel-icon" :name="`pointer`" />
            </div>
          </template>
          <template #dropdown>
            <div class="mt-2 rounded bg-white py-2 text-sm text-atc-text shadow-xl">
              <a
                class="block cursor-pointer px-6 py-2 hover:bg-atc-success hover:text-white"
                @click="$emit('onPrintPrice')"
              >
                Print price table
              </a>
              <a
                class="block cursor-pointer px-6 py-2 hover:bg-atc-success hover:text-white"
                @click="$emit('onPrintParts')"
              >
                Print parts list
              </a>
              <a
                v-if="($page.props.auth.can[`${entity}s.delete`] || $page.props.auth.can[`${entity}s.delete.any`])"
                as="button"
                class="block w-full cursor-pointer px-6 py-2 text-left hover:bg-atc-success hover:text-white"
                @click="$emit('onDelete')"
              >
                Delete
              </a>
            </div>
          </template>
        </Dropdown>
      </div>
      <div class="side-panel-details">
        <h2 class="side-panel-heading">{{ heading() }}</h2>
        <a class="side-panel-contact" :href="`mailto:${form.client.email}`">
          {{ form.client.email }}
        </a>
        <a class="side-panel-contact" :href="`tel:${form.client.phone}`">
          {{ form.client.phone }}
        </a>
      </div>
    </div>

    <div class="side-panel-section">
      <PanelHeading heading="Distributor" class-name="py-0 pb-2" />
      <div class="form-input-edit">
        <FormLabel id="distributor" text="Organization" />
        <VueMultiselect
          id="distributor"
          v-model="mutableForm.distributor"
          :options="distributors ?? []"
          placeholder="Start typing to search"
          label="name"
          track-by="uuid"
          :class="`form-multiselect-edit distributor-select ${
            errors['distributor'] ? 'form-multiselect-error' : ''
          }`"
          :disabled="disabledMultiselectsDist"
          :show-labels="false"
          @select="e => onSelect(e, 'onDistributorSelect')"
        >
          <template #singleLabel="props">
            <span>{{ props.option.name }}</span>
            <i
              class="multiselect__tag-icon"
              @mousedown.stop="e => $emit('onDeselect', e, 'distributor')"
            />
          </template>
        </VueMultiselect>
        <div v-if="$props.errors['distributor']" class="form-error">
          {{ $props.errors['distributor'] }}
        </div>
      </div>
      <div class="form-input-edit">
        <FormLabel id="distMember" text="Employee" />
        <VueMultiselect
          id="distMember"
          v-model="mutableForm.distMember"
          :options="distMembers ?? []"
          placeholder="Start typing to search"
          label="name"
          track-by="uuid"
          :class="`form-multiselect-edit distributor-select ${
            errors['distMember'] ? 'form-multiselect-error' : ''
          }`"
          :disabled="disabledMultiselectsDist"
          :show-labels="false"
          @select="e => onSelect(e)"
        >
          <template #singleLabel="props">
            <span>{{ props.option.name }}</span>
            <i
              class="multiselect__tag-icon"
              @mousedown.stop="e => $emit('onDeselect', e, 'distMember')"
            />
          </template>
        </VueMultiselect>
        <div v-if="$props.errors['distMember']" class="form-error">
          {{ $props.errors['distMember'] }}
        </div>
      </div>
    </div>

    <div class="side-panel-section">
      <PanelHeading heading="Assigned Dealer" class-name="py-0 pb-2" />
      <div class="form-input-edit">
        <FormLabel id="dealership" text="Organization" />
        <VueMultiselect
          id="dealership"
          v-model="mutableForm.dealership"
          :options="dealerships ?? []"
          placeholder="Start typing to search"
          label="name"
          track-by="uuid"
          :class="`form-multiselect-edit ${errors['dealership'] ? 'form-multiselect-error' : ''}`"
          :disabled="disabledMultiselectsDist"
          :show-labels="false"
          @select="e => onSelect(e, 'onDealershipSelect')"
        >
          <template #singleLabel="props">
            <span>{{ props.option.name }}</span>
            <i
              class="multiselect__tag-icon"
              @mousedown.stop="e => $emit('onDeselect', e, 'dealership')"
            />
          </template>
        </VueMultiselect>
        <div v-if="$props.errors['dealership']" class="form-error">
          {{ $props.errors['dealership'] }}
        </div>
      </div>
      <div class="form-input-edit">
        <FormLabel id="dealer" text="Employee" />
        <VueMultiselect
          id="dealer"
          v-model="mutableForm.dealer"
          :options="dealers ?? []"
          placeholder="Start typing to search"
          label="name"
          track-by="uuid"
          :class="`form-multiselect-edit ${errors['dealer'] ? 'form-multiselect-error' : ''}`"
          :disabled="disabledMultiselects"
          :show-labels="false"
          @select="e => onSelect(e)"
        >
          <template #singleLabel="props">
            <span>{{ props.option.name }}</span>
            <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'dealer')" />
          </template>
        </VueMultiselect>
        <div v-if="$props.errors['dealer']" class="form-error">
          {{ $props.errors['dealer'] }}
        </div>
      </div>
    </div>

    <div class="side-panel-section">
      <PanelHeading heading="Contact Information" class-name="py-0 pb-4" />
      <FormInput
        id="first-name"
        v-model="mutableForm.client.firstName"
        :error="formatError(errors['client.firstName'])"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="First Name"
        @change="$emit('update:saved', false)"
      />
      <FormInput
        id="last-name"
        v-model="mutableForm.client.lastName"
        :error="formatError(errors['client.lastName'])"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="Last Name"
        @change="$emit('update:saved', false)"
      />
      <FormInput
        id="email"
        v-model="mutableForm.client.email"
        :error="formatError(errors['client.email'])"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="Email"
        @change="$emit('update:saved', false)"
      />
      <FormInput
        id="phone"
        v-model="mutableForm.client.phone"
        :error="formatError(errors['client.phone'])"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="Phone"
        @change="$emit('update:saved', false)"
      />
      <FormInput
        id="address"
        v-model="mutableForm.client.address"
        :error="formatError(errors['client.address'])"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="Address"
        @change="$emit('update:saved', false)"
      />
      <FormInput
        id="city"
        v-model="mutableForm.client.city"
        :error="formatError(errors['client.city'])"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="City"
        @change="$emit('update:saved', false)"
      />
      <div v-if="countries">
        <VueMultiselect
          v-model="mutableForm.client.country"
          :options="countries.map(country => country.iso2)"
          :custom-label="option => countries.find(country => country.iso2 === option).name"
          placeholder="Country"
          :class="`form-multiselect-edit ${
            errors['client.country'] ? 'form-multiselect-error' : ''
          }`"
          :show-labels="false"
          @select="$emit('update:saved', false)"
        >
          <template #singleLabel="props">
            <span>{{ countries.find(country => country.iso2 === props.option)?.name }}</span>
            <i
              class="multiselect__tag-icon"
              @mousedown.stop="e => clearSelection(e, 'client.country')"
            />
          </template>
        </VueMultiselect>
        <div v-if="$props.errors['client.country']" class="form-error">
          {{ $props.errors['client.country'] }}
        </div>
      </div>
      <FormInput
        v-else
        id="country"
        v-model="mutableForm.client.country"
        :error="errors['client.country'] ?? ''"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="Country"
        @change="$emit('update:saved', false)"
      />
      <div v-if="states && mutableForm.client.country === 'US'">
        <VueMultiselect
          v-model="mutableForm.client.state"
          :options="states.map(state => state.iso2)"
          :custom-label="option => states.find(state => state.iso2 === option).name"
          placeholder="State"
          :class="`form-multiselect-edit ${errors['client.state'] ? 'form-multiselect-error' : ''}`"
          :show-labels="false"
          @select="$emit('update:saved', false)"
        >
          <template #singleLabel="props">
            <span>{{ states.find(state => state.iso2 === props.option)?.name }}</span>
            <i
              class="multiselect__tag-icon"
              @mousedown.stop="e => clearSelection(e, 'client.state')"
            />
          </template>
        </VueMultiselect>
        <div v-if="$props.errors['client.state']" class="form-error">
          {{ $props.errors['client.state'] }}
        </div>
      </div>
      <FormInput
        v-else
        id="state"
        v-model="mutableForm.client.state"
        :error="formatError(errors['client.state'])"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="State"
        @change="$emit('update:saved', false)"
      />
      <FormInput
        id="zip-code"
        v-model="mutableForm.client.zipCode"
        :error="formatError(errors['client.zipCode'])"
        class="form-input-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        placeholder="Zip Code"
        @change="$emit('update:saved', false)"
      />
      <FormTextArea
        id="client-notes"
        v-model="mutableForm.client.clientNotes"
        :error="formatError(errors['client.clientNotes'])"
        class="form-textarea-edit"
        :class="{disabled: disabledInputs}"
        :disabled="disabledInputs"
        rows="10"
        label="Notes"
        @change="$emit('update:saved', false)"
      />
    </div>
    <div v-if="record?.source" class="side-panel-section border-0">
      <PanelHeading heading="Source" class-name="py-0 pb-4" />
      <span class="mt-3 block font-heading text-[15px] capitalize text-atc-text">{{
        record.source
      }}</span>
    </div>

    <slot />
  </div>
</template>

<script>
import {Link as InertiaLink} from '@inertiajs/inertia-vue3'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormInput from 'Shared/Form/FormInput'
import FormLabel from 'Shared/Form/FormLabel'
import FormTextArea from 'Shared/Form/FormTextArea'
import {getCountries, getStates} from 'Shared/helpers'
import Icon from 'Shared/Icon'
import Dropdown from 'Shared/Dropdown'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    Dropdown,
    FormInput,
    FormLabel,
    FormTextArea,
    Icon,
    InertiaLink,
    PanelHeading,
    VueMultiselect
  },
  props: {
    className: String,
    entity: String,
    form: Object,
    record: Object,
    errors: {
      type: Object,
      default: () => ({})
    },
    distributors: Array,
    distMembers: Array,
    dealerships: Array,
    dealers: Array,
    saved: Boolean,
    disabledInputs: Boolean,
    disabledMultiselects: Boolean,
    disabledMultiselectsDist: Boolean
  },
  emits: ['onPrintPrice', 'onPrintParts', 'onDelete', 'onDistributorSelect', 'onDeselect', 'update:saved'],
  data() {
    return {
      mutableForm: this.form,
      countries: null,
      states: null
    }
  },
  mounted() {
    getCountries().then(response => (this.countries = response.data))
    getStates().then(response => (this.states = response.data))
  },
  methods: {
    getCountries,
    getStates,
    heading() {
      return this.form.client.firstName || this.form.client.lastName
        ? `${this.form.client.firstName} ${this.form.client.lastName}`
        : `New ${this.entity}`
    },
    onSelect(e, emits) {
      this.$emit('update:saved', false)

      if (emits) {
        this.$emit(emits, e)
      }
    },
    formatError(error) {
      if (error) {
        let result = error.replace('client.', '').replace('product.', '')
        return result
      } else {
        return error
      }
    }
  }
}
</script>
