<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_963_7387)">
      <path d="M14.2856 2H1.71415C1.41104 2 1.12035 2.12041 0.906024 2.33474C0.691697 2.54906 0.571289 2.83975 0.571289 3.14286V12.8571C0.571289 13.1602 0.691697 13.4509 0.906024 13.6653C1.12035 13.8796 1.41104 14 1.71415 14H14.2856C14.5887 14 14.8794 13.8796 15.0937 13.6653C15.308 13.4509 15.4284 13.1602 15.4284 12.8571V3.14286C15.4284 2.83975 15.308 2.54906 15.0937 2.33474C14.8794 2.12041 14.5887 2 14.2856 2Z" stroke="#626263" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M0.571289 3.42847L7.26843 9.14275C7.47379 9.3138 7.7326 9.40747 7.99986 9.40747C8.26712 9.40747 8.52593 9.3138 8.73129 9.14275L15.4284 3.42847" stroke="#626263" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_963_7387">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
