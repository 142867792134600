<template>
  <div class="flex">
    <FileUpload
      :id="`file-upload-${id}`"
      :value="imageFile"
      class="pr-5"
      description="JPEG, JPG, PNG, GIF up to 10MB"
      @update="file => updatePreview(file)"
    />
    <div v-if="imageUrl" class="w-28 mb-2 mr-4 inline-block relative">
      <img :src="imageUrl" alt="Preview image" class="w-full" />
      <button
        class="absolute top-0 right-0 rounded-md p-1.5 text-red-500"
        type="button"
        @click="deleteImage()"
      >
        <XIcon class="w-5 ml-auto text-red-500 hover:text-red-700 duration-200" />
      </button>
      <div class="form-error">{{ error }}</div>
    </div>
  </div>
</template>

<script>
import {XIcon} from '@heroicons/vue/outline'
import FileUpload from 'Shared/Form/Uploads/FileUpload'

export default {
  components: {
    XIcon,
    FileUpload
  },
  props: {
    id: {
      type: String,
      required: true
    },
    imageFile: {
      type: File,
      default: null
    },
    imageUrl: {
      type: String,
      default: ''
    },
    error: {
      type: String,
      default: ''
    }
  },
  emits: ['update'],
  methods: {
    updatePreview(files) {
      const file = files[0]

      if (file) {
        const reader = new FileReader()

        reader.onload = (event) => {
          this.$emit('update', {
            data: {
              file: file,
              url: event.target.result
            },
            event: event
          })
        }
        reader.readAsDataURL(file)
      } else {
        this.deleteImage()
      }
    },
    deleteImage() {
      this.$emit('update', {
        data: {
          file: null,
          url: ''
        },
        event: null
      })
    }
  }
}
</script>
