<template>
  <label :for="id" class="text-sm text-[15px] mb-1 block font-heading font-medium text-atc-text">
    {{ text }} <span v-if="required" class="text-red-500">*</span>
  </label>
</template>

<script>
export default {
  props: {
    id: {
      type: String
    },
    text: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  }
}
</script>
