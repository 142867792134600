<template>
  <div v-if="links.length > 3" class="flex items-center justify-center mt-5">
    <div class="flex flex-wrap -mb-1">
      <template v-for="(link, key) in links">
        <div
          v-if="link.url === null"
          :key="key"
          class="mb-1 mr-1 px-4 py-3 form-button text-gray-400 hover:bg-atc-white text-sm font-normal leading-4 border cursor-default"
          v-html="link.label"
        />
        <Link
          v-else
          :key="`link-${key}`"
          class="mb-1 mr-1 px-4 py-3 focus:text-atc-text-500 text-sm font-normal leading-4 form-button"
          :class="{ 'form-submit-button': link.active }"
          :href="link.url"
          @click="$emit('changePage', getPageKey(key))"
          v-html="link.label"
        />
      </template>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'

export default {
  components: {
    Link
  },
  props: {
    links: Array,
    currentPage: Number
  },
  emits: ['changePage'],
  methods: {
    getPageKey(key) {
      if (key === 0) {
        return this.currentPage - 1
      }
      if (key === this.links.length - 1) {
        return this.currentPage + 1
      }
      return key
    }
  }
}
</script>
