<template>
  <TransitionRoot as="template" :show="open">
    <DialogHeadless as="div" class="cm-dialog">
      <div class="cm-container">
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0"
          enter-to="opacity-100"
          leave="ease-in duration-200"
          leave-from="opacity-100"
          leave-to="opacity-0"
        >
          <DialogOverlay :class="overlayClass" class="fixed inset-0" @click="$emit('close')"/>
        </TransitionChild>

        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="cm-center-modal" aria-hidden="true">&#8203;</span>
        <TransitionChild
          as="template"
          enter="ease-out duration-300"
          enter-from="opacity-0 sm:scale-95"
          enter-to="opacity-100 sm:scale-100"
          leave="ease-in duration-200"
          leave-from="opacity-100 sm:scale-100"
          leave-to="opacity-0 sm:scale-95"
        >
          <div
            :class="modalClass"
            :style="modalStyle"
            class="relative inline-block align-bottom sm:align-middle bg-white sm:my-8 sm:max-w-lg sm:w-full shadow-dialog"
          >
            <slot />
          </div>
        </TransitionChild>
      </div>
    </DialogHeadless>
  </TransitionRoot>
</template>

<script>
import {
  Dialog as DialogHeadless,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import {ExclamationIcon} from '@heroicons/vue/outline'

export default {
  components: {
    DialogHeadless,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    ExclamationIcon
  },
  props: {
    modalClass: String,
    modalStyle: Object,
    open: Boolean,
    overlayClass: String
  },
  emits: ['close']
}
</script>
