<template>
  <div>
    <Head :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <form class="divide-y-2 divide-gray-200" @submit.prevent="submit">
        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading :heading="title" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div class="grid grid-cols-2 gap-y-6 gap-x-4 col-span-2">
              <div class="grid col-span-2 sm:col-span-1 gap-y-6 gap-x-4">
                <div class="col-span-2 sm:col-span-1">
                  <FormInput id="firstName" v-model="form.firstname" :error="form.errors.firstname ?? ''" class="form-input-edit" label="First Name" required />
                </div>
                <div class="col-span-2 sm:col-span-1">
                  <FormInput id="lastName" v-model="form.lastname" :error="form.errors.lastname ?? ''" class="form-input-edit" label="Last Name" required />
                </div>
                <div class="col-span-2 sm:col-span-1">
                  <label class="form-label-edit block my-3">Dealership</label>
                  <VueMultiselect
                    v-model="form.dealership"
                    :options="dealerships"
                    placeholder="Start typing to search"
                    label="name"
                    track-by="uuid"
                    class="form-multiselect-edit"
                  >
                    <template #singleLabel="props">
                      <span>{{ props.option.name }}</span>
                      <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'dealership')" />
                    </template>
                  </VueMultiselect>
                </div>
              </div>

              <div class="col-span-2 sm:col-span-1">
                <div class="h-64 w-64 rounded-full border-1 border-[#e7e7e7] overflow-hidden m-auto flex mb-5">
                  <img class="max-w-none translate-x-[-50%] ml-[50%]" :src="form.photo?.url === '' ? '/assets/images/test-avatar.png' : form.photo?.url" />
                </div>
                <div class="form-error">{{ form.errors['photo.file'] }}</div>
                <FileUpload
                  id="file-upload"
                  v-model="form.photo.file"
                  :multiple="true"
                  description="JPEG, JPG, PNG, GIF up to 10MB"
                  @update="updatePreview"
                />
              </div>
            </div>

            <div class="col-span-2 sm:col-span-1">
              <FormInput id="phoneNumber" v-model="form.details.phone_number" :error="form.errors['details.phone_number'] ?? ''" class="form-input-edit" label="Phone number" required />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="email" v-model="form.email" :error="form.errors.email ?? ''" class="form-input-edit" label="Email" required />
            </div>

            <div v-if="!dealer" class="col-span-2">
              <FormInput id="password" v-model="password" :error="form.errors.password ?? ''" class="form-input-edit" label="Password" type="password" required />
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Address" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div class="col-span-2">
              <FormInput id="street" v-model="form.address.street" :error="form.errors['address.street'] ?? ''" class="form-input-edit" label="Street" />
            </div>

            <div class="col-span-2 sm:col-span-1">
              <FormInput id="city" v-model="form.address.city" :error="form.errors['address.city'] ?? ''" class="form-input-edit" label="City" />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="zipCode" v-model="form.address.zip_code" :error="form.errors['address.zip_code'] ?? ''" class="form-input-edit" label="Zip Code" />
            </div>

            <div class="col-span-2 sm:col-span-1">
              <div v-if="countries">
                <label class="form-label-edit block my-3">Country</label>
                <VueMultiselect
                  v-model="form.address.country"
                  :options="countries.map(country => country.iso2)"
                  :custom-label="option => countries.find(country => country.iso2 === option).name"
                  placeholder="Start typing to search"
                  :class="`form-multiselect-edit ${form.errors['address.country'] ? 'form-multiselect-error' : ''}`"
                  :show-labels="false"
                >
                  <template #singleLabel="props">
                    <span>{{ countries.find(country => country.iso2 === props.option)?.name }}</span>
                    <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'address.country')" />
                  </template>
                </VueMultiselect>
                <div v-if="form.errors['address.country']" class="form-error">The country field is required.</div>
              </div>
              <FormInput v-else id="country" v-model="form.address.country" :error="form.errors['address.country'] ?? ''" class="form-input-edit" label="Country" />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <div v-if="states && form.address.country === 'US'">
                <label class="form-label-edit block my-3">State</label>
                <VueMultiselect
                  v-model="form.address.state"
                  :options="states.map(state => state.iso2)"
                  :custom-label="option => states.find(state => state.iso2 === option).name"
                  placeholder="Start typing to search"
                  :class="`form-multiselect-edit ${form.errors['address.state'] ? 'form-multiselect-error' : ''}`"
                  :show-labels="false"
                >
                  <template #singleLabel="props">
                    <span>{{ states.find(state => state.iso2 === props.option)?.name }}</span>
                    <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'address.state')" />
                  </template>
                </VueMultiselect>
                <div v-if="form.errors['address.state']" class="form-error">The state field is required.</div>
              </div>
              <FormInput v-else id="state" v-model="form.address.state" :error="form.errors['address.state'] ?? ''" class="form-input-edit" label="State" />
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
          <a v-if="dealer" type="button" as="button" class="form-button" @click="showConfirmPopup">Delete</a>
          <Link type="button" :href="$route('dealers.index')" as="button" class="ml-auto form-button">Cancel</Link>
          <button type="submit" :disabled="form.processing" class="ml-3 form-submit-button">Save</button>
        </div>
      </form>
    </Panel>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this dealer? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import { Head, Link, useForm } from '@inertiajs/inertia-vue3'
import { getCountries, getStates } from 'Shared/helpers'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import FormInput from 'Shared/Form/FormInput'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    Head,
    Link,
    VueMultiselect,
    FileUpload,
    FormInput,
    ConfirmModal,
    Panel,
    PanelHeading
  },
  layout: Layout,
  props: {
    dealer: Object,
    dealerships: Array
  },
  setup(props) {
    const form = useForm({
      _method: props.dealer ? 'PUT' : 'POST',
      firstname: props.dealer?.firstname ?? null,
      lastname: props.dealer?.lastname ?? null,
      email: props.dealer?.email ?? null,
      address: {
        street: props.dealer?.address?.street ?? null,
        city: props.dealer?.address?.city ?? null,
        zip_code: props.dealer?.address?.zip_code ?? null,
        state: props.dealer?.address?.state ?? null,
        country: props.dealer?.address?.country ?? 'US'
      },
      details: {
        phone_number: props.dealer?.details?.phone_number ?? null
      },
      photo: {
        url: props.dealer?.photo ?? '',
        file: null
      },
      dealership: props.dealer ? props.dealer?.dealership : []
    })

    return { form }
  },
  data() {
    return {
      title: `${this.dealer ? 'Edit' : 'Create'} dealer`,
      isConfirmPopupOpen: false,
      password: null,
      countries: null,
      states: null
    }
  },
  mounted() {
    getCountries().then(response => this.countries = response.data)
    getStates().then(response => this.states = response.data)
  },
  methods: {
    getCountries,
    getStates,
    submit() {
      this.form
        .transform((data) => {
          if (this.dealer) return {
            ...data
          }
          return {
            ...data,
            password: this.password
          }
        })
        .post(this.dealer ? this.$route('dealers.update', this.dealer.uuid) : this.$route('dealers.store'), {
          forceFormData: true,
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deleteDealer() {
      this.$inertia
        .post(this.$route('dealers.destroy', this.dealer.uuid), {
          _method: 'DELETE'
        })
    },
    updatePreview(files) {
      this.form.photo.file = files[0]
      this.form.photo.url = URL.createObjectURL(files[0])
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteDealer()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
