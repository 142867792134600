<template>
  <div>
    <Head title="Dealers" />

    <Panel>
      <div class="flex justify-between">
        <PanelHeading :tabs="tabs" />
        <PanelActions
          :create="$route('dealers.create')"
          :widget="false"
          create-permission="dealers.create"
          text="dealer"
        />
      </div>
      <div class="pb-12">
        <div class="flex flex-row justify-between w-full pb-5">
          <div class="flex items-center min-w-[18rem]">
            <VueMultiselect
              v-model="dealership"
              :options="dealerships"
              placeholder="Select dealership"
              label="name"
              track-by="uuid"
              class="form-multiselect-edit"
              :show-labels="false"
              @select="value => buildQuery('dealership', value.uuid)"
            >
              <template #singleLabel="props">
                <span>{{ props.option.name }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="buildQuery('dealership')" />
              </template>
            </VueMultiselect>
          </div>
          <FormInput v-model="term" class="form-input-edit ml-auto w-64" placeholder="Search" @keyup="e => buildQuery('term', e.target.value)" />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">
                <SortingColumn name="firstName" :sort="sort" @click="sortBy('firstName')">Name</SortingColumn>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn name="address" :sort="sort" @click="sortBy('address')">Address</SortingColumn>
              </th>
              <th scope="col" class="table-header">Phone</th>
              <th scope="col" class="table-header">Email</th>
              <th scope="col" class="table-header w-16" />
            </tr>
          </thead>
          <tbody class="table-body">
            <Link v-for="dealer in dealers.data" :key="dealer.uuid" as="tr" :href="$route('dealers.edit', dealer.uuid)" class="table-row cursor-pointer">
              <td class="table-cell py-0">
                <div class="flex items-center">
                  <div class="h-8 w-8 min-h-[2em] min-w-[2em] rounded-full border-1 border-[#e7e7e7] overflow-hidden inline-flex mr-3">
                    <img class="max-w-none translate-x-[-50%] ml-[50%]" :src="dealer.photo ?? '/assets/images/test-avatar.png'" />
                  </div>
                  <span>{{ dealer.name }}</span>
                </div>
              </td>
              <td class="table-cell" @click.stop>
                <a :href="`https://maps.google.com/?q=${formatAddress(dealer.address)}`" target="_blank">
                  {{ formatAddress(dealer.address) }}
                </a>
              </td>
              <td class="table-cell" @click.stop>
                <a :href="`tel:${dealer.details?.phone_number}`">{{ dealer.details?.phone_number ?? '—' }}</a>
              </td>
              <td class="table-cell" @click.stop>
                <a :href="`mailto:${dealer.email}`">{{ dealer.email ?? '—' }}</a>
              </td>
              <td class="table-cell">
                <span title="Edit"><PencilIcon class="w-4 ml-auto" /></span>
              </td>
            </Link>

            <tr v-if="dealers.data.length === 0">
              <td class="table-cell text-center" colspan="5">No dealers found.</td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="dealers.links"
          class="flex items-center justify-center mt-5"
          :current-page="dealers.current_page"
          @change-page="key => buildQuery('page', key)"
        />
      </div>
    </Panel>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import { formatAddress } from 'Shared/helpers'
import { PencilIcon } from '@heroicons/vue/outline'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormInput from 'Shared/Form/FormInput'
import SortingColumn from 'Shared/SortingColumn'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    Head,
    Link,
    PencilIcon,
    VueMultiselect,
    FormInput,
    SortingColumn,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading
  },
  layout: Layout,
  props: {
    dealers: Object,
    dealerships: Array
  },
  data() {
    return {
      tabs: [
        { url: this.$route('dealers.index'), label: 'Dealers' },
        { url: this.$route('dealerships.index'), label: 'Dealerships' }
      ],
      sort: {
        column: '',
        order: ''
      },
      order: '',
      term: '',
      dealership: '',
      query: {}
    }
  },
  methods: {
    formatAddress,
    buildQuery(key, value = null) {
      if (key !== 'page') {
        delete this.query.page
      }
      if (value) {
        this.query = {
          ...this.query,
          [key]: value
        }
      } else {
        this[key] = ''
        delete this.query[key]
      }
      this.fetchData(this.query)
    },
    fetchData(query) {
      this.$inertia
        .get(this.$route('dealers.index'), query, {
          preserveState: true,
          preserveScroll: true,
          replace: true
        })
    },
    sortBy(column) {
      this.sort = {
        column: column,
        order: this.sort.column === column ?
          (this.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
      this.query = {
        ...this.query,
        column: this.sort.column,
        order: this.sort.order
      }
      this.fetchData(this.query)
    }
  }
}
</script>
