<template>
  <Panel data-testid="users-panel">
    <InertiaHead title="Users" />

    <div class="flex justify-between">
      <PanelHeading heading="Users" />
      <PanelActions :create="$route('users.create')" text="user" :widget="false" :permissions="$attrs.auth.can" create-permission="users.create" />
    </div>
    <div class="pb-12">
      <div class="flex flex-row justify-between w-full pb-5">
        <div class="flex items-center w-64 pr-3">
          <VueMultiselect
            v-model="form.role"
            :options="roles.map(role => role.name)"
            placeholder="Select role"
            :custom-label="option => startCase(toLower(roles.find(role => role.name === option).name))"
            class="form-multiselect-edit"
            :show-labels="false"
          >
            <template #singleLabel="props">
              <span>{{ startCase(toLower(roles.find(role => role.name === props.option).name)) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('role')" />
            </template>
          </VueMultiselect>
        </div>
        <div class="flex items-center w-64 pr-3">
          <VueMultiselect
            v-model="form.distributor"
            :options="distributors.map(dist => dist.uuid)"
            placeholder="Select distributor"
            :custom-label="option => distributors.find(dist => dist.uuid === option).name"
            class="form-multiselect-edit"
            :show-labels="false"
          >
            <template #singleLabel="props">
              <span>{{ distributors.find(dist => dist.uuid === props.option).name }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('distributor')" />
            </template>
          </VueMultiselect>
        </div>
        <div class="flex items-center w-64">
          <VueMultiselect
            v-model="form.dealership"
            :options="dealerships.map(dealership => dealership.uuid)"
            placeholder="Select dealership"
            :custom-label="option => dealerships.find(dealership => dealership.uuid === option).name"
            class="form-multiselect-edit"
            :show-labels="false"
          >
            <template #singleLabel="props">
              <span>{{ dealerships.find(dealership => dealership.uuid === props.option).name }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('dealership')" />
            </template>
          </VueMultiselect>
        </div>
        <FormInput v-model="form.search" class="form-input-edit ml-auto w-64" placeholder="Search" />
      </div>
      <table class="table">
        <thead class="table-head">
          <tr class="table-row">
            <th scope="col" class="table-header">
              <SortingColumn name="firstName" :sort="form.sort" @click="sortBy('firstName')">Name</SortingColumn>
            </th>
            <th scope="col" class="table-header">
              <SortingColumn name="address" :sort="form.sort" @click="sortBy('address')">Address</SortingColumn>
            </th>
            <th scope="col" class="table-header">Phone</th>
            <th scope="col" class="table-header">Email</th>
            <th scope="col" class="table-header w-16" />
          </tr>
        </thead>
        <tbody class="table-body">
          <InertiaLink v-for="user in users.data" :key="user.uuid" as="tr" :href="$route('users.edit', user.uuid)" class="table-row cursor-pointer">
            <td class="table-cell py-0">
              <div class="flex items-center">
                <div class="h-8 w-8 min-h-[2em] min-w-[2em] rounded-full border-1 border-[#e7e7e7] overflow-hidden inline-flex mr-3">
                  <img class="max-w-none translate-x-[-50%] ml-[50%]" :src="user.photo ?? '/assets/images/test-avatar.png'" />
                </div>
                <span>{{ user.name }}</span>
              </div>
            </td>
            <td class="table-cell" @click.stop>
              <a :href="`https://maps.google.com/?q=${formatAddress(user.address)}`" target="_blank">
                {{ user.address?.street && user.address?.zip_code && user.address?.city ?
                  formatAddress(user.address) : '—' }}
              </a>
            </td>
            <td class="table-cell" @click.stop>
              <a :href="`tel:${user.details?.phone_number}`">{{ user.details?.phone_number ?? '—' }}</a>
            </td>
            <td class="table-cell" @click.stop>
              <a :href="`mailto:${user.email}`">{{ user.email ?? '—' }}</a>
            </td>
            <td class="table-cell">
              <span title="Edit"><PencilIcon class="w-4 ml-auto" /></span>
            </td>
          </InertiaLink>

          <tr v-if="users.data.length === 0">
            <td class="table-cell text-center" colspan="5">No users found.</td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :links="users.links"
        class="flex items-center justify-center mt-5"
        :current-page="users.current_page"
        @change-page="key => form.page = key"
      />
    </div>
  </Panel>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import { formatAddress } from 'Shared/helpers'
import { PencilIcon } from '@heroicons/vue/outline'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormInput from 'Shared/Form/FormInput'
import SortingColumn from 'Shared/SortingColumn'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import { pickBy, throttle, startCase, toLower } from 'lodash'

export default {
  components: {
    InertiaHead,
    InertiaLink,
    PencilIcon,
    VueMultiselect,
    FormInput,
    SortingColumn,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading
  },
  layout: Layout,
  props: {
    filters: Object,
    roles: Array,
    distributors: Array,
    dealerships: Array,
    users: Object
  },
  data() {
    return {
      form: {
        search: this.filters.search,
        role: this.filters.role,
        distributor: this.filters.distributor,
        dealership: this.filters.dealership,
        sort: {
          column: this.filters.sort?.column,
          order: this.filters.sort?.order
        },
        page: this.filters.page
      }
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('users.index'), pickBy(this.form), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  methods: {
    formatAddress,
    startCase,
    toLower,
    sortBy(column) {
      this.form.sort = {
        column: column,
        order: this.form.sort.column === column ?
          (this.form.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
    },
    reset(filter) {
      this.form[filter] = null
    }
  }
}
</script>
