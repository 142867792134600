<template>
  <div class="flex flex-col bg-atc-white p-4 text-atc-text lg:p-6">
    <div class="mb-2 flex">
      <span class="mr-2 font-heading text-base font-normal">{{ data.name }}</span>
      <a
        v-if="chartType !== 'doughnut'"
        class="ml-auto cursor-pointer text-xs font-medium leading-6 duration-200 hover:text-atc-secondary"
        @click="$emit('expandChart')"
        >Expand</a
      >
    </div>
    <Bar
      v-if="chartType === 'bar'"
      :data="data"
      :days="days"
      :total="total"
      :chart-options="chartOptions"
      :is-expanded="isExpanded"
    />
    <Doughnut
      v-if="chartType === 'doughnut'"
      :data="data"
      :total="total"
      :chart-options="chartOptions"
      :is-expanded="isExpanded"
    />
    <Line
      v-if="chartType === 'line'"
      :data="data"
      :days="days"
      :total="total"
      :chart-options="chartOptions"
      :is-expanded="isExpanded"
    />
  </div>
</template>

<script>
import {Link} from '@inertiajs/inertia-vue3'
import Bar from './ChartTypes/Bar'
import Doughnut from './ChartTypes/Doughnut'
import Line from './ChartTypes/Line'
import {secondsToDays} from 'Shared/helpers'

export default {
  components: {
    Link,
    Bar,
    Doughnut,
    Line
  },
  props: {
    days: Array,
    data: Object,
    isExpanded: {
      type: Boolean,
      default: false
    }
  },
  emits: ['expandChart'],
  data() {
    return {
      chartType: this.data.chartType ?? 'line'
    }
  },
  computed: {
    total() {
      return this.formatData(this.data.total)
    },
    chartOptions() {
      const formatData = this.formatData

      return {
        responsive: true,
        maintainAspectRatio: !this.isExpanded,
        aspectRatio: 1,
        resizeDelay: 50,
        scales: {
          y: {
            min: 0,
            ticks: {
              precision: this.data.isTime ? 0 : 0,
              callback: (value, index, ticks) => {
                return this.formatData(value, false)
              }
            }
          }
        },
        plugins: {
          tooltip: {
            callbacks: {
              label(context) {
                const value = formatData(context.dataset.data[context.dataIndex])

                return `${context.dataset.label}: ${value}`
              }
            }
          }
        }
      }
    }
  },
  methods: {
    secondsToDays,
    formatData(data, isDecimal = true) {
      if (!data && data !== 0) return null

      data = this.data.showDecimals && isDecimal ? parseFloat(data).toFixed(2) : parseInt(data)

      if (this.data.isTime) {
        data = this.secondsToDays(data)
      }
      if (this.data.showPercentage) {
        data = (data * 100).toFixed() + '%'
      }
      if (this.data.showCurrency) {
        data = '$' + data
      }

      return data
    }
  }
}
</script>
