<template>
  <button class="table-controls-create-btn font-heading uppercase">
    <PlusIcon class="table-controls-create-icon" />
    {{ label }}
  </button>
</template>

<script>
import PlusIcon from 'Shared/Icons/PlusIcon'

export default {
  components: {
    PlusIcon
  },
  props: {
    label: String
  }
}
</script>
