<template>
  <NewsLayout :article="article" :auth="$props.auth">
    <div v-if="auth.can['news.update']">
      <PanelActions
        :create="$route('news.create')"
        :link="$route('news.index')"
        text="article"
        :widget="true"
        :confirmation="true"
        class="px-4 sm:px-6 lg:px-8"
        @onConfirm="onRedirect($event)"
      />
      <form class="divide-y-2 divide-gray-200" @submit.prevent="submit">
        <div class="mx-auto w-full px-4 sm:px-6 py-10 lg:w-[80%] xl:w-[65%]">
          <div v-if="article && article.updated_at" class="mb-4 text-right">
            <p class="text-xs">Updated: {{ getFormattedDate(article.updated_at) }}</p>
          </div>
          <FormInput
            v-model="form.title"
            label="Article Title"
            placeholder="Article title..."
            required
            type="text"
            class="form-input-edit mb-2"
            @change="saved = false"
          />
          <div class="mb-2">

            <label class="form-label-edit block my-3">Tags</label>
            <VueMultiselect
              v-model="form.tags"
              :options="tags"
              :multiple="true"
              :taggable="true"
              :hide-selected="true"
              :close-on-select="false"
              placeholder="Search or add a tag"
              label="name"
              track-by="slug"
              :class="`form-multiselect-edit ${form.errors.tags ? 'form-multiselect-error' : ''}`"
              @tag="addTag"
              @search-change="asyncFindTags"
            />
            <div
              v-if="Object.keys(form.errors).filter((k) => k.startsWith('tags.')).length !== 0"
              class="form-error"
            >
              {{
                Object.keys(form.errors)
                  .filter(k => k.startsWith('tags.'))
                  .map(k => form.errors[k].replace(/tags\.(\d+)/g, (match, number) => {
                    return 'tag ' + (parseInt(number) + 1)
                  }))
                  .join(' ')
              }}
            </div>
          </div>
          <TipTap
            ref="editor"
            :content="form.body"
            @contentChanged="saved = false"
          />
        </div>

        <div v-if="attachments.length > 0">
          <div class="mx-auto w-full lg:w-[40%]">
            <template v-for="attachment in attachments">
              <PDFViewer :attachment="attachment" />
            </template>
          </div>
        </div>

        <div class="flex w-full px-4 py-7 sm:px-6 lg:px-8">
          <button
            v-if="article"
            type="button"
            class="form-button"
            @click="showConfirmPopup('delete')"
          >
            Delete
          </button>
          <button
            type="button"
            class="form-button ml-auto"
            @click="showConfirmPopupRedirect($route('news.index'))"
          >
            Cancel
          </button>
          <LoadingButton type="submit" class="form-submit-button ml-3">Save</LoadingButton>
        </div>
      </form>

      <ConfirmModal
        :open="isConfirmPopupOpen"
        :text="popupText"
        button-label="Confirm"
        @on-close="closeConfirmPopup"
        @on-confirm="confirmAction"
      />
    </div>

    <div v-else>
      <PanelActions
        :link="$route('news.index')"
        text="article"
        :widget="true"
        :confirmation="true"
        class="px-4 sm:px-6 lg:px-8"
        @onConfirm="onRedirect($event)"
      />

      <div class="mx-auto w-full py-10 lg:w-[80%] xl:w-[65%]">
        <div>
          <div v-if="article && article.updated_at" class="mb-4 text-right">
            <p class="text-xs">Updated: {{ getFormattedDate(article.updated_at) }}</p>
          </div>
          <div>
            <span class="text-3xl">{{ form.title }} </span>
          </div>
        </div>
        <div class="ProseMirror" v-html="article ? form.body : ''" />
      </div>

      <div class="mx-auto w-full lg:w-[40%]">
        <template v-for="attachment in attachments">
          <PDFViewer :attachment="attachment" />
        </template>
      </div>
    </div>
    <CustomMessages />
  </NewsLayout>
</template>

<script>
import {Link, useForm} from '@inertiajs/inertia-vue3'
import {slugifyString} from 'Shared/helpers'
import axios from 'axios'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import FormInput from 'Shared/Form/FormInput'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import NewsLayout from 'NewsComponents/NewsLayout'
import PDFViewer from 'Shared/PDFViewer'
import Pagination from 'Shared/Pagination'
import PanelActions from 'Shared/PanelActions'
import TipTap from 'NewsComponents/TipTap'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import moment from 'moment'

export default {
  components: {
    ConfirmModal,
    FormInput,
    Icon,
    Layout,
    Link,
    LoadingButton,
    NewsLayout,
    PDFViewer,
    Pagination,
    PanelActions,
    TipTap,
    VueMultiselect
  },
  layout: Layout,
  props: {
    article: Object,
    auth: Object
  },
  setup(props) {
    const form = useForm({
      _method: props.article ? 'PUT' : 'POST',
      title: props.article?.title ?? '',
      body: props.article?.body ?? '',
      tags: props.article?.tags ?? []
    })

    return { form }
  },
  data() {
    return {
      attachments: [],
      tags: [],
      isConfirmPopupOpen: false,
      popupAction: '',
      redirectLink: '',
      popupText: '',
      saved: true
    }
  },
  mounted() {
    this.loadAttachments()
    this.asyncFindTags()
  },
  methods: {
    onRedirect(e) {
      this.redirectLink = e.target.pathname + e.target.search
      if (!this.saved) {
        this.showConfirmPopup('redirect')
      } else {
        this.popupAction = 'redirect'
        this.confirmAction()
      }
    },
    submit() {
      this.form.body = this.$refs.editor.getContent()
      this.form
        .transform(data => {
          data.tags = data.tags.map(({name}) => name)

          return data
        })
        .post(this.article ? this.$route('news.update', this.article.uuid) : this.$route('news.store'), {
          forceFormData: true,
          preserveState: (page) => Object.keys(page.props.errors).length
        })

      this.saved = true
    },
    deleteArticle() {
      this.$inertia.post(this.$route('news.destroy', this.article.uuid), {
        _method: 'DELETE'
      })
    },
    getFormattedDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'delete') {
        this.deleteArticle()
      }
      if (this.popupAction === 'redirect') {
        this.$inertia.visit(this.redirectLink)
      }
    },
    showConfirmPopup(action) {
      this.popupAction = action
      if (this.popupAction === 'delete') {
        this.popupText =
          'Are you sure you want to delete this article? This action cannot be undone.'
      }
      if (this.popupAction === 'redirect') {
        this.popupText = 'Are you sure you want to leave this page without saving?'
      }
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    showConfirmPopupRedirect(link) {
      this.redirectLink = link
      this.popupAction = 'redirect'
      if (!this.saved) {
        if (this.popupAction === 'redirect') {
          this.popupText = 'Are you sure you want to leave this page without saving?'
        }
        document.querySelector('html').classList.add('popup-open')
        this.isConfirmPopupOpen = true
      } else {
        this.popupAction = 'redirect'
        this.confirmAction()
      }
    },
    loadAttachments() {
      if (!this.article?.attachments) return

      this.article.attachments.forEach(attachment => {
        axios
          .post(
            this.$route('media.download', attachment.uuid),
            {},
            {
              responseType: 'blob'
            }
          )
          .then(({data}) => {
            const objectUrl = URL.createObjectURL(new Blob([data]))
            this.attachments.push({
              name: attachment.name,
              url: objectUrl,
              page: 1,
              uuid: attachment.uuid
            })
          })
          .catch(error => {
            console.error(error.response)
          })
      })
    },
    addTag(newTag) {
      this.form.tags.push({
        name: newTag,
        slug: slugifyString(newTag)
      })
    },
    asyncFindTags(query = null) {
      axios
        .post(this.$route('tags.list'), {
          term: query,
          type: 'news'
        })
        .then(({data}) => {
          this.tags = data
        })
        .catch(error => {
          console.error(error)
        })
    }
  }
}
</script>
