<template>
  <div>
    <Head title="Parts" />
    <Panel>
      <div class="flex flex-wrap justify-between">
        <PanelHeading :tabs="tabs" :current="1" />
        <div class="flex w-full flex-row sm:w-auto">
          <PanelActions
            :create="$route('parts-orders.create')"
            :create-permission="'parts-orders.create'"
            :link="$route('parts-orders.index')"
            :override-create-permission="roleCheck()"
            :widget="false"
          />
          <div v-if="auth.can['clients.export']" class="flex items-center py-6">
            <ActionButton label="Export client data" @click="onExport" />
          </div>
        </div>
      </div>
      <div class="pb-12">
        <div class="flex items-center pb-5">
          <div class="flex w-full flex-row flex-wrap items-center justify-between xl:flex-nowrap">
            <div v-if="editMode === false" class="filters items-center justify-between">
              <a
                class="w-full cursor-pointer pb-5 text-[0.92rem] font-medium duration-200 hover:text-atc-secondary xl:w-auto xl:pb-0"
                @click="enterEditMode"
              >
                Bulk Edit
              </a>

              <div class="form-input-edit sm:col-span-3">
                <VueMultiselect
                  v-model="dealership"
                  :options="dealerships"
                  placeholder="Select dealership"
                  label="name"
                  track-by="uuid"
                  class="form-multiselect-edit"
                  :show-labels="false"
                  @select="value => buildQuery('dealership', value.uuid)"
                >
                  <template #singleLabel="props">
                    <span>{{ props.option.name }}</span>
                    <i class="multiselect__tag-icon" @mousedown.stop="buildQuery('dealership')" />
                  </template>
                </VueMultiselect>
              </div>
              <div class="form-input-edit filters-datepicker sm:col-span-3">
                <Datepicker
                  v-model="date"
                  model-type="dd.mm.yyyy"
                  placeholder="Select date"
                  input-class-name=""
                  range
                  :auto-apply="true"
                  :clearable="true"
                  :enable-time-picker="false"
                  @update:model-value="value => buildQuery('date', value)"
                />
              </div>
              <div class="form-input-edit sm:col-span-6">
                <VueMultiselect
                  v-model="status"
                  :options="statuses"
                  :multiple="false"
                  placeholder="Select status"
                  label="label"
                  track-by="value"
                  :close-on-select="true"
                  class="form-multiselect-edit"
                  :show-labels="false"
                  @select="option => buildQuery('status', option.value)"
                >
                  <template #singleLabel="props">
                    <span>{{ props.option.label }}</span>
                    <i class="multiselect__tag-icon" @mousedown.stop="buildQuery('status')" />
                  </template>
                </VueMultiselect>
              </div>
            </div>
            <div
              v-else
              class="filters-bulk flex w-full flex-wrap items-center justify-between xl:w-auto xl:flex-nowrap"
            >
              <div class="xl:ml-0 form-input-edit sm:col-span-6">
                <VueMultiselect
                  v-model="statusBulk"
                  :options="statuses"
                  :multiple="false"
                  placeholder="Change status"
                  label="label"
                  track-by="name"
                  :close-on-select="true"
                  class="form-multiselect-edit"
                  :show-labels="false"
                  @select="value => selectStatusBulk(value)"
                  @remove="clearStatusSelectionBulk"
                />
              </div>
              <a
                class="mx-0 cursor-pointer pt-5 text-[0.92rem] font-medium duration-200 hover:text-atc-secondary lg:mx-3 lg:pt-0"
                @click="editModeDelete"
              >
                Delete Selected
              </a>
              <a
                class="mx-0 cursor-pointer pt-5 text-[0.92rem] font-medium duration-200 hover:text-atc-secondary lg:mx-3 lg:pt-0"
                @click="enterEditMode"
              >
                Cancel
              </a>
            </div>

            <div class="flex w-full pt-5 xl:w-64 xl:pt-0">
              <FormInput
                v-model="term"
                class="form-input-edit ml-auto w-full"
                placeholder="Search"
                @keyup="e => buildQuery('term', e.target.value)"
              />
            </div>
          </div>
        </div>
        <div class="overflow-x-auto">
          <table class="table-record-list table">
            <thead class="table-head">
              <tr class="table-row">
                <th scope="col" class="table-header">
                  <SortingColumn
                    name="number"
                    :sort="sort"
                    @click="sortBy('number')"
                  >
                    Number
                  </SortingColumn>
                </th>
                <th scope="col" class="table-header">
                  <SortingColumn
                    name="dealership_id"
                    :sort="sort"
                    @click="sortBy('dealership_id')"
                  >
                    Dealership
                  </SortingColumn>
                </th>
                <th scope="col" class="table-header">
                  <SortingColumn
                    name="distributor_id"
                    :sort="sort"
                    @click="sortBy('distributor_id')"
                  >
                    Distributor
                  </SortingColumn>
                </th>
                <th scope="col" class="table-header">
                  <SortingColumn
                    name="client->lastName"
                    :sort="sort"
                    @click="sortBy('client->lastName')"
                  >
                    Customer Name
                  </SortingColumn>
                </th>
                <th scope="col" class="table-header">
                  <SortingColumn name="created_at" :sort="sort" @click="sortBy('created_at')">
                    Date
                  </SortingColumn>
                </th>
                <th scope="col" class="table-header table-header-status">
                  <SortingColumn
                    name="status->label"
                    :sort="sort"
                    @click="sortBy('status')"
                  >
                    Status
                  </SortingColumn>
                </th>
              </tr>
            </thead>
            <tbody v-if="!editMode" class="table-body">
              <Link
                v-for="record in orders.data"
                :key="record.uuid"
                as="tr"
                :href="$route('parts-orders.edit', record.uuid)"
                class="table-row cursor-pointer"
              >
                <td class="table-cell">{{ record.number ?? '—' }}</td>
                <td class="table-cell">{{ record.dealership?.name ?? '—' }}</td>
                <td class="table-cell">{{ record.distributor?.name ?? '—' }}</td>
                <td class="table-cell">
                  {{ record.client.firstName + ' ' + record.client.lastName }}
                </td>
                <td class="table-cell">{{ formatDate(record.created_at) }}</td>
                <td class="table-cell">
                  <StatusBadge :label="statuses.find(status => status.value === record.status).label" :value="record.status" />
                </td>
              </Link>
              <tr v-if="orders.data.length === 0">
                <td class="table-cell text-center" colspan="6">No orders found.</td>
              </tr>
            </tbody>
            <tbody v-else class="table-body">
              <tr v-for="record in orders.data" :key="record.uuid" class="table-row cursor-pointer">
                <td class="table-cell">
                  <div class="flex items-center">
                    <FormCheckbox
                      v-if="editMode === true"
                      class="bulk-checkbox mr-2"
                      wrapper-class="mb-0"
                      @change="value => checkboxHandler(record.uuid)"
                    />{{ record.stock }}
                  </div>
                </td>
                <td class="table-cell">{{ record.number ?? '—' }}</td>
                <td class="table-cell">{{ record.dealership?.name ?? '—' }}</td>
                <td class="table-cell">{{ record.distributor?.name ?? '—' }}</td>
                <td class="table-cell">
                  {{ record.client.firstName + ' ' + record.client.lastName }}
                </td>
                <td class="table-cell">{{ formatDate(record.created_at) }}</td>
                <td class="table-cell">
                  <StatusBadge :label="statuses.find(status => status.value === record.status).label" :value="record.status" />
                </td>
              </tr>
              <tr v-if="orders.data.length === 0">
                <td class="table-cell text-center" colspan="6">No orders found.</td>
              </tr>
            </tbody>
          </table>
        </div>
        <Pagination
          :links="orders.links"
          class="mt-5 flex items-center justify-center"
          :current-page="orders.current_page"
          @change-page="key => buildQuery('page', key)"
        />
      </div>
    </Panel>
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import * as XLSX from 'xlsx/xlsx.mjs'
import {Link} from '@inertiajs/inertia-vue3'
import axios from 'axios'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import ActionButton from 'Shared/Buttons/ActionButton'
import FormInput from 'Shared/Form/FormInput'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import StatusBadge from 'Shared/Misc/StatusBadge'
import SortingColumn from 'Shared/SortingColumn'
import {formatDate} from 'Shared/helpers'

export default {
  components: {
    ActionButton,
    Datepicker,
    Head,
    FormInput,
    FormCheckbox,
    VueMultiselect,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading,
    StatusBadge,
    SortingColumn,
    Link
  },
  layout: Layout,
  props: {
    auth: Object,
    dealerships: Array,
    orders: Object,
    statuses: Array
  },
  data() {
    return {
      tabs: [
        {url: '/orders', label: 'Models'},
        {url: '/parts-orders', label: 'Parts'}
      ],
      sort: {
        column: '',
        order: ''
      },
      term: '',
      query: {},
      dealership: '',
      dealershipsFilterData: this.dealerships,
      date: '',
      status: '',
      statusBulk: '',
      editMode: false,
      bulkRecords: []
    }
  },
  methods: {
    formatDate,
    roleCheck() {
      return this.auth.role.name === 'dist_manager' || this.auth.role.name === 'dist_employee'
    },
    prepareDealerships() {
      let records = []
      let record = ''
      this.dealershipsFilterData = []
      this.bulkRecords.forEach(element => {
        if (this.records?.data) {
          record = Object.values(this.records?.data).filter(obj => {
            return obj.uuid === element
          })[0]
          if (records.indexOf(record.distributor.uuid) === -1) {
            records.push(record.distributor.uuid)
          }
        }
      })
      if (records.length === 1) {
        this.dealerships.forEach(element => {
          if (element.distributor.uuid === records[0]) {
            if (this.dealershipsFilterData.indexOf(element) === -1) {
              this.dealershipsFilterData.push(element)
            }
          }
        })
      } else {
        this.dealershipsFilterData = []
      }
    },
    checkboxHandler(uuid) {
      if (this.bulkRecords.indexOf(uuid) >= 0) {
        this.bulkRecords.splice(this.bulkRecords.indexOf(uuid), 1)
      } else {
        this.bulkRecords.push(uuid)
      }
      this.prepareDealerships()
    },
    editModeDelete() {
      this.query = {
        ...this.query,
        action: 'delete',
        records: this.bulkRecords
      }
      this.fetchData(this.query)
      this.enterEditMode()
    },
    selectStatusBulk(value) {
      this.statusBulk = value
      this.query = {
        ...this.query,
        action: 'status',
        value: this.statusBulk?.value,
        records: this.bulkRecords
      }
      this.fetchData(this.query)
      this.enterEditMode()
    },
    clearStatusSelectionBulk() {
      this.statusBulk = ''
      this.selectStatusBulk(this.statusBulk)
    },
    enterEditMode() {
      this.bulkSelectAll = false
      this.statusBulk = ''
      this.bulkRecords = []
      this.editMode = !this.editMode
    },
    buildQuery(key, value = null) {
      if (key !== 'page') {
        delete this.query.page
      }
      if (value) {
        this.query = {
          ...this.query,
          [key]: value
        }
      } else {
        this[key] = ''
        delete this.query[key]
      }
      this.fetchData(this.query)
    },
    sortBy(column) {
      this.sort = {
        column: column,
        order: this.sort.column === column ? (this.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
      this.query = {
        ...this.query,
        ...this.sort
      }
      this.fetchData(this.query)
    },
    fetchData(query) {
      const options = {
        preserveState: true,
        preserveScroll: true,
        replace: true
      }
      if (query.action) {
        this.$inertia.post(this.$route('parts-orders.bulk-edit'), query, options)
      }
      if (!query.action) {
        this.$inertia.get(this.$route('parts-orders.index'), query, options)
      }
    },
    onExport() {
      axios
        .post(this.$route('parts-orders.clients.export'), this.query)
        .then(({data}) => {
          this.exportToExcel(data)
        })
        .catch(response => {
          console.error(response)
        })
    },
    exportToExcel(data) {
      const filename = 'ATC Client Data'

      const rows = data.map(row => ({
        firstName: row.firstName,
        lastName: row.lastName,
        email: row.email,
        phone: row.phone,
        address: row.address,
        city: row.city,
        country: row.country,
        state: row.state,
        zipCode: row.zipCode,
        prefmoc: row.prefmoc,
        marketing: row.marketing,
        clientNotes: row.clientNotes
      }))

      const worksheet = XLSX.utils.json_to_sheet(rows)
      const workbook = XLSX.utils.book_new()
      XLSX.utils.book_append_sheet(workbook, worksheet, filename)

      XLSX.utils.sheet_add_aoa(
        worksheet,
        [
          [
            'First Name',
            'Last Name',
            'Email',
            'Phone',
            'Address',
            'City',
            'Country',
            'State',
            'Zip code',
            'Preferred method of contact',
            'Marketing',
            'Note to dealer'
          ]
        ],
        {origin: 'A1'}
      )

      worksheet['!cols'] = this.calculateColumnWidths(rows)

      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    calculateColumnWidths(rows) {
      const objectMaxLength = []
      const wscols = []

      rows.forEach(row => {
        Object.entries(row).forEach(([key, value], index) => {
          if (typeof value == 'number') {
            objectMaxLength[index] = 10
          } else {
            objectMaxLength[index] =
              objectMaxLength[index] >= value?.length ? objectMaxLength[index] : value?.length
          }
        })
      })

      Object.entries(rows[0]).forEach(([key, value], index) => {
        wscols.push({width: objectMaxLength[index]})
      })

      return wscols
    }
  }
}
</script>
