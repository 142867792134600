<template>
  <div class="flex items-center justify-center">
    <div
      :class="`spinner-border inline-block animate-spin w-${width ? width : 8} h-${
        width ? width : 8
      } rounded-full border-2 border-${color ? color : 'black'} border-r-transparent`"
      role="status"
    >
      <span class="visually-hidden">Loading...</span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Spinner',
  props: {
    width: Number,
    color: String
  }
}
</script>
