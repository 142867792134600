<template>
  <div :class="$attrs.class" :data-testid="dataTestid">
    <FormLabel :id="id" :text="label" />
    <textarea
      :id="id"
      :aria-invalid="error ? true : null"
      :class="{ error: error }"
      :value="modelValue"
      class="border-atc-gray w-full px-3 py-3 font-normal text-atc-text focus:ring-atc-secondary focus:border-atc-secondaryplaceholder:text-gray-400 bg-atc-white"
      v-bind="$attrs"
      @input="$emit('update:modelValue', $event.target.value)"
    />
    <div v-if="error" class="text-red-600 text-xs font-light mt-2">{{ error }}</div>
  </div>
</template>

<script>
import FormLabel from 'Shared/Form/FormLabel'
import {v4 as uuid} from 'uuid'

export default {
  components: {FormLabel},
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default () {
        return `form-input-${uuid()}`
      }
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    dataTestid: {
      type: String,
      default: (props) => `${props.id}-input`
    }
  },
  emits: ['update:modelValue']
}
</script>
