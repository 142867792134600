<template>
  <div class="flex items-center py-8" :class="className">
    <h2 v-if="heading" class="table-title">{{ heading }}</h2>
    <div v-if="tabs" class="flex gap-3 font-heading uppercase">
      <template
        v-for="(tab, index) in tabs"
        :key="`tab-link-${index}`"
      >
        <InertiaLink
          v-if="!tab.disabled"
          class="cursor-pointer flex justify-center hover:text-atc-secondary duration-200"
          :class="{ 'text-atc-success': index === current }"
          :href="tab.url"
        >
          {{ tab.label }}
        </InertiaLink>
      </template>
    </div>
    <Icon name="cheveron-down" class-name="table-title-icon" />
  </div>
</template>

<script>
import Icon from 'Shared/Icon'
import {Link as InertiaLink} from '@inertiajs/inertia-vue3'

export default {
  components: {
    InertiaLink,
    Icon
  },
  props: {
    className: String,
    heading: {
      type: String,
      default: ''
    },
    tabs: Array,
    current: {
      type: Number,
      default: 0
    }
  }
}
</script>
