<template>
    <div class="py-6 px-8 w-[400px] max-w-full bg-black z-30 fixed b-20 bottom-8 left-[50%] translate-x-[-50%]">
        <p class="text-white">{{message}}</p>
    </div>
</template>
<script>
export default {
    name: 'Alert',
    props: {
        message: String
    },
    data() {
        return {
            visible: true
        }
    }

}
</script>
