<template>
  <div class="flex h-full flex-col place-content-between" :class="{'h-full': isExpanded}">
    <div v-if="data.total" class="mb-4 font-heading text-[1.65rem] font-normal">
      {{ total
      }}<span v-if="data?.average" class="text-base"> (avg. {{ data?.average.toFixed(1) }})</span>
    </div>
    <Line
      :chart-options="chartOptions"
      :chart-data="chartData"
      :css-classes="isExpanded ? 'h-[90%]' : null"
    />
  </div>
</template>

<script>
import {Line} from 'vue-chartjs'
import {
  Chart as ChartJS,
  Title,
  Tooltip,
  Legend,
  LineElement,
  LinearScale,
  PointElement,
  CategoryScale
} from 'chart.js'

ChartJS.register(Title, Tooltip, Legend, LineElement, LinearScale, PointElement, CategoryScale)

export default {
  components: {
    Line
  },
  props: {
    days: Array,
    data: Object,
    total: String | Number,
    chartOptions: Object,
    isExpanded: Boolean
  },
  computed: {
    chartData() {
      return {
        labels: this.days,
        datasets: this.data.datasets
      }
    }
  }
}
</script>
