<template>
  <ConfiguratorLayout ref="layout" class="bg-atc-white" :dealership-uuid="dealershipUuid">
    <div class="container max-w-[1296px] mx-auto px-5 pt-10 pb-20">
      <h1 class="mb-8 pb-2.5 text-4xl font-heading border-dashed border-b-1 border-[#e7e7e7] uppercase">Select a Trackchair Model to Get Started</h1>
      <div class="grid grid-cols-1 sm:grid-cols:2 gap-7 md:grid-cols-4">
        <div v-for="product in products" :key="product.sku" class="relative">
          <Link :href="dealershipUuid ? `/${product.sku}/dealer/${dealershipUuid}` : `/${product.sku}`">
            <div class="bg-atc-white relative border-1 border-[#e6e6e6] group flex justify-center md:min-h-[200px] md:h-[200px] lg:min-h-[300px] lg:h-[300px]">
              <div class="absolute w-full h-full border-2 border-transparent group-hover:border-atc-secondary left-0 top-0" />
              <img :src="product.media[0]" alt="" class="w-full h-full object-cover" />
              <div v-if="product.buildBadge.number" class="absolute left-0 bottom-0 px-5" :style="`background-color:${product.buildBadge.color}`">
                <h3 class="text-atc-white font-heading tracking-[.75px] leading-9 uppercase text-[13px]">Starting at ${{ formatPrice(product.price) }}</h3>
              </div>
               <div v-else class="bg-atc-secondary absolute left-0 bottom-0 px-5">
                <h3 class="text-atc-white font-heading tracking-[.75px] leading-9 uppercase text-[13px]">Starting at ${{ formatPrice(product.price) }}</h3>
              </div>
            </div>
            <h2 class="pt-4 text-lg font-heading">{{ product.name }}</h2>
            <h4 v-if="product.buildBadge.number" class="uppercase font-heading tracking-[.75px] text-atc-secondary text-xs mb-2">
              <span>{{ product.buildBadge.number }} </span>
            </h4>
            <h4 v-else class="uppercase font-heading tracking-[.75px] text-atc-secondary text-xs mb-2">
              <span v-for="(variant, $i) of product.variants" :key="$i">{{ formatVariant(variant) }} <span v-if="$i != product.variants.length - 1">/ </span></span>
            </h4>
          </Link>
          <a class="cursor-pointer underline uppercase font-medium text-xs" @click="openPopup(product)">View details</a>
        </div>
      </div>
    </div>
  </ConfiguratorLayout>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue3'
import ConfiguratorLayout from 'ConfiguratorComponents/ConfiguratorLayout'
import {
  formatPrice
} from 'Shared/helpers.js'

export default {
  components: {
    Link,
    ConfiguratorLayout
  },
  props: {
    products: Array,
    dealershipUuid: String
  },
  methods: {
    formatPrice,
    formatVariant(variant){
      if(variant.indexOf(':') != '-1'){
        variant = variant.substring(0, variant.indexOf(':'))
      }
      return variant
    },
    openPopup(product) {
      this.$refs.layout.openPopup(product)
    }
  }
}
</script>
