<template>
<TransitionRoot as="template" :show="open">
    <Dialog as="div" class="cm-dialog">
        <div class="cm-container">
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
                <DialogOverlay class="cm-dialog-overlay" />
            </TransitionChild>
            <!-- This element is to trick the browser into centering the modal contents. -->
            <span class="cm-center-modal" aria-hidden="true">&#8203;</span>
            <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 sm:scale-95" enter-to="opacity-100 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 sm:scale-100" leave-to="opacity-0  sm:scale-95">
                <div class="cm-content">
                    <div class="cm-flex justify-center">
                        <div class="cm-title-wrapper w-full">
                            <DialogTitle as="h3" class="cm-title text-center mb-6">Add video</DialogTitle>
                            <div class="mt-2">
                                <FormInput placeholder="Paste your YouTube URL..." required v-model="url" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="cm-buttons-wrapper pl-0 pr-0 mx-0 mt-5 flex justify-center">
                        <button type="button" :disabled="!url" class="form-red-button" @click="insertVideo">Add video</button>
                        <button ref="cancelButtonRef" type="button" class="form-button ml-3" @click="$emit('on-close')">Cancel</button>
                    </div>
                    <div v-if="loading" class="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[-50%] w-full h-full flex bg-gray-100 justify-center items-center opacity-60">
                        <Spinner />
                    </div>
                </div>
            </TransitionChild>
        </div>
    </Dialog>
</TransitionRoot>
</template>

<script>
import {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot
} from '@headlessui/vue'

import FormInput from 'Shared/Form/FormInput'

export default {
    components: {
        Dialog,
        DialogOverlay,
        DialogTitle,
        TransitionChild,
        TransitionRoot,
        FormInput
    },
    props: {
        open: Boolean,
    },
    data() {
        return {
            url: "",
        }
    },

    methods: {
        insertVideo() {
            this.$emit("onConfirm", this.url);
            this.show = false;
        }
    }

}
</script>
