<template>
  <form class="lead-wrapper flex" @submit.prevent="submit">
    <SidePanel
      :entity="entity"
      :form="form"
      :record="record"
      :errors="$props.errors"
      :distributors="distributors"
      :dist-members="distMembers"
      :dealerships="dealerships"
      :dealers="dealers"
      :saved="saved"
      :disabled-multiselects="disabledMultiselects"
      :disabled-multiselects-dist="disabledMultiselectsDist"
      @on-print-price="printPriceTable"
      @on-print-parts="printPartsList"
      @on-delete="showConfirmPopup('delete')"
      @on-deselect="(e, dropdown) => clearSelection(e, dropdown)"
      @update:saved="saved = false"
    />

    <Panel class-name="w-full xl:w-4/5 ml-4 mt-8 md:mt-12 md:ml-12 sm:px-0 lg:px-0 main-panel h-fit">
      <flash-messages />

      <div v-if="record" class="border-b-2 border-gray-200 px-4 sm:px-6 lg:px-8">
        <Tabs :tabs="tabs" />
      </div>

      <div class="panel-sub-section block xl:hidden">
        <div class="flex justify-between pt-4">
          <div class="group flex cursor-pointer select-none items-center">
            <Icon :name="`pointer`" class="side-panel-icon rotate-90" />
            <InertiaLink :href="$route(singleRoutes.index)" class="side-panel-link">{{ entity }}s</InertiaLink>
          </div>

          <dropdown class="mt-1" placement="bottom-end">
            <template #default>
              <div class="group flex cursor-pointer select-none items-center">
                <span class="side-panel-link">Actions </span>
                <Icon class="side-panel-icon" name="pointer" />
              </div>
            </template>
            <template #dropdown>
              <div class="mt-2 rounded bg-white py-2 text-sm text-atc-text shadow-xl">
                <a
                  v-if="record"
                  class="block cursor-pointer px-6 py-2 hover:bg-atc-success hover:text-white"
                  @click="printPriceTable"
                >
                  Print price table
                </a>
                <a
                  v-if="record"
                  class="block cursor-pointer px-6 py-2 hover:bg-atc-success hover:text-white"
                  @click="printPartsList"
                >
                  Print parts list
                </a>
                <InertiaLink
                  v-if="
                    record &&
                      ($page.props.auth.can['leads.delete'] ||
                        $page.props.auth.can['leads.delete.any'])
                  "
                  method="button"
                  as="button"
                  class="block w-full px-6 py-2 text-left hover:bg-atc-success hover:text-white"
                  @click="deleteRecord"
                >
                  Delete
                </InertiaLink>
              </div>
            </template>
          </dropdown>
        </div>
        <div class="side-panel-details">
          <h2 class="side-panel-heading">{{ heading() }}</h2>
          <a class="side-panel-contact" :href="`mailto:` + form.client.email">
            {{ form.client.email }}
          </a>
          <a class="side-panel-contact" :href="`tel:` + form.client.phone">
            {{ form.client.phone }}
          </a>
        </div>
      </div>
      <div class="panel-sub-section block xl:hidden">
        <PanelHeading heading="Distributor" />
        <div class="form-input-edit">
          <FormLabel id="distributor" text="Organization" />
          <VueMultiselect
            id="distributor"
            v-model="form.distributor"
            :options="distributors"
            placeholder="Start typing to search"
            label="name"
            track-by="uuid"
            :class="`form-multiselect-edit distributor-select ${
              $props.errors['distributor'] ? 'form-multiselect-error' : ''
            }`"
            :disabled="disabledMultiselectsDist"
            :show-labels="false"
            @change="saved = false"
          >
            <template #singleLabel="props">
              <span>{{ props.option.name }}</span>
              <i
                class="multiselect__tag-icon"
                @mousedown.stop="e => clearSelection(e, 'distributor')"
              />
            </template>
          </VueMultiselect>
          <div v-if="$props.errors['distributor']" class="form-error">
            {{ $props.errors['distributor'] }}
          </div>
        </div>
        <div class="form-input-edit">
          <FormLabel id="distMember" text="Employee" />
          <VueMultiselect
            id="distMember"
            v-model="form.distMember"
            :options="distMembers"
            placeholder="Start typing to search"
            label="name"
            track-by="uuid"
            :class="`form-multiselect-edit distributor-select ${
              $props.errors['distMember'] ? 'form-multiselect-error' : ''
            }`"
            :disabled="disabledMultiselectsDist"
            :show-labels="false"
            @change="saved = false"
          >
            <template #singleLabel="props">
              <span>{{ props.option.name }}</span>
              <i
                class="multiselect__tag-icon"
                @mousedown.stop="e => clearSelection(e, 'distMember')"
              />
            </template>
          </VueMultiselect>
          <div v-if="$props.errors['distMember']" class="form-error">
            {{ $props.errors['distMember'] }}
          </div>
        </div>
      </div>
      <div class="panel-sub-section block xl:hidden">
        <PanelHeading heading="Assigned Dealer" />
        <div class="form-input-edit">
          <FormLabel id="dealership" text="Organization" />
          <VueMultiselect
            id="dealership"
            v-model="form.dealership"
            :options="dealerships"
            placeholder="Start typing to search"
            label="name"
            track-by="uuid"
            :class="`form-multiselect-edit ${
              $props.errors['dealership'] ? 'form-multiselect-error' : ''
            }`"
            :disabled="disabledMultiselectsDist"
            :show-labels="false"
            @change="saved = false"
          >
            <template #singleLabel="props">
              <span>{{ props.option.name }}</span>
              <i
                class="multiselect__tag-icon"
                @mousedown.stop="e => clearSelection(e, 'dealership')"
              />
            </template>
          </VueMultiselect>
          <div v-if="$props.errors['dealership']" class="form-error">
            {{ $props.errors['dealership'] }}
          </div>
        </div>
        <div class="form-input-edit">
          <FormLabel id="dealer" text="Employee" />
          <VueMultiselect
            id="dealer"
            v-model="form.dealer"
            :options="dealers"
            placeholder="Start typing to search"
            label="name"
            track-by="uuid"
            :class="`form-multiselect-edit ${
              $props.errors['dealer'] ? 'form-multiselect-error' : ''
            }`"
            :disabled="disabledMultiselects"
            :show-labels="false"
            @change="saved = false"
          >
            <template #singleLabel="props">
              <span>{{ props.option.name }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'dealer')" />
            </template>
          </VueMultiselect>
          <div v-if="$props.errors['dealer']" class="form-error">
            {{ $props.errors['dealer'] }}
          </div>
        </div>
      </div>
      <div class="panel-sub-section block xl:hidden">
        <PanelHeading heading="Contact Information" />
        <FormInput
          id="first-name"
          v-model="form.client.firstName"
          :error="formatError($props.errors['client.firstName'])"
          class="form-input-edit"
          placeholder="First Name"
          @change="saved = false"
        />
        <FormInput
          id="last-name"
          v-model="form.client.lastName"
          :error="formatError($props.errors['client.lastName'])"
          class="form-input-edit"
          placeholder="Last Name"
          @change="saved = false"
        />
        <FormInput
          id="email"
          v-model="form.client.email"
          :error="formatError($props.errors['client.email'])"
          class="form-input-edit"
          placeholder="Email"
          @change="saved = false"
        />
        <FormInput
          id="phone"
          v-model="form.client.phone"
          :error="formatError($props.errors['client.phone'])"
          class="form-input-edit"
          placeholder="Phone"
          @change="saved = false"
        />
        <FormInput
          id="address"
          v-model="form.client.address"
          :error="formatError($props.errors['client.address'])"
          class="form-input-edit"
          placeholder="Address"
          @change="saved = false"
        />
        <FormInput
          id="city"
          v-model="form.client.city"
          :error="formatError($props.errors['client.city'])"
          class="form-input-edit"
          placeholder="City"
          @change="saved = false"
        />
        <div v-if="countries">
          <VueMultiselect
            v-model="form.client.country"
            :options="countries.map(country => country.iso2)"
            :custom-label="option => countries.find(country => country.iso2 === option).name"
            placeholder="Country"
            :class="`form-multiselect-edit ${
              $props.errors['client.country'] ? 'form-multiselect-error' : ''
            }`"
            :show-labels="false"
            @change="saved = false"
          >
            <template #singleLabel="props">
              <span>{{ countries.find(country => country.iso2 === props.option)?.name }}</span>
              <i
                class="multiselect__tag-icon"
                @mousedown.stop="e => clearSelection(e, 'client.country')"
              />
            </template>
          </VueMultiselect>
          <div v-if="$props.errors['client.country']" class="form-error">
            {{ $props.errors['client.country'] }}
          </div>
        </div>
        <FormInput
          v-else
          id="country"
          v-model="form.client.country"
          :error="form.errors['client.country'] ?? ''"
          class="form-input-edit"
          placeholder="Country"
          @change="saved = false"
        />
        <div v-if="states && form.client.country === 'US'">
          <VueMultiselect
            v-model="form.client.state"
            :options="states.map(state => state.iso2)"
            :custom-label="option => states.find(state => state.iso2 === option).name"
            placeholder="State"
            :class="`form-multiselect-edit ${
              $props.errors['client.state'] ? 'form-multiselect-error' : ''
            }`"
            :show-labels="false"
            @change="saved = false"
          >
            <template #singleLabel="props">
              <span>{{ states.find(state => state.iso2 === props.option)?.name }}</span>
              <i
                class="multiselect__tag-icon"
                @mousedown.stop="e => clearSelection(e, 'client.state')"
              />
            </template>
          </VueMultiselect>
          <div v-if="$props.errors['client.state']" class="form-error">
            {{ $props.errors['client.state'] }}
          </div>
        </div>
        <FormInput
          v-else
          id="state"
          v-model="form.client.state"
          :error="formatError($props.errors['client.state'])"
          class="form-input-edit"
          placeholder="State"
          @change="saved = false"
        />
        <FormInput
          id="zip-code"
          v-model="form.client.zipCode"
          :error="formatError($props.errors['client.zipCode'])"
          class="form-input-edit"
          placeholder="Zip Code"
          @change="saved = false"
        />
        <FormTextArea
          id="client-notes"
          v-model="form.client.clientNotes"
          :error="formatError($props.errors['client.clientNotes'])"
          class="form-textarea-edit"
          rows="10"
          label="Notes"
          @change="saved = false"
        />
      </div>

      <div v-if="record?.source" class="panel-sub-section block xl:hidden">
        <PanelHeading heading="Source" />
        <span class="mt-3 block font-heading text-[15px] capitalize text-atc-text">
          {{ record.source }}
        </span>
      </div>

      <div v-if="record" class="panel-sub-section">
        <PanelHeading :heading="`${entity} details`" />
        <div
          v-if="entity === 'lead'"
          class="grid grid-cols-1 gap-y-6 gap-x-4 text-lg sm:grid-cols-2"
        >
          <div class="form-input-edit">
            <FormInput
              id="lvlofint"
              v-model="form.client.lvlofint"
              :error="formatError($props.errors['client.lvlofint'])"
              class="form-input-edit"
              label="Level of interest"
              @change="saved = false"
            />
          </div>
          <div class="form-input-edit">
            <FormInput
              id="prefmoc"
              v-model="form.client.prefmoc"
              :error="formatError($props.errors['client.prefmoc'])"
              class="form-input-edit"
              label="Prefered method of contact"
              @change="saved = false"
            />
          </div>
          <div class="form-input-edit sm:col-span-2">
            <FormLabel id="marketing" text="Where did you hear about Action Trackchair?" />
            <VueMultiselect
              id="marketing"
              v-model="form.client.marketing"
              :custom-label="option => marketingTypeLabel(option)"
              :disabled="disabledMultiselects"
              :options="marketingTypes.map(type => type.value)"
              class="form-multiselect-edit"
              placeholder="Start typing to search"
              @change="saved = false"
            >
              <template #singleLabel="props">
                <span>{{ marketingTypeLabel(props.option) }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'client.marketing')" />
              </template>
            </VueMultiselect>
            <div v-if="$props.errors['client.marketing']" class="form-error">
              {{ $props.errors['client.marketing'] }}
            </div>
          </div>
        </div>
        <div v-else class="grid grid-cols-1 gap-y-6 gap-x-4 text-lg sm:grid-cols-6">
          <div class="form-input-edit sm:col-span-3">
            <FormInput
              id="stock"
              v-model="form.stock"
              :error="formatError($props.errors['stock'])"
              class="form-input-edit"
              label="Stock #"
              @change="saved = false"
            />
          </div>
          <div class="form-input-edit sm:col-span-3">
            <FormInput
              id="registration"
              v-model="form.client.registration"
              :error="formatError($props.errors['client.registration'])"
              class="form-input-edit"
              label="Serial #"
              @change="saved = false"
            />
          </div>
          <div class="form-input-edit sm:col-span-3">
            <FormInput
              id="deliveryDate"
              v-model="form.client.deliveryDate"
              :error="formatError($props.errors['client.deliveryDate'])"
              class="form-input-edit"
              :label="
                ['delivered', 'registered'].includes(form.status)
                  ? 'Delivery Date'
                  : 'Estimated Ship Date'
              "
              @change="saved = false"
            />
          </div>
          <div class="form-input-edit sm:col-span-3">
            <FormInput
              id="registrationDate"
              v-model="form.client.registrationDate"
              :error="formatError($props.errors['client.registrationDate'])"
              class="form-input-edit"
              label="Registration Date"
              @change="saved = false"
            />
          </div>
          <div class="form-input-edit sm:col-span-3">
            <FormInput
              id="deliveredBy"
              v-model="form.client.deliveredBy"
              :error="formatError($props.errors['client.deliveredBy'])"
              class="form-input-edit"
              label="Delivered By"
              @change="saved = false"
            />
          </div>
          <div class="form-input-edit sm:col-span-3">
            <FormInput
              id="dealership"
              v-model="form.client.dealership"
              :error="formatError($props.errors['client.dealership'])"
              class="form-input-edit"
              label="Dealership Name"
              @change="saved = false"
            />
          </div>
        </div>
      </div>
      <div v-if="record" class="panel-sub-section text-area-break">
        <PanelHeading :heading="`${entity} status`" />
        <StatusSelector
          v-model="form.status"
          :auth="$page.props?.auth"
          :entity="entity"
          :statuses="statuses"
          :class="entity === 'lead' ? 'lg:!grid-cols-7' : 'sm:!grid-cols-4 lg:!grid-cols-8'"
          @input="onStatusSelect"
        />
      </div>
      <div :class="`panel-sub-section ${entity === 'lead' ? 'build-info-print' : ''}`">
        <PanelHeading heading="Build Information" />
        <div class="grid grid-cols-1 gap-y-6 gap-x-4 text-lg sm:grid-cols-6">
          <div class="form-input-edit sm:col-span-6">
            <FormLabel id="product" text="Product" required />
            <VueMultiselect
              id="product"
              v-model="form.product.data"
              :options="products"
              placeholder="Start typing to search"
              label="name"
              track-by="uuid"
              :class="`form-multiselect-edit required ${
                $props.errors['product.data'] ? 'form-multiselect-error' : ''
              }`"
              :disabled="disabledMultiselects"
              :allow-empty="false"
              deselect-label=""
              @select="selectedProduct"
              @change="saved = false"
            />
            <div v-if="$props.errors['product.data']" class="form-error">
              {{ $props.errors['product.data'] }}
            </div>
          </div>
          <div class="form-input-edit sm:col-span-3">
            <FormLabel id="model" text="Model" required />
            <VueMultiselect
              id="model"
              v-model="form.product.model"
              :options="modelDataSet"
              placeholder="Start typing to search"
              :class="`form-multiselect-edit required ${
                $props.errors['product.model'] ? 'form-multiselect-error' : ''
              }`"
              :disabled="disabledMultiselects"
              :allow-empty="false"
              deselect-label=""
              @change="saved = false"
            />
            <div v-if="$props.errors['product.model']" class="form-error">
              {{ $props.errors['product.model'] }}
            </div>
          </div>
          <div class="form-input-edit sm:col-span-3">
            <FormLabel id="joystickMounting" text="Joystick Mounting" required />
            <VueMultiselect
              id="joystickMounting"
              v-model="form.product.joystickMounting"
              :options="[
                {label: 'Left mounted', id: 'left'},
                {label: 'Right mounted', id: 'right'}
              ]"
              placeholder="Start typing to search"
              label="label"
              track-by="id"
              :class="`form-multiselect-edit required ${
                $props.errors['product.joystickMounting'] ? 'form-multiselect-error' : ''
              }`"
              :allow-empty="false"
              deselect-label=""
              :disabled="disabledMultiselects"
              @change="saved = false"
            />
            <div v-if="$props.errors['product.joystickMounting']" class="form-error">
              {{ $props.errors['product.joystickMounting'] }}
            </div>
          </div>
          <template v-for="extensionType in extensions" :key="extensionType">
            <div
              v-if="getProductExtensionsByType(extensionType.name).length"
              class="form-input-edit"
              :class="extensionType.multiselect ? 'sm:col-span-6' : 'sm:col-span-3'"
            >
              <FormLabel :id="camelCase(extensionType.name)" :text="extensionType.name" :required="!extensionType.multiselect" />
              <VueMultiselect
                :id="camelCase(extensionType.name)"
                v-model="form.product.extensions[camelCase(extensionType.name)]"
                :options="getProductExtensionsByType(extensionType.name)"
                :multiple="!!extensionType.multiselect"
                :close-on-select="!extensionType.multiselect"
                placeholder="Start typing to search"
                label="name"
                track-by="uuid"
                :class="`form-multiselect-edit required ${
                  $props.errors[`product.extensions.${camelCase(extensionType.name)}`]
                    ? 'form-multiselect-error'
                    : ''
                }
                  ${
                  extensionType.multiselect
                    ? 'form-multiselect-edit--big form-multiselect-product'
                    : ''
                }`"
                :allow-empty="!!extensionType.multiselect"
                :deselect-label="extensionType.multiselect ? 'Press enter to remove' : ''"
                :disabled="disabledMultiselects"
                @change="saved = false"
              />
              <div
                v-if="$props.errors[`product.extensions.${camelCase(extensionType.name)}`]"
                class="form-error"
              >
                The {{ extensionType.name.toLowerCase() }} field is required.
              </div>
            </div>
          </template>
          <div class="text-area-break sm:col-span-6">
            <FormTextArea
              id="notes"
              v-model="form.product.description"
              :error="formatError($props.errors['product.description'])"
              :class="`form-textarea-edit product-notes ${
                entity === 'lead' ? 'leads-product-notes' : ''
              }`"
              rows="4"
              label="Chair Builder Notes"
              @change="saved = false"
            />
          </div>
        </div>
      </div>
      <div class="panel-sub-section border-b-0">
        <PanelHeading heading="Customer Attributes" />
        <div class="grid grid-cols-1 gap-y-6 gap-x-4 text-lg sm:grid-cols-2">
          <div class="form-input-edit sm:col-span-2">
            <VueMultiselect
              v-model="form.client.data"
              :options="[
                'Veteran',
                'Pediatric',
                'Interested In A Used Chair',
                'The Independence Fund'
              ]"
              multiple
              placeholder="Start typing to search"
              label=""
              :close-on-select="false"
              class="form-multiselect-edit form-multiselect-edit--big form-multiselect-product"
              :disabled="disabledMultiselects"
              @change="saved = false"
            />
          </div>
          <FormInput
            id="financing"
            v-model="form.client.financing"
            :error="form.errors.financing"
            class="form-input-edit"
            label="Financing"
            @change="saved = false"
          />
          <FormInput
            id="beneficiary"
            v-model="form.client.beneficiary"
            :error="form.errors.beneficiary"
            class="form-input-edit"
            label="Beneficiary"
            @change="saved = false"
          />
          <div class="form-input-edit">
            <FormLabel id="consumerType" text="Consumer type" />
            <VueMultiselect
              id="consumerType"
              v-model="form.client.consumerType"
              :custom-label="option => consumerTypeLabel(option)"
              :disabled="disabledMultiselects"
              :options="consumerTypes.map(type => type.value)"
              class="form-multiselect-edit"
              placeholder="Start typing to search"
              @change="saved = false"
            >
              <template #singleLabel="props">
                <span>{{ consumerTypeLabel(props.option) }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'client.consumerType')" />
              </template>
            </VueMultiselect>
            <div v-if="$props.errors['client.consumerType']" class="form-error">
              {{ $props.errors['client.consumerType'] }}
            </div>
          </div>
        </div>
      </div>
      <div class="record-control-panel px-10 py-7">
        <div class="justify-even flex">
          <div class="flex w-full">
            <button
              v-if="record &&
                ($page.props.auth.can[`${entity}s.delete`] ||
                  $page.props.auth.can[`${entity}s.delete.any`])"
              type="button"
              class="form-button"
              @click="showConfirmPopup('delete')"
            >
              Delete
            </button>
            <InertiaLink
              type="button"
              :href="$route(singleRoutes.index)"
              as="button"
              class="form-button ml-auto"
            >
              Cancel
            </InertiaLink>
            <button type="submit" :disabled="form.processing" class="form-submit-button ml-3">
              Save
            </button>
          </div>
        </div>
      </div>
    </Panel>
  </form>
  <ConfirmModal
    :open="isConfirmPopupOpen"
    :text="popupText"
    button-label="Confirm"
    @on-close="closeConfirmPopup"
    @on-confirm="confirmAction"
  />

  <FullPageLoader />
  <Pdf v-if="record" ref="pdf" :record="record" :entity="entity" :data="printData" />
</template>

<script>
import {Link as InertiaLink, useForm} from '@inertiajs/inertia-vue3'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import SidePanel from 'Shared/LeadsAndOrders/SidePanel'
import StatusSelector from 'Shared/Misc/StatusSelector'
import FormInput from 'Shared/Form/FormInput'
import FormLabel from 'Shared/Form/FormLabel'
import FormTextArea from 'Shared/Form/FormTextArea'
import {getCountries, getStates} from 'Shared/helpers'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Icon from 'Shared/Icon'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import Pdf from 'Shared/PDF/PartsList'
import Dropdown from 'Shared/Dropdown'
import FlashMessages from 'Shared/FlashMessages'
import FullPageLoader from 'Shared/FullPageLoader'
import Tabs from 'Shared/Navigation/Tabs'
import {camelCase} from 'lodash'
import axios from 'axios'

export default {
  components: {
    ConfirmModal,
    Dropdown,
    FlashMessages,
    FormInput,
    FormLabel,
    FormTextArea,
    FullPageLoader,
    Icon,
    InertiaLink,
    Panel,
    PanelHeading,
    Pdf,
    SidePanel,
    StatusSelector,
    Tabs,
    VueMultiselect
  },
  props: {
    consumerTypes: Array,
    dealers: Array,
    dealership: String,
    dealerships: Array,
    distMembers: Array,
    distributors: Array,
    entity: String,
    errors: Object,
    extensions: Array,
    marketingTypes: Array,
    onConfirm: Object,
    printData: Object,
    products: Array,
    record: Object,
    statuses: Array
  },
  data() {
    return {
      tabs: this.record
        ? [
          {
            label: 'Details',
            route: this.$route(`${this.entity}s.edit`, this.record.uuid),
            current: [`${this.entity}s.edit`]
          },
          {
            label: 'Documents',
            route: this.$route(`${this.entity}s.documents.index`, this.record.uuid),
            current: [`${this.entity}s.documents.index`, `${this.entity}s.documents.show`]
          },
          {
            label: 'Activity',
            route: this.$route(`${this.entity}s.activity.index`, this.record.uuid),
            current: [`${this.entity}s.activity.index`]
          }
        ]
        : null,
      singleRoutes: {
        index: `${this.entity}s.index`,
        edit: `${this.entity}s.edit`,
        create: `${this.entity}s.create`,
        update: `${this.entity}s.update`,
        store: `${this.entity}s.store`
      },
      disabledMultiselects: false,
      disabledMultiselectsDist: false,
      modelDataSet: [''],
      extensionsDataSet: {},
      isConfirmPopupOpen: false,
      popupAction: '',
      redirectLink: '',
      popupText: '',
      saved: true,
      form: useForm({
        _method: this.record ? 'PUT' : 'POST',
        _source: 'backend',
        product: {
          data: this.record?.product?.data ?? this.products[0],
          description: this.record?.product?.description ?? null,
          model: this.record?.product?.model ?? null,
          joystickMounting: this.record?.product?.joystickMounting ?? null,
          extensions: {...this.record?.product?.extensions} ?? {}
        },
        client: {
          firstName: this.record?.client?.firstName ?? '',
          lastName: this.record?.client?.lastName ?? '',
          email: this.record?.client?.email ?? null,
          phone: this.record?.client?.phone ?? null,
          address: this.record?.client?.address ?? null,
          city: this.record?.client?.city ?? null,
          country: this.record?.client?.country ?? 'US',
          state: this.record?.client?.state ?? null,
          zipCode: this.record?.client?.zipCode ?? null,
          clientNotes: this.record?.client?.clientNotes ?? null,
          beneficiary: this.record?.client?.beneficiary ?? null,
          data: this.record?.client?.data ?? null,
          financing: this.record?.client?.financing ?? null,
          prefmoc: this.record?.client?.prefmoc ?? null,
          lvlofint: this.record?.client?.lvlofint ?? null,
          marketing: this.record?.client?.marketing ?? null,
          consumerType: this.record?.client?.consumerType ?? null,
          registration: this.record?.client?.registration ?? null,
          deliveryDate: this.record?.client?.deliveryDate ?? null,
          registrationDate: this.record?.client?.registrationDate ?? null,
          deliveredBy: this.record?.client?.deliveredBy ?? null,
          dealership: this.record?.client?.dealership ?? null
        },
        distributor:
          this.record?.distributor ??
          this.$page.props?.auth?.user?.distributor ??
          this.$page.props?.auth?.user?.dealership?.distributor,
        dealer: this.record?.dealer ?? null,
        dealership: this.record?.dealership ?? this.$page.props?.auth?.user?.dealership,
        distMember: this.record?.distMember ?? null,
        status: this.record?.status ?? (this.entity === 'lead' ? 'new-lead' : 'received'),
        stock: this.record?.stock ?? 'T000000'
      }),
      countries: null,
      states: null,
    }
  },
  computed: {
    distributorUuid() {
      return this.form.distributor?.uuid
    },
    dealershipUuid() {
      return this.form.dealership?.uuid
    }
  },
  watch: {
    onConfirm(e) {
      this.onRedirect(e)
    }
  },
  mounted() {
    this.$watch(
      () => [this.distributorUuid, this.dealershipUuid],
      ([newDist, newDealer]) => {
        this.$inertia
          .get(
            this.record
              ? this.$route(`${this.entity}s.show`, this.record.uuid)
              : this.$route(`${this.entity}s.create`),
            {
              distributor: newDist,
              dealership: newDealer
            },
            {
              preserveState: true,
              preserveScroll: true
            })
      }
    )
    this.selectedProduct()
    this.blockFieldsIfPermissionDenied()
    this.blockDistFieldsIfPermissionDenied()
    getCountries().then(response => (this.countries = response.data))
    getStates().then(response => (this.states = response.data))
    this.form.client.dealership = this.dealership
  },
  methods: {
    getCountries,
    getStates,
    camelCase,
    async printPriceTable() {
      try {
        const response = await axios.get(this.$route(`${this.entity}s.pdf`, this.record.uuid), {
          responseType: 'blob'
        })

        const blob = new Blob([response.data], { type: 'application/pdf' })
        const url = window.URL.createObjectURL(blob)
        const newWindow = window.open(url, '_blank')

        if (newWindow) {
          const interval = setInterval(() => {
            if (newWindow.document.readyState === 'complete') {
              clearInterval(interval)
              newWindow.print()
            }
          }, 100)
        } else {
          alert('Popup blocked. Please allow popups for this website.')
        }
      } catch (error) {
        console.error('Error printing PDF: ', error)
        alert('An error occurred while printing the PDF.')
      }
    },
    printPartsList() {
      this.$refs.pdf.exportToPDF()
    },
    onStatusSelect(event) {
      if (event === 'delivered') {
        this.fillInputHandler('delivered')
      }
      if (event === 'registered') {
        this.fillInputHandler('registered')
      }
      this.saved = false
    },
    fillInputHandler(action) {
      let date = new Date()
      date =
        (date.getMonth() + 1).toLocaleString('en-US', {
          minimumIntegerDigits: 2,
          useGrouping: false
        }) +
        '/' +
        (date.getDay() + 1).toLocaleString('en-US', {minimumIntegerDigits: 2, useGrouping: false}) +
        '/' +
        date.getFullYear()
      if (action === 'delivered') {
        this.form.client.deliveryDate = date
        this.form.client.deliveredBy = this.$page.props.auth.user
      }
      if (action === 'registered') {
        this.form.client.registrationDate = date
      }
    },
    blockFieldsIfPermissionDenied() {
      if (!this.record) return

      const allowedAny = this.$page.props.auth.can[`${this.entity}s.update.any`]
      const allowed = this.$page.props.auth.can[`${this.entity}s.update`]
      const inputs = document.querySelectorAll('input.form-input-edit')
      const editableInputIds = [
        'deliveredBy',
        'first-name',
        'last-name',
        'email',
        'phone',
        'address',
        'city',
        'zip-code'
      ]

      if (!allowed && !allowedAny) {
        this.disabledMultiselects = true
        inputs.forEach(element => {
          if (
            editableInputIds.includes(element.id) &&
            this.$page.props.auth.can['orders.manage']
          ) {
            element.disabled = false
          } else {
            element.disabled = true
          }
        })
      }
    },
    blockDistFieldsIfPermissionDenied() {
      if (this.record) {
        let allowedAny = this.$page.props.auth.role.name === 'admin' ? true : false
        let allowed = this.$page.props.auth.role.name === 'dist_manager' ? true : false
        if (!allowed && !allowedAny) {
          this.disabledMultiselectsDist = true
        }
      }
    },
    heading() {
      return this.form.client.firstName || this.form.client.lastName
        ? `${this.form.client.firstName} ${this.form.client.lastName}`
        : `New ${this.entity}`
    },
    submit() {
      this.form
        .transform(data => {
          data.product.data = {
            uuid: data.product.data.uuid,
            name: data.product.data.name,
            sku: data.product.data.sku,
            price: data.product.data.price,
            media: data.product.data.media
          }
          return data
        })
        .post(
          this.record
            ? this.$route(this.singleRoutes.update, this.record.uuid)
            : this.$route(this.singleRoutes.store),
          {
            forceFormData: true,
            preserveState: page => Object.keys(page.props.errors).length
          }
        )
    },
    deleteRecord() {
      this.$inertia.post(this.$route(`${this.entity}s.destroy`, this.record.uuid), {
        _method: 'DELETE'
      })
    },
    selectedProduct(selected) {
      const product = selected ?? this.form.product?.data

      this.modelDataSet = this.products.find(x => x.uuid == product?.uuid)?.variants

      if (!this.modelDataSet.includes(this.form.product.model)) {
        this.form.product.model = null
      }

      this.extensions.forEach(type => {
        const typeName = camelCase(type.name)

        this.extensionsDataSet[typeName] = this.getProductExtensionsByType(type.name, product)

        if (
          !this.form.product.extensions[typeName] ||
          this.form.product.extensions[typeName] === []
        )
          return

        if (type.multiselect) {
          this.form.product.extensions[typeName] = this.form.product.extensions[typeName].filter(
            extension => {
              if (
                this.extensionsDataSet[typeName].find(ext => {
                  return ext.uuid === extension.uuid
                })
              )
              return extension
            }
          )
        } else {
          if (
            !this.extensionsDataSet[typeName].find(ext => {
              return ext.uuid === this.form.product.extensions[typeName]?.uuid
            })
          ) {
            this.form.product.extensions[typeName] = null
          }
        }
      })
    },
    getProductExtensionsByType(type, selected = null) {
      const product = selected ?? this.form.product.data
      const current = this.record?.product?.extensions?.[camelCase(type)]

      const extensions = this.extensions
        .find(x => x.name == type)
        .extensions.filter(o1 =>
          this.products
            .find(x => x.uuid == product?.uuid)
            .extensions.some(o2 => o1.uuid === o2.uuid)
        )

      if (current && !extensions.filter(ext => ext.sku === current.sku).length > 0) {
        extensions.push(current)
      }

      return extensions
    },
    formatError(error) {
      if (error) {
        let result = error.replace('client.', '').replace('product.', '')
        return result
      } else {
        return error
      }
    },
    marketingTypeLabel(option) {
      return this.marketingTypes.find(type => type.value === option)?.label ?? option
    },
    consumerTypeLabel(option) {
      return this.consumerTypes.find(type => type.value === option)?.label
    },
    clearSelectionNoEvent(selection) {
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    onRedirect(e) {
      this.redirectLink = e.target.pathname + e.target.search
      if (!this.saved) {
        this.showConfirmPopup('redirect')
      } else {
        this.popupAction = 'redirect'
        this.confirmAction()
      }
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'delete') {
        this.deleteRecord()
      }
      if (this.popupAction === 'redirect') {
        this.$inertia.visit(this.redirectLink)
      }
    },
    showConfirmPopup(action) {
      this.popupAction = action
      if (this.popupAction === 'delete') {
        this.popupText = `Are you sure you want to delete this ${this.entity}? This action cannot be undone.`
      }
      if (this.popupAction === 'redirect') {
        this.popupText = 'Are you sure you want to leave this page without saving?'
      }
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>

<style>
.cursor-not-allowed .panel-inactive,
.cursor-not-allowed .status-badge {
  cursor: not-allowed;
}
.record-control-panel {
  position: fixed;
  bottom: 0px;
  left: 0px;
  display: block;
  background-color: #fff;
  width: 100%;
  border-top: 1px solid #e7e7e7;
  padding: 0.6rem 1.5rem;
}
.panel-wrapper-padding {
  padding-bottom: 110px;
}
</style>
