<template>
  <div>
    <InertiaHead title="Dashboard" />

    <div class="grid grid-cols-1 xl:grid-cols-2 gap-y-8 gap-x-12">
      <Panel v-if="auth.can['reservations.index']" class="flex flex-col xl:col-span-2 pb-12">
        <div class="flex justify-between">
          <PanelHeading heading="Reservations" />
          <PanelActions
            :create="$route('reservations.create')"
            :link="$route('reservations.index')"
            :widget="true"
            create-permission="reservations.create"
          />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">Date</th>
              <th scope="col" class="table-header">Client</th>
              <th v-if="auth.can['reservations.view.any']" scope="col" class="table-header">Organization</th>
              <th scope="col" class="table-header">Product</th>
              <th scope="col" class="table-header">Price</th>
              <th scope="col" class="table-header">Status</th>
            </tr>
          </thead>
          <tbody class="table-body">
            <InertiaLink
              v-for="reservation in reservations.data"
              :key="reservation.uuid"
              as="tr"
              :href="$route('reservations.show', reservation.uuid)"
              class="table-row cursor-pointer"
            >
              <td class="table-cell truncate grid">
                <span>{{ formatDate(reservation.start_at) }}</span>
                <span>{{ formatTime(reservation.start_at) }} - {{ formatTime(reservation.end_at) }}</span>
              </td>
              <td class="table-cell truncate" :title="reservation.client_data?.fullName">
                {{ reservation.client_data?.fullName }}
              </td>
              <td v-if="auth.can['reservations.view.any']" class="table-cell truncate">
                {{ reservation.organizationProduct.organization.name }}
              </td>
              <td class="table-cell truncate">{{ reservation.organizationProduct.serial }}</td>
              <td class="table-cell truncate">
                ${{ formatPrice(reservation.payment?.amount ?? reservation.organizationProduct.price) }}
              </td>
              <td class="table-cell truncate">
                <StatusBadge
                  :label="reservationStatuses.find(status => status.value === reservation.status).label"
                  :value="reservation.status"
                />
              </td>
            </InertiaLink>

            <tr v-if="reservations.data.length === 0">
              <td
                class="table-cell text-center"
                :colspan="auth.can['reservations.view.any'] ? '6' : '5'"
              >
                No reservations found.
              </td>
            </tr>
          </tbody>
        </table>
      </Panel>

      <RecordList
        v-if="auth.can['leads.index']"
        :dashboard="true"
        :permissions="auth.can"
        :records="leads"
        :role="auth.role"
        :statuses="leadStatuses"
        name="Leads"
        class="w-full xl:col-span-2"
      />

      <RecordList
        v-if="auth.can['orders.index']"
        :dashboard="true"
        :permissions="auth.can"
        :records="orders"
        :role="auth.role"
        :statuses="orderStatuses"
        class="w-full"
        name="Orders"
      />

      <NewsList
        v-if="auth.can['news.index']"
        :dashboard="true"
        :news="news"
        :permissions="auth.can"
        name="Support"
      />
    </div>
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import {formatPrice} from 'Shared/helpers'
import Layout from 'Shared/Layouts/Layout'
import NewsList from 'CoreComponents/NewsList'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import PanelActions from 'Shared/PanelActions'
import RecordList from 'Shared/LeadsAndOrders/RecordList'
import SortingColumn from 'Shared/SortingColumn'
import StatusBadge from 'Shared/Misc/StatusBadge'

export default {
  components: {
    StatusBadge,
    SortingColumn,
    InertiaHead,
    InertiaLink,
    NewsList,
    Panel,
    PanelActions,
    PanelHeading,
    RecordList
  },
  layout: Layout,
  props: {
    auth: Object,
    leadStatuses: Array,
    leads: Object,
    news: Object,
    orderStatuses: Array,
    orders: Object,
    reservations: Object,
    reservationStatuses: Object
  },
  methods: {
    formatPrice,
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    },
    formatTime(date) {
      return new Date(date).toLocaleTimeString('en-US', {
        hour: '2-digit',
        minute: '2-digit'
      })
    }
  }
}
</script>
