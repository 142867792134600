<template>
  <div class="flex space-x-3">
    <div class="flex-shrink-0">
      <img
        class="h-10 w-10 rounded-full"
        :src="message.user.photo ?? '/assets/images/test-avatar.png'"
        :alt="`${message.user.name} photo`"
      />
    </div>
    <div>
      <div class="text-sm flex items-center space-x-2">
        <a href="#" class="font-medium text-gray-900">{{ message.user.name }}</a>
        <dropdown v-if="$page.props.auth.role.name === 'admin'" placement="bottom-start" data-html2canvas-ignore>
          <template #default>
            <div class="group cursor-pointer select-none">
              <Icon class="w-2" :name="`pointer`" />
            </div>
          </template>
          <template #dropdown>
            <div class="mt-2 py-2 text-sm bg-white text-atc-text rounded shadow-xl">
              <a
                as="button"
                class="block px-6 py-2 w-full cursor-pointer text-left hover:text-white hover:bg-atc-success"
                @click="$emit('onDelete', e)"
              >Delete</a>
            </div>
          </template>
        </dropdown>
      </div>
      <div class="mt-1 text-sm text-gray-700">
        <p>{{ message.content }}</p>
      </div>
      <div class="mt-2 text-sm space-x-2">
        <span class="text-gray-500 font-medium flex">
          <ClockIcon class="w-4 mr-1" />{{ formatDateTime(message.created_at) }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
import { ClockIcon } from '@heroicons/vue/outline'
import Dropdown from 'Shared/Dropdown'
import Icon from 'Shared/Icon'

export default {
  components: {
    ClockIcon,
    Dropdown,
    Icon
  },
  props: {
    message: Object
  },
  emits: ['onDelete'],
  methods: {
    formatDateTime(date) {
      date = new Date(date)
      return date.toLocaleString('en-US', {
        dateStyle: 'long',
        timeStyle: 'short'
      })
    }
  }
}
</script>
