<template>
  <div>
    <Head title="Orders" />
    <RecordList
      :records="orders"
      :filters="true"
      :widget="false"
      name="Orders"
      :statuses="statuses"
      :dealerships="dealerships"
      :permissions="$attrs.auth.can"
      :role="$attrs.auth.role"
      :partsOrderingEnabled="partsOrderingEnabled"
    />
  </div>
</template>

<script>
import { Head } from '@inertiajs/inertia-vue3'
import Layout from 'Shared/Layouts/Layout'
import RecordList from 'Shared/LeadsAndOrders/RecordList'

export default {
  components: {
    Head,
    RecordList
  },
  layout: Layout,
  props: {
    dealerships: Array,
    orders: Object,
    statuses: Array,
    partsOrderingEnabled: Boolean
  }
}
</script>
