<template>
  <DialogBase
    :open="open"
    modal-class="cm-content"
    overlay-class="bg-gray-500 bg-opacity-75 transition-opacity"
    @close="$emit('on-close')"
  >
    <div class="cm-flex">
      <div class="cm-icon-wrapper">
        <ExclamationIcon class="cm-exclamation-icon" aria-hidden="true" />
      </div>
      <div class="cm-title-wrapper">
        <DialogTitle as="h3" class="cm-title">Confirm</DialogTitle>
        <div class="mt-2">
          <p class="text-sm text-gray-500">{{ text }}</p>
        </div>
      </div>
    </div>

    <div class="cm-buttons-wrapper">
      <LoadingButton :loading="loading" class="form-red-button" type="button" @click="$emit('on-confirm')">
        {{ buttonLabel }}
      </LoadingButton>
      <button ref="cancelButtonRef" class="form-button ml-3" type="button" @click="$emit('on-close')">
        Cancel
      </button>
    </div>
  </DialogBase>
</template>

<script>
import DialogBase from 'Shared/Dialogs/DialogBase'
import {DialogTitle} from '@headlessui/vue'
import {ExclamationIcon} from '@heroicons/vue/outline'
import LoadingButton from 'Shared/Buttons/LoadingButton'

export default {
  components: {
    LoadingButton,
    DialogBase,
    DialogTitle,
    ExclamationIcon
  },
  props: {
    open: Boolean,
    text: {
      type: String,
      default: 'Are you sure you want to leave this page? All of your data will be permanently lost. This action cannot be undone.'
    },
    buttonLabel: {
      type: String,
      default: 'Leave'
    },
    loading: {
      type: Boolean,
      default: false
    }
  },
  emits: ['on-close', 'on-confirm']
}
</script>
