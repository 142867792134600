<template>
  <div class="h-full">
    <InertiaHead :title="title" />
    <div class="flex min-h-full bg-form-pattern">
      <div class="relative hidden w-0 flex-1 lg:block">
        <img
          class="absolute inset-0 h-full w-full object-cover"
          src="/assets/images/form-img.jpg"
          alt=""
        />
      </div>
      <div class="flex w-1/3 flex-1 flex-col justify-between lg:flex-none">
        <div
          class="sm:px-18 xl:px-15 flex-1 flex-col justify-between px-12 pt-12 lg:flex-none lg:px-20"
        >
          <div class="flex h-full w-full flex-col">
            <Logo class="fill-atc-secondary" width="160" height="54" />

            <slot />
          </div>
        </div>

        <div
          class="mt-auto flex w-full bg-[#F5F7FA] py-3 px-6 font-footer text-xs leading-5 text-atc-black"
        >
          Created by<a
            title="Massive Pixel Creation"
            href="https://massivepixel.io/"
            target="_blank"
            class="pl-3"
          >
            <MPCLogo />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Logo from 'Shared/Logo'
import MPCLogo from 'Shared/Icons/MPCLogo'

export default {
  components: {
    InertiaHead,
    Logo,
    MPCLogo
  },
  props: {
    title: String
  }
}
</script>

<style>
body > div#app {
  height: 100%;
}
</style>
