<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clip-path="url(#clip0_963_7424)">
      <path d="M7.14244 17.4858L0.713867 19.2858L2.51387 12.8572L14.2853 1.14296C14.4183 1.00689 14.5772 0.898779 14.7526 0.824968C14.928 0.751157 15.1164 0.713135 15.3067 0.713135C15.497 0.713135 15.6854 0.751157 15.8608 0.824968C16.0362 0.898779 16.1951 1.00689 16.3282 1.14296L18.8567 3.68581C18.9906 3.81862 19.0969 3.97662 19.1694 4.1507C19.242 4.32479 19.2793 4.51151 19.2793 4.7001C19.2793 4.88869 19.242 5.07541 19.1694 5.24949C19.0969 5.42358 18.9906 5.58158 18.8567 5.71439L7.14244 17.4858Z" stroke="#333333" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M12.5 3L17 7.5" stroke="#333333" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_963_7424">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
