<template>
  <Layout :confirmation="true" :auth="$attrs.auth" @on-confirm="passValue($event)">
    <div>
      <InertiaHead title="Lead documents" />
      <RecordDocumentsList
        :record="lead"
        :attachments="attachments"
        entity="lead"
        :print-data="formatOrderPrintData(lead)"
        :permissions="$attrs.auth.can"
        :on-confirm="confirmSignal"
        :statuses="statuses"
      />
    </div>
  </Layout>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Layout from 'Shared/LeadsAndOrders/LayoutWide'
import RecordDocumentsList from 'Shared/LeadsAndOrders/RecordDocumentsList'
import {formatOrderPrintData} from 'Shared/helpers'

export default {
  components: {
    InertiaHead,
    RecordDocumentsList,
    Layout
  },
  props:{
    lead: Object,
    attachments: Object,
    statuses: Array
  },
  data () {
    return {
      confirmSignal: null
    }
  },
  methods: {
    formatOrderPrintData,
    passValue(e) {
      this.confirmSignal = e
    }
  }
}
</script>
