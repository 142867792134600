<template>
  <carousel ref="slider" :items-to-show="1" class="details-carousel">
    <slide v-for="slide in images" :key="slide" class="p-10">
      <img :src="slide" alt="" class="" />
    </slide>
    <template #addons="{ currentSlide }">
      <div class="slider-counter absolute bottom-[14px] translate-x-[-50%] left-1/2">
        <p class="text-black">{{ currentSlide + 1 }} / {{ slidesCount }}</p>
      </div>
      <navigation>
        <template #next>
          <ArrowNarrowRightIcon class="text-black w-[50px]" />
        </template>
        <template #prev>
          <ArrowNarrowLeftIcon class="text-black w-[50px]" />
        </template>
      </navigation>
    </template>
  </carousel>
</template>

<script>
import 'vue3-carousel/dist/carousel.css'
import {
  Carousel,
  Slide,
  Navigation
} from 'vue3-carousel'
import {
  ArrowNarrowLeftIcon
} from '@heroicons/vue/outline'
import {
  ArrowNarrowRightIcon
} from '@heroicons/vue/outline'
export default {
  components: {
    Carousel,
    Slide,
    Navigation,
    ArrowNarrowLeftIcon,
    ArrowNarrowRightIcon
  },
  props: {
    images: Array
  },
  data() {
    return {
      slidesCount: this.images.length,
      activeSlide: 0
    }
  },
  watch: {
    images: {
      deep: true,
      handler: function () {
        this.$refs.slider.slideTo(0)
      }
    }
  }
}
</script>
