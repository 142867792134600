<template>
  <div>
    <InertiaHead title="Support" />
    <Panel>
      <div class="flex justify-between">
        <PanelHeading heading="Support" />
        <PanelActions
          :create="$route('news.create')"
          :widget="false"
          create-permission="news.create"
          text="article"
        />
      </div>
      <div class="pb-12">
        <div class="flex flex-row justify-between items-center w-full pb-5">
          <div class="flex items-center">
            <div class="min-w-[18rem]">
              <VueMultiselect
                v-model="query.tags"
                :options="tags"
                :multiple="true"
                :hide-selected="true"
                :close-on-select="false"
                placeholder="Select tags"
                label="name"
                track-by="slug"
                class="form-multiselect-edit"
                :show-labels="false"
                @search-change="asyncFindTags"
              />
            </div>
            <InertiaLink
              v-if="getPermissions('tags.index')"
              class="text-[0.92rem] font-medium whitespace-nowrap ml-3 hover:text-atc-secondary duration-200"
              :href="$route('tags.index')"
            >
              Manage Tags
            </InertiaLink>
          </div>
          <FormInput v-model="query.search" class="form-input-edit ml-auto w-64" placeholder="Search" />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">Name</th>
              <th scope="col" class="table-header w-64" />
            </tr>
          </thead>
          <tbody class="table-body">
            <tr v-for="(item, index) in news.data" :key="index" class="py-2">
              <td
                class="flex items-center justify-between whitespace-nowrap py-4 pl-4 pr-3 text-lg font-medium sm:pl-6"
              >
                <InertiaLink
                  :href="$route('news.show', item)"
                  class="flex w-full flex-col content-center justify-between"
                >
                  <div class="text-xs flex flex-wrap gap-2 mb-1">
                    <p class="py-0.5">{{ getFormattedDate(item.created_at) }}</p>
                    <span
                      v-for="(tag, index) in item.tags"
                      :key="index"
                      class="bg-atc-gray rounded-full px-2 py-0.5"
                    >
                      {{ tag.name }}
                    </span>
                  </div>
                  <p class="... flex flex-row items-center truncate uppercase">
                    {{ item.title }}
                    <Icon class="ml-3 w-3 -rotate-90" :name="`pointer`" />
                  </p>
                </InertiaLink>
              </td>
              <td>
                <div class="flex">
                  <InertiaLink
                    v-if="getPermissions('news.update')"
                    type="button"
                    as="button"
                    class="form-submit-button mr-3"
                    :href="$route('news.documents.index', item.uuid)"
                  >
                    Documents
                  </InertiaLink>
                  <button
                    v-if="getPermissions('news.delete')"
                    type="button"
                    as="button"
                    class="form-button"
                    @click="showConfirmPopup('delete', item)"
                  >
                    Delete
                  </button>
                </div>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="news.links"
          class="mt-5 flex items-center justify-center"
          :current-page="news.current_page"
          @change-page="key => query.page = key"
        />
      </div>
    </Panel>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this article? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import axios from 'axios'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import FormInput from 'Shared/Form/FormInput'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import moment from 'moment'
import {pickBy, throttle} from 'lodash'

export default {
  components: {
    InertiaHead,
    InertiaLink,
    ConfirmModal,
    FormInput,
    Icon,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading,
    VueMultiselect
  },
  layout: Layout,
  props: {
    filters: Object,
    news: Object
  },
  data() {
    return {
      query: {
        search: this.filters.search,
        tags: this.filters.tags,
        page: this.filters.page
      },
      tags: [],
      popupAction: '',
      deletedArticle: null,
      isConfirmPopupOpen: false
    }
  },
  watch: {
    query: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('news.index'), pickBy({
            ...this.query,
            tags: this.query.tags ? this.query.tags.map(({name}) => name).join(',') : null
          }), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  mounted() {
    this.asyncFindTags()
  },
  methods: {
    getPermissions(permission) {
      return !!this.$attrs.auth.can[permission]
    },
    asyncFindTags(query = null) {
      axios
        .post(this.$route('tags.list'), {
          term: query,
          type: 'news'
        })
        .then(({data}) => {
          this.tags = data
        })
        .catch(error => {
          console.error(error)
        })
    },
    deleteArticle(article) {
      this.$inertia.delete(`/news/${article.uuid}`)
    },
    getFormattedDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'delete' && this.deletedArticle) {
        this.deleteArticle(this.deletedArticle)
        this.isConfirmPopupOpen = false
      }
    },
    showConfirmPopup(action, item) {
      this.popupAction = action
      this.deletedArticle = item
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
