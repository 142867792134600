<template>
  <section aria-labelledby="activity-title" class="mt-5 xl:mt-3">
    <div class="divide-y divide-gray-200">
      <PanelHeading heading="Upload files" class="pb-4 pt-4" />
      <div class="pt-4 pb-8">
        <label class="block text-[15px] font-medium font-heading text-gray-700">Select files to upload</label>
        <FileUpload
          id="file-upload"
          :multiple="true"
          :description="description"
          @update="files => $emit('update', files)"
        />
        <div v-for="(error, index) in errors" :key="`error-${index}`" class="form-error">
          {{ error }}
        </div>
        <div v-if="files.length > 0" class="py-4">
          <div class="mb-1 text-sm font-medium text-gray-500">
            Uploading... {{ progress ? `${parseInt(progress)}%` : '' }}
          </div>
          <dd class="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
            <ul role="list" class="divide-y divide-gray-200 rounded-md border border-gray-200">
              <li
                v-for="(file, index) in files"
                :key="`uploaded-file-${index}`"
                class="flex items-center justify-between py-3 pl-3 pr-4 text-sm"
              >
                <div class="flex w-0 flex-1 items-center">
                  <PaperClipIcon class="w-5 text-gray-400" />
                  <span class="ml-2 w-0 flex-1 truncate">{{ file.name }}</span>
                </div>
              </li>
            </ul>
          </dd>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import {PaperClipIcon} from '@heroicons/vue/solid'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    PaperClipIcon,
    FileUpload,
    PanelHeading
  },
  props: {
    description: {
      default: 'PDF, TXT, DOC, XLS, XLSX, ZIP, RAR, JPEG, JPG, PNG, GIF up to 20MB',
      type: String
    },
    errors: Object,
    files: Array,
    progress: Number
  },
  emits: ['update']
}
</script>
