<template>
  <div :class="$attrs.class">
    <label v-if="label" :for="id" class="text-sm mb-1 block font-medium text-atc-text">
      {{ label }}<span v-if="required" class="text-red-500">*</span>
    </label>
    <button type="button" class="table-controls-create-btn uppercase font-heading p-0 flex flex-row items-center" @click="open = true">
      <span class="color-box" :style="`background-color:${color}`" />
      Pick a color
    </button>
    <TransitionRoot as="template" :show="open">
      <Dialog as="div" class="cm-dialog">
        <div class="cm-container">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
            <DialogOverlay class="cm-dialog-overlay" />
          </TransitionChild>
          <span class="cm-center-modal" aria-hidden="true">&#8203;</span>
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 sm:scale-95" enter-to="opacity-100 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 sm:scale-100" leave-to="opacity-0  sm:scale-95">
            <div class="cm-content">
              <div class="cm-flex w-full">
                <div class="cm-title-wrapper w-full">
                  <DialogTitle as="h3" class="cm-title">Choose badge color</DialogTitle>
                  <div class="mt-2 justify-center">
                    <ColorPicker
                      theme="light"
                      :color="color"
                      @change-color="changeColor"
                    />
                    <FormInput :id="id" v-model="color" label="HEX / rgba" />
                  </div>
                </div>
              </div>
              <div class="mt-2 flex w-full justify-center">
                <button ref="cancelButtonRef" type="button" class="form-button ml-3" @click="open = false">Close</button>
              </div>
            </div>
          </TransitionChild>
        </div>
      </Dialog>
    </TransitionRoot>

    <div v-if="error" class="text-red-600 text-xs font-light mt-2">{{ error }}</div>
  </div>
</template>

<script>
import { ColorPicker } from 'vue-color-kit'
import 'vue-color-kit/dist/vue-color-kit.css'
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import FormInput from 'Shared/Form/FormInput'

export default {
  components: {
    ColorPicker,
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    FormInput
  },
  inheritAttrs: false,
  props: {
    id: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    modelValue: {
      type: [String, Number],
      default: ''
    },
    error: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    }
  },
  emits: ['colorChanged'],
  data() {
    return {
      color: this.modelValue,
      open: false
    }
  },
  methods:{
    changeColor(color) {
      this.color = `rgba(${color.rgba.r}, ${color.rgba.g}, ${color.rgba.b}, ${color.rgba.a})`
      this.$emit('colorChanged', this.color)
    }
  }
}
</script>
