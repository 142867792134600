<template>
  <OrganizationLayout title="Organization branding" :organization="organization">
    <form class="divide-y-2 divide-gray-200" data-testid="organizations-edit-form" @submit.prevent="submit">
      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Logo" />
        <div class="grid grid-cols-2 gap-y-6 gap-x-4">
          <ImagePreview
            id="logo"
            :error="form.errors[`logo.file`]"
            :image-file="form.logo.file"
            :image-url="form.logo.url"
            @update="handleLogoUpdate"
          />
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
        <button
          v-if="organization && auth.can['organizations.delete']"
          type="button"
          class="form-button"
          data-testid="deleteButton"
          @click="showConfirmPopup"
        >
          Delete
        </button>
        <div class="ml-auto">
          <InertiaLink
            v-if="auth.can['organizations.index']"
            as="button"
            type="button"
            :href="$route('organizations.index')"
            class="form-button"
            data-testid="cancelButton"
          >
            Cancel
          </InertiaLink>
          <button
            type="submit"
            :disabled="form.processing"
            class="ml-3 form-submit-button"
            data-testid="submitButton"
          >
            Save
          </button>
        </div>
      </div>
    </form>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this organization? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </OrganizationLayout>
</template>

<script>
import {
  Link as InertiaLink,
  useForm
} from '@inertiajs/inertia-vue3'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import PanelHeading from 'Shared/PanelHeading'
import OrganizationLayout from 'OrganizationsComponents/OrganizationLayout'
import {XIcon} from '@heroicons/vue/outline'
import ImagePreview from 'Shared/Form/Uploads/ImagePreview'

export default {
  components: {
    ImagePreview,
    XIcon,
    OrganizationLayout,
    InertiaLink,
    VueMultiselect,
    FormCheckbox,
    FormInput,
    ConfirmModal,
    PanelHeading
  },
  layout: Layout,
  props: {
    auth: Object,
    organization: Object
  },
  setup(props) {
    const form = useForm({
      _method: 'PUT',
      logo: {
        url: props.organization?.logo,
        file: null
      }
    })

    return { form }
  },
  data() {
    return {
      isConfirmPopupOpen: false
    }
  },
  methods: {
    submit() {
      this.form
        .post(this.$route('organizations.branding.update', this.organization.uuid), {
          forceFormData: true,
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deleteOrganization() {
      this.$inertia
        .post(this.$route('organizations.destroy', this.organization.uuid), {
          _method: 'DELETE'
        })
    },
    handleLogoUpdate(dataObject) {
      const file = dataObject.data?.file

      if (!file) {
        const url = this.form.logo.url

        delete this.form.logo.file
        delete this.form.logo.url
        URL.revokeObjectURL(url)

        return
      }
      this.form.logo.file = file
      this.form.logo.url = URL.createObjectURL(file)
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteOrganization()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
