<template>
  <span class="cursor-pointer" title="Click to sort by column contents">
    <slot />
    <SortAscendingIcon v-if="sort?.column === name && sort?.order === 'asc'" class="w-4 ml-1 inline" />
    <SortDescendingIcon v-if="sort?.column === name && sort?.order === 'desc'" class="w-4 ml-1 inline" />
  </span>
</template>

<script>
import { SortAscendingIcon, SortDescendingIcon } from '@heroicons/vue/outline'

export default {
  components: {
    SortAscendingIcon,
    SortDescendingIcon
  },
  props: {
    name: String,
    sort: Object
  }
}
</script>
