export const formatDate = date => {
  if (!date) {
    date = new Date()
  } else {
    date = new Date(date)
  }

  return date.toLocaleDateString('en-US', {
    month: '2-digit',
    day: '2-digit',
    year: 'numeric'
  })
}

export const formatTime = date => {
  if (!date) {
    date = new Date()
  } else {
    date = new Date(date)
  }

  return date.toLocaleTimeString('en-US', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit'
  })
}

export const secondsToDays = (
  seconds,
  showDays = true,
  showHours = true,
  showMinutes = true,
  showSeconds = false
) => {
  seconds = Number(seconds)
  const d = Math.floor(seconds / (3600 * 24))
  const h = Math.floor((seconds % (3600 * 24)) / 3600)
  const m = Math.floor((seconds % 3600) / 60)
  const s = Math.floor(seconds % 60)

  const time = [
    showDays && d > 0 ? `${d}d` : null,
    showHours ? `${h}h` : null,
    showMinutes && h === 0 ? `${m}m` : null,
    showSeconds && h === 0 ? `${s}s` : null
  ]
  return time.join(' ')
}

export const minutesToHours = minutes => {
  if (!minutes) {
    return
  }

  const totalMinutes = Math.floor(minutes)
  const hours = Math.floor(totalMinutes / 60)
  const remainingMinutes = totalMinutes % 60

  return `${hours.toString().padStart(2, '0')}:${remainingMinutes.toString().padStart(2, '0')}`
}

export const dateDiffInDays = (dateA, dateB) => {
  const msPerDay = 24 * 60 * 60 * 1000;

  const utc1 = Date.UTC(dateA.getFullYear(), dateA.getMonth(), dateA.getDate())
  const utc2 = Date.UTC(dateB.getFullYear(), dateB.getMonth(), dateB.getDate())

  return Math.floor((utc2 - utc1) / msPerDay)
}

export const formatDiffInDays = date => {
  if (dateDiffInDays(new Date(date), new Date()) === 0) {
    return 'Today'
  }
  return `${dateDiffInDays(new Date(date), new Date())}d ago`
}
