<template>
  <div>
    <InertiaHead title="Cancel reservation" />

    <div class="flex-shrink-0 pt-10 sm:pt-16">
      <InertiaLink class="mt-1 w-60 md:min-w-60" :href="$route('dashboard')">
        <Logo class="fill-black" width="160" height="54" />
      </InertiaLink>
    </div>
    <div class="flex-shrink-0 my-auto py-16 sm:py-32">
      <p class="text-sm font-semibold text-atc-secondary uppercase tracking-wide">Cancelling the reservation</p>

      <div v-if="error">
        <h1 class="mt-2 text-4xl font-extrabold text-atc-graphite tracking-tight sm:text-5xl">Something went wrong</h1>
        <p class="mt-2 text-base text-atc-primary">
          {{ error }}
        </p>
      </div>
      <div v-else-if="success">
        <h1 class="mt-2 text-4xl font-extrabold text-atc-graphite tracking-tight sm:text-5xl">Success</h1>
        <p class="mt-2 text-base text-atc-primary">
          This reservation has been cancelled.
        </p>
      </div>
      <div v-else>
        <h1 class="mt-2 text-4xl font-extrabold text-atc-graphite tracking-tight sm:text-5xl">Are you sure?</h1>
        <p class="mt-2 text-base text-atc-primary">
          Are you sure you want to cancel reservation for {{ reservation.organizationProduct.product.name }}?
        </p>
        <div class="mt-6">
          <button
            class="text-base font-medium text-atc-black hover:text-atc-secondary"
            data-testid="cancelButton"
            @click="cancelReservation"
          >
            Cancel reservation<span aria-hidden="true"> &rarr;</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import LeftLayout from 'Shared/Layouts/LeftLayout'
import Logo from 'Shared/Logo'
import axios from 'axios'

export default {
  components: {
    InertiaLink,
    InertiaHead,
    Logo
  },
  layout: LeftLayout,
  props: {
    error: String,
    reservation: Object,
    token: String
  },
  data() {
    return {
      success: false
    }
  },
  methods: {
    cancelReservation() {
      axios
        .post(this.$route('reservations.change-status', this.reservation.uuid), {
          status: 'rejected',
          token: this.token
        })
        .then(response => {
          if (response.status !== 200) {
            return
          }
          this.success = true
        })
    }
  }
}
</script>
