<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_963_7381)">
      <path d="M8 8.99976C8.79565 8.99976 9.55871 8.68369 10.1213 8.12108C10.6839 7.55847 11 6.79541 11 5.99976C11 5.20411 10.6839 4.44104 10.1213 3.87844C9.55871 3.31583 8.79565 2.99976 8 2.99976C7.20435 2.99976 6.44129 3.31583 5.87868 3.87844C5.31607 4.44104 5 5.20411 5 5.99976C5 6.79541 5.31607 7.55847 5.87868 8.12108C6.44129 8.68369 7.20435 8.99976 8 8.99976Z" stroke="#626263" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
      <path d="M2 15.9998C2.28104 14.5887 3.03191 13.32 4.1254 12.4088C5.21888 11.4975 6.58773 10.9998 8 10.9998C9.41227 10.9998 10.7811 11.4975 11.8746 12.4088C12.9681 13.32 13.719 14.5887 14 15.9998" stroke="#626263" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_963_7381">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
