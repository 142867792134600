<template>
  <div class="pt-6 pb-8">
    <h2 ref="formTop" class="mb-6 text-2xl font-semibold">Get Pricing & Information</h2>
    <FlashMessages class="mb-5" />
    <p class="text-[15px] font-light leading-7">
      Complete the form below to receive your estimated price for your custom {{ product.name }}. If
      you would like additional information, an authorized dealer will contact you shortly. Get
      ready to explore the freedom and independence our all-terrain wheelchair provides.
    </p>
    <form class="mt-6" @submit.prevent="submit">
      <div class="grid grid-cols-1 gap-2 md:grid-cols-2 md:gap-3">
        <div class="mb-2">
          <ConfiguratorFormInput
            v-model="form.client.firstName"
            :error="formatError(form.errors['client.firstName'])"
            label="First name"
            required
            type="text"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormInput
            v-model="form.client.lastName"
            :error="formatError(form.errors['client.lastName'])"
            label="Last name"
            required
            type="text"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormInput
            v-model="form.client.email"
            :error="formatError(form.errors['client.email'])"
            label="Email"
            required
            type="email"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormInput
            v-model="form.client.phone"
            :error="formatError(form.errors['client.phone'])"
            label="Phone number"
            required
            type="text"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormInput
            v-model="form.client.address"
            :error="formatError(form.errors['client.address'])"
            label="Address"
            type="text"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormInput
            v-model="form.client.city"
            :error="formatError(form.errors['client.city'])"
            label="City"
            required
            type="text"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormSelect
            v-if="countries"
            v-model="form.client.country"
            :error="formatError(form.errors['client.country'])"
            :options="countries"
            label="Country"
            option-label="name"
            required
            track-by="iso2"
          />
          <ConfiguratorFormInput
            v-else
            v-model="form.client.country"
            :error="formatError(form.errors['client.country'])"
            label="Country"
            required
            type="text"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormSelect
            v-if="states && form.client.country === 'US'"
            v-model="form.client.state"
            :error="formatError(form.errors['client.state'])"
            :options="states"
            label="State"
            option-label="name"
            required
            track-by="iso2"
          />
          <ConfiguratorFormInput
            v-else
            v-model="form.client.state"
            :error="formatError(form.errors['client.state'])"
            label="State"
            type="text"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormInput
            v-model="form.client.zipCode"
            :error="formatError(form.errors['client.zipCode'])"
            label="Zip code"
            required
            type="text"
          />
        </div>
        <div v-if="autoDealerAssign" class="mb-2">
          <ConfiguratorFormSelect
            v-model="form.dealership"
            :disabled="!!dealership || dealerships?.length === 0"
            :error="formatError(form.errors['dealership'])"
            :options="dealerships"
            label="Dealership"
            option-label="name"
            required
            track-by="uuid"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormSelect
            v-model="form.client.prefmoc"
            :error="formatError(form.errors['client.prefmoc'])"
            :options="contactMethods"
            label="Preferred method of contact"
            required
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormSelect
            v-model="form.client.marketing"
            :error="formatError(form.errors['client.marketing'])"
            :options="marketingTypes"
            label="Where did you hear about Action Trackchair?"
            option-label="label"
            required
            track-by="value"
          />
        </div>
        <div class="mb-2">
          <ConfiguratorFormSelect
            v-model="form.client.consumerType"
            :error="formatError(form.errors['client.consumerType'])"
            :options="consumerTypes"
            label="Consumer type"
            option-label="label"
            required
            track-by="value"
          />
        </div>
      </div>
      <div class="mb-2 mt-2 w-full">
        <label>
          <span class="mb-1 block font-heading text-[15px] font-medium">Note to dealer</span>
          <textarea
            id="description"
            v-model="form.product.description"
            class="w-full border-1 border-gray-700 p-2 font-normal focus:border-atc-secondary focus:ring-atc-secondary"
            cols="30"
            rows="5"
          />
        </label>
        <div v-if="form.errors['client.clientNotes']" class="text-red-600 text-xs font-light mt-2">
          {{ formatError(form.errors['client.clientNotes']) }}
        </div>
      </div>
      <p class="text-xs font-light leading-5 text-gray-700">
        Action Trackchair Builder is an unofficial quote and may be subject to change. By
        submitting the form you agree to receive pricing of your custom Action Trackchair® and have
        an authorized dealer reach out to answer any additional questions you may have.
      </p>
      <div class="mt-6 flex justify-end">
        <button :disabled="form.processing" type="submit" class="form-submit-button">
          <span v-if="form.processing" class="flex items-center">
            <Spinner class="mr-2" :width="5" />Processing
          </span>
          <span v-else>Receive Pricing</span>
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import {useForm} from '@inertiajs/inertia-vue3'
import ConfiguratorFormInput from 'ConfiguratorComponents/ConfiguratorFormInput.vue'
import ConfiguratorFormSelect from 'ConfiguratorComponents/ConfiguratorFormSelect.vue'
import FlashMessages from 'Shared/FlashMessages'
import Spinner from 'Shared/Spinner'
import {getCoordinatesByZipcode, getCountries, getStates} from 'Shared/helpers'
import axios from 'axios'

export default {
  name: 'ConfiguratorForm',
  components: {
    ConfiguratorFormInput,
    ConfiguratorFormSelect,
    FlashMessages,
    Spinner
  },
  props: {
    buildProductData: Object,
    consumerTypes: Array,
    dealership: Object,
    distributors: Array,
    errors: Object,
    marketingTypes: Array,
    price: String,
    product: Object
  },
  data() {
    return {
      form: useForm({
        _method: 'POST',
        product: {
          data: this.product,
          description: null,
          model: this.buildProductData.model,
          joystickMounting: this.buildProductData.joystick,
          extensions: this.buildProductData.extensions
        },
        client: {
          firstName: null,
          lastName: null,
          email: null,
          phone: null,
          address: null,
          city: null,
          country: 'US',
          state: null,
          zipCode: null,
          clientNotes: null,
          beneficiary: null,
          data: null,
          financing: null,
          prefmoc: null,
          lvlofint: null,
          marketing: null,
          consumerType: null
        },
        dealership: this.dealership ? this.dealership.uuid : null,
        captchaResponse: null
      }),
      autoDealerAssign: false,
      dealerships: this.dealership ? [ this.dealership ] : [],
      countries: null,
      states: null,
      contactMethods: ['Phone', 'Email', 'Phone or Email', 'Do NOT contact me'],
      interests: [
        'I am ready to make a purchase',
        'Thank you, I am just shopping',
        'I have some questions'
      ],
      companyInfo: ['Google', 'Advertising', 'Facebook', 'Youtube', 'Referral', 'Other']
    }
  },
  computed: {
    clientCountry() {
      return this.form.client.country
    },
    clientState() {
      return this.form.client.state
    },
    clientZipCode() {
      return this.form.client.zipCode
    }
  },
  watch: {
    buildProductData: function(newVal) {
      this.form.product.model = newVal.model
      this.form.product.joystickMounting = newVal.joystick
      this.form.product.extensions = newVal.extensions
    },
    clientCountry: function(newVal, oldVal) {
      this.clearState(oldVal)
      this.checkAutoDealerAssign()
      this.getClosestDealerships()
    },
    clientState: function() {
      this.checkAutoDealerAssign()
    },
    clientZipCode: function() {
      this.getClosestDealerships()
    },
    autoDealerAssign: function(newVal, oldVal) {
      if (oldVal === true) {
        this.form.clearErrors('client.zipCode')
      }
    }
  },
  mounted() {
    getCountries().then(response => (this.countries = response.data))
    getStates().then(response => (this.states = response.data))
  },
  methods: {
    getCoordinatesByZipcode,
    getCountries,
    getStates,
    async submit() {
      await this.$recaptchaLoaded()
      this.form.captchaResponse = await this.$recaptcha('submit')

      this.form
        .transform(data => {
          data.product.data = {
            uuid: data.product.data.uuid,
            name: data.product.data.name,
            sku: data.product.data.sku,
            price: data.product.data.price,
            media: data.product.data.media
          }
          return data
        })
        .post(this.$route('build.store'), {
          forceFormData: true,
          preserveState: page => Object.keys(page.props.errors).length,
          onSuccess: response => {
            setTimeout(() => {
              this.form.processing = false

              if (response.props.flash.error != null) {
                this.$refs.formTop.scrollIntoView({
                  behavior: 'smooth'
                })
                return
              }

              this.$inertia.get(this.$route('build.index'), {
                status: 'form-submitted'
              })
            }, 1000)
          },
          onError: () => {
            this.$refs.formTop.scrollIntoView({
              behavior: 'smooth'
            })
          }
        })
    },
    clearState(oldCountry) {
      if (oldCountry !== 'US') return
      this.form.client.state = ''
    },
    checkAutoDealerAssign() {
      if (this.dealership) {
        this.autoDealerAssign = false
        return
      }
      if (this.form.client.country !== 'US') {
        this.autoDealerAssign = true
        return
      }

      const distributor = this.getDistributorByState(this.form.client.state)

      this.autoDealerAssign = distributor ? !!distributor.auto_dealer_assignment : true
    },
    getDistributorByState(state) {
      return this.distributors.find(d => d.operating_states.includes(state))
    },
    getClosestDealerships() {
      if (this.dealership || !this.autoDealerAssign || !this.form.client.zipCode) {
        this.dealerships = []
        return
      }

      getCoordinatesByZipcode(this.form.client.zipCode, this.form.client.country)
        .then(result => {
          if (!result) {
            this.form.setError('client.zipCode', 'Enter correct zip code.')
            this.dealerships = []
            return
          }

          const {lat, lng} = result

          axios
            .post(this.$route('dealerships.closest'), {
              latitude: lat,
              longitude: lng
            })
            .then(response => {
              if (response.status === 200) {
                this.dealerships = response.data
                this.form.clearErrors('client.zipCode')
                return
              }
              console.error(response)
            })
            .catch(error => {
              console.error(error)
            })
        })
    },
    formatError(error) {
      if (error) {
        return error.replace('client.', '').replace('product.', '')
      } else {
        return error
      }
    }
  }
}
</script>
