<template>
  <div class="grid sm:grid-cols-4 lg:grid-cols-6 w-full gap-4 flex-wrap lg:flex-nowrap">
    <input :value="modelValue" type="hidden" />
    <StatusBadge
      v-for="(item, index) in statuses"
      :key="index"
      :active="modelValue === item.value"
      :class="{
        'status-badge-wrapper w-full cursor-pointer': true,
        'cursor-not-allowed': !canChangeStatus(item.value),
      }"
      :label="item.label"
      :value="item.value"
      @click="onBadgeClick(item.value)"
    />
  </div>
</template>

<script>
import StatusBadge from 'Shared/Misc/StatusBadge'

export default {
  components: {
    StatusBadge
  },
  props: {
    auth: Object,
    statuses: Array,
    modelValue: String,
    entity: String
  },
  emits: ['update:modelValue'],
  methods: {
    canChangeStatus(status) {
      if ((this.entity === 'reservation'
        && this.auth.can['reservations.update'])
        || (this.entity === 'purchase-intent'
        && this.auth.can['purchase-intents.update'])) {
        return true
      }
      if (status === 'submit-order'
        && this.auth.can['leads.transformToOrder']) {
        return true
      }
      if (status !== 'submit-order'
        && this.auth.can[`${this.entity}s.update.status`]) {
        return true
      }
      if (status === 'delivered'
        && this.modelValue === 'shipped') {
        return true
      }
      if (status === 'registered'
        && (this.modelValue === 'stock' || this.modelValue === 'delivered')) {
        return true
      }
      if (status === 'stock'
        && this.modelValue === 'delivered'
        && (this.auth.role.name === 'dist_manager' || this.auth.role.name === 'dist_employee')) {
        return true
      }
      return false
    },
    onBadgeClick(status) {
      if (this.canChangeStatus(status)) {
        this.$emit('update:modelValue', status)
      }
    }
  }
}
</script>
