<template>
  <div>
    <InertiaHead :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <form class="divide-y-2 divide-gray-200" @submit.prevent="submit">
        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading :heading="title" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="name" v-model="form.name" :error="form.errors.name" class="form-input-edit" label="Name" required />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="price" v-model="form.price" :error="form.errors.price" type="number" step=".01" min="0" class="form-input-edit" label="Price" required />
            </div>

            <div class="col-span-2 sm:col-span-1">
              <FormInput id="sku" v-model="form.sku" :error="form.errors.sku" class="form-input-edit" label="SKU" required />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="preview" v-model="form.preview" :error="form.errors.preview" class="form-input-edit" label="Preview link" />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="bb-number" v-model="form.buildBadge.number" :error="form.errors['buildBadge.number']" class="form-input-edit" label="FDA Certification" />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormColorPicker id="badge-color" label="Badge Color" class="form-input-edit" :model-value="form.buildBadge.color" @color-changed="color => colorChanged(color)" />
            </div>
            <div class="col-span-2">
              <FormTextArea id="description" v-model="form.description" :error="form.errors.description" class="form-textarea-edit" rows="4" maxlength="2500" label="Description" />
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Models" class="pb-2" />
          <p class="text-sm text-atc-graphite mb-2"><span class="text-red-500">*</span> (at least one required)</p>
          <div v-for="(variant, index) in form.variants" :key="`variant-${index}`" class="mb-2 relative">
            <FormInput
              :ref="`variant-${index}`"
              v-model="form.variants[index]"
              class="form-input-edit form-repeater-input"
              :error="form.errors['variants.0'] && index === 0 ? 'The model field is required.' : ''"
              @keydown.enter.prevent="addVariant"
            />
            <button v-if="index !== 0" type="button" class="absolute top-0 right-0 rounded-md p-4" @click="deleteVariant(index)">
              <XIcon class="w-5 ml-auto hover:text-red-500 duration-200" />
            </button>
          </div>
          <button type="button" class="mt-3 table-controls-create-btn uppercase" @click="addVariant">
            <Icon class="table-controls-create-icon" name="plus" /><span>Add model</span>
          </button>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Gallery" />
          <ProductGallery :images="form.imageUrls" :errors="Object.keys(form.errors).filter((k) => k.startsWith('files.')).map(k => form.errors[k])" @delete-image="deleteImage"  @update-gallery="updatePreview" />
        </div>

        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Available extensions" />

          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div v-for="type in extensions" :key="type" class="form-input-edit" :class="type.multiselect ? 'sm:col-span-2' : 'sm:col-span-1'">
              <label class="form-label">{{ type.name }}</label>
              <VueMultiselect
                v-model="rawExtensions[camelCase(type.name)]"
                :options="type.extensions"
                :multiple="true"
                :close-on-select="false"
                placeholder="Start typing to search"
                label="name"
                track-by="uuid"
                class="form-multiselect-edit form-multiselect-edit--big"
              />
            </div>
          </div>
        </div>

        <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
          <button v-if="product" type="button" class="form-button" @click="showConfirmPopup">Delete</button>
          <InertiaLink type="button" :href="$route('products.index')" as="button" class="ml-auto form-button">Cancel</InertiaLink>
          <LoadingButton type="submit" :loading="form.processing" class="ml-3 form-submit-button">Save</LoadingButton>
        </div>
      </form>
    </Panel>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this product? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink,
  useForm
} from '@inertiajs/inertia-vue3'
import {formatPrice} from 'Shared/helpers'
import {XIcon} from '@heroicons/vue/outline'
import {VueDraggableNext} from 'vue-draggable-next'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import FormInput from 'Shared/Form/FormInput'
import FormTextArea from 'Shared/Form/FormTextArea'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import { camelCase } from 'lodash'
import FormColorPicker from 'Shared/Form/FormColorPicker'
import ProductGallery from 'Shared/Form/Uploads/ImageGallery'

export default {
  components: {
    ConfirmModal,
    FileUpload,
    FormColorPicker,
    FormInput,
    FormTextArea,
    Icon,
    InertiaHead,
    InertiaLink,
    LoadingButton,
    Panel,
    PanelHeading,
    ProductGallery,
    VueDraggableNext,
    VueMultiselect,
    XIcon
  },
  layout: Layout,
  props: {
    product: Object,
    extensions: Array
  },
  setup(props) {
    const form = useForm({
      _method: props.product ? 'PUT' : 'POST',
      name: props.product?.name ?? null,
      price: parseFloat(props.product?.price ?? 0).toFixed(2),
      sku: props.product?.sku ?? null,
      description: props.product?.description ?? null,
      variants: props.product?.variants ?? [''],
      preview: props.product?.preview ?? null,
      imageUrls: props.product?.media ?? [],
      files: [],
      extensions: props.product?.extensions ?? [],
      buildBadge: {
        number: props.product?.buildBadge?.number ?? null,
        color: props.product?.buildBadge?.color ?? '#616E52'
      }
    })

    return { form }
  },
  data() {
    return {
      title: `${this.product ? 'Edit' : 'Create'} product`,
      isConfirmPopupOpen: false,
      rawExtensions: []
    }
  },
  mounted() {
    this.sortAvailableExtensions()
  },
  methods: {
    formatPrice,
    camelCase,
    submit() {
      this.mergeSelectedExtensions()

      this.form
        .transform((data) => ({
          ...data,
          variants: this.form.variants.filter((v) => (v))
        }))
        .post(this.product ? this.$route('products.update', this.product.uuid) : this.$route('products.store'), {
          forceFormData: true,
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deleteProduct() {
      this.$inertia
        .post(this.$route('products.destroy', this.product.uuid), {
          _method: 'DELETE'
        })
    },
    addVariant() {
      this.form.variants.push('')
      this.$nextTick(() => {
        this.$refs[`variant-${this.form.variants.length - 1}`][0].focus()
      })
    },
    deleteVariant(index) {
      if (index === 0) return
      this.form.variants.splice(index, 1)
    },
    updatePreview(files) {
      [...files].forEach(file => {
        this.form.files.push(file)
        this.form.imageUrls.push(URL.createObjectURL(file))
      })
    },
    deleteImage(index) {
      const url = this.form.imageUrls[index]
      this.form.files.splice(index, 1)
      this.form.imageUrls.splice(index, 1)
      URL.revokeObjectURL(url)
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteProduct()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    sortAvailableExtensions() {
      this.product?.extensions.forEach(ext => {
        const name = camelCase(ext.type.name)

        this.rawExtensions[name] = [
          ...(this.rawExtensions[name] ? this.rawExtensions[name] : []),
          ext
        ]
      })
    },
    mergeSelectedExtensions() {
      this.form.extensions = []

      Object.entries(this.rawExtensions).forEach(([typeName, type]) => {
        type.forEach(ext => {
          this.form.extensions.push(ext.uuid)
        })
      })
    },
    colorChanged(color){
      this.form.buildBadge.color = color
    }
  }
}
</script>
