<template>
  <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="none">
    <g clip-path="url(#clip0_963_7410)">
      <path d="M0.571289 9.77148L3.69129 13.7829C3.79579 13.9185 3.9294 14.0289 4.08227 14.1059C4.23514 14.1829 4.40335 14.2246 4.57449 14.2279C4.74563 14.2312 4.91532 14.196 5.07104 14.1249C5.22675 14.0538 5.36451 13.9487 5.47415 13.8172L15.4284 1.77148" stroke="#333333" stroke-width="1.25" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
      <clipPath id="clip0_963_7410">
        <rect width="16" height="16" fill="white"/>
      </clipPath>
    </defs>
  </svg>
</template>
