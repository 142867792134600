<template>
  <div>
    <InertiaHead :title="title" />

    <Panel class-name="sm:px-0 lg:px-0">
      <form class="divide-y-2 divide-gray-200" @submit.prevent="submit">
        <div class="px-4 sm:px-6 lg:px-8 pb-7">
          <PanelHeading :heading="title" />
          <div class="grid grid-cols-2 gap-y-6 gap-x-4">
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="name" v-model="form.name" :error="form.errors.name" class="form-input-edit" label="Name" required />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <FormInput id="price" v-model="form.price" :error="form.errors.price" type="number" step=".01" min="0" class="form-input-edit" label="Price" />
            </div>

            <div class="col-span-2 sm:col-span-1">
              <FormInput id="sku" v-model="form.sku" :error="form.errors.sku" class="form-input-edit" label="SKU" required />
            </div>
            <div class="col-span-2 sm:col-span-1">
              <label class="form-label-edit block my-3">Type <span class="text-red-500">*</span></label>
              <VueMultiselect
                v-model="form.type"
                :options="types"
                placeholder="Start typing to search"
                label="name"
                track-by="uuid"
                :class="`form-multiselect-edit ${form.errors.type ? 'form-multiselect-error' : ''}`"
              >
                <template #singleLabel="props">
                  <span>{{ props.option.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="clearSelection" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors.type" class="form-error">The type field is required.</div>
            </div>

            <div class="col-span-2">
              <FormTextArea id="description" v-model="form.description" :error="form.errors.description" class="form-textarea-edit" rows="4" maxlength="2500" label="Description" />
            </div>
          </div>
        </div>
        <div class="sm:px-6 lg:px-8 pb-7">
          <PanelHeading heading="Product preview" />
          <ImagePreview
            id="product-preview"
            :error="form.errors[`preview.file`]"
            :image-file="form.preview.file"
            :image-url="form.preview.url"
            @update="handlePreviewUpdate"
          />
        </div>
        <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
          <button v-if="part" type="button" class="form-button" @click="showConfirmPopup">Delete</button>
          <InertiaLink type="button" :href="$route('parts.index')" as="button" class="ml-auto form-button">Cancel</InertiaLink>
          <LoadingButton type="submit" :loading="form.processing" class="ml-3 form-submit-button">Save</LoadingButton>
        </div>
      </form>
    </Panel>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this part? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink,
  useForm
} from '@inertiajs/inertia-vue3'
import {XIcon} from '@heroicons/vue/outline'
import {VueDraggableNext} from 'vue-draggable-next'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import FormTextArea from 'Shared/Form/FormTextArea'
import FileUpload from 'Shared/Form/Uploads/FileUpload'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import ImagePreview from 'Shared/Form/Uploads/ImagePreview'

export default {
  components: {
    ImagePreview,
    InertiaHead,
    InertiaLink,
    XIcon,
    VueDraggableNext,
    VueMultiselect,
    FormCheckbox,
    FormInput,
    FormTextArea,
    FileUpload,
    LoadingButton,
    ConfirmModal,
    Panel,
    PanelHeading
  },
  layout: Layout,
  props: {
    part: Object,
    types: Array
  },
  setup(props) {
    const form = useForm({
      _method: props.part ? 'PUT' : 'POST',
      name: props.part?.name ?? null,
      price: parseFloat(props.part?.price ?? 0).toFixed(2),
      sku: props.part?.sku ?? null,
      type: props.part?.type ?? null,
      description: props.part?.description ?? null,
      preview: {
        url: props.part?.preview,
        file: null
      }
    })

    return { form }
  },
  data() {
    return {
      title: `${this.part ? 'Edit' : 'Create'} part`,
      isConfirmPopupOpen: false
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          return data.price === '' ? { ...data, price: 0 } : data
        })
        .post(this.part ? this.$route('parts.update', this.part.uuid) : this.$route('parts.store'), {
          forceFormData: true,
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deletePart() {
      this.$inertia
        .post(this.$route('parts.destroy', this.part.uuid), {
          _method: 'DELETE'
        })
    },
    handlePreviewUpdate(dataObject) {
      const file = dataObject.data?.file

      if (!file) {
        const url = this.form.preview.url

        delete this.form.preview.file
        delete this.form.preview.url
        URL.revokeObjectURL(url)

        return
      }
      this.form.preview.file = file
      this.form.preview.url = URL.createObjectURL(file)
    },
    clearSelection() {
      this.form.type = null
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deletePart()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
