<template>
  <div>
    <InertiaHead title="Organizations" />

    <Panel data-testid="organizations-panel">
      <div class="flex justify-between">
        <PanelHeading :tabs="tabs" />
        <PanelActions
          :create="$route('organizations.create')"
          :widget="false"
          create-permission="organizations.create"
          text="organization"
        />
      </div>
      <div class="pb-12">
        <div class="flex pb-5">
          <FormInput id="search" v-model="query.search" class="form-input-edit ml-auto w-64" placeholder="Search" />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">
                <SortingColumn name="name" :sort="query.sort" @click="sortBy('name')">Name</SortingColumn>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn name="address->street" :sort="query.sort" @click="sortBy('address->street')">Address</SortingColumn>
              </th>
              <th scope="col" class="table-header">Phone</th>
              <th scope="col" class="table-header">Email</th>
              <th scope="col" class="table-header w-16" />
            </tr>
          </thead>
          <tbody class="table-body">
            <InertiaLink
              v-for="organization in organizations.data"
              :key="organization.uuid"
              as="tr"
              :href="$route('organizations.edit', organization.uuid)"
              class="table-row cursor-pointer"
            >
              <td class="table-cell truncate">{{ organization.name }}</td>
              <td class="table-cell truncate">
                <a
                  :href="`https://maps.google.com/?q=${formatAddress(organization.address)}`"
                  target="_blank"
                  @click.stop
                >
                  {{ formatAddress(organization.address) }}
                </a>
              </td>
              <td class="table-cell truncate">
                <a :href="`tel:${organization.phone_number}`" @click.stop>{{ organization.phone_number ?? '—' }}</a>
              </td>
              <td class="table-cell truncate">
                <a :href="`mailto:${organization.email}`" @click.stop>{{ organization.email ?? '—' }}</a>
              </td>
              <td class="table-cell truncate">
                <span title="Edit"><PencilIcon class="w-4 ml-auto" /></span>
              </td>
            </InertiaLink>

            <tr v-if="organizations.data.length === 0">
              <td class="table-cell text-center" colspan="5">No organizations found.</td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="organizations.links"
          class="flex items-center justify-center mt-5"
          :current-page="organizations.current_page"
          @change-page="key => query.page = key"
        />
      </div>
    </Panel>
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import {formatAddress} from 'Shared/helpers'
import {PencilIcon} from '@heroicons/vue/outline'
import FormInput from 'Shared/Form/FormInput'
import SortingColumn from 'Shared/SortingColumn'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import {pickBy, throttle} from 'lodash'

export default {
  components: {
    InertiaHead,
    InertiaLink,
    PencilIcon,
    FormInput,
    SortingColumn,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading
  },
  layout: Layout,
  props: {
    auth: Object,
    filters: Object,
    organizations: Object
  },
  data() {
    return {
      tabs: [
        { url: this.$route('organizations.index'), label: 'Organizations' },
        { url: this.$route('organization-products.index'), label: 'Rental Equipment' }
      ],
      query: {
        search: this.filters.search,
        sort: {
          column: this.filters.sort?.column,
          order: this.filters.sort?.order
        },
        page: this.filters.page
      }
    }
  },
  watch: {
    query: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('organizations.index'), pickBy(this.query), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  methods: {
    formatAddress,
    sortBy(column) {
      this.query.sort = {
        column: column,
        order: this.query.sort.column === column ?
          (this.query.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
    }
  }
}
</script>
