<template>
  <div>
    <label :for="id" :class="{relative: true, 'cursor-pointer': !disabled}">
      <div
        class="flex justify-center px-6 pt-5 pb-6 mt-2 border-2 border-gray-300 border-dashed rounded-md space-y-1"
        @drop.prevent="drop"
        @dragenter.prevent
        @dragover.prevent
      >
        <div class="space-y-1 text-center">
          <svg class="mx-auto h-12 w-12 text-gray-400" stroke="currentColor" fill="none" viewBox="0 0 48 48" aria-hidden="true">
            <path d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
          </svg>
          <span
            :class="{
              'block text-center text-sm font-medium text-gray-400': true,
              'text-atc-secondary hover:text-atc-secondary': !disabled
            }"
          >
            Upload files or drag and drop
          </span>
          <p v-if="description" class="text-xs text-gray-500">{{ description }}</p>
        </div>
      </div>
    </label>
    <input
      :id="id"
      :accept="acceptTypes"
      :disabled="disabled"
      :multiple="multiple"
      class="sr-only hidden"
      name="file-upload"
      type="file"
      @change="change"
      @click="resetTargetValue"
    />
  </div>
</template>

<script>
export default {
  props: {
    id: {
      type: String,
      required: true
    },
    description: String,
    modelValue: {
      type: File
    },
    multiple: {
      type: Boolean,
      default: false
    },
    acceptTypes: String,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  emits: ['update'],
  methods: {
    drop(event) {
      this.addFiles(event.dataTransfer.files)
    },
    change(event) {
      this.addFiles(event.target.files)
    },
    addFiles(files) {
      this.$emit('update', files)
    },
    resetTargetValue(event) {
      event.target.value = ''
    }
  }
}
</script>
