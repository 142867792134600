<template>
  <Panel class-name="sm:px-0 lg:px-0">
    <InertiaHead :title="title" />
    <form class="divide-y-2 divide-gray-200" data-testid="purchase-intent-edit-form" @submit.prevent="submit">
      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading :heading="title" />
        <StatusSelector
          v-model="form.status"
          :auth="auth"
          :statuses="statuses"
          class="lg:!grid-cols-4"
          entity="purchase-intent"
        />
      </div>
      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Client's aswers" />
        <div class="grid gap-y-6">
          <component
            :is="inputComponents[item.type]"
            v-for="(item, index) in purchaseIntent.data"
            :id="`question-${index}`"
            :key="`question-${index}`"
            v-model="form.questions[index].answer"
            :class="`form-${item.type}-edit`"
            :error="form.errors[`questions.${index}.answer`] ?? ''"
            :label="item.question"
            disabled
          />
        </div>
      </div>

      <div
        v-if="['admin', 'dist_manager', 'dist_employee'].includes(auth.role.name)"
        class="px-4 sm:px-6 lg:px-8 pb-7 grid gap-y-6"
      >
        <div v-if="auth.role.name === 'admin'" class="pt-4">
          <FormLabel id="distributor" text="Distributor" class="mt-3 mb-3" />
          <VueMultiselect
            id="distributor"
            v-model="form.distributor"
            :options="distributors.map(dist => dist.uuid)"
            :custom-label="option => distributors.find(dist => dist.uuid === option).name"
            :class="`form-multiselect-edit required ${
              form.errors['distributor'] ? 'form-multiselect-error' : ''
            }`"
            :show-labels="false"
          >
            <template #singleLabel="props">
              <span>{{ distributors.find(dist => dist.uuid === props.option)?.name }}</span>
            </template>
          </VueMultiselect>
          <div v-if="form.errors['distributor']" class="text-red-500 text-xs font-light mt-2">
            {{ form.errors['distributor'] }}
          </div>
        </div>
        <div v-if="['admin', 'dist_manager', 'dist_employee'].includes(auth.role.name)">
          <FormLabel id="dealership" text="Dealership" class="mt-3 mb-3" />
          <VueMultiselect
            id="dealership"
            v-model="form.dealership"
            :options="dealerships.map(dealer => dealer.uuid)"
            :custom-label="option => dealerships.find(dealer => dealer.uuid === option).name"
            :class="`form-multiselect-edit ${
              form.errors['dealership'] ? 'form-multiselect-error' : ''
            }`"
            :show-labels="false"
          >
            <template #singleLabel="props">
              <span>{{ dealerships.find(dealer => dealer.uuid === props.option)?.name }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="e => reset(e, 'dealership')" />
            </template>
          </VueMultiselect>
          <div v-if="form.errors['dealership']" class="text-red-500 text-xs font-light mt-2">
            {{ form.errors['dealership'] }}
          </div>
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
        <button
          v-if="auth.can['purchase-intents.delete']"
          type="button"
          class="form-button"
          data-testid="deleteButton"
          @click="showConfirmPopup"
        >
          Delete
        </button>
        <div class="ml-auto">
          <InertiaLink
            v-if="auth.can['purchase-intents.index']"
            as="button"
            type="button"
            :href="$route('purchase-intents.index')"
            class="form-button"
            data-testid="cancelButton"
          >
            Cancel
          </InertiaLink>
          <button
            type="submit"
            :disabled="form.processing"
            class="ml-3 form-submit-button"
            data-testid="submitButton"
          >
            Save
          </button>
        </div>
      </div>
      <ConfirmModal
        :open="isConfirmPopupOpen"
        text="Are you sure you want to delete this purchase intent? This action cannot be undone."
        button-label="Delete"
        @on-close="closeConfirmPopup"
        @on-confirm="confirmAction"
      />
    </form>
  </Panel>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink,
  useForm
} from '@inertiajs/inertia-vue3'
import {shallowRef} from 'vue'
import FormInput from 'Shared/Form/FormInput'
import FormLabel from 'Shared/Form/FormLabel'
import FormRange from 'Shared/Form/FormRange'
import FormTextArea from 'Shared/Form/FormTextArea'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import StatusSelector from 'Shared/Misc/StatusSelector'
import PanelHeading from 'Shared/PanelHeading'
import Panel from 'Shared/Panel'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  components: {
    StatusSelector,
    InertiaHead,
    InertiaLink,
    VueMultiselect,
    FormInput,
    FormLabel,
    FormRange,
    ConfirmModal,
    PanelHeading,
    Panel
  },
  layout: Layout,
  props: {
    auth: Object,
    purchaseIntent: Object,
    distributors: Array,
    dealerships: Array,
    statuses: Array
  },
  setup(props) {
    const form = useForm({
      _method: 'PUT',
      status: props.purchaseIntent.status,
      questions: props.purchaseIntent.data.map((item) => ({
        question: item.question,
        answer: item.answer,
        type: item.type
      })),
      distributor: props.purchaseIntent.distributor.uuid,
      dealership: props.purchaseIntent.dealership?.uuid
    })

    return { form }
  },
  data() {
    return {
      title: 'Purchase Intent details',
      isConfirmPopupOpen: false,
      inputComponents: {
        input: shallowRef(FormInput),
        range: shallowRef(FormRange),
        textarea: shallowRef(FormTextArea)
      }
    }
  },
  methods: {
    submit() {
      this.form
        .transform((data) => {
          data.data = data.questions
          return data
        })
        .post(this.$route('purchase-intents.update', this.purchaseIntent.uuid), {
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deletePurchaseIntent() {
      this.$inertia
        .post(this.$route('purchase-intents.destroy', this.purchaseIntent.uuid), {
          _method: 'DELETE'
        })
    },
    customLabel(options) {
      return options.name ?? options.email
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deletePurchaseIntent()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    reset(attribute) {
      this.form[attribute] = null
    }
  }
}
</script>
