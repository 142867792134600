<template>
  <div>
    <div id="dropdown" />
    <div class="md:flex md:flex-col">
      <div class="md:flex md:flex-col md:h-screen">
        <TopMenu
          :confirmation="confirmation"
          @on-confirm="$emit('onConfirm', $event)"
        />
        <div class="md:flex md:flex-grow md:overflow-hidden">
          <div class="pr-4 pb-8 md:flex-1 md:p-12 md:pl-0 md:pt-0 md:overflow-y-auto panel-wrapper-padding" scroll-region>
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopMenu from 'Shared/Navigation/TopMenu'

export default {
  components: {
    TopMenu
  },
  props: {
    auth: Object,
    confirmation: Boolean
  }
}
</script>
