<template>
  <div>
    <InertiaHead title="Error - Access denied" />

    <div class="flex-shrink-0 pt-10 sm:pt-16">
      <InertiaLink class="mt-1 w-60 md:min-w-60" :href="$route('dashboard')">
        <Logo class="fill-black" width="160" height="54" />
      </InertiaLink>
    </div>
    <div class="flex-shrink-0 my-auto py-16 sm:py-32">
      <p class="text-sm font-semibold text-atc-secondary uppercase tracking-wide">403 error</p>
      <h1 class="mt-2 text-4xl font-extrabold text-atc-graphite tracking-tight sm:text-5xl">Access denied</h1>
      <p class="mt-2 text-base text-atc-primary">You don't have access to this page.</p>
      <div class="mt-6">
        <InertiaLink :href="$route('dashboard')" class="text-base font-medium text-atc-black hover:text-atc-secondary">
          Go back home<span aria-hidden="true"> &rarr;</span>
        </InertiaLink>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import Logo from 'Shared/Logo'
import LeftLayout from 'Shared/Layouts/LeftLayout'

export default {
  components: {
    InertiaHead,
    InertiaLink,
    Logo
  },
  layout: LeftLayout
}
</script>
