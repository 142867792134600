<template>
  <Popover>
    <header class="border-b-1 border-[#e7e7e7] bg-atc-white">
      <nav class="flex w-full items-center px-4 sm:px-6 lg:px-8" aria-label="Top">
        <div class="mt-1 w-60 md:min-w-60">
          <Logo class="fill-black" width="160" height="54" />
        </div>
        <div class="flex w-full items-center justify-between py-4 px-8">
          <div class="menu-items">
            <component
              :is="confirmation || item.target === '_blank' ? 'a' : 'InertiaLink'"
              v-for="item in topMenuItems"
              :key="item"
              :class="{
                'menu-item': true,
                'navigation-active text-atc-success': isTabActive(item)
              }"
              :href="item.url"
              :target="item.target"
              @click="(e) => onLinkClick(e, item)"
            >
              {{ item.label }}
            </component>
          </div>
          <dropdown class="mt-1 hidden lg:block" placement="bottom-end">
            <template #default>
              <div class="group flex cursor-pointer select-none items-center">
                <div
                  class="mr-3 inline-flex h-10 w-10 overflow-hidden rounded-full border-1 border-[#e7e7e7]"
                >
                  <img
                    :src="$page.props.auth.user.photo ?? '/assets/images/test-avatar.png'"
                    alt="User avatar"
                    class="ml-[50%] max-w-none translate-x-[-50%]"
                  />
                </div>
                <Icon name="pointer" />
              </div>
            </template>
            <template #dropdown>
              <div class="w-48 mt-2 rounded bg-white py-2 text-sm text-atc-text shadow-xl">
                <span class="block px-6 py-2 text-lg text-atc-black">
                  {{ firstName }} {{ lastName }}
                </span>
                <span class="mb-2 block px-6 py-2 capitalize text-atc-secondary">
                  {{ userRole }}
                </span>
                <hr class="mx-4 border-atc-black py-2" />
                <component
                  v-for="item in dropdownMenuItems"
                  :is="confirmation ? 'a' : 'InertiaLink'"
                  class="block px-6 py-2 hover:bg-atc-success hover:text-white"
                  :href="item.url"
                  @click.prevent="$emit('on-confirm', $event)"
                >
                  {{ item.label }}
                </component>
                <InertiaLink
                  class="block w-full px-6 py-2 text-left hover:bg-atc-success hover:text-white"
                  :href="$route('auth.logout')"
                  method="delete"
                  as="button"
                >
                  Logout
                </InertiaLink>
              </div>
            </template>
          </dropdown>
        </div>
        <div class="-my-2 -mr-2 lg:hidden">
          <PopoverButton class="menu-button">
            <span class="sr-only">Open menu</span>
            <MenuIcon class="h-6 w-6" aria-hidden="true" />
          </PopoverButton>
        </div>

        <transition
          enter-active-class="duration-200 ease-out"
          enter-from-class="opacity-0 scale-95"
          enter-to-class="opacity-100 scale-100"
          leave-active-class="duration-100 ease-in"
          leave-from-class="opacity-100 scale-100"
          leave-to-class="opacity-0 scale-95"
        >
          <PopoverPanel
            focus
            class="absolute inset-x-0 top-0 z-10 origin-top-right transform p-2 transition lg:hidden"
            @click="panelClicked"
          >
            <div
              class="divide-y-2 divide-gray-50 rounded-none bg-white shadow-lg ring-1 ring-black ring-opacity-5"
            >
              <div class="px-5 pt-5 pb-6">
                <div class="flex items-center justify-between">
                  <InertiaLink class="mt-1 w-60 md:min-w-60" :href="$route('dashboard')">
                    <Logo class="fill-black" width="160" height="54" />
                  </InertiaLink>
                  <div class="-mr-2">
                    <PopoverButton id="closebtn" class="menu-button">
                      <span class="sr-only">Close menu</span>
                      <XIcon class="h-6 w-6" aria-hidden="true" />
                    </PopoverButton>
                  </div>
                </div>
                <div class="mt-6">
                  <nav class="grid gap-y-8 text-atc-black">
                    <component
                      :is="confirmation || item.target === '_blank' ? 'a' : 'InertiaLink'"
                      v-for="item in topMenuItems"
                      :key="item"
                      :class="{
                        'font-bold uppercase transition delay-150 ease-in-out hover:text-atc-success': true,
                        'navigation-active text-atc-success': isTabActive(item)
                      }"
                      :href="item.url"
                      :target="item.target"
                      @click="(e) => onLinkClick(e, item)"
                    >
                      {{ item.label }}
                    </component>
                    <hr />
                    <div class="mb-4 flex flex-wrap items-center">
                      <img
                        :src="$page.props.auth.user.photo ?? '/assets/images/test-avatar.png'"
                        alt="User avatar"
                        class="mx-3 h-10 w-10 rounded-full border-1 border-[#e7e7e7]"
                      />
                      <span class="block px-6 py-2 text-atc-black">
                        {{ firstName }} {{ lastName }}
                      </span>
                      <span class="block px-6 py-2 capitalize text-atc-secondary">
                        {{ userRole }}
                      </span>
                    </div>
                    <div>
                      <component
                        v-for="item in dropdownMenuItems"
                        :is="confirmation ? 'a' : 'InertiaLink'"
                        class="block px-6 py-2 hover:bg-atc-success hover:text-white"
                        :href="item.url"
                        @click.prevent="$emit('on-confirm', $event)"
                      >
                        {{ item.label }}
                      </component>
                      <InertiaLink
                        class="block w-full px-6 py-2 text-left hover:bg-atc-success hover:text-white"
                        :href="$route('auth.logout')"
                        method="delete"
                        as="button"
                      >
                        Logout
                      </InertiaLink>
                    </div>
                  </nav>
                </div>
              </div>
            </div>
          </PopoverPanel>
        </transition>
      </nav>
    </header>
  </Popover>
</template>

<script>
import {Popover, PopoverButton, PopoverPanel} from '@headlessui/vue'
import {MenuIcon, XIcon} from '@heroicons/vue/outline'
import {Link as InertiaLink} from '@inertiajs/inertia-vue3'
import Dropdown from 'Shared/Dropdown'
import Logo from 'Shared/Logo'
import Icon from 'Shared/Icon'

export default {
  components: {
    InertiaLink,
    Dropdown,
    Logo,
    Icon,
    XIcon,
    MenuIcon,
    PopoverButton,
    Popover,
    PopoverPanel
  },
  props: {
    confirmation: Boolean
  },
  emits: ['on-confirm', 'on-confirm-external'],
  data() {
    const auth = this.$page.props.auth

    return {
      firstName: auth.user.firstname,
      lastName: auth.user.lastname,
      userRole: auth.role.name.replace('_', ' '),
      topMenuItems: [
        {label: 'Dashboard', url: this.$route('dashboard')},

        ...(auth.can['statistics.orders.index']
          ? [{label: 'Reports', url: this.$route('statistics.orders.index')}]
          : (auth.can['statistics.booking.index']
            ? [{label: 'Reports', url: this.$route('statistics.booking.index')}]
            : [])),

        ...(auth.can['leads.index']
          ? [{label: 'Leads', url: this.$route('leads.index')}]
          : []),

        ...(auth.can['orders.index']
          ? [{label: 'Orders', url: this.$route('orders.index')}]
          : []),

        ...(auth.can['reservations.index']
          ? [{label: 'Reservations', url: this.$route('reservations.index')}]
          : []),

        ...(auth.can['payments.index']
          ? [{label: 'Payments', url: this.$route('payments.index')}]
          : []),

        ...(auth.can['purchase-intents.index']
          ? [{label: 'Purchase Intents', url: this.$route('purchase-intents.index')}]
          : []),

        ...(auth.can['news.index']
          ? [{label: 'Support', url: this.$route('news.index')}]
          : []),

        ...(auth.role.name !== 'client'
          ? [{
            label: 'Marketing',
            url: 'https://www.dropbox.com/scl/fo/lss68qeuhs43utj915xma/h?dl=0&rlkey=0fzonz6adrh7cqjk36jbu6aol',
            target: '_blank'
          }] : []),
      ],
      dropdownMenuItems: [
        ...(auth.can['users.update']
          ? [{label: 'Profile', url: this.$route('users.edit', auth.user.uuid)}]
          : []),

        ...(auth.can['users.index']
          ? [{label: 'Users', url: this.$route('users.index')}]
          : []),

        ...(auth.can['distributors.index']
          ? [{label: 'Distributors', url: this.$route('distributors.index')}]
          : []),
        ...(!auth.can['distributors.index'] &&
        auth.can['distributors.view'] &&
        auth.user.distributor
          ? [{label: 'Distributor', url: this.$route('distributors.edit', auth.user.distributor.uuid)}]
          : []),

        ...(auth.can['dealerships.index']
          ? [{label: 'Dealerships', url: this.$route('dealerships.index')}]
          : []),
        ...(!auth.can['dealerships.index'] &&
        auth.can['dealerships.view'] &&
        auth.user.distributor
          ? [{label: 'Dealership', url: this.$route('dealerships.edit', auth.user.dealership.uuid)}]
          : []),

        ...(auth.can['organizations.index']
          ? [{label: 'Organizations', url: this.$route('organizations.index')}]
          : []),
        ...(!auth.can['organizations.index'] &&
        auth.can['organizations.view'] &&
        auth.user.organization
          ? [{ label: 'Organization', url: this.$route('organizations.edit', auth.user.organization.uuid)}]
          : []),

        ...(!auth.can['organization-products.view.any'] &&
        auth.can['organization-products.index'] &&
        auth.user.organization
          ? [{label: 'Rental Equipment', url: this.$route('organization-products.index')}]
          : []),

        ...(auth.can['products.index']
          ? [{label: 'Products', url: this.$route('products.index')}]
          : [])

        // ...(auth.can['settings.index']
        //   ? [{label: 'Settings', url: this.$route('settings.index')}]
        //   : [])
      ]
    }
  },
  methods: {
    onLinkClick(event, item) {
      if (item.target === '_blank') {
        return
      }
      event.preventDefault()
      this.$emit('on-confirm', event)
    },
    panelClicked() {
      document.getElementById('closebtn').click()
    },
    isTabActive(item) {
      const currentUrl = this.$route().t.url + this.$page.url
      return (
        currentUrl === item.url ||
        currentUrl === item.url + '/' ||
        (item.label !== 'Dashboard' && currentUrl.startsWith(item.url)) ||
        (item.label === 'Orders' && currentUrl.startsWith(this.$route().t.url + '/parts-orders')) ||
        (item.label === 'Organizations' && currentUrl.startsWith(this.$route().t.url + '/organization-products'))
      )
    }
  }
}
</script>
