<template>
  <ProfileLayout title="Documents" :user="user">
    <Search v-model="query.filter.search" placeholder="Type to search attachments" />

    <main class="flex-1">
      <div class="xl:grid xl:grid-cols-3">
        <div class="px-6 lg:px-8 xl:col-span-2 xl:border-r xl:border-gray-200">
          <DocumentsSection :attachments="attachments" :record="user" entity="user" />
        </div>

        <aside class="px-6 lg:px-8 xl:col-span-1">
          <section aria-labelledby="activity-title" class="mt-6 lg:mt-8">
            <div class="space-y-6 pb-16">
              <div>
                <div class="aspect-w-10 aspect-h-7 block w-full overflow-hidden rounded-lg">
                  <img :src="attachment.reference" alt="" class="object-cover" />
                </div>
                <div class="mt-4 flex items-start justify-between">
                  <div class="w-full">
                    <h2 class="break-words text-lg font-medium text-gray-900">
                      <span class="sr-only">Details for </span>{{ attachment.name }}
                    </h2>
                    <p class="text-sm font-medium text-gray-500">
                      {{ formatBytes(attachment.size) }}
                    </p>
                  </div>
                </div>
              </div>

              <div>
                <h3 class="font-medium text-gray-900">Information</h3>
                <dl class="mt-2 divide-y divide-gray-200 border-t border-b border-gray-200">
                  <div class="flex justify-between py-3 text-sm font-medium">
                    <dt class="text-gray-500">Created</dt>
                    <dd class="text-gray-900">{{ formatDate(attachment.created_at) }}</dd>
                  </div>
                  <div class="py-3 text-sm font-medium">
                    <div class="flex justify-between">
                      <dt class="flex items-center text-gray-500">Expiration Date</dt>
                      <dd class="text-gray-900">
                        <Datepicker
                          v-model="form.expired_at"
                          model-type="dd.mm.yyyy"
                          placeholder="Select the date"
                          :auto-apply="true"
                          :enable-time-picker="false"
                          class="w-40"
                        />
                      </dd>
                    </div>
                    <div v-if="form.errors.expired_at" class="mt-2 text-xs font-light text-red-600">
                      {{ form.errors.expired_at }}
                    </div>
                  </div>
                </dl>
              </div>
              <button
                v-if="form.expired_at !== attachment.expired_at"
                type="button"
                class="form-submit-button w-full flex-1"
                @click="saveChanges"
              >
                Save
              </button>
              <div class="flex">
                <button
                  type="button"
                  class="form-submit-button mr-3 flex-1"
                  @click="downloadAttachment"
                >
                  Download
                </button>
                <button
                  type="button"
                  class="form-button flex-1"
                  @click="showConfirmPopup('deleteAttachment')"
                >
                  Delete
                </button>
              </div>
            </div>
          </section>
        </aside>
      </div>
    </main>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      :text="popupText"
      button-label="Confirm"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </ProfileLayout>
</template>

<script>
import axios from 'axios'
import {Inertia} from '@inertiajs/inertia'
import {useForm} from '@inertiajs/inertia-vue3'
import {formatBytes} from 'Shared/helpers'
import {throttle} from 'lodash'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import DocumentsSection from 'MediaComponents/DocumentsSection'
import Layout from 'Shared/Layouts/Layout'
import ProfileLayout from 'UsersComponents/ProfileLayout'
import RecordDocumentsList from 'Shared/LeadsAndOrders/RecordDocumentsList'
import Search from 'Shared/Misc/Search'
import UploadSection from 'MediaComponents/UploadSection'

export default {
  components: {
    ConfirmModal,
    Datepicker,
    DocumentsSection,
    ProfileLayout,
    RecordDocumentsList,
    Search,
    UploadSection
  },
  layout: Layout,
  props: {
    user: Object,
    attachment: Object,
    attachments: Array
  },
  setup(props) {
    const form = useForm({
      _method: 'put',
      expired_at: props.attachment.expired_at
    })

    return {form}
  },
  data() {
    return {
      isConfirmPopupOpen: false,
      popupAction: '',
      popupText: '',
      query: {
        page: this.attachments.current_page,
        filter: {}
      }
    }
  },
  watch: {
    query: {
      handler: throttle(function (newQuery) {
        Inertia.get(
          this.$route('users.documents.show', [this.user.uuid, this.attachment.uuid]),
          newQuery,
          {
            preserveState: true,
            preserveScroll: true,
            replace: true
          }
        )
      }, 150),
      deep: true
    }
  },
  methods: {
    formatBytes,
    formatDate(date) {
      if (!date) {
        return '—'
      }
      return new Date(date).toLocaleString('en-US', {dateStyle: 'long'})
    },
    downloadAttachment() {
      axios
        .post(
          this.$route('media.download', this.attachment.uuid),
          {},
          {
            responseType: 'blob'
          }
        )
        .then(response => {
          const link = document.createElement('a')

          link.href = window.URL.createObjectURL(response.data)
          link.download = this.attachment.name
          link.click()
          setTimeout(() => {
            window.URL.revokeObjectURL(response.data)
          }, 100)
        })
        .catch(error => {
          console.error(error.response)
        })
    },
    saveChanges() {
      this.form.post(this.$route('media.update', this.attachment.uuid), {
        preserveState: page => Object.keys(page.props.errors).length
      })
    },
    showConfirmPopup(action) {
      this.popupAction = action
      if (this.popupAction === 'deleteAttachment') {
        this.popupText =
          'Are you sure you want to delete this attachment? This action cannot be undone.'
      }
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    deleteAttachment() {
      this.$inertia.post(this.$route('media.destroy', this.attachment.uuid), {
        _method: 'DELETE'
      })
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'deleteAttachment') {
        this.deleteAttachment()
      }
      if (this.popupAction === 'redirect') {
        this.$inertia.visit(this.redirectLink)
      }
    }
  }
}
</script>
