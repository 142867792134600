<template>
  <div class="text-atc-text p-16 text-center">
    Processing...
  </div>
</template>

<script>
export default {
  props: {
    status: String,
    message: String
  },
  mounted() {
    window.opener.postMessage({
      status: this.status,
      message: this.message
    }, window.origin)
    window.close()
  }
}
</script>
