<template>
  <ConfiguratorLayout
    :menu-items="extensionTypes"
    :visible-section="visibleSection"
    class="bg-atc-white"
    :dealership-uuid="dealership?.uuid"
    @scroll-to="scrollToSection"
  >
    <div class="flex flex-wrap justify-center lg:justify-end">
      <div class="left-0 top-16 pt-5 lg:fixed lg:block lg:w-[55%] lg:pl-5 lg:pr-10 lg:pt-10">
        <ConfiguratorProductPreview
          :images="images"
          :show-product-price="showProductPrice"
          :product-name="product.name"
          :price="formattedPrice"
        />
      </div>
      <div class="relative min-h-screen w-full bg-atc-gray pt-0 pb-9 lg:w-[45%] lg:pt-9">
        <div class="relative">
          <div class="px-5 pt-4 lg:pt-0">
            <div ref="chair-model" class="border-b-2 border-gray-300 pb-8">
              <div class="flex items-center justify-between">
                <h2 class="mb-6 font-heading text-2xl">Select seat size</h2>
                <a
                  class="mb-6 hidden px-5 text-sm font-thin underline lg:block"
                  :href="product.preview"
                  target="_blank"
                  >View product page <LinkIcon class="ml-1 inline-block w-4"
                /></a>
              </div>
              <div v-if="product.variants">
                <ConfiguratorFormSelect
                  v-model="selectedModel"
                  :options="product.variants"
                  label="Chair size/model"
                  required
                />
              </div>
              <div class="mt-6">
                <p class="mb-3 block font-heading text-sm text-[15px] font-medium" for="mount">
                  Joystick mount
                </p>
                <label class="mb-2 block" for="left">
                  <input
                    id="left"
                    v-model="selectedJoystick"
                    type="radio"
                    name="joystick"
                    value="left"
                    class="text-atc-secondary focus:border-atc-secondary focus:ring-atc-secondary"
                  />
                  <span class="ml-1 inline-block font-normal">Left mounted</span>
                </label>
                <label for="right">
                  <input
                    id="right"
                    v-model="selectedJoystick"
                    checked
                    type="radio"
                    name="joystick"
                    value="right"
                    class="text-atc-secondary focus:border-atc-secondary focus:ring-atc-secondary"
                  />
                  <span class="ml-1 inline-block font-normal">Right mounted</span>
                </label>
              </div>
            </div>
            <div
              v-for="extensionType in extensionTypes"
              :key="extensionType.id"
              :ref="slugifyString(extensionType.name)"
            >
              <ConfiguratorExtensionSection
                :extension-type="extensionType"
                :errors="errors"
                :product="product"
                @extension-choice="onExtensionChoice"
              />
            </div>
          </div>
        </div>
        <div ref="finish-order" class="px-5">
          <ConfiguratorForm
            :build-product-data="buildProductData"
            :consumer-types="consumerTypes"
            :dealership="dealership"
            :distributors="distributors"
            :errors="errors"
            :marketing-types="marketingTypes"
            :price="formattedPrice"
            :product="product"
          />
        </div>
      </div>
      <ConfiguratorBottomBar :price="formattedPrice" />
    </div>
  </ConfiguratorLayout>
</template>

<script>
import {LinkIcon} from '@heroicons/vue/outline'
import ConfiguratorLayout from 'ConfiguratorComponents/ConfiguratorLayout'
import ConfiguratorBottomBar from 'ConfiguratorComponents/ConfiguratorBottomBar'
import ConfiguratorExtensionSection from 'ConfiguratorComponents/ConfiguratorExtensionSection'
import ConfiguratorForm from 'ConfiguratorComponents/ConfiguratorForm'
import ConfiguratorFormSelect from 'ConfiguratorComponents/ConfiguratorFormSelect'
import ConfiguratorProductPreview from 'ConfiguratorComponents/ConfiguratorProductPreview'
import {formatPrice, slugifyString} from 'Shared/helpers.js'
import {camelCase} from 'lodash'

export default {
  components: {
    ConfiguratorBottomBar,
    ConfiguratorLayout,
    ConfiguratorExtensionSection,
    ConfiguratorForm,
    ConfiguratorFormSelect,
    ConfiguratorProductPreview,
    LinkIcon
  },
  props: {
    consumerTypes: Array,
    dealership: Object,
    distributors: Array,
    errors: Object,
    extensions: Array,
    marketingTypes: Array,
    product: Object
  },
  data() {
    return {
      calculatedPrice: +this.product.price,
      images: this.product.media,
      visibleSection: null,
      isScrolling: false,
      selectedModel: this.product.variants[0],
      selectedJoystick: 'right',
      selectedExtensions: {},
      showProductPrice: true,
      showExtensionsPrice: false,
      extensionTypes: this.extensions
    }
  },
  computed: {
    formattedPrice() {
      if (this.showProductPrice === false) {
        return ''
      }
      return this.formatPrice(
        (this.showExtensionsPrice ? this.calculatedPrice : +this.product.price).toFixed(2)
      )
    },
    buildProductData() {
      let selectedJoystick =
        this.selectedJoystick === 'right'
          ? {label: 'Right mounted', id: 'right'}
          : {label: 'Left mounted', id: 'left'}

      return {
        model: this.selectedModel,
        joystick: selectedJoystick,
        extensions: this.selectedExtensions
      }
    }
  },
  mounted() {
    window.addEventListener('scroll', this.handleScroll)
  },
  unmounted() {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    formatPrice,
    slugifyString,
    camelCase,
    scrollToSection(name) {
      const slug = this.slugifyString(name)
      const element = this.$refs[slug][0] ? this.$refs[slug][0] : this.$refs[slug]

      if (!element) {
        return
      }
      const top = slug === 'chair-model' ? element.offsetTop - 64 : element.offsetTop

      window.scrollTo(0, top)
      this.isScrolling = true
      setTimeout(() => {
        this.isScrolling = false
      }, 800)
    },
    onJoystickChange(e) {
      this.selectedJoystick = e.target.value
    },
    onExtensionChoice(extensionType) {
      const typeName = camelCase(extensionType.name)
      const extension = extensionType.selectedExtensions[0]

      if (extensionType.multiselect) {
        const index = this.selectedExtensions[typeName]
          ? this.selectedExtensions[typeName].findIndex(ext => ext.uuid === extension.uuid)
          : null

        if (index === null || index === -1) {
          this.selectedExtensions[typeName] = [
            ...(this.selectedExtensions[typeName] ? this.selectedExtensions[typeName] : []),
            extension
          ]
        } else if (index > -1) {
          this.selectedExtensions[typeName].splice(index, 1)
        }
      } else {
        this.selectedExtensions = {
          ...this.selectedExtensions,
          [typeName]: extension
        }
      }

      this.calculatePrice()

      if (extension?.preview?.[this.product.sku]) {
        this.changeImage(extension.preview[this.product.sku])
      }
    },
    calculatePrice() {
      if (!this.showProductPrice || !this.showExtensionsPrice) {
        return
      }
      let extensionsPrice = 0

      Object.entries(this.selectedExtensions).forEach(([type, value]) => {
        if (Array.isArray(value)) {
          value.forEach(extension => {
            if (extension && extension.price) {
              extensionsPrice += +extension.price
            }
          })
          return
        }
        if (value && value.price) {
          extensionsPrice += +value.price
        }
      })
      this.calculatedPrice = +this.product.price + +extensionsPrice
    },
    changeImage(image) {
      this.images[0] = image
    },
    handleScroll() {
      for (const ref in this.$refs) {
        const currentRef = Array.isArray(this.$refs[ref]) ? this.$refs[ref][0] : this.$refs[ref]
        const top = currentRef.getBoundingClientRect().top
        if (top > 20 && top < 68) {
          if (this.visibleSection !== ref && !this.isScrolling) {
            this.visibleSection = ref
          }
        }
      }
    }
  }
}
</script>
