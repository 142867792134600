import { createApp, h } from 'vue'
import { InertiaProgress } from '@inertiajs/progress'
import { createInertiaApp } from '@inertiajs/inertia-vue3'
import mitt from 'mitt'
import { VueReCaptcha } from 'vue-recaptcha-v3'

InertiaProgress.init()

createInertiaApp({
  resolve: name => {
    const [Module, View] = name.split('::')

    return require(`./../modules/${Module}/Resources/views/${View}.vue`)
  },
  title: title => title ? `${title} - Action Trackchair` : 'Action Trackchair',
  setup({ el, App, props, plugin }) {
    const app = createApp({ render: () => h(App, props) })
    // eslint-disable-next-line no-undef
    app.config.globalProperties.$route = route
    app.config.globalProperties.emitter = mitt()

    app.use(VueReCaptcha, { siteKey: props.initialPage.props.recaptcha.siteKey })

    return app.use(plugin).mount(el)
  }
})
