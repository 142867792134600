<template>
  <Panel data-testid="purchase-intents-panel">
    <InertiaHead :title="title" />
    <div class="flex justify-between">
      <PanelHeading :heading="title" />
    </div>
    <div class="pb-12">
      <div class="flex flex-row justify-between gap-3 w-full pb-5">
        <div class="flex items-center w-64">
          <VueMultiselect
            v-model="query.status"
            :custom-label="option => statusLabel(option)"
            :options="statuses.map(status => status.value)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Select status"
          >
            <template #singleLabel="props">
              <span>{{ statusLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('status')" />
            </template>
          </VueMultiselect>
        </div>
      </div>
      <table class="table">
        <thead class="table-head">
          <tr class="table-row">
            <th scope="col" class="table-header">
              <SortingColumn name="created_at" :sort="query.sort" @click="sortBy('created_at')">Date</SortingColumn>
            </th>
            <th scope="col" class="table-header">Client</th>
            <th scope="col" class="table-header">Reservation</th>
            <th scope="col" class="table-header">Dealership</th>
            <th scope="col" class="table-header">Status</th>
          </tr>
        </thead>
        <tbody class="table-body">
          <InertiaLink
            v-for="purchaseIntent in purchaseIntents.data"
            :key="purchaseIntent.uuid"
            :href="$route('purchase-intents.show', purchaseIntent.uuid)"
            as="tr"
            class="table-row cursor-pointer"
          >
            <td class="table-cell truncate">
              <div class="grid">
                <span>{{ formatDate(purchaseIntent.created_at) }}</span>
              </div>
            </td>
            <td class="table-cell truncate">
              <InertiaLink :href="$route('users.show', purchaseIntent.reservation.user?.uuid)" class="hover:underline">
                {{ purchaseIntent.reservation.user?.full_name ?? purchaseIntent.reservation.client_data.fullName }}
              </InertiaLink>
            </td>
            <td class="table-cell truncate">
              <InertiaLink :href="$route('reservations.show', purchaseIntent.reservation.uuid)" class="hover:underline">
                {{ purchaseIntent.reservation.organizationProduct.product.name }}
              </InertiaLink>
            </td>
            <td v-if="auth.can['purchase-intents.view.any']" class="table-cell truncate">
              <InertiaLink
                v-if="purchaseIntent.dealership"
                :href="$route('dealerships.show', purchaseIntent.dealership.uuid)"
                class="hover:underline"
              >
                {{ purchaseIntent.dealership.name }}
              </InertiaLink>
              <span v-else>—</span>
            </td>
            <td class="table-cell truncate">
              <StatusBadge
                :label="statuses.find(status => status.value === purchaseIntent.status).label"
                :value="purchaseIntent.status"
              />
            </td>
          </InertiaLink>

          <tr v-if="purchaseIntents.data.length === 0">
            <td
              class="table-cell text-center"
              :colspan="auth.can['purchase-intents.view.any'] ? '5' : '4'"
            >
              No purchase intents found.
            </td>
          </tr>
        </tbody>
      </table>
      <Pagination
        :links="purchaseIntents.links"
        class="flex items-center justify-center mt-5"
        :current-page="purchaseIntents.current_page"
        @change-page="key => query.page = key"
      />
    </div>
  </Panel>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink
} from '@inertiajs/inertia-vue3'
import {PencilIcon} from '@heroicons/vue/outline'
import {pickBy, startCase, throttle} from 'lodash'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import SortingColumn from 'Shared/SortingColumn'
import StatusBadge from 'Shared/Misc/StatusBadge.vue'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'

export default {
  components: {
    InertiaHead,
    InertiaLink,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading,
    PencilIcon,
    SortingColumn,
    StatusBadge,
    VueMultiselect
  },
  layout: Layout,
  props: {
    auth: Object,
    filters: Object,
    purchaseIntents: Object,
    statuses: Array
  },
  data() {
    return {
      title: 'Purchase Intents',
      query: {
        sort: {
          column: this.filters.sort?.column,
          order: this.filters.sort?.order
        },
        page: this.filters.page,
        status: this.filters.status
      }
    }
  },
  watch: {
    query: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('purchase-intents.index'), pickBy(this.query), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  methods: {
    formatDate(date) {
      return new Date(date).toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric'
      })
    },
    statusLabel(status) {
      return startCase(this.statuses.find(s => s.value === status).label)
    },
    sortBy(column) {
      this.query.sort = {
        column: column,
        order: this.query.sort.column === column ?
          (this.query.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
    },
    reset(filter) {
      this.query[filter] = null
    }
  }
}
</script>
s
