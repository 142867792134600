<template>
  <div
    class="max-w-8xl mx-auto px-4 sm:px-6 lg:px-8 bg-atc-white text-atc-text"
    :class="className"
    :data-testid="dataTestid"
  >
    <slot />
  </div>
</template>

<script>
export default {
  props: {
    className: String,
    dataTestid: {
      type: String,
      default: 'panel'
    }
  }
}
</script>
