<template>
  <div>
    <button
      class="flex items-center justify-between uppercase hover:text-atc-secondary duration-200 w-full bg-atc-accent mb-1 border-l-4 border-atc-success"
      :aria-expanded="isOpen"
      @click="toggleAccordion()"
    >
      <span class="pl-[1.65rem] py-3">{{ title }}</span>
      <Icon
        name="pointer"
        class="mx-3 w-3 transition-all duration-200 transform"
        :class="{ 'rotate-180': isOpen, 'rotate-0': !isOpen }"
      />
    </button>
    <div v-show="isOpen">
      <div class="flex pl-10 py-5 text-base bg-atc-text w-full mb-1 flex-col gap-2">
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script>
import Icon from 'Shared/Icon'

export default {
  components: {
    Icon
  },
  props: {
    title: {
      type: String,
      required: true
    }
  },
  data() {
    return {
      isOpen: false
    }
  },
  methods: {
    toggleAccordion() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>
