<template>
  <OrganizationLayout title="Organization details" :organization="organization">
    <form class="divide-y-2 divide-gray-200" data-testid="organizations-edit-form" @submit.prevent="submit">
      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading :heading="title" />
        <div class="grid grid-cols-2 gap-y-6 gap-x-4">
          <FormInput
            id="name"
            v-model="form.name"
            :error="form.errors.name ?? ''"
            class="form-input-edit col-span-2 sm:col-span-1"
            label="Name"
            required
            @change="generateSlug"
          />
          <FormInput
            id="slug"
            v-model="form.slug"
            :error="form.errors.slug ?? ''"
            class="form-input-edit col-span-2 sm:col-span-1"
            label="Slug"
            :disabled="!auth.can['organizations.update.any']"
            required
          />

          <FormInput
            id="phoneNumber"
            v-model="form.phone_number"
            :error="form.errors.phone_number ?? ''"
            class="form-input-edit col-span-2 sm:col-span-1"
            label="Phone number"
          />
          <FormInput
            id="email"
            v-model="form.email"
            :error="form.errors.email ?? ''"
            class="form-input-edit col-span-2 sm:col-span-1"
            label="Email"
          />

          <FormInput
            id="websiteUrl"
            v-model="form.website_url"
            :error="form.errors.website_url ?? ''"
            class="form-input-edit col-span-2 sm:col-span-1"
            label="Website URL"
            placeholder="https://actiontrackchair.com"
          />
          <FormInput
            id="bookingUrl"
            v-model="form.booking_url"
            :error="form.errors.booking_url ?? ''"
            class="form-input-edit col-span-2 sm:col-span-1"
            label="Booking URL"
            placeholder="https://actiontrackchair.com/booking"
            hint="Address of page on which the booking widget is placed."
          />
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Address" />
        <div class="grid grid-cols-2 gap-y-6 gap-x-4">
          <FormInput
            id="street"
            v-model="form.address.street"
            :error="form.errors['address.street'] ?? ''"
            class="form-input-edit col-span-2"
            label="Street"
          />

          <FormInput
            id="city"
            v-model="form.address.city"
            :error="form.errors['address.city'] ?? ''"
            class="form-input-edit col-span-2 sm:col-span-1"
            label="City"
          />
          <FormInput
            id="zipCode"
            v-model="form.address.zip_code"
            :error="form.errors['address.zip_code'] ?? ''"
            class="form-input-edit col-span-2 sm:col-span-1"
            label="Zip Code"/>

          <div class="col-span-2 sm:col-span-1">
            <div v-if="countries">
              <label class="form-label-edit block my-3">Country</label>
              <VueMultiselect
                v-model="form.address.country"
                :options="countries.map(country => country.iso2)"
                :custom-label="option => countries.find(country => country.iso2 === option).name"
                placeholder="Start typing to search"
                :class="`form-multiselect-edit ${form.errors['address.country'] ? 'form-multiselect-error' : ''}`"
                :show-labels="false"
              >
                <template #singleLabel="props">
                  <span>{{ countries.find(country => country.iso2 === props.option)?.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'address.country')" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors['address.country']" class="form-error">The country field is required.</div>
            </div>
            <FormInput v-else id="country" v-model="form.address.country" :error="form.errors['address.country'] ?? ''" class="form-input-edit" label="Country" />
          </div>
          <div class="col-span-2 sm:col-span-1">
            <div v-if="states && form.address.country === 'US'">
              <label class="form-label-edit block my-3">State</label>
              <VueMultiselect
                v-model="form.address.state"
                :options="states.map(state => state.iso2)"
                :custom-label="option => states.find(state => state.iso2 === option).name"
                placeholder="Start typing to search"
                :class="`form-multiselect-edit ${form.errors['address.state'] ? 'form-multiselect-error' : ''}`"
                :show-labels="false"
              >
                <template #singleLabel="props">
                  <span>{{ states.find(state => state.iso2 === props.option)?.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="e => clearSelection(e, 'address.state')" />
                </template>
              </VueMultiselect>
              <div v-if="form.errors['address.state']" class="form-error">The state field is required.</div>
            </div>
            <FormInput v-else id="state" v-model="form.address.state" :error="form.errors['address.state'] ?? ''" class="form-input-edit" label="State" />
          </div>
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 pb-7">
        <PanelHeading heading="Members" />
        <div class="col-span-2 pb-4">
          <label class="form-label-edit block my-3">Manager</label>
          <VueMultiselect
            v-model="form.managers"
            :options="managers"
            :multiple="true"
            placeholder="Start typing to search"
            :custom-label="customLabel"
            track-by="uuid"
            :class="`form-multiselect-edit ${form.errors.managers ? 'form-multiselect-error' : ''}`"
            :disabled="!auth.can['organizations.update.any']"
          >
            <template #option="{ option }">
              <img class="w-6 h-6 rounded-full border-1 border-[#e7e7e7] inline mr-3" :src="option.photo ?? '/assets/images/test-avatar.png'" :alt="`${option.details.display_name} Photo`" />
              {{ `${option.name} ${option.email ? `(${option.email})` : ''}` }}
            </template>
          </VueMultiselect>
        </div>

        <div class="col-span-2 pb-4">
          <label class="form-label-edit block my-3">Employees</label>
          <VueMultiselect
            v-model="form.employees"
            :options="employees"
            :multiple="true"
            :custom-label="customLabel"
            placeholder="Start typing to search"
            track-by="uuid"
            :close-on-select="false"
            class="form-multiselect-edit form-multiselect-edit--big"
          >
            <template #option="{ option }">
              <img class="w-6 h-6 rounded-full border-1 border-[#e7e7e7] inline mr-3" :src="option.photo ?? '/assets/images/test-avatar.png'" :alt="`${option.details.display_name} Photo`" />
              {{ `${option.name} ${option.email ? `(${option.email})` : ''}` }}
            </template>
          </VueMultiselect>
        </div>

        <FormInput
          id="newEmployeeEmail"
          v-model="form.new_employee_email"
          :error="form.errors.new_employee_email ?? ''"
          class="form-input-edit col-span-2"
          label="Add / Invite new employee"
          placeholder="example@email.com"
        />
      </div>

      <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
        <button
          v-if="organization && auth.can['organizations.delete']"
          type="button"
          class="form-button"
          data-testid="deleteButton"
          @click="showConfirmPopup"
        >
          Delete
        </button>
        <div class="ml-auto">
          <InertiaLink
            v-if="auth.can['organizations.index']"
            as="button"
            type="button"
            :href="$route('organizations.index')"
            class="form-button"
            data-testid="cancelButton"
          >
            Cancel
          </InertiaLink>
          <button
            type="submit"
            :disabled="form.processing"
            class="ml-3 form-submit-button"
            data-testid="submitButton"
          >
            Save
          </button>
        </div>
      </div>
    </form>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this organization? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </OrganizationLayout>
</template>

<script>
import { Link as InertiaLink, useForm } from '@inertiajs/inertia-vue3'
import {getCountries, getStates, slugifyString} from 'Shared/helpers'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import Layout from 'Shared/Layouts/Layout'
import PanelHeading from 'Shared/PanelHeading'
import OrganizationLayout from 'OrganizationsComponents/OrganizationLayout'

export default {
  components: {
    OrganizationLayout,
    InertiaLink,
    VueMultiselect,
    FormCheckbox,
    FormInput,
    ConfirmModal,
    PanelHeading
  },
  layout: Layout,
  props: {
    auth: Object,
    organization: Object,
    employees: Array,
    managers: Array
  },
  setup(props) {
    const form = useForm({
      _method: props.organization ? 'PUT' : 'POST',
      name: props.organization?.name ?? null,
      slug: props.organization?.slug ?? null,
      address: {
        street: props.organization?.address?.street ?? null,
        city: props.organization?.address?.city ?? null,
        zip_code: props.organization?.address?.zip_code ?? null,
        state: props.organization?.address?.state ?? null,
        country: props.organization?.address?.country ?? 'US'
      },
      phone_number: props.organization?.phone_number ?? null,
      email: props.organization?.email ?? null,
      website_url: props.organization?.website_url ?? null,
      booking_url: props.organization?.booking_url ?? null,
      managers: props.organization?.managers ?? [],
      employees: props.organization?.employees ?? [],
      new_employee_email: null
    })

    return { form }
  },
  data() {
    return {
      title: `${this.organization ? 'Edit' : 'Create'} organization`,
      isConfirmPopupOpen: false,
      countries: null,
      states: null
    }
  },
  mounted() {
    getCountries().then(response => this.countries = response.data)
    getStates().then(response => this.states = response.data)
  },
  methods: {
    getCountries,
    getStates,
    submit() {
      this.form
        .post(this.organization ? this.$route('organizations.update', this.organization.uuid) : this.$route('organizations.store'), {
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    deleteOrganization() {
      this.$inertia
        .post(this.$route('organizations.destroy', this.organization.uuid), {
          _method: 'DELETE'
        })
    },
    customLabel(options) {
      return options.name ?? options.email
    },
    clearSelection(e, selection) {
      e.preventDefault()
      if (selection.includes('.')) {
        selection = selection.split('.')
        this.form[selection[0]][selection[1]] = ''
        return
      }
      this.form[selection] = ''
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteOrganization()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    },
    generateSlug() {
      if (!this.form.name) {
        return
      }
      this.form.slug = slugifyString(this.form.name)
    }
  }
}
</script>
