<template>
  <Panel data-testid="settings-panel" class-name="sm:px-0 lg:px-0">
    <InertiaHead title="Settings" />

    <form autocomplete="off" class="divide-y-2 divide-gray-200" @submit.prevent="submit">
      <div class="px-4 sm:px-6 lg:px-8 pt-2 pb-7">
        <div class="flex justify-between">
          <PanelHeading heading="Payment Intents Setting" />
          <div class="py-8">
            <FormCheckbox
              id="paymentIntentsEnabled"
              v-model="form.payment_intents.enabled"
              label="Enable sending surveys to customers"
              wrapper-class="mb-0"
            />
          </div>
        </div>

        <div class="grid gap-y-6 gap-x-4">
          <div>
            <div
              v-for="(question, index) in form['paymentIntents.questions']"
              :key="`question-${index}`"
              class="mb-2 relative"
            >
              <FormLabel :text="`Question ${index + 1}`" class="text-sm mb-1 block font-medium text-atc-text" />
              <div class="flex">
                <FormInput
                  :id="`question-${index}`"
                  :ref="`question-${index}`"
                  v-model="question.value.label"
                  :error="form.errors[`paymentIntents.questions.${index}.value.label`]"
                  class="form-input-edit w-full form-repeater-input mr-2"
                />
                <VueMultiselect
                  v-model="question.value.type"
                  :options="questionTypes.map(type => type.value)"
                  :custom-label="option => questionLabel(option)"
                  :show-labels="false"
                  class="form-multiselect-edit required flex-[1_0_230px]"
                >
                  <template #singleLabel="props">
                    <span>{{ questionLabel(props.option) }}</span>
                  </template>
                </VueMultiselect>
                <button
                  type="button"
                  class="top-0 right-0 rounded-md px-2"
                  @click="deleteQuestion(index)"
                >
                  <XIcon class="w-5 ml-auto hover:text-red-500 duration-200" />
                </button>
              </div>
            </div>
            <button type="button" class="mt-3 table-controls-create-btn uppercase" @click="addQuestion">
              <Icon class="table-controls-create-icon" name="plus" /><span>Add question</span>
            </button>
          </div>
        </div>
      </div>

      <div class="px-4 sm:px-6 lg:px-8 py-7 flex w-full">
        <button type="submit" :disabled="form.processing" class="ml-auto form-submit-button">Save</button>
      </div>
    </form>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this user? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </Panel>
</template>

<script>
import {
  Head as InertiaHead,
  Link as InertiaLink,
  useForm
} from '@inertiajs/inertia-vue3'
import { startCase, toLower } from 'lodash'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import FormCheckbox from 'Shared/Form/FormCheckbox'
import FormInput from 'Shared/Form/FormInput'
import FormLabel from 'Shared/Form/FormLabel'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import Icon from 'Shared/Icon'
import {XIcon} from '@heroicons/vue/outline'

export default {
  components: {
    XIcon,
    ConfirmModal,
    InertiaHead,
    InertiaLink,
    FormCheckbox,
    FormInput,
    FormLabel,
    Panel,
    PanelHeading,
    VueMultiselect,
    Icon
  },
  layout: Layout,
  props: {
    questionTypes: Array,
    settings: Object
  },
  setup(props) {
    const form = useForm({
      _method: 'PUT',
      payment_intents: {
        enabled: props.settings.paymentIntents?.value?.enabled ?? false
      },
      ['paymentIntents.questions']: props.settings['paymentIntents.questions'] ?? []
    })

    return { form }
  },
  data() {
    return {
      isConfirmPopupOpen: false
    }
  },
  methods: {
    startCase,
    toLower,
    submit() {
      this.form
        .post(this.$route('settings.update'), {
          preserveState: (page) => Object.keys(page.props.errors).length
        })
    },
    addQuestion() {
      this.form['paymentIntents.questions'].push({
        value: {
          label: '',
          type: 'input'
        }
      })
    },
    deleteQuestion(index) {
      this.form['paymentIntents.questions'].splice(index, 1)
    },
    questionLabel(option) {
      return this.questionTypes.find(type => type.value === option).label
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      this.deleteUser()
    },
    showConfirmPopup() {
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
