<template>
  <NewsLayout :article="article" :auth="$props.auth">
    <Search v-model="query.filter.search" placeholder="Type to search attachments" />

    <main v-if="article" class="flex-1">
      <div class="xl:grid xl:grid-cols-3">
        <div class="px-6 lg:px-8 xl:col-span-2 xl:border-r xl:border-gray-200">
          <DocumentsSection :attachments="allAttachments" :record="article" entity="new" />
        </div>

        <aside class="px-6 lg:px-8 xl:col-span-1">
          <UploadSection
            description="PDF up to 20MB"
            :errors="errors"
            :files="files"
            :progress="progressPercentage"
            @update="uploadFiles"
          />
        </aside>
      </div>
    </main>
  </NewsLayout>
</template>

<script>
import {Inertia} from '@inertiajs/inertia'
import {throttle} from 'lodash'
import axios from 'axios'
import DocumentsSection from 'MediaComponents/DocumentsSection'
import FormInput from 'Shared/Form/FormInput'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import LoadingButton from 'Shared/Buttons/LoadingButton'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import TipTap from 'NewsComponents/TipTap'
import Search from 'Shared/Misc/Search'
import UploadSection from 'MediaComponents/UploadSection'
import NewsLayout from 'NewsComponents/NewsLayout'

export default {
  components: {
    DocumentsSection,
    FormInput,
    Icon,
    Layout,
    LoadingButton,
    Pagination,
    Panel,
    TipTap,
    Search,
    UploadSection,
    NewsLayout
  },
  props: {
    article: Object,
    attachments: Array,
    auth: Object
  },
  layout: Layout,
  data() {
    return {
      allAttachments: this.attachments,
      errors: [],
      files: [],
      progressEvents: [],
      query: {
        page: this.attachments.current_page,
        filter: {}
      }
    }
  },
  computed: {
    progressPercentage() {
      if (this.progressEvents.length === 0) {
        return null
      }

      let loaded = 0
      let total = 0

      this.progressEvents.forEach(event => {
        loaded += event.loaded
        total += event.total
      })

      if (loaded === total) {
        return null
      }

      return (loaded / total) * 100
    }
  },
  methods: {
    uploadFiles(files) {
      this.$page.props.flash.success = null
      this.$page.props.flash.error = null
      this.files = [...files]
      this.errors = []
      this.progressEvents = []

      Array.from(files).forEach((file, index) => {
        const formData = new FormData()

        formData.append('attachment', file)
        axios
          .post(this.$route(`news.documents.store`, this.article.uuid), formData, {
            onUploadProgress: progressEvent => {
              this.progressEvents[index] = progressEvent
            }
          })
          .then(({data}) => {
            this.allAttachments.data.unshift(data.attachment)
            this.$page.props.flash.success = data.message
          })
          .catch(({response}) => {
            if (response.data.errors) {
              this.errors.push(response.data.errors.attachment[0])
            } else {
              this.errors.push(response.data.message)
            }
          })
          .then(() => {
            this.files.shift()
          })
      })
    }
  },
  watch: {
    attachments(newAttachments) {
      this.allAttachments = newAttachments
    },
    query: {
      handler: throttle(function (newQuery) {
        Inertia.get(route(`news.documents.index`, this.article.uuid), newQuery, {
          preserveState: true,
          preserveScroll: true,
          replace: true
        })
      }, 150),
      deep: true
    }
  }
}
</script>
