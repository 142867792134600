<template>
  <div>
    <Head title="Dealerships" />

    <Panel>
      <div class="flex justify-between">
        <PanelHeading :tabs="tabs" :current="1" />
        <PanelActions
          :create="$route('dealerships.create')"
          :widget="false"
          create-permission="dealerships.create"
          text="dealership"
        />
      </div>
      <div class="pb-12">
        <div class="flex flex-row justify-between items-center w-full pb-5">
          <div class="flex items-center">
            <div class="min-w-[18rem]">
              <VueMultiselect
                v-model="distributor"
                :options="distributors"
                placeholder="Select distributor"
                label="name"
                track-by="uuid"
                :close-on-select="true"
                class="form-multiselect-edit"
                :show-labels="false"
                @select="value => buildQuery('distributor', value.uuid)"
              >
                <template #singleLabel="props">
                  <span>{{ props.option.name }}</span>
                  <i class="multiselect__tag-icon" @mousedown.stop="buildQuery('distributor')" />
                </template>
              </VueMultiselect>
            </div>
          </div>
          <FormInput v-model="term" class="form-input-edit ml-auto w-64" placeholder="Search" @keyup="e => buildQuery('term', e.target.value)" />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">
                <SortingColumn name="name" :sort="sort" @click="sortBy('name')">Name</SortingColumn>
              </th>
              <th scope="col" class="table-header">
                <SortingColumn name="address->street" :sort="sort" @click="sortBy('address->street')">Address</SortingColumn>
              </th>
              <th scope="col" class="table-header">Phone</th>
              <th scope="col" class="table-header">Email</th>
              <th scope="col" class="table-header w-16" />
            </tr>
          </thead>
          <tbody class="table-body">
            <Link v-for="dealership in dealerships.data" :key="dealership.uuid" as="tr" :href="$route('dealerships.edit', dealership.uuid)" class="table-row cursor-pointer">
              <td class="table-cell truncate">{{ dealership.name }}</td>
              <td class="table-cell truncate" @click.stop>
                <a :href="`https://maps.google.com/?q=${formatAddress(dealership.address)}`" target="_blank">
                  {{ formatAddress(dealership.address) }}
                </a>
              </td>
              <td class="table-cell truncate" @click.stop>
                <a :href="`tel:${dealership.phone_number}`">{{ dealership.phone_number ?? '—' }}</a>
              </td>
              <td class="table-cell truncate" @click.stop>
                <a :href="`mailto:${dealership.email}`">{{ dealership.email ?? '—' }}</a>
              </td>
              <td class="table-cell truncate">
                <span title="Edit"><PencilIcon class="w-4 ml-auto" /></span>
              </td>
            </Link>

            <tr v-if="dealerships.data.length === 0">
              <td class="table-cell text-center" colspan="5">No dealerships found.</td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="dealerships.links"
          class="flex items-center justify-center mt-5"
          :current-page="dealerships.current_page"
          @change-page="key => buildQuery('page', key)"
        />
      </div>
    </Panel>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import { formatAddress } from 'Shared/helpers'
import { PencilIcon } from '@heroicons/vue/outline'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormInput from 'Shared/Form/FormInput'
import SortingColumn from 'Shared/SortingColumn'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'

export default {
  components: {
    Head,
    Link,
    PencilIcon,
    VueMultiselect,
    FormInput,
    SortingColumn,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading
  },
  layout: Layout,
  props: {
    dealerships: Object,
    distributors: Array
  },
  data() {
    return {
      tabs: [
        { url: this.$route('dealers.index'), label: 'Dealers' },
        { url: this.$route('dealerships.index'), label: 'Dealerships' }
      ],
      sort: {
        column: '',
        order: ''
      },
      term: '',
      type: '',
      query: {}
    }
  },
  methods: {
    formatAddress,
    buildQuery(key, value = null) {
      if (key !== 'page') {
        delete this.query.page
      }
      if (value) {
        this.query = {
          ...this.query,
          [key]: value
        }
      } else {
        this[key] = ''
        delete this.query[key]
      }
      this.fetchData(this.query)
    },
    fetchData(query) {
      this.$inertia
        .get(this.$route('dealerships.index'), query, {
          preserveState: true,
          preserveScroll: true,
          replace: true
        })
    },
    sortBy(column) {
      this.sort = {
        column: column,
        order: this.sort.column === column ?
          (this.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
      this.query = {
        ...this.query,
        column: this.sort.column,
        order: this.sort.order
      }
      this.fetchData(this.query)
    }
  }
}
</script>
