<template>
  <div>
    <Head title="Tags" />
    <Panel>
      <div class="flex justify-between">
        <PanelHeading heading="Tags" />
      </div>
      <div class="pb-12">
        <div class="pb-5">
          <FormInput v-model="query.search" class="form-input-edit ml-auto w-64" placeholder="Search" />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">Name</th>
              <th scope="col" class="table-header">Items</th>
              <th scope="col" class="table-header w-36" />
            </tr>
          </thead>
          <tbody class="table-body">
            <tr v-for="(item, index) in tags.data" :key="index" class="table-row py-2">
              <td class="table-cell">
                <p class="... truncate">{{ item.name }}</p>
              </td>
              <td class="table-cell">
                <p>{{ item.articles_count }}</p>
              </td>
              <td class="table-cell">
                <button
                  type="button"
                  class="form-button"
                  @click="showConfirmPopup('delete', item)"
                >
                  Delete
                </button>
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="tags.links"
          class="mt-5 flex items-center justify-center"
          :current-page="tags.current_page"
          @change-page="key => query.page = key"
        />
      </div>
    </Panel>
    <ConfirmModal
      :open="isConfirmPopupOpen"
      text="Are you sure you want to delete this tag? This action cannot be undone."
      button-label="Delete"
      @on-close="closeConfirmPopup"
      @on-confirm="confirmAction"
    />
  </div>
</template>

<script>
import {Head, Link} from '@inertiajs/inertia-vue3'
import ConfirmModal from 'Shared/Dialogs/ConfirmModal'
import FormInput from 'Shared/Form/FormInput'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import moment from 'moment'
import {pickBy, throttle} from 'lodash'

export default {
  components: {
    ConfirmModal,
    FormInput,
    Head,
    Icon,
    Link,
    Pagination,
    Panel,
    PanelHeading,
    VueMultiselect
  },
  layout: Layout,
  props: {
    filters: Object,
    tags: Object
  },
  data() {
    return {
      query: {
        search: this.filters.search,
        page: this.filters.page
      },
      popupAction: '',
      deletedTag: null,
      isConfirmPopupOpen: false
    }
  },
  watch: {
    query: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('tags.index'), pickBy(this.query), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  methods: {
    delete(tag) {
      this.$inertia
        .post(this.$route('tags.destroy', tag.id), {
          _method: 'DELETE'
        })
    },
    getFormattedDate(date) {
      return moment(date).format('MM/DD/YYYY')
    },
    closeConfirmPopup() {
      this.isConfirmPopupOpen = false
      setTimeout(() => {
        document.querySelector('html').classList.remove('popup-open')
      }, 500)
    },
    confirmAction() {
      document.querySelector('html').classList.remove('popup-open')
      if (this.popupAction === 'delete' && this.deletedTag) {
        this.delete(this.deletedTag)
        this.isConfirmPopupOpen = false
      }
    },
    showConfirmPopup(action, item) {
      this.popupAction = action
      this.deletedTag = item
      document.querySelector('html').classList.add('popup-open')
      this.isConfirmPopupOpen = true
    }
  }
}
</script>
