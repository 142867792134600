<template>
  <div>
    <div id="dropdown" />
    <div class="md:flex md:flex-col">
      <div class="md:flex md:flex-col md:h-screen">
        <TopMenu
          :confirmation="confirmation"
          @on-confirm="$emit('onConfirm', $event)"
        />
        <div class="md:flex md:flex-grow md:overflow-hidden">
          <QuickLinks
            :confirmation="confirmation"
            class="quick-links relative ease-in-out transition-all duration-200 hidden flex-shrink-0 w-[19rem] pr-1 bg-atc-text overflow-hidden md:block"
            @on-confirm="$emit('onConfirm', $event)"
          />
          <div class="px-4 py-8 md:flex-1 md:p-12 md:overflow-y-auto" scroll-region>
            <FlashMessages />
            <slot />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import QuickLinks from 'CoreComponents/QuickLinks'
import TopMenu from 'Shared/Navigation/TopMenu'
import FlashMessages from 'Shared/FlashMessages'

export default {
  components: {
    FlashMessages,
    QuickLinks,
    TopMenu
  },
  props: {
    confirmation: Boolean
  },
  emits: ['onConfirm']
}
</script>
