<template>
  <FullCalendar ref="fullCalendar" :options="calendarOptions" class="w-full" />

  <ReservationDetailsDialog
    :assigned-colors="assignedColors"
    :open="dialogOpen"
    :reservation="selectedEvent"
    @close="dialogOpen = false"
  />
</template>

<script>
import {h, render} from 'vue'
import FullCalendar from '@fullcalendar/vue3'
import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import interactionPlugin from '@fullcalendar/interaction'
import {FilterIcon, PlusIcon} from 'Shared/Icons/Icons'
import ReservationDetailsDialog from 'BookingComponents/ReservationDetailsDialog'
import {addOpacityToRGB} from 'Shared/helpers'
import {isEmpty} from 'lodash'
import moment from 'moment'

export default {
  components: {
    FilterIcon,
    FullCalendar,
    PlusIcon,
    ReservationDetailsDialog
  },
  props: {
    reservations: Array,
    service: Object
  },
  data() {
    const route = (arg) => this.$route(arg)
    const inertia = this.$inertia
    const calendarState = inertia.restore('calendarState')
    const rawEvents = [
      ...this.reservations,
      ...(this.service?.days_off ?? [])
    ]
    const assignedColors = this.assignColors(rawEvents)

    return {
      assignedColors: assignedColors,
      calendarOptions: {
        allDaySlot: false,
        businessHours: this.mapBusinessHours(this.service?.availability),
        contentHeight: 'auto',
        customButtons: {
          create: {
            click: () => inertia.get(route('reservations.create'))
          },
          filters: {
            hint: 'Toggle filters',
            click: this.toggleFilters
          }
        },
        datesSet: this.handleDatesSet,
        dayMaxEventRows: 4,
        dayPopoverFormat: {
          weekday: 'short',
          month: 'numeric',
          day: '2-digit',
          omitCommas: true
        },
        eventClick: this.openEventDialog,
        eventContent: this.handleEventContent,
        eventDidMount: this.handleEventDidMount,
        events: this.setupEvents(rawEvents, assignedColors),
        firstDay: 1,
        headerToolbar: {
          left: this.$page.props.auth.can['reservations.create'] ? 'create,filters' : 'filters',
          center: 'prev,title,next',
          right: 'today timeGridDay,timeGridWeek,dayGridMonth'
        },
        initialDate: calendarState?.data ?? new Date(),
        initialView: calendarState?.view ?? 'timeGridWeek',
        moreLinkContent: this.handleMoreLinkContent,
        navLinks: true,
        nowIndicator: true,
        plugins: [dayGridPlugin, timeGridPlugin, interactionPlugin],
        slotDuration: '01:00:00',
        slotLabelFormat: {
          hour: '2-digit',
          minute: '2-digit',
          omitZeroMinute: false,
          meridiem: 'long'
        },
        slotMinTime: '05:00:00'
      },
      dialogOpen: false,
      filtersOpen: false,
      selectedEvent: null
    }
  },
  computed: {
    calendarApi() {
      return this.$refs.fullCalendar.getApi()
    },
    calendar() {
      return this.$refs.fullCalendar
    }
  },
  watch: {
    service(newValue) {
      this.calendarApi.setOption('businessHours', this.mapBusinessHours(newValue?.availability))
      this.calendarApi.removeAllEvents()
      this.calendarApi.addEventSource(
        this.setupEvents([
          ...this.reservations,
          ...(newValue?.days_off ?? [])
        ], this.assignedColors)
      )
    },
    reservations(newValue) {
      this.calendarApi.removeAllEvents()
      this.calendarApi.addEventSource(
        this.setupEvents([
          ...newValue,
          ...(this.service?.days_off ?? [])
        ], this.assignedColors)
      )
    }
  },
  mounted() {
    this.emitter.on('toggle-quicklinks', this.resizeCalendar)

    this.prependButtonIcons('.fc-create-button', PlusIcon, 'Create new reservation')
    this.prependButtonIcons('.fc-filters-button', FilterIcon, 'Filters')
  },
  methods: {
    handleEventDidMount({event, el}) {
      if (this.calendarApi.currentData.currentViewType === 'dayGridMonth' || event.allDay) {
        return
      }

      const slotHeight = parseInt(
        getComputedStyle(document.documentElement)
          .getPropertyValue('--fc-timegrid-slot-height')
      )
      const container = el.parentElement

      container.style.borderRadius = '3px'
      container.style.backgroundColor = addOpacityToRGB(el.style.backgroundColor, 0.75)

      if (event.extendedProps.bufferTimeBefore > 0) {
        const paddingHeight = slotHeight * event.extendedProps.bufferTimeBefore / 60
        el.style.marginTop = `${paddingHeight}px`
      }
      if (event.extendedProps.bufferTimeAfter > 0) {
        const paddingHeight = slotHeight * event.extendedProps.bufferTimeAfter / 60

        el.style.marginBottom = `${paddingHeight}px`
      }
    },
    handleEventContent({event, view}) {
      if (event.allDay) {
        return {
          html: `<div class="font-medium text-sm text-atc-black">
            ${event.title}
          </div>`
        }
      }

      const isApproved = event.extendedProps.status === 'approved'
      const isRejected = event.extendedProps.status === 'rejected'

      return {
        html: view.type === 'dayGridMonth' ?
          `<div
            class="w-2 h-2 rounded-full border-[1.5px]"
            style="background-color: ${event.backgroundColor}; border-color: ${event.borderColor};"
          ></div>
          <div class="overflow-hidden ml-1.5 leading-4">
            <span class="text-atc-accent ${isRejected ? 'text-xs font-normal line-through' : 'text-sm font-medium'}">
              ${event.title}
            </span>
            <span class="text-xs whitespace-nowrap overflow-hidden text-[#626263] ${isRejected ? 'line-through' : ''}">
              ${this.formatActualTime(event, false)}
            </span>
          </div>` :
          `<div class="p-2 overflow-hidden">
            <div class="mb-1 ${isRejected ? 'text-xs font-normal line-through' : 'text-sm font-medium'} ${isApproved ? 'text-atc-white' : 'text-atc-accent'}">
               ${event.title}
            </div>
            <div class="text-xs whitespace-nowrap overflow-hidden ${isApproved ? 'text-atc-white' : 'text-[#626263]'} ${isRejected ? 'line-through' : ''}">
              ${this.formatActualTime(event, true)}
            </div>
          </div>`
      }
    },
    handleDatesSet(dateInfo) {
      this.$inertia.remember({
        data: dateInfo.start,
        view: dateInfo.view.type
      }, 'calendarState')
    },
    handleMoreLinkContent({text}) {
      return text.substring(1)
    },
    formatActualTime(event, showEnd) {
      const formatOptions = {
        hour: 'numeric',
        minute: '2-digit'
      }
      const start = moment(event.start)
        .add(event.extendedProps.bufferTimeBefore, 'minutes')
        .toDate()
        .toLocaleTimeString('en-US', formatOptions)
      const end = moment(event.end)
        .subtract(event.extendedProps.bufferTimeAfter, 'minutes')
        .toDate()
        .toLocaleTimeString('en-US', formatOptions)
      return showEnd ? `${start} - ${end}` : start
    },
    toggleFilters() {
      const filtersEl = this.$parent.$parent.$refs.calendarFiltersRef

      if (filtersEl.style.maxHeight) {
        filtersEl.style.maxHeight = null
        filtersEl.classList.add('overflow-hidden')
      } else {
        filtersEl.style.maxHeight = '46px'
        setTimeout(() => {
          filtersEl.classList.remove('overflow-hidden')
        }, 200)
      }
    },
    openEventDialog({event}) {
      this.selectedEvent = event
      this.dialogOpen = true
    },
    assignColors(events) {
      const modelColors = [
        {accepted: '#8AA66A', pending: '#DCE8D1'},
        {accepted: '#DB985B', pending: '#F0DBC7'},
        {accepted: '#47A4A4', pending: '#D5EBEB'},
        {accepted: '#576344', pending: '#D1D8C5'},
        {accepted: '#999181', pending: '#DCD9D2'},
        {accepted: '#D5A558', pending: '#F1E1C9'}
      ]
      const assignedColors = {}

      events.forEach(event => {
        if (!event.uuid) {
          return
        }

        const productUuid = event.organizationProduct.product.uuid

        if (!Object.prototype.hasOwnProperty.call(assignedColors, productUuid)) {
          const colorIndex = Object.keys(assignedColors).length % modelColors.length
          assignedColors[productUuid] = modelColors[colorIndex]
        }
      })

      return assignedColors
    },
    setupEvents(events, assignedColors) {
      return events.map(event => {
        if (!event.uuid) {
          return this.setupDaysOff(event)
        }

        const productUuid = event.organizationProduct.product.uuid

        const backgroundColor = event.status === 'approved'
          ? assignedColors[productUuid].accepted
          : assignedColors[productUuid].pending
        const borderColor = assignedColors[productUuid].accepted

        return this.setupReservation(event, backgroundColor, borderColor)
      })
    },
    setupDaysOff(event) {
      return {
        title: event.name,
        start: event.date['0'],
        end: event.date['1'],
        display: 'background',
        allDay: true
      }
    },
    setupReservation(event, backgroundColor, borderColor) {
      return {
        backgroundColor: backgroundColor,
        borderColor: borderColor,
        clientData: event.client_data,
        end: moment(event.start_at)
          .add(event.organizationProduct.slot_duration_minutes, 'minutes')
          .add(event.organizationProduct.buffer_time_after, 'minutes')
          .format(),
        extendedProps: {
          bufferTimeAfter: event.organizationProduct.buffer_time_after,
          bufferTimeBefore: event.organizationProduct.buffer_time_before,
          productUuid: event.organizationProduct.product.uuid,
          status: event.status
        },
        id: event.uuid,
        start: moment(event.start_at)
          .subtract(event.organizationProduct.buffer_time_before, 'minutes')
          .format(),
        title: this.$page.props.auth.can['reservations.view.any']
        || this.isAssignedOrganizationMember(event)
        || this.isAssignedClient(event)
          ? event.organizationProduct.product.sku : 'Busy',
        uuid: event.uuid
      }
    },
    mapBusinessHours(hours) {
      if (isEmpty(hours)) {
        return []
      }

      const daysOrder = [6, 0, 1, 2, 3, 4, 5]

      return daysOrder.flatMap((dayOrder, index) => {
        const dayObject = hours[dayOrder]

        return dayObject.periods.map(periodObject => ({
          daysOfWeek: [index],
          startTime: periodObject[0],
          endTime: periodObject[1],
        }))
      })
    },
    prependButtonIcons(buttonSelector, iconComponent, label) {
      const button = document.querySelector(buttonSelector)
      if (!button) {
        return
      }
      const component = h(iconComponent)

      button.innerHTML = null
      render(component, button)
      button.append(label)
    },
    isAssignedOrganizationMember(event) {
      return this.$page.props.auth.can['reservations.view'] &&
        this.$page.props.auth.user?.organization?.uuid === event.organizationProduct.organization.uuid
    },
    isAssignedClient(event) {
      return this.$page.props.auth.can['reservations.view'] &&
        this.$page.props.auth.user?.uuid === event?.user?.uuid
    },
    resizeCalendar() {
      setTimeout(() => {
        this.calendarApi.updateSize()
      }, 200)
    }
  }
}
</script>
