<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="fixed z-10 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
          <DialogOverlay class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" @click="$emit('on-close')" />
        </TransitionChild>
        <!-- This element is to trick the browser into centering the modal contents. -->
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 sm:scale-95" enter-to="opacity-100 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100  sm:scale-100" leave-to="opacity-0  sm:scale-95">
          <div class="relative inline-block align-bottom bg-white px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm md:max-w-xl lg:max-w-3xl xl:max-w-5xl lg:w-full sm:p-6 lg:p-12">
            <XIcon class="absolute top-2 right-2 w-5 cursor-pointer text-atc-text" @click="$emit('on-close')" />
            <div class="flex flex-wrap">
              <div v-if="data.media" class="w-full lg:w-[40%]">
                <ConfiguratorDetailsSlider :images="data.media" />
              </div>
              <div class="w-full: lg:w-[60%] flex-1 lg:pl-8 text-atc-text">
                <h2 class="uppercase text-xl lg:text-3xl mb-5 font-heading">{{ data.name }}</h2>
                <p class="font-light text-[15px] leading-7">{{ data.description }}</p>
                <h3 class="mt-5 mb-3 uppercase font-semibold text-md">Additional information</h3>
                <p class="font-light text-base mb-2"><span class="font-semibold">SKU:</span> {{ data.sku }}</p>
                <p v-if="data.variants" class="font-light text-base mb-2"><span class="font-semibold">Variants:</span> <span v-for="(variant, $i) of data.variants" :key="$i">{{ variant }} <span v-if="$i != data.variants.length - 1">, </span></span></p>
              </div>
            </div>
          </div>
        </TransitionChild>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import {
  Dialog,
  DialogOverlay,
  DialogTitle,
  TransitionChild,
  TransitionRoot
} from '@headlessui/vue'
import {
  CheckIcon
} from '@heroicons/vue/outline'
import {
  XIcon
} from '@heroicons/vue/outline'
import ConfiguratorDetailsSlider from 'ConfiguratorComponents/ConfiguratorDetailsSlider'

export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    CheckIcon,
    XIcon,
    ConfiguratorDetailsSlider
  },
  props: {
    open: Boolean,
    data: Object
  }
}
</script>
