<template>
  <div>
    <Head title="Organization Products" />

    <Panel data-testid="organizations-products-panel">
      <div class="flex justify-between">
        <PanelHeading v-if="auth.can['organizations.index']" :tabs="tabs" :current="1" />
        <PanelHeading v-else heading="Products" />
        <PanelActions
          :create="$route('organization-products.create')"
          :widget="false"
          create-permission="organization-products.create"
          text="product"
        />
      </div>
      <div class="pb-12">
        <div class="flex flex-row justify-between gap-3 w-full pb-5">
          <div class="flex items-center w-64">
            <VueMultiselect
              v-model="query.product"
              :options="products.map(product => product.uuid)"
              placeholder="Select product"
              :custom-label="option => products.find(product => product.uuid === option).name"
              class="form-multiselect-edit"
              :show-labels="false"
            >
              <template #singleLabel="props">
                <span>{{ products.find(product => product.uuid === props.option).name }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="reset('product')" />
              </template>
            </VueMultiselect>
          </div>
          <div v-if="auth.can['organization-products.view.any']" class="flex items-center w-64">
            <VueMultiselect
              v-model="query.organization"
              :options="organizations.map(organization => organization.uuid)"
              placeholder="Select organization"
              :custom-label="option => organizations.find(organization => organization.uuid === option).name"
              class="form-multiselect-edit"
              :show-labels="false"
            >
              <template #singleLabel="props">
                <span>{{ organizations.find(organization => organization.uuid === props.option).name }}</span>
                <i class="multiselect__tag-icon" @mousedown.stop="reset('organization')" />
              </template>
            </VueMultiselect>
          </div>
          <FormInput id="search" v-model="query.search" class="form-input-edit ml-auto w-64" placeholder="Search" />
        </div>
        <table class="table">
          <thead class="table-head">
            <tr class="table-row">
              <th scope="col" class="table-header">
                <SortingColumn name="serial" :sort="query.sort" @click="sortBy('serial')">Serial #</SortingColumn>
              </th>
              <th scope="col" class="table-header">Product</th>
              <th scope="col" class="table-header">
                <SortingColumn name="details->variant" :sort="query.sort" @click="sortBy('details->variant')">Model</SortingColumn>
              </th>
              <th scope="col" class="table-header">Preview</th>
              <th scope="col" class="table-header">
                <SortingColumn name="extensions_count" :sort="query.sort" @click="sortBy('extensions_count')">Extensions</SortingColumn>
              </th>
              <th v-if="auth.can['organization-products.view.any']" scope="col" class="table-header">
                <SortingColumn name="organization" :sort="query.sort" @click="sortBy('organization')">Organization</SortingColumn>
              </th>
              <th scope="col" class="table-header w-12" />
              <th scope="col" class="table-header w-12" />
            </tr>
          </thead>
          <tbody class="table-body">
            <Link
              v-for="product in organizationsProducts.data"
              :key="product.uuid"
              as="tr"
              :href="$route('organization-products.show', product.uuid)"
              class="table-row cursor-pointer"
            >
              <td class="table-cell truncate">{{ product.serial }}</td>
              <td class="table-cell truncate" :title="product.product.name">{{ product.product.name }}</td>
              <td class="table-cell truncate">{{ product.details.variant }}</td>
              <td class="table-cell">
                <div v-if="product.product?.media[0]" class="flex">
                  <img class="block -my-2 mr-2 w-9 h-9 object-cover" :src="product.product?.media[0]" alt="Product preview" />
                </div>
              </td>
              <td class="table-cell truncate">{{ product.extensions_count }}</td>
              <Link
                v-if="auth.can['organization-products.view.any']"
                :href="$route('organizations.show', product.organization.uuid)"
                as="td"
                class="table-cell truncate"
                title="Click to view organization"
              >
                {{ product.organization.name }}
              </Link>
              <td class="table-cell truncate">
                <span title="Edit"><PencilIcon class="w-4 ml-auto" /></span>
              </td>
              <Link as="td" :href="$route('organization-products.rental.edit', product.uuid)" class="table-cell truncate">
                <span title="Rental"><CalendarIcon class="w-4 ml-auto" /></span>
              </Link>
            </Link>

            <tr v-if="organizationsProducts.data.length === 0">
              <td
                class="table-cell text-center"
                :colspan="auth.can['organization-products.view.any'] ? '8' : '7'"
              >
                No products found.
              </td>
            </tr>
          </tbody>
        </table>
        <Pagination
          :links="organizationsProducts.links"
          class="flex items-center justify-center mt-5"
          :current-page="organizationsProducts.current_page"
          @change-page="key => query.page = key"
        />
      </div>
    </Panel>
  </div>
</template>

<script>
import { Head, Link } from '@inertiajs/inertia-vue3'
import { PencilIcon, CalendarIcon } from '@heroicons/vue/outline'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import FormInput from 'Shared/Form/FormInput'
import SortingColumn from 'Shared/SortingColumn'
import Layout from 'Shared/Layouts/Layout'
import Pagination from 'Shared/Pagination'
import Panel from 'Shared/Panel'
import PanelActions from 'Shared/PanelActions'
import PanelHeading from 'Shared/PanelHeading'
import {pickBy, throttle} from 'lodash'

export default {
  components: {
    Head,
    Link,
    CalendarIcon,
    VueMultiselect,
    PencilIcon,
    FormInput,
    SortingColumn,
    Pagination,
    Panel,
    PanelActions,
    PanelHeading
  },
  layout: Layout,
  props: {
    auth: Object,
    filters: Object,
    products: Array,
    organizations: Array,
    organizationsProducts: Object
  },
  data() {
    return {
      tabs: [
        { url: this.$route('organizations.index'), label: 'Organizations' },
        { url: this.$route('organization-products.index'), label: 'Rental Equipment' }
      ],
      query: {
        search: this.filters.search,
        sort: {
          column: this.filters.sort?.column,
          order: this.filters.sort?.order
        },
        page: this.filters.page,
        product: this.filters.product,
        organization: this.filters.organization
      }
    }
  },
  watch: {
    query: {
      deep: true,
      handler: throttle(function () {
        this.$inertia
          .get(this.$route('organization-products.index'), pickBy(this.query), {
            preserveState: true,
            preserveScroll: true
          })
      }, 150)
    }
  },
  methods: {
    sortBy(column) {
      this.query.sort = {
        column: column,
        order: this.query.sort.column === column ?
          (this.query.sort.order === 'asc' ? 'desc' : 'asc') : 'desc'
      }
    },
    reset(filter) {
      this.query[filter] = null
    }
  }
}
</script>
