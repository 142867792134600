<template>
  <VueFinalModal
    v-slot="{ params, close }"
    v-bind="$attrs"
    classes="vf-modal"
    content-class="vf-modal-content"
  >
    <div class="flex justify-between">
      <PanelHeading v-if="title" :heading="title" />
      <button class="py-6 cursor-pointer">
        <XIcon class="w-6 ml-auto hover:text-red-700 duration-200" @click="$emit('close', close)" />
      </button>
    </div>
    <div class="flex-grow">
      <slot :params="params" />
    </div>
    <button class="absolute top-0 right-0 mt-2 mr-2" @click="close" />
  </VueFinalModal>
</template>

<script>
import {VueFinalModal} from 'vue-final-modal'
import {XIcon} from '@heroicons/vue/outline'
import PanelHeading from 'Shared/PanelHeading'

export default {
  name: 'VTailwindModal',
  components: {
    VueFinalModal,
    XIcon,
    PanelHeading
  },
  inheritAttrs: false,
  props: {
    title: String
  },
  emits: ['close']
}
</script>
