<template>
  <div ref="document" :html-to-pdf-options="options" class="w-full flex text-atc-text text-lg hidden">
    <div :class="[(accessories ? 'w-2/3' : 'w-full'), 'border-1', 'm-2']">
      <div class="py-1 mx-3 h-96 border-b-1 space-y-4">
        <div v-for="(row, rowKey) in productTable" :key="`product-table-row-${rowKey}`">
          <span v-for="(info, infoKey) in row" :key="`product-table-info-${infoKey}`">
            <h6 class="font-medium" v-if="info.title">{{ info.title }}</h6>
            <template v-else>
            {{ info.label }}: <span class="font-medium mr-3 capitalize">{{ info.value }}</span>
          </template>
          </span>
        </div>
      </div>
      <div v-if="record.product?.description" class="py-1 mx-3">
        <div class="mb-3">Dealer Notes:</div>
        <p class="leading-10 h-32 overflow-hidden">
          <div v-if="record.product?.description">{{ record.product?.description }}</div>
          <div v-else>
            <div class="h-10 border-b-1"></div>
            <div class="h-10 border-b-1"></div>
            <div class="h-10 border-b-1"></div>
          </div>
        </p>
      </div>
      <div class="py-1 mx-3 mb-5">
        <div class="mb-3">Factory Notes:</div>
        <div class="leading-10 h-32 overflow-hidden">
          <div class="h-10 border-b-1"></div>
          <div class="h-10 border-b-1"></div>
          <div class="h-10 border-b-1"></div>
        </div>
      </div>
    </div>
    <div v-if="accessories" class="w-1/3 border-1 m-2">
      <div class="mx-3">
        <div class="py-1 border-b-1 mb-3">Accessories:</div>
        <div
          v-for="(item, key) in accessories"
          :key="`accessories-${key}`"
          class="pl-5 font-medium"
        >{{key + 1}}. ({{item.sku}}) {{item.name}}</div>
      </div>
    </div>
  </div>
</template>

<script>
import html2pdf from 'html2pdf.js'

export default {
  name: 'PartsList',
  props: {
    record: Object,
    entity: String,
    data: Object
  },
  data(props) {
    return {
      productTable: props.data?.productTable,
      accessories: props.data?.accessories ?? null,
      options: {
        margin: [15, 15],
        filename: `${props.entity ? props.entity : 'order'}-print.pdf`,
        image: {
          type: 'jpeg',
          quality: 0.98
        },
        html2canvas: {
          scale: 1,
          letterRendering: true
        },
        jsPDF: {
          unit: 'pt',
          format: 'letter',
          orientation: 'landscape'
        }
      }
    }
  },
  methods: {
    async exportToPDF() {
      const source = document.querySelector('body')
      const loader = document.querySelector('.fullpage-loader')

      source.classList.add('print')
      loader.classList.remove('hidden')
      this.$refs.document.classList.remove('hidden')
      await html2pdf().set(this.options)
        .from(this.$refs.document)
        .toPdf()
        .get('pdf')
        .then(pdfObj => {
          pdfObj.autoPrint()
          window.open(pdfObj.output('bloburl'), '_blank')
          source.classList.remove('print')
        })
        .then(() => {
          loader.classList.add('hidden')
          this.$refs.document.classList.add('hidden')
        })
    }
  }
}
</script>