<template>
  <div>
    <InertiaHead title="Statistics" />

    <Panel>
      <div class="flex flex-wrap justify-between">
        <PanelHeading
          v-if="auth.can['statistics.orders.index'] && auth.can['statistics.booking.index']"
          :tabs="tabs"
          :current="1"
        />
        <PanelHeading v-else heading="Statistics" />
        <div class="flex flex-row items-center py-6 font-heading uppercase">
          <a class="table-controls-create-btn" @click="exportToExcel">
            <Icon class="table-controls-create-icon" name="plus" />
            Export
          </a>
        </div>
      </div>
      <div class="filters pb-5">
        <div class="form-input-edit filters-datepicker">
          <Datepicker
            v-model="form.date"
            model-type="dd.mm.yyyy"
            placeholder="Last 7 days"
            range
            :preset-ranges="presetRanges"
            :auto-apply="true"
            :enable-time-picker="false"
          />
        </div>
        <div v-if="auth.role.name === 'admin'" class="form-input-edit">
          <VueMultiselect
            v-model="form.organization"
            :custom-label="option => organizationLabel(option)"
            :options="organizations.map(organization => organization.uuid)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Organization"
          >
            <template #singleLabel="props">
              <span>{{ organizationLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('organization')" />
            </template>
          </VueMultiselect>
        </div>
        <div v-if="auth.role.name === 'admin'" class="form-input-edit">
          <VueMultiselect
            v-model="form.country"
            :custom-label="option => countryLabel(option)"
            :options="countries.map(country => country.iso2)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Country"
          >
            <template #singleLabel="props">
              <span>{{ countryLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="resetCountry()" />
            </template>
          </VueMultiselect>
        </div>
        <div v-if="form.country === 'US'" class="form-input-edit">
          <VueMultiselect
            v-model="form.state"
            :custom-label="option => stateLabel(option)"
            :options="states.map(state => state.iso2)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="State"
          >
            <template #singleLabel="props">
              <span>{{ stateLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('state')" />
            </template>
          </VueMultiselect>
        </div>
        <div class="form-input-edit">
          <VueMultiselect
            v-model="form.organizationProduct"
            :custom-label="option => productLabel(option)"
            :options="organizationProducts.map(product => product.uuid)"
            :show-labels="false"
            class="form-multiselect-edit"
            placeholder="Trackchair model"
          >
            <template #singleLabel="props">
              <span>{{ productLabel(props.option) }}</span>
              <i class="multiselect__tag-icon" @mousedown.stop="reset('organizationProduct')" />
            </template>
          </VueMultiselect>
        </div>
      </div>
    </Panel>
    <div class="grid grid-cols-1 gap-4 pt-4 sm:grid-cols-2 xl:grid-cols-3">
      <Chart
        v-for="(metric, key) in statistics"
        :key="key"
        :days="days"
        :data="metric"
        class="col-span-1"
        :class="{'chart-expanded': expanded === key}"
        :is-expanded="expanded === key"
        @expand-chart="expandChart(key)"
      />
    </div>
  </div>
</template>

<script>
import {Head as InertiaHead} from '@inertiajs/inertia-vue3'
import Datepicker from 'vue3-date-time-picker'
import 'vue3-date-time-picker/dist/main.css'
import VueMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.css'
import * as XLSX from 'xlsx/xlsx.mjs'
import Chart from 'Shared/Charts/Chart'
import Icon from 'Shared/Icon'
import Layout from 'Shared/Layouts/Layout'
import Panel from 'Shared/Panel'
import PanelHeading from 'Shared/PanelHeading'
import {getCountries, getStates} from 'Shared/helpers'
import {pickBy, throttle} from 'lodash'
import {endOfMonth, startOfMonth, subMonths} from 'date-fns'
import {secondsToDays} from 'Shared/helpers'

export default {
  components: {
    InertiaHead,
    Datepicker,
    VueMultiselect,
    Chart,
    Icon,
    Panel,
    PanelHeading
  },
  layout: Layout,
  props: {
    auth: Object,
    filters: Object,
    days: Array,
    statistics: Array,
    organizations: Array,
    organizationProducts: Array
  },
  setup() {
    return {
      presetRanges: [
        {label: 'Today', range: [new Date(), new Date()]},
        {
          label: 'Last 7 days',
          range: [new Date(new Date().setDate(new Date().getDate() - 6)), new Date()]
        },
        {label: 'This month', range: [startOfMonth(new Date()), endOfMonth(new Date())]},
        {
          label: 'Last month',
          range: [startOfMonth(subMonths(new Date(), 1)), endOfMonth(subMonths(new Date(), 1))]
        }
      ]
    }
  },
  data() {
    return {
      form: {
        date: this.filters.date,
        organization: this.filters.organization,
        country: this.filters.country,
        state: this.filters.state,
        organizationProduct: this.filters.organizationProduct
      },
      countries: [],
      states: [],
      expanded: null,
      tabs: [
        {
          label: 'Orders statistics',
          url: this.$route('statistics.orders.index')
        },
        {
          label: 'Booking statistics',
          url: this.$route('statistics.booking.index')
        }
      ]
    }
  },
  watch: {
    form: {
      deep: true,
      handler: throttle(function () {
        this.$inertia.get(this.$route('statistics.booking.index'), pickBy(this.form), {
          preserveState: true,
          preserveScroll: true
        })
      }, 150)
    }
  },
  mounted() {
    getCountries().then(({data}) => { this.countries = data })
    getStates().then(({data}) => { this.states = data })
  },
  methods: {
    secondsToDays,
    resetCountry() {
      this.form.country = null
      this.form.state = null
    },
    reset(filter) {
      this.form[filter] = null
    },
    expandChart(key) {
      this.expanded = this.expanded === key ? null : key
    },
    exportToExcel() {
      const filename = 'ATC Reports'
      const workbook = XLSX.utils.book_new()
      const rows = []

      this.statistics.forEach((stat, index) => {
        const metrics = stat?.allMetrics ?? stat?.datasets[0]?.data

        rows[0] = [
          ...(rows[0] ?? []),
          stat.name,
          stat.total ? this.formatData(stat, this.formatDecimals(stat, stat.total)) : null,
          null
        ]

        metrics.map((element, i) => {
          if (!rows[i + 1]) {
            rows[i + 1] = []
          }

          rows[i + 1][index * 3] = stat.allLabels?.[i] ?? this.days?.[i]
          rows[i + 1][index * 3 + 1] = this.formatData(stat, this.formatDecimals(stat, element))
          rows[i + 1][index * 3 + 2] = null
        })
      })

      const worksheet = XLSX.utils.json_to_sheet(rows, {
        skipHeader: true
      })

      if (!worksheet['!cols']) worksheet['!cols'] = []

      rows[0].forEach((column, index) => {
        if (!column) return 10

        const max_width = rows[0][index]
          ? rows.reduce((w, r) => {
            if (!r[index]) return w

            return Math.max(w, r[index]?.toString().length)
          }, 10)
          : 10

        worksheet['!cols'][index] = {wch: max_width}
      })

      XLSX.utils.book_append_sheet(workbook, worksheet, filename)
      XLSX.writeFile(workbook, `${filename}.xlsx`)
    },
    formatDecimals(data, value) {
      if (data.showPercentage) {
        value = value * 100
      }
      return data.showDecimals ? parseFloat(value).toFixed(2) : parseInt(value)
    },
    formatData(data, value) {
      if (data.isTime) {
        value = this.secondsToDays(value)
      }
      if (data.showPercentage) {
        value += '%'
      }
      if (data.showCurrency) {
        value = '$' + value
      }

      return value
    },
    organizationLabel(option) {
      return this.organizations.find(organization => organization.uuid === option)?.name
    },
    countryLabel(option) {
      return this.countries.find(country => country.iso2 === option)?.name
    },
    stateLabel(option) {
      return this.states.find(state => state.iso2 === option)?.name
    },
    productLabel(option) {
      const organizationProduct = this.organizationProducts.find(product => product.uuid === option)
      const name = `${organizationProduct?.product.name} - ${organizationProduct?.serial}`
      return name + (this.auth.can['organizations.view.any'] ? ` (${organizationProduct?.organization.name})` : '')
    }
  }
}
</script>
